





import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

@Component({ components: {} })
export default class TooltipSelect extends BaseVue {
  @Prop({ required: true })
  public readonly tip!: string;

  private isOpen = false;

  handleClick() {
    this.setTip(this.tip);
    this.setOpen();
  }

  setTip(tip: string) {
    this.$store.dispatch('setTip', tip);
  }

  setOpen() {
    this.isOpen = !this.isOpen;
    this.$store.dispatch('setOpen', this.isOpen);
  }

  setCursor(cursorStyle: string) {
    (this.$el as HTMLElement).style.cursor = cursorStyle;
  }

  mounted() {
    this.setTip(this.tip);
  }
}
