













import Vue from 'vue';
import Component from 'vue-class-component';
const ResizeObserver = window.ResizeObserver || require('resize-observer-polyfill');

@Component({
  props: {
    progress: {
      type: Number,
      required: false,
    },
  },
})
export default class UiLoading extends Vue {
  public progress!: number | undefined;

  public mounted() {
    new ResizeObserver(this.onResize).observe(this.$el);
    this.onResize();
  }

  public onResize() {
    if ((this.$el as HTMLDivElement).offsetHeight <= 24) {
      this.$el.classList.add('small-mode');
    } else {
      this.$el.classList.remove('small-mode');
    }
  }
}
