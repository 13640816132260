



















import { v4 as uuidv4 } from 'uuid';
import Vue, { ComponentOptions } from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';

import UiFormLabel from './UiFormLabel.vue';

// This hoists all the props from the DatePicker component up to this component
// See here for prop list https://www.npmjs.com/package/vue2-datepicker
const DatePickerProps = Vue.extend({
  props: (DatePicker as ComponentOptions<never>).props,
});

@Component({ components: { UiFormLabel, DatePicker } })
export default class UiDatePicker2 extends DatePickerProps {
  /** Use via `v-model`. The value of this form element, can be either a string or a JS Date object. */
  @Prop({ default: '' })
  public readonly value!: string | string[];

  /** Optional label to display above this form input element */
  @Prop()
  public readonly label?: string;

  /** Optional description to show below the label for this form input element */
  @Prop()
  public readonly description?: string;

  @Prop({ default: true })
  public readonly isValid?: boolean;

  @Prop()
  public readonly validationMessage?: string;

  @Prop({ default: false })
  public readonly validated?: boolean;

  @Prop({ default: false })
  public readonly range?: boolean;

  @Prop({ default: 'YYYY-MM-DD' })
  public readonly valueType?: string;

  @Prop({ default: false })
  public readonly minimalSelector?: boolean;

  public isDirty = false;

  public instanceId = uuidv4();

  public localeDateFormatter = {
    stringify: (date: Date) => {
      return date ? date.toLocaleDateString() : '';
    },
    parse: (value: string) => {
      return value ? new Date(value) : null;
    },
  };

  public get propsToDatePicker() {
    return {
      ...this.$props,
      value: this.value,
      format: 'MM/DD/YYYY',
      valueType: this.valueType,
      id: this.instanceId,
    };
  }

  public onClose() {
    this.isDirty = true;
  }

  public onDateChange(newValue: Date) {
    this.$emit('input', newValue);
  }
}
