import { InMemoryCache, IntrospectionFragmentMatcher, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import firebase from 'firebase/app';
import Vue from 'vue';
import VueApollo from 'vue-apollo';

import introspectionQueryResultData from './fragmentTypes.json';
import { MUT_IS_LOADING, MUT_LOGOUT, MUT_SNACKBAR, store } from './store';
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
// Install the vue plugin
Vue.use(VueApollo);

const GRAPHQL_ENDPOINT_HTTP = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql';
const RPT_GRAPHQL_ENDPOINT_HTTP = process.env.VUE_APP_RPT_GRAPHQL_HTTP || 'http://localhost:4000/graphql';

const cache = new InMemoryCache({ fragmentMatcher });

const authLink = setContext(async (_, { headers }) => {
  return {
    withCredentials: true,
    ...headers,
  };

  // get the authentication token from local storage if it exists
  // if (firebase.auth().currentUser) {
  //   const token = await firebase.auth().currentUser?.getIdToken();
  //   // return the headers to the context so httpLink can read them
  //   return {
  //     headers: {
  //       ...headers,
  //       authorization: token ? `Bearer ${token}` : '',
  //     },
  //   };
  // } else {
  //   return {
  //     withCredentials: true,
  //     ...headers,
  //   };
  // }
});

export function createClients(
  queryParams?: Record<string, string>,
  authToken?: string
): {
  clients: Record<string, ApolloClient<NormalizedCacheObject>>;
  default: ApolloClient<NormalizedCacheObject>;
} {
  let urlSuffix = '';
  if (queryParams && Object.values(queryParams).length) {
    urlSuffix = '?' + new URLSearchParams(queryParams);
  }

  const urls = { apolloClient: GRAPHQL_ENDPOINT_HTTP, rptApolloClient: RPT_GRAPHQL_ENDPOINT_HTTP };
  const headers: Record<string, string> = {
    'X-Requested-With': 'XMLHttpRequest',
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  const clients = Object.entries(urls).reduce(
    (a, [name, url]) => ({
      [name]: new ApolloClient({
        link: authLink.concat(
          createHttpLink({
            uri: url + urlSuffix,
            credentials: 'include',
            headers,
          })
        ),
        cache,
      }),
      ...a,
    }),
    {} as Record<string, ApolloClient<NormalizedCacheObject>>
  );

  return { clients, default: clients.apolloClient };
}

const apolloProviderFactory = () => {
  const clients = createClients();
  return new VueApollo({
    clients: clients.clients,
    defaultClient: clients.default,
    errorHandler(err) {
      // TODO handle auth failure from apollo here
      const isAuthPage = window.location.hash.includes('auth');
      const error = JSON.parse(JSON.stringify(err, null, 2));
      const { networkError } = error;
      if (networkError) {
        const statusCode = networkError.statusCode;
        if (statusCode === 401 && !isAuthPage) {
          window.location.href = `${process.env.VUE_APP_BASE_URL}/#/auth`;
        } else if (
          (statusCode === 403 ||
            (statusCode === 400 && networkError.result.errors[0].extensions.code === 'UNAUTHENTICATED')) &&
          !isAuthPage
        ) {
          firebase.auth().signOut();
          store.commit(MUT_LOGOUT);
          store.commit(MUT_IS_LOADING, false);
          if (err.message) {
            store.commit(MUT_SNACKBAR, {
              color: 'error',
              message: err.message,
            });
          }
          window.location.href = `${process.env.VUE_APP_BASE_URL}/#/auth`;
        }
        console.error('apollo error', err.message, err.stack);
      }
    },
  });
};

export const apolloProvider = apolloProviderFactory();
