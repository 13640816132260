var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('abbr',{staticClass:"tw-relative tooltipContainer tw-flex",on:{"mouseenter":_vm.onMouseIn}},[_vm._t("default"),(_vm.show)?_c('span',{class:[
      'tooltip',
      'tw-bg-neutral-500',
      'tw-text-white',
      'tw-whitespace-pre',
      'tw-z-20',
      ( _obj = {}, _obj['pos-' + _vm.position] = true, _obj['tw-fixed'] =  _vm.fixed, _obj['tw-absolute'] =  !_vm.fixed, _obj ) ].concat( _vm.tooltipClass.split(' ') ),style:(_vm.styles)},[_vm._t("text",function(){return [_vm._v(_vm._s(_vm.text)+" ")]})],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }