import { doCookieAuth } from '@/BaseVue';
import router from '@/router';
import { MUT_PARENT_AUTH_TOKEN, store } from '@/store';

import { stringifyParams } from './endpointUrlUtil';

let requestId = 0;
const responses: { [key: number]: { resolve: (value: string) => void; reject: (reason?: any) => void } } = {};

export function requestTokenFromParent(): Promise<string> {
  const currentRequestId = ++requestId;
  const parentOrigin = process.env.VUE_APP_PARENT_WEB_APP;
  if (!parentOrigin) throw new Error('VUE_APP_PARENT_WEB_APP is not set');
  window.parent.postMessage({ request: 'token', requestId: currentRequestId }, parentOrigin);

  return new Promise((resolve, reject) => {
    responses[currentRequestId] = { resolve, reject };
  });
}

export function requestParentToKeepActive(event: string, pauseWatcher?: boolean) {
  if (window.self !== window.top) {
    const parentOrigin = process.env.VUE_APP_PARENT_WEB_APP;
    if (!parentOrigin) throw new Error('VUE_APP_PARENT_WEB_APP is not set');

    window.parent.postMessage({ request: 'keepActive', payload: { event, pauseWatcher } }, parentOrigin);
  }
}

export function requestParentToNavigate(path: string, params?: any) {
  if (window.self !== window.top) {
    const parentOrigin = process.env.VUE_APP_PARENT_WEB_APP;
    if (!parentOrigin) throw new Error('VUE_APP_PARENT_WEB_APP is not set');

    const stringParams = typeof params !== 'string' ? stringifyParams(params) : params;
    window.parent.postMessage({ request: 'navigate', path, params: stringParams }, parentOrigin);
  }
}

window.addEventListener('message', async (event: MessageEvent) => {
  if (event.origin !== process.env.VUE_APP_PARENT_WEB_APP) return; // Check origin
  const data = event.data as { type: string; responseId: number; token: string; currentOrg?: string; path: string };
  const type = data.type;
  switch (type) {
    case 'auth': {
      const responseHandler = responses[data.responseId];
      if (responseHandler) {
        responseHandler.resolve(data.token);
        delete responses[data.responseId]; // Clean up
      }
      break;
    }
    case 'orgChange':
      await store.commit(MUT_PARENT_AUTH_TOKEN, data.token);
      await doCookieAuth();
      await store.dispatch('setCurrentOrg', data.currentOrg);
      break;
    case 'navigate':
      router.push(data.path);
      break;
    default:
      break;
  }
});
