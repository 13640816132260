import { PermissionsCore } from '@bitwave/security-sdk';

import { store } from '../store';

export function checkUserRole() {
  let isOrgAdmin = false;
  let isReadOnlyUser = false;

  const org = store.state.currentOrg;
  const userId = store.state.user?.id;
  if (org) {
    const ur = org.userRoles.find((m: any) => m.userId === userId);
    if (ur) {
      if (ur.role >= 4) {
        isOrgAdmin = true;
      } else if (ur.role === 0) {
        isReadOnlyUser = true;
      }
    }
  }

  return { isOrgAdmin, isReadOnlyUser };
}

// keep or remove org admin routes
export function isOrgAdminRoute(route: string): boolean {
  return ['/settings'].includes(route);
}

export function checkScope(scope: string): boolean {
  if (store.state.currentOrg === undefined) {
    return false;
  }

  const orgId = store.state.currentOrg.id;
  const resolvedScope = scope.replace('$orgId', orgId).replace('$walletId', '*');
  const userScopes = store.state.scopes;
  if (userScopes) {
    return PermissionsCore.checkUserHasPermissions(resolvedScope, new Set(userScopes));
    // return userScopes.some((m: string) => m === resolvedScope);
  }

  return false;
  // return true;
}
