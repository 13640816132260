/* tslint:disable */
/* eslint-disable */
/**
 * api-svc
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ApiSvcAccessToken
 */
export interface ApiSvcAccessToken {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccessToken
   */
  value: string;
}
/**
 *
 * @export
 * @interface ApiSvcAccountBalanceLine
 */
export interface ApiSvcAccountBalanceLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBalanceLine
   */
  ticker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBalanceLine
   */
  currencyId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBalanceLine
   */
  amount: string;
}
/**
 *
 * @export
 * @interface ApiSvcAccountBasedBlockchainTransaction
 */
export interface ApiSvcAccountBasedBlockchainTransaction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  type: ApiSvcAccountBasedBlockchainTransactionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  txId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  to: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  from: string;
  /**
   *
   * @type {ApiSvcTxnCryptoAmount}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  amount: ApiSvcTxnCryptoAmount;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  isComplete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  isErrored: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  methodId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  data?: string;
  /**
   *
   * @type {Array<ApiSvcSubTransaction>}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  subTransactions?: Array<ApiSvcSubTransaction>;
  /**
   *
   * @type {ApiSvcTxnCryptoAmount}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  fee?: ApiSvcTxnCryptoAmount;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainTransaction
   */
  feeAddress?: string;
}

export const ApiSvcAccountBasedBlockchainTransactionTypeEnum = {
  AccountBasedBlockchainTransaction: 'account-based-blockchain-transaction',
} as const;

export type ApiSvcAccountBasedBlockchainTransactionTypeEnum =
  typeof ApiSvcAccountBasedBlockchainTransactionTypeEnum[keyof typeof ApiSvcAccountBasedBlockchainTransactionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcAccountBasedBlockchainWalletDTO
 */
export interface ApiSvcAccountBasedBlockchainWalletDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainWalletDTO
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainWalletDTO
   */
  networkId: string;
  /**
   *
   * @type {ApiSvcRollupConfigDTO}
   * @memberof ApiSvcAccountBasedBlockchainWalletDTO
   */
  rollupConfig?: ApiSvcRollupConfigDTO;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcAccountBasedBlockchainWalletDTO
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainWalletDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainWalletDTO
   */
  connectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountBasedBlockchainWalletDTO
   */
  remoteId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcAccountDetailsTypeDTO = {
  AccountTransfer: 'AccountTransfer',
  Trade: 'Trade',
  Standard: 'Standard',
  DeFi: 'DeFi',
} as const;

export type ApiSvcAccountDetailsTypeDTO = typeof ApiSvcAccountDetailsTypeDTO[keyof typeof ApiSvcAccountDetailsTypeDTO];

/**
 *
 * @export
 * @interface ApiSvcAccountInfo
 */
export interface ApiSvcAccountInfo {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountInfo
   */
  accountId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountInfo
   */
  subAccountId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcAccountTransaction
 */
export interface ApiSvcAccountTransaction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountTransaction
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountTransaction
   */
  link?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAccountTransaction
   */
  timestampSEC: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountTransaction
   */
  desc: string;
}
/**
 *
 * @export
 * @interface ApiSvcAccountTransferTxnDetailsDTO
 */
export interface ApiSvcAccountTransferTxnDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountTransferTxnDetailsDTO
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountTransferTxnDetailsDTO
   */
  otherAccountId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcAccountTransferTxnDetailsDTO
   */
  metadataIds?: Array<string>;
  /**
   *
   * @type {ApiSvcTransferDirection}
   * @memberof ApiSvcAccountTransferTxnDetailsDTO
   */
  direction: ApiSvcTransferDirection;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountTransferTxnDetailsDTO
   */
  feeContactId?: string;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcAccountTransferTxnDetailsDTO
   */
  exchangeRates?: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {ApiSvcTaxDispositionDetailsDTO}
   * @memberof ApiSvcAccountTransferTxnDetailsDTO
   */
  dispositionDetails?: ApiSvcTaxDispositionDetailsDTO;
  /**
   *
   * @type {ApiSvcTaxAcquisitionDetailsDTO}
   * @memberof ApiSvcAccountTransferTxnDetailsDTO
   */
  acquisitionDetails?: ApiSvcTaxAcquisitionDetailsDTO;
}

/**
 *
 * @export
 * @interface ApiSvcAccountValidationResponse
 */
export interface ApiSvcAccountValidationResponse {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAccountValidationResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountValidationResponse
   */
  error?: string;
  /**
   *
   * @type {ApiSvcAccountValidationResults}
   * @memberof ApiSvcAccountValidationResponse
   */
  results?: ApiSvcAccountValidationResults;
}
/**
 *
 * @export
 * @interface ApiSvcAccountValidationResults
 */
export interface ApiSvcAccountValidationResults {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountValidationResults
   */
  approximateTransactionCountHeader: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAccountValidationResults
   */
  approximateTransactionCount: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountValidationResults
   */
  accountName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcAccountValidationResults
   */
  addressSample?: Array<string>;
  /**
   *
   * @type {Array<ApiSvcAccountTransaction>}
   * @memberof ApiSvcAccountValidationResults
   */
  recentTransactions?: Array<ApiSvcAccountTransaction>;
  /**
   *
   * @type {Array<ApiSvcAccountBalanceLine>}
   * @memberof ApiSvcAccountValidationResults
   */
  balances: Array<ApiSvcAccountBalanceLine>;
  /**
   *
   * @type {ApiSvcAccountInfo}
   * @memberof ApiSvcAccountValidationResults
   */
  accountInfo: ApiSvcAccountInfo;
}
/**
 *
 * @export
 * @interface ApiSvcAccountingConfigDTO
 */
export interface ApiSvcAccountingConfigDTO {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAccountingConfigDTO
   */
  multiCurrency: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAccountingConfigDTO
   */
  separateFeeTransactions: boolean;
  /**
   *
   * @type {Array<ApiSvcAccountingConfigDTONetworkContactIdsInner>}
   * @memberof ApiSvcAccountingConfigDTO
   */
  networkContactIds?: Array<ApiSvcAccountingConfigDTONetworkContactIdsInner>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingConfigDTO
   */
  defaultFeeCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingConfigDTO
   */
  defaultAccountsReceivableCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingConfigDTO
   */
  defaultAccountsPayableCategoryId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAccountingConfigDTO
   */
  allowTxnInference?: boolean;
  /**
   *
   * @type {Array<ApiSvcAccountingConfigDTODefaultFeeCategoryIdsInner>}
   * @memberof ApiSvcAccountingConfigDTO
   */
  defaultFeeCategoryIds: Array<ApiSvcAccountingConfigDTODefaultFeeCategoryIdsInner>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAccountingConfigDTO
   */
  collapseAcrossWallets?: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcAccountingConfigDTODefaultFeeCategoryIdsInner
 */
export interface ApiSvcAccountingConfigDTODefaultFeeCategoryIdsInner {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingConfigDTODefaultFeeCategoryIdsInner
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingConfigDTODefaultFeeCategoryIdsInner
   */
  blockchain: string;
}
/**
 *
 * @export
 * @interface ApiSvcAccountingConfigDTONetworkContactIdsInner
 */
export interface ApiSvcAccountingConfigDTONetworkContactIdsInner {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingConfigDTONetworkContactIdsInner
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingConfigDTONetworkContactIdsInner
   */
  blockchain: string;
}
/**
 *
 * @export
 * @interface ApiSvcAccountingDetailsDTO
 */
export interface ApiSvcAccountingDetailsDTO {
  /**
   *
   * @type {ApiSvcSimpleTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  simple?: ApiSvcSimpleTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcSellTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  sell?: ApiSvcSellTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcBillPayTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  billPay?: ApiSvcBillPayTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcTradeTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  trade?: ApiSvcTradeTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcSimpleTradeTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  simpleTrade?: ApiSvcSimpleTradeTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcTransferTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  transfer?: ApiSvcTransferTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcAccountTransferTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  accountTransfer?: ApiSvcAccountTransferTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcStakingTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  staking?: ApiSvcStakingTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcDetailedTxnDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  detailed?: ApiSvcDetailedTxnDTO;
  /**
   *
   * @type {ApiSvcMultiValueTxnDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  multivalue?: ApiSvcMultiValueTxnDTO;
  /**
   *
   * @type {ApiSvcInvoicePaymentTxnDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  invoice?: ApiSvcInvoicePaymentTxnDTO;
  /**
   *
   * @type {ApiSvcAssetStakingTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  assetStaking?: ApiSvcAssetStakingTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcDeFiTransferTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  deFiTransfer?: ApiSvcDeFiTransferTxnDetailsDTO;
  /**
   *
   * @type {ApiSvcAdvanceDeFiTxnDetailsDTO}
   * @memberof ApiSvcAccountingDetailsDTO
   */
  advanceDeFi?: ApiSvcAdvanceDeFiTxnDetailsDTO;
}
/**
 *
 * @export
 * @interface ApiSvcAccountingSetupDTO
 */
export interface ApiSvcAccountingSetupDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingSetupDTO
   */
  initialSyncMethod: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAccountingSetupDTO
   */
  isReconciled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAccountingSetupDTO
   */
  isFinished?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingSetupDTO
   */
  balanceExportStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAccountingSetupDTO
   */
  balanceExportReportId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcAccountingSourceIds = {
  Xero: 'xero',
  Quickbooks: 'quickbooks',
  Netsuite: 'netsuite',
  SageIntacct: 'sage-intacct',
  Manual: 'manual',
  Workday: 'workday',
} as const;

export type ApiSvcAccountingSourceIds = typeof ApiSvcAccountingSourceIds[keyof typeof ApiSvcAccountingSourceIds];

/**
 *
 * @export
 * @interface ApiSvcAction
 */
export interface ApiSvcAction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAction
   */
  type: ApiSvcActionTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAction
   */
  ignoreFailPricing?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAction
   */
  feeCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAction
   */
  feeContactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAction
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAction
   */
  categoryId: string;
  /**
   *
   * @type {Array<ApiSvcDetailedCategorizationActionLines>}
   * @memberof ApiSvcAction
   */
  lines: Array<ApiSvcDetailedCategorizationActionLines>;
  /**
   *
   * @type {Array<ApiSvcSplit>}
   * @memberof ApiSvcAction
   */
  feeSplits?: Array<ApiSvcSplit>;
  /**
   *
   * @type {Array<ApiSvcSplit>}
   * @memberof ApiSvcAction
   */
  splits: Array<ApiSvcSplit>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAction
   */
  deFiWalletId: string;
}

export const ApiSvcActionTypeEnum = {
  DeFiCategorization: 'DeFiCategorization',
} as const;

export type ApiSvcActionTypeEnum = typeof ApiSvcActionTypeEnum[keyof typeof ApiSvcActionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcAddressEnrollmentStatus
 */
export interface ApiSvcAddressEnrollmentStatus {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressEnrollmentStatus
   */
  address: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAddressEnrollmentStatus
   */
  emailSent: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAddressEnrollmentStatus
   */
  enrolled: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressEnrollmentStatus
   */
  signature?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressEnrollmentStatus
   */
  signatureData?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressEnrollmentStatus
   */
  pennyTestAmount?: string;
}
/**
 *
 * @export
 * @interface ApiSvcAddressPointer
 */
export interface ApiSvcAddressPointer {
  /**
   *
   * @type {ApiSvcPointerTypes}
   * @memberof ApiSvcAddressPointer
   */
  type: ApiSvcPointerTypes;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressPointer
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressPointer
   */
  address: string;
}

/**
 *
 * @export
 * @interface ApiSvcAddressWallet
 */
export interface ApiSvcAddressWallet {
  /**
   *
   * @type {ApiSvcRollupConfig}
   * @memberof ApiSvcAddressWallet
   */
  rollupConfig?: ApiSvcRollupConfig | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressWallet
   */
  address: string;
}
/**
 *
 * @export
 * @interface ApiSvcAddressWalletDTO
 */
export interface ApiSvcAddressWalletDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressWalletDTO
   */
  address: string;
  /**
   *
   * @type {ApiSvcRollupConfigDTO}
   * @memberof ApiSvcAddressWalletDTO
   */
  rollupConfig?: ApiSvcRollupConfigDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressWalletDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressWalletDTO
   */
  connectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAddressWalletDTO
   */
  remoteId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiCategorizationDTO
 */
export interface ApiSvcAdvanceDeFiCategorizationDTO {
  /**
   *
   * @type {ApiSvcCategorizationTypeAdvanceDeFi}
   * @memberof ApiSvcAdvanceDeFiCategorizationDTO
   */
  type: ApiSvcCategorizationTypeAdvanceDeFi;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcAdvanceDeFiCategorizationDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAdvanceDeFiCategorizationDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {Array<ApiSvcAdvanceDeFiItemDTO>}
   * @memberof ApiSvcAdvanceDeFiCategorizationDTO
   */
  items: Array<ApiSvcAdvanceDeFiItemDTO>;
  /**
   *
   * @type {Array<ApiSvcFeeLineDTO>}
   * @memberof ApiSvcAdvanceDeFiCategorizationDTO
   */
  fees?: Array<ApiSvcFeeLineDTO>;
}

/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiItem
 */
export interface ApiSvcAdvanceDeFiItem {
  /**
   *
   * @type {Array<ApiSvcAdvanceDeFiLine>}
   * @memberof ApiSvcAdvanceDeFiItem
   */
  lines: Array<ApiSvcAdvanceDeFiLine>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItem
   */
  defiWalletId: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiItemDTO
 */
export interface ApiSvcAdvanceDeFiItemDTO {
  /**
   *
   * @type {Array<ApiSvcAdvanceDeFiItemDTOLinesInner>}
   * @memberof ApiSvcAdvanceDeFiItemDTO
   */
  lines: Array<ApiSvcAdvanceDeFiItemDTOLinesInner>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTO
   */
  defiWalletId: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiItemDTOLinesInner
 */
export interface ApiSvcAdvanceDeFiItemDTOLinesInner {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  type: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  isNonVaultTracking?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  sourceAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  sourceTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  walletId?: string;
  /**
   *
   * @type {Array<ApiSvcMetadataDTO>}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  metadata?: Array<ApiSvcMetadataDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  fiatAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  fiat: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemDTOLinesInner
   */
  contactId: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiItemInput
 */
export interface ApiSvcAdvanceDeFiItemInput {
  /**
   *
   * @type {Array<ApiSvcAdvanceDeFiLineNonPostInput>}
   * @memberof ApiSvcAdvanceDeFiItemInput
   */
  nonpostingLines?: Array<ApiSvcAdvanceDeFiLineNonPostInput> | null;
  /**
   *
   * @type {Array<ApiSvcAdvanceDeFiLinePostInput>}
   * @memberof ApiSvcAdvanceDeFiItemInput
   */
  postingLines?: Array<ApiSvcAdvanceDeFiLinePostInput> | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiItemInput
   */
  defiWalletId: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiLine
 */
export interface ApiSvcAdvanceDeFiLine {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  isNonVaultTracking?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  sourceAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  sourceTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  type: string;
  /**
   *
   * @type {Array<ApiSvcMetadataDTO>}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  metadata?: Array<ApiSvcMetadataDTO> | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  fiatAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  fiat: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLine
   */
  contactId: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiLineNonPost
 */
export interface ApiSvcAdvanceDeFiLineNonPost {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAdvanceDeFiLineNonPost
   */
  isNonVaultTracking?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPost
   */
  sourceAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPost
   */
  sourceTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPost
   */
  type: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiLineNonPostDTO
 */
export interface ApiSvcAdvanceDeFiLineNonPostDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPostDTO
   */
  type: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAdvanceDeFiLineNonPostDTO
   */
  isNonVaultTracking?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPostDTO
   */
  sourceAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPostDTO
   */
  sourceTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPostDTO
   */
  walletId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiLineNonPostInput
 */
export interface ApiSvcAdvanceDeFiLineNonPostInput {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPostInput
   */
  walletId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAdvanceDeFiLineNonPostInput
   */
  isNonVaultTracking?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPostInput
   */
  sourceAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLineNonPostInput
   */
  sourceTicker: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiLinePost
 */
export interface ApiSvcAdvanceDeFiLinePost {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  isNonVaultTracking?: boolean | null;
  /**
   *
   * @type {Array<ApiSvcMetadataDTO>}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  metadata?: Array<ApiSvcMetadataDTO> | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  fiatAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  fiat: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  sourceAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  sourceTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePost
   */
  type: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiLinePostDTO
 */
export interface ApiSvcAdvanceDeFiLinePostDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  type: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  isNonVaultTracking?: boolean;
  /**
   *
   * @type {Array<ApiSvcMetadataDTO>}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  metadata?: Array<ApiSvcMetadataDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  fiatAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  fiat: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  sourceAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  sourceTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostDTO
   */
  contactId: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiLinePostInput
 */
export interface ApiSvcAdvanceDeFiLinePostInput {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  walletId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  isNonVaultTracking?: boolean | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  metadataIds?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  fiatAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  fiat: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  sourceAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  sourceTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvanceDeFiLinePostInput
   */
  contactId: string;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiTransactionInput
 */
export interface ApiSvcAdvanceDeFiTransactionInput {
  /**
   *
   * @type {Array<ApiSvcDetailedFeeInput>}
   * @memberof ApiSvcAdvanceDeFiTransactionInput
   */
  fees?: Array<ApiSvcDetailedFeeInput> | null;
  /**
   *
   * @type {Array<ApiSvcExchangeRateInput>}
   * @memberof ApiSvcAdvanceDeFiTransactionInput
   */
  exchangeRates: Array<ApiSvcExchangeRateInput>;
  /**
   *
   * @type {Array<ApiSvcAdvanceDeFiItemInput>}
   * @memberof ApiSvcAdvanceDeFiTransactionInput
   */
  items: Array<ApiSvcAdvanceDeFiItemInput>;
}
/**
 *
 * @export
 * @interface ApiSvcAdvanceDeFiTxnDetailsDTO
 */
export interface ApiSvcAdvanceDeFiTxnDetailsDTO {
  /**
   *
   * @type {Array<ApiSvcDetailedFeeDTO>}
   * @memberof ApiSvcAdvanceDeFiTxnDetailsDTO
   */
  fees?: Array<ApiSvcDetailedFeeDTO>;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcAdvanceDeFiTxnDetailsDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {Array<ApiSvcAdvanceDeFiItemDTO>}
   * @memberof ApiSvcAdvanceDeFiTxnDetailsDTO
   */
  items: Array<ApiSvcAdvanceDeFiItemDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcAdvancedCostBasisRollForwardLine
 */
export interface ApiSvcAdvancedCostBasisRollForwardLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  asset: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  asset_id: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  starting_fiat_value: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  starting_qty_value: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  fiat_increases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  qty_increases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  qty_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  ending_fiat_value: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  ending_qty_value: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  fmv_disposed_fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  stGainLoss_fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  ltGainLoss_fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  impairExpense_fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  fiat_check_mustbe_zero: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  qty_check_mustbe_zero: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  undatedGainLoss_fiat_decreases: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  lotID?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  original_inventory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  original_wallet?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  inventory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  wallet?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  current_price?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  fair_market_value?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  unrealized_gain_loss?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAdvancedCostBasisRollForwardLine
   */
  impairExpenseReversal_fiat_increases?: number;
}
/**
 *
 * @export
 * @interface ApiSvcAdvancedCostBasisRollForwardResp
 */
export interface ApiSvcAdvancedCostBasisRollForwardResp {
  /**
   *
   * @type {Array<ApiSvcAdvancedCostBasisRollForwardLine>}
   * @memberof ApiSvcAdvancedCostBasisRollForwardResp
   */
  lines: Array<ApiSvcAdvancedCostBasisRollForwardLine>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAdvancedCostBasisRollForwardResp
   */
  pageToken?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcAdvancedCostBasisRollForwardResp
   */
  exportIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcAmountDTO
 */
export interface ApiSvcAmountDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAmountDTO
   */
  displayValue: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAmountDTO
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAmountDTO
   */
  coin: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAmountDTO
   */
  unit: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAmountDTO
   */
  parentTimestamp?: number;
  /**
   *
   * @type {ApiSvcValueBase}
   * @memberof ApiSvcAmountDTO
   */
  itemValue: ApiSvcValueBase;
  /**
   *
   * @type {ApiSvcOrgDTO}
   * @memberof ApiSvcAmountDTO
   */
  org: ApiSvcOrgDTO;
  /**
   *
   * @type {ApiSvcFiatValueDTO}
   * @memberof ApiSvcAmountDTO
   */
  fiatValue?: ApiSvcFiatValueDTO;
}
/**
 *
 * @export
 * @interface ApiSvcAmountLiteDTO
 */
export interface ApiSvcAmountLiteDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAmountLiteDTO
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAmountLiteDTO
   */
  currencyId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAmountLiteDTO
   */
  coin?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAmountLiteDTO
   */
  unit?: string;
}
/**
 *
 * @export
 * @interface ApiSvcApiKeyToken
 */
export interface ApiSvcApiKeyToken {
  /**
   *
   * @type {string}
   * @memberof ApiSvcApiKeyToken
   */
  apiKey: string;
}
/**
 *
 * @export
 * @interface ApiSvcApplyRuleToTxnResult
 */
export interface ApiSvcApplyRuleToTxnResult {
  /**
   *
   * @type {ApiSvcAccountingDetailsDTO}
   * @memberof ApiSvcApplyRuleToTxnResult
   */
  categorization?: ApiSvcAccountingDetailsDTO;
  /**
   *
   * @type {ApiSvcRuleProcessingResult}
   * @memberof ApiSvcApplyRuleToTxnResult
   */
  result: ApiSvcRuleProcessingResult;
}
/**
 *
 * @export
 * @interface ApiSvcAssetBasedValuationConfig
 */
export interface ApiSvcAssetBasedValuationConfig {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetBasedValuationConfig
   */
  assetId: string;
  /**
   *
   * @type {ApiSvcAssetValuationStrategies}
   * @memberof ApiSvcAssetBasedValuationConfig
   */
  strategy: ApiSvcAssetValuationStrategies;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcAssetExtractor = {
  Eth: 'ETH',
  Token: 'TOKEN',
  Coin: 'COIN',
  Fiat: 'FIAT',
} as const;

export type ApiSvcAssetExtractor = typeof ApiSvcAssetExtractor[keyof typeof ApiSvcAssetExtractor];

/**
 *
 * @export
 * @interface ApiSvcAssetStakingPoolDetails
 */
export interface ApiSvcAssetStakingPoolDetails {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetStakingPoolDetails
   */
  addressPoolTokens: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetStakingPoolDetails
   */
  totalPoolTokens: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetStakingPoolDetails
   */
  percentOfPool: string;
}
/**
 *
 * @export
 * @interface ApiSvcAssetStakingTxnDetailsDTO
 */
export interface ApiSvcAssetStakingTxnDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  type: string;
  /**
   *
   * @type {Array<ApiSvcValueDetailsDTO>}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  stakedAssets: Array<ApiSvcValueDetailsDTO>;
  /**
   *
   * @type {Array<ApiSvcValueDetailsDTO>}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  withdrawnAssets?: Array<ApiSvcValueDetailsDTO>;
  /**
   *
   * @type {Array<ApiSvcValueDetailsDTO>}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  totalHoldings?: Array<ApiSvcValueDetailsDTO>;
  /**
   *
   * @type {ApiSvcAssetStakingPoolDetails}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  poolDetails?: ApiSvcAssetStakingPoolDetails;
  /**
   *
   * @type {ApiSvcBlockPointer}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  block: ApiSvcBlockPointer;
  /**
   *
   * @type {ApiSvcAddressPointer}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  holdingAddress: ApiSvcAddressPointer;
  /**
   *
   * @type {ApiSvcAddressPointer}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  stakingTarget: ApiSvcAddressPointer;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {Array<ApiSvcMultiValueTxnItemDTO>}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  recognizedRevenue?: Array<ApiSvcMultiValueTxnItemDTO>;
  /**
   *
   * @type {ApiSvcDetailedMultiFeeOutDTO}
   * @memberof ApiSvcAssetStakingTxnDetailsDTO
   */
  fee?: ApiSvcDetailedMultiFeeOutDTO;
}
/**
 *
 * @export
 * @interface ApiSvcAssetSummary
 */
export interface ApiSvcAssetSummary {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetSummary
   */
  asset: string;
  /**
   *
   * @type {ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId}
   * @memberof ApiSvcAssetSummary
   */
  amount: ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId;
  /**
   *
   * @type {ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId}
   * @memberof ApiSvcAssetSummary
   */
  value: ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcAssetValuationStrategies = {
  HistoricalCost: 'historical-cost',
  Impairment: 'impairment',
  MarkToMarket: 'mark-to-market',
  ImpairmentReversalNonRevalued: 'impairment-reversal-non-revalued',
  ImpairmentReversalRevalued: 'impairment-reversal-revalued',
  GaapFairValue: 'gaap-fair-value',
} as const;

export type ApiSvcAssetValuationStrategies =
  typeof ApiSvcAssetValuationStrategies[keyof typeof ApiSvcAssetValuationStrategies];

/**
 *
 * @export
 * @interface ApiSvcAssetValuationStrategy
 */
export interface ApiSvcAssetValuationStrategy {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetValuationStrategy
   */
  ticker: string;
  /**
   *
   * @type {ApiSvcAssetValuationStrategies}
   * @memberof ApiSvcAssetValuationStrategy
   */
  assetValuationStrategy: ApiSvcAssetValuationStrategies;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetValuationStrategy
   */
  currencyId?: string;
}

/**
 *
 * @export
 * @interface ApiSvcAssetValueDTO
 */
export interface ApiSvcAssetValueDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetValueDTO
   */
  assetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAssetValueDTO
   */
  amount: string;
}
/**
 *
 * @export
 * @interface ApiSvcAssetsSummaryResponse
 */
export interface ApiSvcAssetsSummaryResponse {
  /**
   *
   * @type {Array<ApiSvcAssetSummary>}
   * @memberof ApiSvcAssetsSummaryResponse
   */
  items: Array<ApiSvcAssetSummary>;
  /**
   *
   * @type {ApiSvcAssetSummary}
   * @memberof ApiSvcAssetsSummaryResponse
   */
  total: ApiSvcAssetSummary;
}
/**
 *
 * @export
 * @interface ApiSvcAuditLogEntryDTO
 */
export interface ApiSvcAuditLogEntryDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  entityType: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  entityId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  action: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  timestamp: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  userEmail: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  category: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcAuditLogEntryDTO
   */
  detail?: string;
}
/**
 *
 * @export
 * @interface ApiSvcBalanceCheckResultDTO
 */
export interface ApiSvcBalanceCheckResultDTO {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcBalanceCheckResultDTO
   */
  metaSuccess: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcBalanceCheckResultDTO
   */
  metaIsNegative: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBalanceCheckResultDTO
   */
  serviceName?: string;
  /**
   *
   * @type {Array<ApiSvcBalanceCompareResultDTO>}
   * @memberof ApiSvcBalanceCheckResultDTO
   */
  results: Array<ApiSvcBalanceCompareResultDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBalanceCheckResultDTO
   */
  balanceId: string;
}
/**
 *
 * @export
 * @interface ApiSvcBalanceCompareResultDTO
 */
export interface ApiSvcBalanceCompareResultDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  controlSource: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  controlTimestamp: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  testSource: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  testTimestamp: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  totalFiatValue: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  totalFiatDelta: string;
  /**
   *
   * @type {Array<ApiSvcLineDeltaDTO>}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  lineDeltas: Array<ApiSvcLineDeltaDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  succeedsThresholdCheck: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  isNegative: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcBalanceCompareResultDTO
   */
  warnings: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcBalanceType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type ApiSvcBalanceType = typeof ApiSvcBalanceType[keyof typeof ApiSvcBalanceType];

/**
 *
 * @export
 * @interface ApiSvcBillPayTxnDetailsDTO
 */
export interface ApiSvcBillPayTxnDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcBillPayTxnDetailsDTO
   */
  billId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBillPayTxnDetailsDTO
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBillPayTxnDetailsDTO
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBillPayTxnDetailsDTO
   */
  description?: string;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcBillPayTxnDetailsDTO
   */
  costBasis: ApiSvcCostBasisDTO;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcBitcoinMultisigDerivationMode = {
  Legacy: 'legacy',
  P2shSegwit: 'p2shSegwit',
  NativeSegwit: 'nativeSegwit',
} as const;

export type ApiSvcBitcoinMultisigDerivationMode =
  typeof ApiSvcBitcoinMultisigDerivationMode[keyof typeof ApiSvcBitcoinMultisigDerivationMode];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcBlockHeightEvaluator = {
  GreaterThanOrEqualTo: '>=',
  LessThanOrEqualTo: '<=',
} as const;

export type ApiSvcBlockHeightEvaluator = typeof ApiSvcBlockHeightEvaluator[keyof typeof ApiSvcBlockHeightEvaluator];

/**
 *
 * @export
 * @interface ApiSvcBlockPointer
 */
export interface ApiSvcBlockPointer {
  /**
   *
   * @type {ApiSvcPointerTypes}
   * @memberof ApiSvcBlockPointer
   */
  type: ApiSvcPointerTypes;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBlockPointer
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBlockPointer
   */
  blockId: string;
}

/**
 *
 * @export
 * @interface ApiSvcBlockchainTransaction
 */
export interface ApiSvcBlockchainTransaction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcBlockchainTransaction
   */
  type: ApiSvcBlockchainTransactionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBlockchainTransaction
   */
  walletId: string;
  /**
   *
   * @type {ApiSvcBlockchainTransactionDiscoveringAddress}
   * @memberof ApiSvcBlockchainTransaction
   */
  discoveringAddress: ApiSvcBlockchainTransactionDiscoveringAddress;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBlockchainTransaction
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBlockchainTransaction
   */
  block: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBlockchainTransaction
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcBlockchainTransaction
   */
  createdSEC: number;
  /**
   *
   * @type {ApiSvcAccountBasedBlockchainTransaction}
   * @memberof ApiSvcBlockchainTransaction
   */
  txn: ApiSvcAccountBasedBlockchainTransaction;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBlockchainTransaction
   */
  fullTransactionDetailsLink?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ApiSvcBlockchainTransaction
   */
  metadata?: { [key: string]: string };
  /**
   *
   * @type {ApiSvcTxnOrigin}
   * @memberof ApiSvcBlockchainTransaction
   */
  txnOrigin?: ApiSvcTxnOrigin;
}

export const ApiSvcBlockchainTransactionTypeEnum = {
  Blockchain: 'blockchain',
} as const;

export type ApiSvcBlockchainTransactionTypeEnum =
  typeof ApiSvcBlockchainTransactionTypeEnum[keyof typeof ApiSvcBlockchainTransactionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcBlockchainTransactionDiscoveringAddress
 */
export interface ApiSvcBlockchainTransactionDiscoveringAddress {}
/**
 *
 * @export
 * @interface ApiSvcBulkCategorizeTransactions200Response
 */
export interface ApiSvcBulkCategorizeTransactions200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactions200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactions200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactions200Response
   */
  traceId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcBulkCategorizeTransactionsDTO
 */
export interface ApiSvcBulkCategorizeTransactionsDTO {
  /**
   *
   * @type {ApiSvcBulkCategorizeTransactionsDTOOptions}
   * @memberof ApiSvcBulkCategorizeTransactionsDTO
   */
  options?: ApiSvcBulkCategorizeTransactionsDTOOptions;
  /**
   *
   * @type {ApiSvcBulkCategorizeTransactionsDTOCategorization}
   * @memberof ApiSvcBulkCategorizeTransactionsDTO
   */
  categorization: ApiSvcBulkCategorizeTransactionsDTOCategorization;
}
/**
 *
 * @export
 * @interface ApiSvcBulkCategorizeTransactionsDTOCategorization
 */
export interface ApiSvcBulkCategorizeTransactionsDTOCategorization {
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorization
   */
  accountingConnectionId: string;
  /**
   *
   * @type {ApiSvcBulkCategorizeTransactionsDTOCategorizationTransfer}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorization
   */
  transfer?: ApiSvcBulkCategorizeTransactionsDTOCategorizationTransfer;
  /**
   *
   * @type {ApiSvcBulkCategorizeTransactionsDTOCategorizationTransfer}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorization
   */
  trade?: ApiSvcBulkCategorizeTransactionsDTOCategorizationTransfer;
  /**
   *
   * @type {ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorization
   */
  multivalue?: ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue;
}
/**
 *
 * @export
 * @interface ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue
 */
export interface ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue {
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue
   */
  receiveCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue
   */
  receiveContactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue
   */
  sendCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue
   */
  sendContactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue
   */
  feeCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue
   */
  feeContactId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationMultivalue
   */
  txnIds: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcBulkCategorizeTransactionsDTOCategorizationTransfer
 */
export interface ApiSvcBulkCategorizeTransactionsDTOCategorizationTransfer {
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationTransfer
   */
  feeCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationTransfer
   */
  feeContactId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOCategorizationTransfer
   */
  txnIds: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcBulkCategorizeTransactionsDTOOptions
 */
export interface ApiSvcBulkCategorizeTransactionsDTOOptions {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcBulkCategorizeTransactionsDTOOptions
   */
  overwriteExistingCategorization?: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcCancelResult
 */
export interface ApiSvcCancelResult {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCancelResult
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCancelResult
   */
  id: string;
}
/**
 *
 * @export
 * @interface ApiSvcCategoriesResponse
 */
export interface ApiSvcCategoriesResponse {
  /**
   *
   * @type {Array<ApiSvcCategoryDTO>}
   * @memberof ApiSvcCategoriesResponse
   */
  items: Array<ApiSvcCategoryDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategoriesResponse
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategoriesResponse
   */
  nextPage?: string;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizSuccess
 */
export interface ApiSvcCategorizSuccess {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizSuccess
   */
  txnId: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizSuccess
   */
  success: ApiSvcCategorizSuccessSuccessEnum;
}

export const ApiSvcCategorizSuccessSuccessEnum = {
  True: true,
} as const;

export type ApiSvcCategorizSuccessSuccessEnum =
  typeof ApiSvcCategorizSuccessSuccessEnum[keyof typeof ApiSvcCategorizSuccessSuccessEnum];

/**
 *
 * @export
 * @interface ApiSvcCategorizationDTO
 */
export interface ApiSvcCategorizationDTO {
  /**
   *
   * @type {ApiSvcCategorizationTypeMultivalue}
   * @memberof ApiSvcCategorizationDTO
   */
  type: ApiSvcCategorizationTypeMultivalue;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcCategorizationDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizationDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {Array<ApiSvcMultiValueCategorizationItemDTO>}
   * @memberof ApiSvcCategorizationDTO
   */
  items: Array<ApiSvcMultiValueCategorizationItemDTO>;
  /**
   *
   * @type {Array<ApiSvcFeeLineDTO>}
   * @memberof ApiSvcCategorizationDTO
   */
  fees?: Array<ApiSvcFeeLineDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizationDTO
   */
  totalAmount: string;
  /**
   *
   * @type {Array<ApiSvcMultiContactInvoicePaymentLineDTO>}
   * @memberof ApiSvcCategorizationDTO
   */
  invoices: Array<ApiSvcMultiContactInvoicePaymentLineDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizationDTO
   */
  invoiceType: string;
  /**
   *
   * @type {ApiSvcInvoicePaymentLineForexDTO}
   * @memberof ApiSvcCategorizationDTO
   */
  forex?: ApiSvcInvoicePaymentLineForexDTO;
  /**
   *
   * @type {Array<ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO>}
   * @memberof ApiSvcCategorizationDTO
   */
  unapplied?: Array<ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizationDTO
   */
  accountingConnectionId?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCategorizationDTO
   */
  dateTimeSec: number;
  /**
   *
   * @type {Array<ApiSvcTransferLineDTO>}
   * @memberof ApiSvcCategorizationDTO
   */
  disposedAssets: Array<ApiSvcTransferLineDTO>;
  /**
   *
   * @type {Array<ApiSvcTransferLineDTO>}
   * @memberof ApiSvcCategorizationDTO
   */
  acquiredAssets: Array<ApiSvcTransferLineDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizationDTO
   */
  carryForwardCostBasis?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizationDTO
   */
  notes: string;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcCategorizationDTO
   */
  feeCostBasis?: ApiSvcRawValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizationDTO
   */
  feeContactId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizationDTO
   */
  feeWalletId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCategorizationDTO
   */
  metadataIds?: Array<string>;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationMethod = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const;

export type ApiSvcCategorizationMethod = typeof ApiSvcCategorizationMethod[keyof typeof ApiSvcCategorizationMethod];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationMethodology = {
  Manual: 'manual',
  Inferred: 'inferred',
  Rule: 'rule',
  System: 'system',
  Ops: 'ops',
} as const;

export type ApiSvcCategorizationMethodology =
  typeof ApiSvcCategorizationMethodology[keyof typeof ApiSvcCategorizationMethodology];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationStatusDTO = {
  All: 'All',
  Uncategorized: 'Uncategorized',
  Categorized: 'Categorized',
} as const;

export type ApiSvcCategorizationStatusDTO =
  typeof ApiSvcCategorizationStatusDTO[keyof typeof ApiSvcCategorizationStatusDTO];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTransactionType = {
  Invoice: 'invoice',
  InvoiceV2: 'invoice-v2',
  Multivalue: 'multivalue',
  Trade: 'trade',
  Transfer: 'transfer',
  AdvanceDefi: 'advance-defi',
} as const;

export type ApiSvcCategorizationTransactionType =
  typeof ApiSvcCategorizationTransactionType[keyof typeof ApiSvcCategorizationTransactionType];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTransactionTypeAdvanceDeFi = {
  AdvanceDefi: 'advance-defi',
} as const;

export type ApiSvcCategorizationTransactionTypeAdvanceDeFi =
  typeof ApiSvcCategorizationTransactionTypeAdvanceDeFi[keyof typeof ApiSvcCategorizationTransactionTypeAdvanceDeFi];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTransactionTypeInvoice = {
  Invoice: 'invoice',
} as const;

export type ApiSvcCategorizationTransactionTypeInvoice =
  typeof ApiSvcCategorizationTransactionTypeInvoice[keyof typeof ApiSvcCategorizationTransactionTypeInvoice];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTransactionTypeInvoiceV2 = {
  InvoiceV2: 'invoice-v2',
} as const;

export type ApiSvcCategorizationTransactionTypeInvoiceV2 =
  typeof ApiSvcCategorizationTransactionTypeInvoiceV2[keyof typeof ApiSvcCategorizationTransactionTypeInvoiceV2];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTransactionTypeMultivalue = {
  Multivalue: 'multivalue',
} as const;

export type ApiSvcCategorizationTransactionTypeMultivalue =
  typeof ApiSvcCategorizationTransactionTypeMultivalue[keyof typeof ApiSvcCategorizationTransactionTypeMultivalue];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTransactionTypeTrade = {
  Trade: 'trade',
} as const;

export type ApiSvcCategorizationTransactionTypeTrade =
  typeof ApiSvcCategorizationTransactionTypeTrade[keyof typeof ApiSvcCategorizationTransactionTypeTrade];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTransactionTypeTransfer = {
  Transfer: 'transfer',
} as const;

export type ApiSvcCategorizationTransactionTypeTransfer =
  typeof ApiSvcCategorizationTransactionTypeTransfer[keyof typeof ApiSvcCategorizationTransactionTypeTransfer];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTypeAdvanceDeFi = {
  AdvanceDefi: 'advance-defi',
} as const;

export type ApiSvcCategorizationTypeAdvanceDeFi =
  typeof ApiSvcCategorizationTypeAdvanceDeFi[keyof typeof ApiSvcCategorizationTypeAdvanceDeFi];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTypeInvoicePayment = {
  InvoicePayment: 'invoice-payment',
} as const;

export type ApiSvcCategorizationTypeInvoicePayment =
  typeof ApiSvcCategorizationTypeInvoicePayment[keyof typeof ApiSvcCategorizationTypeInvoicePayment];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTypeMultiContactInvoice = {
  MultiContactInvoice: 'multi-contact-invoice',
} as const;

export type ApiSvcCategorizationTypeMultiContactInvoice =
  typeof ApiSvcCategorizationTypeMultiContactInvoice[keyof typeof ApiSvcCategorizationTypeMultiContactInvoice];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTypeMultivalue = {
  Multivalue: 'multivalue',
} as const;

export type ApiSvcCategorizationTypeMultivalue =
  typeof ApiSvcCategorizationTypeMultivalue[keyof typeof ApiSvcCategorizationTypeMultivalue];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTypeTrade = {
  Trade: 'trade',
} as const;

export type ApiSvcCategorizationTypeTrade =
  typeof ApiSvcCategorizationTypeTrade[keyof typeof ApiSvcCategorizationTypeTrade];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizationTypeTransfer = {
  Transfer: 'transfer',
} as const;

export type ApiSvcCategorizationTypeTransfer =
  typeof ApiSvcCategorizationTypeTransfer[keyof typeof ApiSvcCategorizationTypeTransfer];

/**
 *
 * @export
 * @interface ApiSvcCategorizeAdvanceDeFiItemDTO
 */
export interface ApiSvcCategorizeAdvanceDeFiItemDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeAdvanceDeFiItemDTO
   */
  defiWalletId: string;
  /**
   *
   * @type {Array<ApiSvcCategorizeAdvanceDeFiNonPostLineDTO>}
   * @memberof ApiSvcCategorizeAdvanceDeFiItemDTO
   */
  lines: Array<ApiSvcCategorizeAdvanceDeFiNonPostLineDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeAdvanceDeFiNonPostLineDTO
 */
export interface ApiSvcCategorizeAdvanceDeFiNonPostLineDTO {
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeAdvanceDeFiNonPostLineDTO
   */
  source: ApiSvcAssetValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeAdvanceDeFiNonPostLineDTO
   */
  walletId: string;
  /**
   *
   * @type {ApiSvcCategorizeLineOperation}
   * @memberof ApiSvcCategorizeAdvanceDeFiNonPostLineDTO
   */
  operation: ApiSvcCategorizeLineOperation;
}

/**
 *
 * @export
 * @interface ApiSvcCategorizeAdvanceDeFiTransactionDTO
 */
export interface ApiSvcCategorizeAdvanceDeFiTransactionDTO {
  /**
   *
   * @type {ApiSvcCategorizationTransactionTypeAdvanceDeFi}
   * @memberof ApiSvcCategorizeAdvanceDeFiTransactionDTO
   */
  type: ApiSvcCategorizationTransactionTypeAdvanceDeFi;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcCategorizeAdvanceDeFiTransactionDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeAdvanceDeFiTransactionDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeAdvanceDeFiTransactionDTO
   */
  accountingConnectionId: string;
  /**
   *
   * @type {Array<ApiSvcExchangeRateV2DTO>}
   * @memberof ApiSvcCategorizeAdvanceDeFiTransactionDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateV2DTO>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCategorizeAdvanceDeFiTransactionDTO
   */
  exchangeRateVersion: number;
  /**
   *
   * @type {Array<ApiSvcCategorizeAdvanceDeFiItemDTO>}
   * @memberof ApiSvcCategorizeAdvanceDeFiTransactionDTO
   */
  items: Array<ApiSvcCategorizeAdvanceDeFiItemDTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeFeeV2DTO>}
   * @memberof ApiSvcCategorizeAdvanceDeFiTransactionDTO
   */
  fees?: Array<ApiSvcCategorizeFeeV2DTO>;
}

/**
 *
 * @export
 * @interface ApiSvcCategorizeFailure
 */
export interface ApiSvcCategorizeFailure {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeFailure
   */
  reason: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeFailure
   */
  txnId: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeFailure
   */
  success: ApiSvcCategorizeFailureSuccessEnum;
}

export const ApiSvcCategorizeFailureSuccessEnum = {
  False: false,
} as const;

export type ApiSvcCategorizeFailureSuccessEnum =
  typeof ApiSvcCategorizeFailureSuccessEnum[keyof typeof ApiSvcCategorizeFailureSuccessEnum];

/**
 *
 * @export
 * @interface ApiSvcCategorizeFeeDTO
 */
export interface ApiSvcCategorizeFeeDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeFeeDTO
   */
  walletId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeFeeDTO
   */
  feeCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeFeeDTO
   */
  feeContactId: string;
  /**
   *
   * @type {ApiSvcValueDTO}
   * @memberof ApiSvcCategorizeFeeDTO
   */
  source: ApiSvcValueDTO;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeFeeV2DTO
 */
export interface ApiSvcCategorizeFeeV2DTO {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeFeeV2DTO
   */
  isImputed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeFeeV2DTO
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeFeeV2DTO
   */
  feeCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeFeeV2DTO
   */
  feeContactId: string;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeFeeV2DTO
   */
  source: ApiSvcAssetValueDTO;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeInvoiceDTO
 */
export interface ApiSvcCategorizeInvoiceDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeInvoiceDTO
   */
  walletId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeInvoiceDTO
   */
  forexCategoryId: string;
  /**
   *
   * @type {ApiSvcValueDTO}
   * @memberof ApiSvcCategorizeInvoiceDTO
   */
  source: ApiSvcValueDTO;
  /**
   *
   * @type {ApiSvcValueDTO}
   * @memberof ApiSvcCategorizeInvoiceDTO
   */
  paid: ApiSvcValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeInvoiceDTO
   */
  invoiceId: string;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeInvoiceTransactionV2DTO
 */
export interface ApiSvcCategorizeInvoiceTransactionV2DTO {
  /**
   *
   * @type {ApiSvcCategorizationTransactionTypeInvoiceV2}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  type: ApiSvcCategorizationTransactionTypeInvoiceV2;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  accountingConnectionId: string;
  /**
   *
   * @type {Array<ApiSvcExchangeRateV2DTO>}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  exchangeRates: Array<ApiSvcExchangeRateV2DTO>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  exchangeRateVersion: number;
  /**
   *
   * @type {Array<ApiSvcCategorizeInvoiceV2DTO>}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  invoices: Array<ApiSvcCategorizeInvoiceV2DTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeUnappliedV2DTO>}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  unapplied?: Array<ApiSvcCategorizeUnappliedV2DTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeFeeV2DTO>}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  fees?: Array<ApiSvcCategorizeFeeV2DTO>;
  /**
   *
   * @type {ApiSvcInvoiceInputType}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  invoiceType: ApiSvcInvoiceInputType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeInvoiceTransactionV2DTO
   */
  totalFmv: string;
}

/**
 *
 * @export
 * @interface ApiSvcCategorizeInvoiceV2DTO
 */
export interface ApiSvcCategorizeInvoiceV2DTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeInvoiceV2DTO
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeInvoiceV2DTO
   */
  forexCategoryId: string;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeInvoiceV2DTO
   */
  source: ApiSvcAssetValueDTO;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeInvoiceV2DTO
   */
  paid: ApiSvcAssetValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeInvoiceV2DTO
   */
  invoiceId: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizeLineOperation = {
  Send: 'Send',
  Receive: 'Receive',
  Fee: 'Fee',
} as const;

export type ApiSvcCategorizeLineOperation =
  typeof ApiSvcCategorizeLineOperation[keyof typeof ApiSvcCategorizeLineOperation];

/**
 *
 * @export
 * @interface ApiSvcCategorizeMultiValueItemTO
 */
export interface ApiSvcCategorizeMultiValueItemTO {
  /**
   *
   * @type {Array<ApiSvcCategorizeMultiValueLineDTO>}
   * @memberof ApiSvcCategorizeMultiValueItemTO
   */
  lines: Array<ApiSvcCategorizeMultiValueLineDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeMultiValueItemTO
   */
  contactId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCategorizeMultiValueItemTO
   */
  metadataIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeMultiValueLineDTO
 */
export interface ApiSvcCategorizeMultiValueLineDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeMultiValueLineDTO
   */
  categoryId: string;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeMultiValueLineDTO
   */
  sourceValue: ApiSvcAssetValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeMultiValueLineDTO
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeMultiValueLineDTO
   */
  description: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCategorizeMultiValueLineDTO
   */
  metadataIds?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCategorizeMultiValueLineDTO
   */
  txnLineId?: number;
  /**
   *
   * @type {ApiSvcCategorizeMultiValueLineDTOAttributes}
   * @memberof ApiSvcCategorizeMultiValueLineDTO
   */
  attributes: ApiSvcCategorizeMultiValueLineDTOAttributes;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeMultiValueLineDTOAttributes
 */
export interface ApiSvcCategorizeMultiValueLineDTOAttributes {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeMultiValueLineDTOAttributes
   */
  forceZeroGain?: boolean;
  /**
   *
   * @type {ApiSvcCategorizeLineOperation}
   * @memberof ApiSvcCategorizeMultiValueLineDTOAttributes
   */
  operation: ApiSvcCategorizeLineOperation;
}

/**
 *
 * @export
 * @interface ApiSvcCategorizeMultivalueTransactionDTO
 */
export interface ApiSvcCategorizeMultivalueTransactionDTO {
  /**
   *
   * @type {ApiSvcCategorizationTransactionTypeMultivalue}
   * @memberof ApiSvcCategorizeMultivalueTransactionDTO
   */
  type: ApiSvcCategorizationTransactionTypeMultivalue;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcCategorizeMultivalueTransactionDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeMultivalueTransactionDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeMultivalueTransactionDTO
   */
  accountingConnectionId: string;
  /**
   *
   * @type {Array<ApiSvcExchangeRateV2DTO>}
   * @memberof ApiSvcCategorizeMultivalueTransactionDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateV2DTO>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCategorizeMultivalueTransactionDTO
   */
  exchangeRateVersion: number;
  /**
   *
   * @type {Array<ApiSvcCategorizeMultiValueItemTO>}
   * @memberof ApiSvcCategorizeMultivalueTransactionDTO
   */
  items: Array<ApiSvcCategorizeMultiValueItemTO>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCategorizeMultivalueTransactionDTO
   */
  metadataIds?: Array<string>;
}

/**
 *
 * @export
 * @interface ApiSvcCategorizeResult
 */
export interface ApiSvcCategorizeResult {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeResult
   */
  txnId: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeResult
   */
  success: ApiSvcCategorizeResultSuccessEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeResult
   */
  reason: string;
}

export const ApiSvcCategorizeResultSuccessEnum = {
  False: false,
} as const;

export type ApiSvcCategorizeResultSuccessEnum =
  typeof ApiSvcCategorizeResultSuccessEnum[keyof typeof ApiSvcCategorizeResultSuccessEnum];

/**
 *
 * @export
 * @interface ApiSvcCategorizeTradeAssetDTO
 */
export interface ApiSvcCategorizeTradeAssetDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTradeAssetDTO
   */
  walletId: string;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeTradeAssetDTO
   */
  asset: ApiSvcAssetValueDTO;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeTradeTransactionDTO
 */
export interface ApiSvcCategorizeTradeTransactionDTO {
  /**
   *
   * @type {ApiSvcCategorizationTransactionTypeTrade}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  type: ApiSvcCategorizationTransactionTypeTrade;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  accountingConnectionId: string;
  /**
   *
   * @type {Array<ApiSvcExchangeRateV2DTO>}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateV2DTO>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  exchangeRateVersion: number;
  /**
   *
   * @type {Array<ApiSvcCategorizeTradeAssetDTO>}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  disposedAssets: Array<ApiSvcCategorizeTradeAssetDTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeTradeAssetDTO>}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  acquiredAssets: Array<ApiSvcCategorizeTradeAssetDTO>;
  /**
   *
   * @type {ApiSvcCategorizeTradeTransactionDTOTradeFees}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  tradeFees?: ApiSvcCategorizeTradeTransactionDTOTradeFees;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCategorizeTradeTransactionDTO
   */
  metadataIds?: Array<string>;
}

/**
 *
 * @export
 * @interface ApiSvcCategorizeTradeTransactionDTOTradeFees
 */
export interface ApiSvcCategorizeTradeTransactionDTOTradeFees {
  /**
   *
   * @type {Array<ApiSvcCategorizeTradeAssetDTO>}
   * @memberof ApiSvcCategorizeTradeTransactionDTOTradeFees
   */
  fees: Array<ApiSvcCategorizeTradeAssetDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTradeTransactionDTOTradeFees
   */
  feeContactId: string;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeTransaction200Response
 */
export interface ApiSvcCategorizeTransaction200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransaction200Response
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransaction200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransaction200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransaction200Response
   */
  traceId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeTransactionDTO
 */
export interface ApiSvcCategorizeTransactionDTO {
  /**
   *
   * @type {ApiSvcCategorizationTransactionTypeAdvanceDeFi}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  type: ApiSvcCategorizationTransactionTypeAdvanceDeFi;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {Array<ApiSvcCategorizeInvoiceV2DTO>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  invoices: Array<ApiSvcCategorizeInvoiceV2DTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeUnappliedV2DTO>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  unapplied?: Array<ApiSvcCategorizeUnappliedV2DTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeFeeV2DTO>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  fees?: Array<ApiSvcCategorizeFeeV2DTO>;
  /**
   *
   * @type {Array<ApiSvcExchangeRateV2DTO>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateV2DTO>;
  /**
   *
   * @type {ApiSvcInvoiceInputType}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  invoiceType: ApiSvcInvoiceInputType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  totalFmv: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  accountingConnectionId: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  exchangeRateVersion: number;
  /**
   *
   * @type {Array<ApiSvcCategorizeAdvanceDeFiItemDTO>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  items: Array<ApiSvcCategorizeAdvanceDeFiItemDTO>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  metadataIds?: Array<string>;
  /**
   *
   * @type {Array<ApiSvcCategorizeTradeAssetDTO>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  disposedAssets: Array<ApiSvcCategorizeTradeAssetDTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeTradeAssetDTO>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  acquiredAssets: Array<ApiSvcCategorizeTradeAssetDTO>;
  /**
   *
   * @type {ApiSvcCategorizeTradeTransactionDTOTradeFees}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  tradeFees?: ApiSvcCategorizeTradeTransactionDTOTradeFees;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  notes: string;
  /**
   *
   * @type {Array<ApiSvcTransferAssetDTO>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  acquired: Array<ApiSvcTransferAssetDTO>;
  /**
   *
   * @type {Array<ApiSvcTransferAssetDTO>}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  disposed: Array<ApiSvcTransferAssetDTO>;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeTransactionDTO
   */
  totalFeeCostBasis?: ApiSvcAssetValueDTO;
}

/**
 *
 * @export
 * @interface ApiSvcCategorizeTransactions200Response
 */
export interface ApiSvcCategorizeTransactions200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransactions200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransactions200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransactions200Response
   */
  traceId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeTransferTransactionDTO
 */
export interface ApiSvcCategorizeTransferTransactionDTO {
  /**
   *
   * @type {ApiSvcCategorizationTransactionTypeTransfer}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  type: ApiSvcCategorizationTransactionTypeTransfer;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  accountingConnectionId: string;
  /**
   *
   * @type {Array<ApiSvcExchangeRateV2DTO>}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateV2DTO>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  exchangeRateVersion: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  metadataIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  notes: string;
  /**
   *
   * @type {Array<ApiSvcTransferAssetDTO>}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  acquired: Array<ApiSvcTransferAssetDTO>;
  /**
   *
   * @type {Array<ApiSvcTransferAssetDTO>}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  disposed: Array<ApiSvcTransferAssetDTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeFeeV2DTO>}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  fees?: Array<ApiSvcCategorizeFeeV2DTO>;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeTransferTransactionDTO
   */
  totalFeeCostBasis?: ApiSvcAssetValueDTO;
}

/**
 *
 * @export
 * @interface ApiSvcCategorizeUnappliedDTO
 */
export interface ApiSvcCategorizeUnappliedDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeUnappliedDTO
   */
  walletId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeUnappliedDTO
   */
  forexCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeUnappliedDTO
   */
  contactId: string;
  /**
   *
   * @type {ApiSvcValueDTO}
   * @memberof ApiSvcCategorizeUnappliedDTO
   */
  source: ApiSvcValueDTO;
  /**
   *
   * @type {ApiSvcValueDTO}
   * @memberof ApiSvcCategorizeUnappliedDTO
   */
  paid: ApiSvcValueDTO;
}
/**
 *
 * @export
 * @interface ApiSvcCategorizeUnappliedV2DTO
 */
export interface ApiSvcCategorizeUnappliedV2DTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeUnappliedV2DTO
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeUnappliedV2DTO
   */
  forexCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategorizeUnappliedV2DTO
   */
  contactId: string;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeUnappliedV2DTO
   */
  source: ApiSvcAssetValueDTO;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcCategorizeUnappliedV2DTO
   */
  paid: ApiSvcAssetValueDTO;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizedLineDirection = {
  Disposed: 'disposed',
  Acquired: 'acquired',
} as const;

export type ApiSvcCategorizedLineDirection =
  typeof ApiSvcCategorizedLineDirection[keyof typeof ApiSvcCategorizedLineDirection];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizedLineTypeFeeLine = {
  FeeLine: 'fee-line',
} as const;

export type ApiSvcCategorizedLineTypeFeeLine =
  typeof ApiSvcCategorizedLineTypeFeeLine[keyof typeof ApiSvcCategorizedLineTypeFeeLine];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizedLineTypeInvoicePaymentLine = {
  InvoicePaymentLine: 'invoice-payment-line',
} as const;

export type ApiSvcCategorizedLineTypeInvoicePaymentLine =
  typeof ApiSvcCategorizedLineTypeInvoicePaymentLine[keyof typeof ApiSvcCategorizedLineTypeInvoicePaymentLine];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizedLineTypeMultiContactInvoicePaymentLine = {
  MultiContactInvoicePaymentLine: 'multi-contact-invoice-payment-line',
} as const;

export type ApiSvcCategorizedLineTypeMultiContactInvoicePaymentLine =
  typeof ApiSvcCategorizedLineTypeMultiContactInvoicePaymentLine[keyof typeof ApiSvcCategorizedLineTypeMultiContactInvoicePaymentLine];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizedLineTypeMultiContactInvoiceUnappliedPaymentLine = {
  MultiContactInvoiceUnappliedPaymentLine: 'multi-contact-invoice-unapplied-payment-line',
} as const;

export type ApiSvcCategorizedLineTypeMultiContactInvoiceUnappliedPaymentLine =
  typeof ApiSvcCategorizedLineTypeMultiContactInvoiceUnappliedPaymentLine[keyof typeof ApiSvcCategorizedLineTypeMultiContactInvoiceUnappliedPaymentLine];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizedLineTypeMultiValueLine = {
  MultiValueLine: 'multi-value-line',
} as const;

export type ApiSvcCategorizedLineTypeMultiValueLine =
  typeof ApiSvcCategorizedLineTypeMultiValueLine[keyof typeof ApiSvcCategorizedLineTypeMultiValueLine];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizedLineTypeTradeLine = {
  TradeLine: 'trade-line',
} as const;

export type ApiSvcCategorizedLineTypeTradeLine =
  typeof ApiSvcCategorizedLineTypeTradeLine[keyof typeof ApiSvcCategorizedLineTypeTradeLine];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategorizedLineTypeTransferLine = {
  TransferLine: 'transfer-line',
} as const;

export type ApiSvcCategorizedLineTypeTransferLine =
  typeof ApiSvcCategorizedLineTypeTransferLine[keyof typeof ApiSvcCategorizedLineTypeTransferLine];

/**
 *
 * @export
 * @interface ApiSvcCategoryDTO
 */
export interface ApiSvcCategoryDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategoryDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategoryDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategoryDTO
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategoryDTO
   */
  code: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCategoryDTO
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategoryDTO
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategoryDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCategoryDTO
   */
  accountingConnectionId: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCategoryDTO
   */
  lastUpdatedSEC?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCategoryTypeIds = {
  Expense: 'expense',
  Revenue: 'revenue',
  Equity: 'equity',
  Liability: 'liability',
  Asset: 'asset',
  Bank: 'bank',
  Other: 'other',
} as const;

export type ApiSvcCategoryTypeIds = typeof ApiSvcCategoryTypeIds[keyof typeof ApiSvcCategoryTypeIds];

/**
 *
 * @export
 * @interface ApiSvcCheckPriceDirty200Response
 */
export interface ApiSvcCheckPriceDirty200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCheckPriceDirty200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCheckPriceDirty200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcCheckRuleApplication200Response
 */
export interface ApiSvcCheckRuleApplication200Response {
  /**
   *
   * @type {ApiSvcAccountingDetailsDTO}
   * @memberof ApiSvcCheckRuleApplication200Response
   */
  categorization?: ApiSvcAccountingDetailsDTO;
  /**
   *
   * @type {ApiSvcRuleProcessingResult}
   * @memberof ApiSvcCheckRuleApplication200Response
   */
  result: ApiSvcRuleProcessingResult;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCheckRuleApplication200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCheckRuleApplication200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCheckRuleApplication200Response
   */
  traceId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcClientTransferDirection = {
  Deposit: 'deposit',
  Withdrawal: 'withdrawal',
} as const;

export type ApiSvcClientTransferDirection =
  typeof ApiSvcClientTransferDirection[keyof typeof ApiSvcClientTransferDirection];

/**
 *
 * @export
 * @interface ApiSvcCloneCollectionRequest
 */
export interface ApiSvcCloneCollectionRequest {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCloneCollectionRequest
   */
  srcCollectionName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCloneCollectionRequest
   */
  destCollectionName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCloneCollectionRequest
   */
  documentIdsToCopy: string;
}
/**
 *
 * @export
 * @interface ApiSvcCloneOrgConfigDTO
 */
export interface ApiSvcCloneOrgConfigDTO {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCloneOrgConfigDTO
   */
  cloneConnectionToken?: boolean;
  /**
   *
   * @type {ApiSvcCloneTypes}
   * @memberof ApiSvcCloneOrgConfigDTO
   */
  type: ApiSvcCloneTypes;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCloneTypes = {
  Upgrade: 'upgrade',
  Migrate: 'migrate',
  Clone: 'clone',
  Replicate: 'replicate',
} as const;

export type ApiSvcCloneTypes = typeof ApiSvcCloneTypes[keyof typeof ApiSvcCloneTypes];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcClosedTransactionStates = {
  Syncing: 'syncing',
  Synced: 'synced',
  FailedToSync: 'failed-to-sync',
  MarkedSynced: 'marked-synced',
  ClosedNeedsReview: 'closed-needs-review',
} as const;

export type ApiSvcClosedTransactionStates =
  typeof ApiSvcClosedTransactionStates[keyof typeof ApiSvcClosedTransactionStates];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCoin = {
  NUMBER_999999: 999999,
  NUMBER_1: 1,
  NUMBER_5: 5,
  NUMBER_10: 10,
  NUMBER_20: 20,
  NUMBER_30: 30,
  NUMBER_703: 703,
  NUMBER_704: 704,
  NUMBER_731: 731,
  NUMBER_800: 800,
  NUMBER_1000: 1000,
  NUMBER_1001: 1001,
  NUMBER_1002: 1002,
  NUMBER_1003: 1003,
  NUMBER_1005: 1005,
  NUMBER_1012: 1012,
  NUMBER_1018: 1018,
  NUMBER_1022: 1022,
  NUMBER_1023: 1023,
  NUMBER_1025: 1025,
  NUMBER_1041: 1041,
  NUMBER_1044: 1044,
  NUMBER_1072: 1072,
  NUMBER_1087: 1087,
  NUMBER_1103: 1103,
  NUMBER_1107: 1107,
  NUMBER_1152: 1152,
  NUMBER_2435: 2435,
  NUMBER_2729: 2729,
  NUMBER_2882: 2882,
  NUMBER_3170: 3170,
  NUMBER_3315: 3315,
} as const;

export type ApiSvcCoin = typeof ApiSvcCoin[keyof typeof ApiSvcCoin];

/**
 *
 * @export
 * @interface ApiSvcCoinMapping
 */
export interface ApiSvcCoinMapping {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCoinMapping
   */
  currencyId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCoinMapping
   */
  ticker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCoinMapping
   */
  unit?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCoinMapping
   */
  networkId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCoinMapping
   */
  networkName?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCoinMapping
   */
  nft?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCoinUnit = {
  NUMBER_999999: 999999,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_10: 10,
  NUMBER_11: 11,
  NUMBER_20: 20,
  NUMBER_30: 30,
  NUMBER_50: 50,
  NUMBER_51: 51,
  NUMBER_52: 52,
  NUMBER_53: 53,
  NUMBER_54: 54,
  NUMBER_55: 55,
  NUMBER_56: 56,
  NUMBER_703: 703,
  NUMBER_704: 704,
  NUMBER_731: 731,
  NUMBER_800: 800,
  NUMBER_1000: 1000,
  NUMBER_1001: 1001,
  NUMBER_1002: 1002,
  NUMBER_1003: 1003,
  NUMBER_1005: 1005,
  NUMBER_1012: 1012,
  NUMBER_1018: 1018,
  NUMBER_1022: 1022,
  NUMBER_1023: 1023,
  NUMBER_1025: 1025,
  NUMBER_1041: 1041,
  NUMBER_1044: 1044,
  NUMBER_1072: 1072,
  NUMBER_1087: 1087,
  NUMBER_1103: 1103,
  NUMBER_1107: 1107,
  NUMBER_1152: 1152,
  NUMBER_2435: 2435,
  NUMBER_2729: 2729,
  NUMBER_2882: 2882,
  NUMBER_3170: 3170,
  NUMBER_3315: 3315,
} as const;

export type ApiSvcCoinUnit = typeof ApiSvcCoinUnit[keyof typeof ApiSvcCoinUnit];

/**
 *
 * @export
 * @interface ApiSvcColumnUniqueValueResponse
 */
export interface ApiSvcColumnUniqueValueResponse {
  /**
   *
   * @type {Array<ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId>}
   * @memberof ApiSvcColumnUniqueValueResponse
   */
  values: Array<ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcColumnUniqueValueResponse
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface ApiSvcCombinationDetailsDTO
 */
export interface ApiSvcCombinationDetailsDTO {
  /**
   *
   * @type {Array<number>}
   * @memberof ApiSvcCombinationDetailsDTO
   */
  timestampSECRange?: Array<number>;
  /**
   *
   * @type {ApiSvcCombinationMethods}
   * @memberof ApiSvcCombinationDetailsDTO
   */
  type: ApiSvcCombinationMethods;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCombinationMethods = {
  Combined: 'combined',
  RolledUp: 'rolled-up',
} as const;

export type ApiSvcCombinationMethods = typeof ApiSvcCombinationMethods[keyof typeof ApiSvcCombinationMethods];

/**
 *
 * @export
 * @interface ApiSvcCombinedTxnDTO
 */
export interface ApiSvcCombinedTxnDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCombinedTxnDTO
   */
  parentTxnId: string;
  /**
   *
   * @type {ApiSvcCombinationMethods}
   * @memberof ApiSvcCombinedTxnDTO
   */
  type: ApiSvcCombinationMethods;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcComparison = {
  Eq: 'EQ',
  Lt: 'LT',
  Lte: 'LTE',
  Gt: 'GT',
  Gte: 'GTE',
} as const;

export type ApiSvcComparison = typeof ApiSvcComparison[keyof typeof ApiSvcComparison];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcComparisonGraphQL = {
  Eq: 'EQ',
  Lt: 'LT',
  Lte: 'LTE',
  Gt: 'GT',
  Gte: 'GTE',
} as const;

export type ApiSvcComparisonGraphQL = typeof ApiSvcComparisonGraphQL[keyof typeof ApiSvcComparisonGraphQL];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcConnectionCategory = {
  AccountingConnection: 'AccountingConnection',
  ExchangeConnection: 'ExchangeConnection',
  ExchangeConnectionPointer: 'ExchangeConnectionPointer',
} as const;

export type ApiSvcConnectionCategory = typeof ApiSvcConnectionCategory[keyof typeof ApiSvcConnectionCategory];

/**
 *
 * @export
 * @interface ApiSvcConnectionDTO
 */
export interface ApiSvcConnectionDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  orgId: string;
  /**
   *
   * @type {ApiSvcConnectionType}
   * @memberof ApiSvcConnectionDTO
   */
  type: ApiSvcConnectionType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  provider: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcConnectionDTO
   */
  lastSyncSEC?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcConnectionDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcConnectionDTO
   */
  isDeleted: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcConnectionDTO
   */
  isSetupComplete: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  walletId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  contactId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  accountId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  subAccountId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  s3Bucket?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  s3Prefix?: string;
  /**
   *
   * @type {ApiSvcConnectionCategory}
   * @memberof ApiSvcConnectionDTO
   */
  category?: ApiSvcConnectionCategory;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcConnectionDTO
   */
  syncerVersion?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  feeAccountCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcConnectionDTO
   */
  syncSubAccounts?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  accountCode?: string;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcConnectionDTO
   */
  connectionSpecificFields?: object;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcConnectionDTO
   */
  isDefault?: boolean;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcConnectionDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionDTO
   */
  subAccountName?: string;
}

/**
 *
 * @export
 * @interface ApiSvcConnectionFlags
 */
export interface ApiSvcConnectionFlags {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcConnectionFlags
   */
  inferVaultTradeMovements?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcConnectionFlags
   */
  useCompletedAtStartTime?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcConnectionFlags
   */
  syncStartTime?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcConnectionFlags
   */
  syncEndTime?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionFlags
   */
  walletCreateFilter?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcConnectionSyncStatus = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_10: 10,
  NUMBER_20: 20,
} as const;

export type ApiSvcConnectionSyncStatus = typeof ApiSvcConnectionSyncStatus[keyof typeof ApiSvcConnectionSyncStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcConnectionType = {
  Xero: 'xero',
  Quickbooks: 'quickbooks',
  Binance: 'binance',
  Bitfinex: 'bitfinex',
  Kraken: 'kraken',
  Itbit: 'itbit',
  Bitstamp: 'bitstamp',
  Gemini: 'gemini',
  Bitgo: 'bitgo',
  Paxos: 'paxos',
  Nydig: 'nydig',
  Bittrex: 'bittrex',
  Ftx: 'ftx',
  Netsuite: 'netsuite',
  SageIntacct: 'sage-intacct',
  Sfox: 'sfox',
  PrimeTrust: 'prime-trust',
  CoinbasePro: 'coinbase-pro',
  CoinbaseCustody: 'coinbase-custody',
  CoinbaseCommerce: 'coinbase-commerce',
  CoinbasePrime: 'coinbase-prime',
  CoinbaseRetail: 'coinbase-retail',
  Fireblocks: 'fireblocks',
  CryptoDotCom: 'crypto-dot-com',
  Circle: 'circle',
  Foundry: 'foundry',
  Test: 'test',
  Anchorage: 'anchorage',
  Manual: 'manual',
  Request: 'request',
} as const;

export type ApiSvcConnectionType = typeof ApiSvcConnectionType[keyof typeof ApiSvcConnectionType];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcConnectionTypes = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
  NUMBER_11: 11,
  NUMBER_12: 12,
  NUMBER_13: 13,
  NUMBER_14: 14,
  NUMBER_15: 15,
  NUMBER_16: 16,
  NUMBER_17: 17,
  NUMBER_18: 18,
  NUMBER_19: 19,
  NUMBER_20: 20,
  NUMBER_21: 21,
  NUMBER_22: 22,
  NUMBER_23: 23,
  NUMBER_24: 24,
  NUMBER_25: 25,
  NUMBER_50: 50,
  NUMBER_100: 100,
  NUMBER_200: 200,
  NUMBER_300: 300,
  NUMBER_400: 400,
  NUMBER_999: 999,
} as const;

export type ApiSvcConnectionTypes = typeof ApiSvcConnectionTypes[keyof typeof ApiSvcConnectionTypes];

/**
 *
 * @export
 * @interface ApiSvcConnectionsErrorResponse
 */
export interface ApiSvcConnectionsErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionsErrorResponse
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionsErrorResponse
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcConnectionsResponse
 */
export interface ApiSvcConnectionsResponse {
  /**
   *
   * @type {Array<ApiSvcConnectionDTO>}
   * @memberof ApiSvcConnectionsResponse
   */
  items: Array<ApiSvcConnectionDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionsResponse
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcConnectionsResponse
   */
  nextPage?: string;
}
/**
 *
 * @export
 * @interface ApiSvcContactAddressDTO
 */
export interface ApiSvcContactAddressDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactAddressDTO
   */
  networkId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactAddressDTO
   */
  defaultRevenueCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactAddressDTO
   */
  defaultExpenseCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactAddressDTO
   */
  memo?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactAddressDTO
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactAddressDTO
   * @deprecated
   */
  coin?: string;
}
/**
 *
 * @export
 * @interface ApiSvcContactDTO
 */
export interface ApiSvcContactDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcContactDTO
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  type: string;
  /**
   *
   * @type {Array<ApiSvcContactAddressDTO>}
   * @memberof ApiSvcContactDTO
   */
  addresses?: Array<ApiSvcContactAddressDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  emailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  remoteId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  connectionId: string;
  /**
   * Equivalent to connectionId
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  accountingConnectionId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  defaultExpenseCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactDTO
   */
  defaultRevenueCategoryId?: string;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcContactDTO
   */
  metadata?: object;
}
/**
 *
 * @export
 * @interface ApiSvcContactErrorResponse
 */
export interface ApiSvcContactErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactErrorResponse
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcContactErrorResponse
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcCostAverageOverride
 */
export interface ApiSvcCostAverageOverride {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCostAverageOverride
   */
  ticker: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCostAverageOverride
   */
  value: number;
}
/**
 *
 * @export
 * @interface ApiSvcCostBasisDTO
 */
export interface ApiSvcCostBasisDTO {
  /**
   *
   * @type {number}
   * @memberof ApiSvcCostBasisDTO
   */
  cost: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCostBasisDTO
   */
  exchangeRate: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCostBasisDTO
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCostBasisDTO
   */
  invoiceId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCostBasisDTO
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCostBasisDTO
   */
  costBasisType: string;
}
/**
 *
 * @export
 * @interface ApiSvcCostBasisInput
 */
export interface ApiSvcCostBasisInput {
  /**
   *
   * @type {ApiSvcCostBasisType}
   * @memberof ApiSvcCostBasisInput
   */
  costBasisType: ApiSvcCostBasisType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCostBasisInput
   */
  notes?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCostBasisInput
   */
  invoiceId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCostBasisInput
   */
  currency: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCostBasisInput
   */
  exchangeRate: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCostBasisInput
   */
  cost: number;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCostBasisType = {
  ExchangeRate: 'ExchangeRate',
  Invoice: 'Invoice',
  Manual: 'Manual',
  NoConversion: 'NoConversion',
} as const;

export type ApiSvcCostBasisType = typeof ApiSvcCostBasisType[keyof typeof ApiSvcCostBasisType];

/**
 *
 * @export
 * @interface ApiSvcCreateAnchorage
 */
export interface ApiSvcCreateAnchorage {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateAnchorage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateAnchorage
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateAnchorage
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateAnchorage
   */
  apiKey: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateApiKeyDTO
 */
export interface ApiSvcCreateApiKeyDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateApiKeyDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateApiKeyDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateApiKeyDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateApiKeyDTO
   */
  apiKey: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateBitGoDTO
 */
export interface ApiSvcCreateBitGoDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateBitGoDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateBitGoDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateBitGoDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateBitGoDTO
   */
  token: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateBitstampDTO
 */
export interface ApiSvcCreateBitstampDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateBitstampDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateBitstampDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateBitstampDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateBitstampDTO
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateBitstampDTO
   */
  secret: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateCategory200Response
 */
export interface ApiSvcCreateCategory200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCategory200Response
   */
  id: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateCategoryRequest
 */
export interface ApiSvcCreateCategoryRequest {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCategoryRequest
   */
  connectionId?: string;
  /**
   *
   * @type {ApiSvcAccountingSourceIds}
   * @memberof ApiSvcCreateCategoryRequest
   */
  source: ApiSvcAccountingSourceIds;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCategoryRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCategoryRequest
   */
  name: string;
  /**
   *
   * @type {ApiSvcCategoryTypeIds}
   * @memberof ApiSvcCreateCategoryRequest
   */
  type: ApiSvcCategoryTypeIds;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCategoryRequest
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCategoryRequest
   */
  description: string;
}

/**
 *
 * @export
 * @interface ApiSvcCreateClientTransaction200Response
 */
export interface ApiSvcCreateClientTransaction200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateClientTransaction200Response
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateClientTransaction200Response
   */
  success: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcCreateCoinbasePrime
 */
export interface ApiSvcCreateCoinbasePrime {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbasePrime
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbasePrime
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateCoinbasePrime
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbasePrime
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbasePrime
   */
  passphrase: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbasePrime
   */
  signingKey: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateCoinbaseProDTO
 */
export interface ApiSvcCreateCoinbaseProDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbaseProDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbaseProDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateCoinbaseProDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbaseProDTO
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbaseProDTO
   */
  apiSecret: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateCoinbaseProDTO
   */
  apiPassphrase: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateCoinbaseProDTO
   */
  isPrime?: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcCreateConnectionDTO
 */
export interface ApiSvcCreateConnectionDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateConnectionDTO
   */
  subsidiaryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateConnectionDTO
   */
  name?: string;
  /**
   *
   * @type {ApiSvcCreateLocationDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createBinance?: ApiSvcCreateLocationDTO;
  /**
   *
   * @type {ApiSvcCreateFtx}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createFtx?: ApiSvcCreateFtx;
  /**
   *
   * @type {ApiSvcCreateAnchorage}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createAnchorage?: ApiSvcCreateAnchorage;
  /**
   *
   * @type {ApiSvcCreateKeySecretDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createBittrex?: ApiSvcCreateKeySecretDTO;
  /**
   *
   * @type {ApiSvcCreateKeySecretDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createCoinbaseCustody?: ApiSvcCreateKeySecretDTO;
  /**
   *
   * @type {ApiSvcCreateKeySecretDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createBitfinex?: ApiSvcCreateKeySecretDTO;
  /**
   *
   * @type {ApiSvcCreateKeySecretDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createGemini?: ApiSvcCreateKeySecretDTO;
  /**
   *
   * @type {ApiSvcCreatePrimeTrustDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createPrimeTrust?: ApiSvcCreatePrimeTrustDTO;
  /**
   *
   * @type {ApiSvcCreatePrivateKeySecretDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createKraken?: ApiSvcCreatePrivateKeySecretDTO;
  /**
   *
   * @type {ApiSvcCreateXeroDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createXero?: ApiSvcCreateXeroDTO;
  /**
   *
   * @type {ApiSvcCreateNyDig}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createNyDig?: ApiSvcCreateNyDig;
  /**
   *
   * @type {ApiSvcCreateCoinbaseProDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createCoinbasePro?: ApiSvcCreateCoinbaseProDTO;
  /**
   *
   * @type {ApiSvcCreateItBitDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createItBit?: ApiSvcCreateItBitDTO;
  /**
   *
   * @type {ApiSvcCreateBitstampDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createBitstamp?: ApiSvcCreateBitstampDTO;
  /**
   *
   * @type {ApiSvcCreateBitGoDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createBitGo?: ApiSvcCreateBitGoDTO;
  /**
   *
   * @type {ApiSvcCreatePaxosDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createPaxos?: ApiSvcCreatePaxosDTO;
  /**
   *
   * @type {ApiSvcCreateNetSuite}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createNetSuite?: ApiSvcCreateNetSuite;
  /**
   *
   * @type {ApiSvcCreateSageIntacct}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createSageIntacct?: ApiSvcCreateSageIntacct;
  /**
   *
   * @type {ApiSvcCreateSFOX}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createSFOX?: ApiSvcCreateSFOX;
  /**
   *
   * @type {ApiSvcCreateKeySecretDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createCoinbaseCommerce?: ApiSvcCreateKeySecretDTO;
  /**
   *
   * @type {ApiSvcCreateCoinbasePrime}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createCoinbasePrime?: ApiSvcCreateCoinbasePrime;
  /**
   *
   * @type {ApiSvcCreatePrivateKeySecretDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createFireblocks?: ApiSvcCreatePrivateKeySecretDTO;
  /**
   *
   * @type {ApiSvcCreateKeySecretDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createCoinbaseRetail?: ApiSvcCreateKeySecretDTO;
  /**
   *
   * @type {ApiSvcCreateKeySecretDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createCryptoDotCom?: ApiSvcCreateKeySecretDTO;
  /**
   *
   * @type {ApiSvcCreateApiKeyDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createCircle?: ApiSvcCreateApiKeyDTO;
  /**
   *
   * @type {ApiSvcCreateFoundryDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createFoundry?: ApiSvcCreateFoundryDTO;
  /**
   *
   * @type {ApiSvcCreateRequestApiKeyDTO}
   * @memberof ApiSvcCreateConnectionDTO
   */
  createRequest?: ApiSvcCreateRequestApiKeyDTO;
}
/**
 *
 * @export
 * @interface ApiSvcCreateFoundryDTO
 */
export interface ApiSvcCreateFoundryDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateFoundryDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateFoundryDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateFoundryDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateFoundryDTO
   */
  xApiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateFoundryDTO
   */
  subAccountName: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateFtx
 */
export interface ApiSvcCreateFtx {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateFtx
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateFtx
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateFtx
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateFtx
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateFtx
   */
  apiSecret: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateFtx
   */
  location: ApiSvcCreateFtxLocationEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateFtx
   */
  syncSubAccounts: boolean;
}

export const ApiSvcCreateFtxLocationEnum = {
  Us: 'us',
  Standard: 'standard',
} as const;

export type ApiSvcCreateFtxLocationEnum = typeof ApiSvcCreateFtxLocationEnum[keyof typeof ApiSvcCreateFtxLocationEnum];

/**
 *
 * @export
 * @interface ApiSvcCreateInventoryViewRequest
 */
export interface ApiSvcCreateInventoryViewRequest {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateInventoryViewRequest
   */
  name: string;
  /**
   *
   * @type {ApiSvcGainLossConfigCreateRequest}
   * @memberof ApiSvcCreateInventoryViewRequest
   */
  config: ApiSvcGainLossConfigCreateRequest;
  /**
   *
   * @type {ApiSvcGainLossSetupDTO}
   * @memberof ApiSvcCreateInventoryViewRequest
   */
  strategy: ApiSvcGainLossSetupDTO;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateInventoryViewRequest
   */
  impair: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateInventoryViewRequest
   */
  ignoreNFTs: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateInventoryViewRequest
   */
  ignoreOrgWrappingTreatments: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcCreateItBitDTO
 */
export interface ApiSvcCreateItBitDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateItBitDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateItBitDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateItBitDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateItBitDTO
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateItBitDTO
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateItBitDTO
   */
  secret: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateKeySecretDTO
 */
export interface ApiSvcCreateKeySecretDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateKeySecretDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateKeySecretDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateKeySecretDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateKeySecretDTO
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateKeySecretDTO
   */
  apiSecret: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateLocationDTO
 */
export interface ApiSvcCreateLocationDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateLocationDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateLocationDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateLocationDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateLocationDTO
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateLocationDTO
   */
  apiSecret: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateLocationDTO
   */
  location: ApiSvcCreateLocationDTOLocationEnum;
}

export const ApiSvcCreateLocationDTOLocationEnum = {
  Us: 'us',
  Standard: 'standard',
} as const;

export type ApiSvcCreateLocationDTOLocationEnum =
  typeof ApiSvcCreateLocationDTOLocationEnum[keyof typeof ApiSvcCreateLocationDTOLocationEnum];

/**
 *
 * @export
 * @interface ApiSvcCreateNetSuite
 */
export interface ApiSvcCreateNetSuite {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNetSuite
   */
  subsidiary?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNetSuite
   */
  assetAccountCode: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNetSuite
   */
  feeAccountCode: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNetSuite
   */
  account: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNetSuite
   */
  consumerKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNetSuite
   */
  consumerSecret: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNetSuite
   */
  tokenId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNetSuite
   */
  tokenSecret: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNetSuite
   */
  name?: string;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcCreateNetSuite
   */
  accountMappingConfig?: object;
}
/**
 *
 * @export
 * @interface ApiSvcCreateNyDig
 */
export interface ApiSvcCreateNyDig {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNyDig
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNyDig
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateNyDig
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNyDig
   */
  s3Bucket: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateNyDig
   */
  s3Prefix: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreatePaxosDTO
 */
export interface ApiSvcCreatePaxosDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePaxosDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePaxosDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreatePaxosDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePaxosDTO
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePaxosDTO
   */
  clientSecret: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePaxosDTO
   */
  scope: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePaxosDTO
   */
  itBitUserId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePaxosDTO
   */
  itBitKey?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePaxosDTO
   */
  itBitSecret?: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreatePrimeTrustDTO
 */
export interface ApiSvcCreatePrimeTrustDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrimeTrustDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrimeTrustDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreatePrimeTrustDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreatePrimeTrustDTO
   */
  useJwt: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrimeTrustDTO
   */
  jwt?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrimeTrustDTO
   */
  user?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrimeTrustDTO
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrimeTrustDTO
   */
  accountId: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreatePrivateKeySecretDTO
 */
export interface ApiSvcCreatePrivateKeySecretDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrivateKeySecretDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrivateKeySecretDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreatePrivateKeySecretDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrivateKeySecretDTO
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreatePrivateKeySecretDTO
   */
  privateKey: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateRequestApiKeyDTO
 */
export interface ApiSvcCreateRequestApiKeyDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateRequestApiKeyDTO
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateRequestApiKeyDTO
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateResult
 */
export interface ApiSvcCreateResult {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateResult
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateResult
   */
  id: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateSFOX
 */
export interface ApiSvcCreateSFOX {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSFOX
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSFOX
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateSFOX
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSFOX
   */
  apiKey: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateSageIntacct
 */
export interface ApiSvcCreateSageIntacct {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSageIntacct
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSageIntacct
   */
  entityId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSageIntacct
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSageIntacct
   */
  userPassword: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSageIntacct
   */
  assetAccountCode: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSageIntacct
   */
  feeAccountCode: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSageIntacct
   */
  baseCurrency?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSageIntacct
   */
  sageDigitalAssetAccountCode?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSageIntacct
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateSodaReportSnapshotRequest
 */
export interface ApiSvcCreateSodaReportSnapshotRequest {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSodaReportSnapshotRequest
   */
  title: string;
  /**
   *
   * @type {ApiSvcRecordType}
   * @memberof ApiSvcCreateSodaReportSnapshotRequest
   */
  recordType: ApiSvcRecordType;
  /**
   *
   * @type {ApiSvcRecordBucketSodaReports}
   * @memberof ApiSvcCreateSodaReportSnapshotRequest
   */
  recordBucket: ApiSvcRecordBucketSodaReports;
  /**
   *
   * @type {Array<ApiSvcSodaLine>}
   * @memberof ApiSvcCreateSodaReportSnapshotRequest
   */
  lines: Array<ApiSvcSodaLine>;
  /**
   *
   * @type {ApiSvcCreateSodaReportSnapshotRequestConfig}
   * @memberof ApiSvcCreateSodaReportSnapshotRequest
   */
  config: ApiSvcCreateSodaReportSnapshotRequestConfig;
}

/**
 *
 * @export
 * @interface ApiSvcCreateSodaReportSnapshotRequestConfig
 */
export interface ApiSvcCreateSodaReportSnapshotRequestConfig {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSodaReportSnapshotRequestConfig
   */
  runId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSodaReportSnapshotRequestConfig
   */
  inventoryViewId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSodaReportSnapshotRequestConfig
   */
  reportAsOfDate: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateSubsidiaryRequest
 */
export interface ApiSvcCreateSubsidiaryRequest {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSubsidiaryRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateSubsidiaryRequest
   */
  parentSubsidiaryId: string;
  /**
   *
   * @type {ApiSvcSubsidiaryRelationship}
   * @memberof ApiSvcCreateSubsidiaryRequest
   */
  relationship: ApiSvcSubsidiaryRelationship;
  /**
   *
   * @type {ApiSvcFiatTickers}
   * @memberof ApiSvcCreateSubsidiaryRequest
   */
  baseCurrency: ApiSvcFiatTickers;
}

/**
 *
 * @export
 * @interface ApiSvcCreateTransactionBatch200Response
 */
export interface ApiSvcCreateTransactionBatch200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionBatch200Response
   */
  systemId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionBatch200Response
   */
  error: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateTransactionBatch200ResponseAnyOf
 */
export interface ApiSvcCreateTransactionBatch200ResponseAnyOf {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionBatch200ResponseAnyOf
   */
  systemId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionBatch200ResponseAnyOf
   */
  error: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateTransactionDTO
 */
export interface ApiSvcCreateTransactionDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  systemId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  blockchainId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  transactionType?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  accountId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  amount?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  amountTicker?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  cost?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  costTicker?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  fee?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  feeTicker?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateTransactionDTO
   */
  taxExempt?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  categoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  contactId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  remoteContactId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  accountingConnectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  memo?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  fromAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  toAddress?: string;
  /**
   *
   * @type {any}
   * @memberof ApiSvcCreateTransactionDTO
   */
  metadata?: any;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCreateTransactionDTO
   */
  metadataIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  tradeId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransactionDTO
   */
  importId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateTransferRecord
 */
export interface ApiSvcCreateTransferRecord {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransferRecord
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcCreateTransferRecord
   */
  createdSEC: number;
  /**
   *
   * @type {ApiSvcClientTransferDirection}
   * @memberof ApiSvcCreateTransferRecord
   */
  direction: ApiSvcClientTransferDirection;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransferRecord
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransferRecord
   */
  ticker: string;
  /**
   *
   * @type {ApiSvcTxnOrigin}
   * @memberof ApiSvcCreateTransferRecord
   */
  txnOrigin: ApiSvcTxnOrigin;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransferRecord
   */
  blockchainId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransferRecord
   */
  networkId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransferRecord
   */
  costBasisAsset?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateTransferRecord
   */
  costBasisAmount?: string;
}

/**
 *
 * @export
 * @interface ApiSvcCreateUser201Response
 */
export interface ApiSvcCreateUser201Response {
  /**
   *
   * @type {ApiSvcCreateUser201ResponseAnyOfMeta}
   * @memberof ApiSvcCreateUser201Response
   */
  meta: ApiSvcCreateUser201ResponseAnyOfMeta;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201Response
   */
  organization: string;
  /**
   *
   * @type {Array<any>}
   * @memberof ApiSvcCreateUser201Response
   */
  groups: Array<any>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateUser201Response
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201Response
   */
  locale: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201Response
   */
  displayName: string;
  /**
   *
   * @type {ApiSvcCreateUser201ResponseAnyOfName}
   * @memberof ApiSvcCreateUser201Response
   */
  name: ApiSvcCreateUser201ResponseAnyOfName;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201Response
   */
  userName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201Response
   */
  id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCreateUser201Response
   */
  schemas: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201Response
   */
  errors: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateUser201ResponseAnyOf
 */
export interface ApiSvcCreateUser201ResponseAnyOf {
  /**
   *
   * @type {ApiSvcCreateUser201ResponseAnyOfMeta}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  meta: ApiSvcCreateUser201ResponseAnyOfMeta;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  organization: string;
  /**
   *
   * @type {Array<any>}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  groups: Array<any>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  locale: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  displayName: string;
  /**
   *
   * @type {ApiSvcCreateUser201ResponseAnyOfName}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  name: ApiSvcCreateUser201ResponseAnyOfName;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  userName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCreateUser201ResponseAnyOf
   */
  schemas: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcCreateUser201ResponseAnyOf1
 */
export interface ApiSvcCreateUser201ResponseAnyOf1 {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOf1
   */
  errors: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateUser201ResponseAnyOfMeta
 */
export interface ApiSvcCreateUser201ResponseAnyOfMeta {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOfMeta
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOfMeta
   */
  resourceType: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateUser201ResponseAnyOfName
 */
export interface ApiSvcCreateUser201ResponseAnyOfName {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOfName
   */
  familyName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateUser201ResponseAnyOfName
   */
  givenName: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateVarianceReport200Response
 */
export interface ApiSvcCreateVarianceReport200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateVarianceReport200Response
   */
  csv: string;
  /**
   *
   * @type {ApiSvcCreateVarianceReport200ResponseMetadata}
   * @memberof ApiSvcCreateVarianceReport200Response
   */
  metadata: ApiSvcCreateVarianceReport200ResponseMetadata;
}
/**
 *
 * @export
 * @interface ApiSvcCreateVarianceReport200ResponseMetadata
 */
export interface ApiSvcCreateVarianceReport200ResponseMetadata {
  /**
   *
   * @type {Array<ApiSvcVarianceReportLineV2>}
   * @memberof ApiSvcCreateVarianceReport200ResponseMetadata
   */
  lines: Array<ApiSvcVarianceReportLineV2>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateVarianceReport200ResponseMetadata
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateVarianceReport200ResponseMetadata
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateVarianceReport200ResponseMetadata
   */
  walletId: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateVarianceReport200ResponseMetadata
   */
  coinId: string | null;
}
/**
 *
 * @export
 * @interface ApiSvcCreateWallet200Response
 */
export interface ApiSvcCreateWallet200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWallet200Response
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWallet200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWallet200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateWalletDTO
 */
export interface ApiSvcCreateWalletDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  name: string;
  /**
   *
   * @type {ApiSvcCreateWalletTypes}
   * @memberof ApiSvcCreateWalletDTO
   */
  type: ApiSvcCreateWalletTypes;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  subsidiaryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  accountingConnection?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  defaultInflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  defaultInflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  defaultOutflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  defaultOutflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  defaultFeeContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  defaultFeeCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  feeContact?: string;
  /**
   *
   * @type {Array<ApiSvcWatchDTO>}
   * @memberof ApiSvcCreateWalletDTO
   */
  watches?: Array<ApiSvcWatchDTO>;
  /**
   *
   * @type {ApiSvcWatchDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  watch?: ApiSvcWatchDTO;
  /**
   *
   * @type {ApiSvcEnterpriseWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  enterpriseWallet?: ApiSvcEnterpriseWalletDTO;
  /**
   *
   * @type {ApiSvcMultisigWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  multisig?: ApiSvcMultisigWalletDTO;
  /**
   *
   * @type {ApiSvcHardwareWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  hardware?: ApiSvcHardwareWalletDTO;
  /**
   *
   * @type {ApiSvcManualWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  manual?: ApiSvcManualWalletDTO;
  /**
   *
   * @type {ApiSvcManualBlockchainWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  manualBlockchain?: ApiSvcManualBlockchainWalletDTO;
  /**
   *
   * @type {ApiSvcUserWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  user?: ApiSvcUserWalletDTO;
  /**
   *
   * @type {ApiSvcAddressWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  addressBasedBlockchain?: ApiSvcAddressWalletDTO;
  /**
   *
   * @type {ApiSvcAccountBasedBlockchainWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  accountBasedBlockchain?: ApiSvcAccountBasedBlockchainWalletDTO;
  /**
   *
   * @type {ApiSvcLrcMarketplaceWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  lrcMarketplace?: ApiSvcLrcMarketplaceWalletDTO;
  /**
   *
   * @type {ApiSvcGroupWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  group?: ApiSvcGroupWalletDTO;
  /**
   *
   * @type {ApiSvcDefiWalletDTO}
   * @memberof ApiSvcCreateWalletDTO
   */
  defi?: ApiSvcDefiWalletDTO;
  /**
   *
   * @type {ApiSvcWalletsFlags}
   * @memberof ApiSvcCreateWalletDTO
   */
  flags?: ApiSvcWalletsFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletDTO
   */
  walletRoleId?: string;
}

/**
 *
 * @export
 * @interface ApiSvcCreateWalletRequest
 */
export interface ApiSvcCreateWalletRequest {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  name: string;
  /**
   *
   * @type {ApiSvcCreateWalletTypes}
   * @memberof ApiSvcCreateWalletRequest
   */
  type: ApiSvcCreateWalletTypes;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  remoteId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  connectionId?: string;
  /**
   *
   * @type {any}
   * @memberof ApiSvcCreateWalletRequest
   */
  metadata?: any;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  defaultInflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  defaultInflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  defaultOutflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  defaultOutflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  defaultFeeContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  defaultFeeCategory?: string;
  /**
   *
   * @type {ApiSvcWalletsFlags}
   * @memberof ApiSvcCreateWalletRequest
   */
  flags?: ApiSvcWalletsFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  subsidiaryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  accountingConnection?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  feeContact?: string;
  /**
   *
   * @type {Array<ApiSvcWatchDTO>}
   * @memberof ApiSvcCreateWalletRequest
   */
  watches?: Array<ApiSvcWatchDTO>;
  /**
   *
   * @type {ApiSvcWatchDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  watch?: ApiSvcWatchDTO;
  /**
   *
   * @type {ApiSvcEnterpriseWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  enterpriseWallet?: ApiSvcEnterpriseWalletDTO;
  /**
   *
   * @type {ApiSvcMultisigWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  multisig?: ApiSvcMultisigWalletDTO;
  /**
   *
   * @type {ApiSvcHardwareWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  hardware?: ApiSvcHardwareWalletDTO;
  /**
   *
   * @type {ApiSvcManualWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  manual?: ApiSvcManualWalletDTO;
  /**
   *
   * @type {ApiSvcManualBlockchainWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  manualBlockchain?: ApiSvcManualBlockchainWalletDTO;
  /**
   *
   * @type {ApiSvcUserWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  user?: ApiSvcUserWalletDTO;
  /**
   *
   * @type {ApiSvcAddressWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  addressBasedBlockchain?: ApiSvcAddressWalletDTO;
  /**
   *
   * @type {ApiSvcAccountBasedBlockchainWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  accountBasedBlockchain?: ApiSvcAccountBasedBlockchainWalletDTO;
  /**
   *
   * @type {ApiSvcLrcMarketplaceWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  lrcMarketplace?: ApiSvcLrcMarketplaceWalletDTO;
  /**
   *
   * @type {ApiSvcGroupWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  group?: ApiSvcGroupWalletDTO;
  /**
   *
   * @type {ApiSvcDefiWalletDTO}
   * @memberof ApiSvcCreateWalletRequest
   */
  defi?: ApiSvcDefiWalletDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequest
   */
  walletRoleId?: string;
}

/**
 *
 * @export
 * @interface ApiSvcCreateWalletRequestDTO
 */
export interface ApiSvcCreateWalletRequestDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  name: string;
  /**
   *
   * @type {ApiSvcWalletTypes}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  type: ApiSvcWalletTypes;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  remoteId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  connectionId?: string;
  /**
   *
   * @type {any}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  metadata?: any;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  defaultInflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  defaultInflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  defaultOutflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  defaultOutflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  defaultFeeContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  defaultFeeCategory?: string;
  /**
   *
   * @type {ApiSvcWalletsFlags}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  flags?: ApiSvcWalletsFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRequestDTO
   */
  subsidiaryId?: string;
}

/**
 *
 * @export
 * @interface ApiSvcCreateWalletResponse
 */
export interface ApiSvcCreateWalletResponse {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletResponse
   */
  walletId: string;
}
/**
 *
 * @export
 * @interface ApiSvcCreateWalletRoleRequestDTO
 */
export interface ApiSvcCreateWalletRoleRequestDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateWalletRoleRequestDTO
   */
  name: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCreateWalletTypes = {
  Eh: 'eh',
  Watch: 'watch',
  Multisig: 'multisig',
  Hardware: 'hardware',
  Manual: 'manual',
  User: 'user',
  AccountBasedBlockchain: 'accountBasedBlockchain',
  LrcMarketplace: 'lrc-marketplace',
  Group: 'group',
  Celo: 'celo',
  Polygon: 'polygon',
  Avalanche: 'avalanche',
  Stx: 'stx',
  Heco: 'heco',
  Bsc: 'bsc',
  Klay: 'klay',
  Loopring: 'loopring',
  Flow: 'flow',
  Mina: 'mina',
  Optimism: 'optimism',
  Defi: 'defi',
} as const;

export type ApiSvcCreateWalletTypes = typeof ApiSvcCreateWalletTypes[keyof typeof ApiSvcCreateWalletTypes];

/**
 *
 * @export
 * @interface ApiSvcCreateXeroDTO
 */
export interface ApiSvcCreateXeroDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateXeroDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateXeroDTO
   */
  exchangeContactId: string;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcCreateXeroDTO
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateXeroDTO
   */
  consumerKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateXeroDTO
   */
  consumerSecret: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateXeroDTO
   */
  accountCode: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCreateXeroDTO
   */
  feeAccountCode: string;
}
/**
 *
 * @export
 * @interface ApiSvcCryptoExchangeTransferMeta
 */
export interface ApiSvcCryptoExchangeTransferMeta {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCryptoExchangeTransferMeta
   */
  cryptoTxId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCryptoExchangeTransferMeta
   */
  fromAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCryptoExchangeTransferMeta
   */
  toAddress?: string;
  /**
   *
   * @type {ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId}
   * @memberof ApiSvcCryptoExchangeTransferMeta
   */
  externalStatus?: ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCryptoExchangeTransferMeta
   */
  exchangeId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCryptoExchangeTransferMeta
   */
  balance?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCryptoExchangeTransferMeta
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCryptoExchangeTransferMeta
   */
  holdingPeriodCompletionDate?: string;
}
/**
 *
 * @export
 * @interface ApiSvcCsvExportResponse
 */
export interface ApiSvcCsvExportResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcCsvExportResponse
   */
  exportIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcCustomerReport
 */
export interface ApiSvcCustomerReport {
  /**
   *
   * @type {string}
   * @memberof ApiSvcCustomerReport
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCustomerReport
   */
  customerId: string;
  /**
   *
   * @type {ApiSvcCustomerReportType}
   * @memberof ApiSvcCustomerReport
   */
  type: ApiSvcCustomerReportType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcCustomerReport
   */
  name: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCustomerReportType = {
  GainLoss: 'gain-loss',
} as const;

export type ApiSvcCustomerReportType = typeof ApiSvcCustomerReportType[keyof typeof ApiSvcCustomerReportType];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcCustomerType = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

export type ApiSvcCustomerType = typeof ApiSvcCustomerType[keyof typeof ApiSvcCustomerType];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcDateOrder = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type ApiSvcDateOrder = typeof ApiSvcDateOrder[keyof typeof ApiSvcDateOrder];

/**
 *
 * @export
 * @interface ApiSvcDeFiCategorizationAction
 */
export interface ApiSvcDeFiCategorizationAction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcDeFiCategorizationAction
   */
  deFiWalletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDeFiCategorizationAction
   */
  feeContactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDeFiCategorizationAction
   */
  type: ApiSvcDeFiCategorizationActionTypeEnum;
}

export const ApiSvcDeFiCategorizationActionTypeEnum = {
  DeFiCategorization: 'DeFiCategorization',
} as const;

export type ApiSvcDeFiCategorizationActionTypeEnum =
  typeof ApiSvcDeFiCategorizationActionTypeEnum[keyof typeof ApiSvcDeFiCategorizationActionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcDeFiTransferTxnDetailsDTO
 */
export interface ApiSvcDeFiTransferTxnDetailsDTO {
  /**
   *
   * @type {Array<ApiSvcValueDetailsDTO>}
   * @memberof ApiSvcDeFiTransferTxnDetailsDTO
   */
  toTotalHoldings: Array<ApiSvcValueDetailsDTO>;
  /**
   *
   * @type {Array<ApiSvcValueDetailsDTO>}
   * @memberof ApiSvcDeFiTransferTxnDetailsDTO
   */
  fromTotalHoldings: Array<ApiSvcValueDetailsDTO>;
  /**
   *
   * @type {ApiSvcAssetStakingPoolDetails}
   * @memberof ApiSvcDeFiTransferTxnDetailsDTO
   */
  poolDetails?: ApiSvcAssetStakingPoolDetails;
  /**
   *
   * @type {ApiSvcBlockPointer}
   * @memberof ApiSvcDeFiTransferTxnDetailsDTO
   */
  block: ApiSvcBlockPointer;
  /**
   *
   * @type {ApiSvcAddressPointer}
   * @memberof ApiSvcDeFiTransferTxnDetailsDTO
   */
  stakingTarget: ApiSvcAddressPointer;
  /**
   *
   * @type {ApiSvcAddressPointer}
   * @memberof ApiSvcDeFiTransferTxnDetailsDTO
   */
  fromAddress: ApiSvcAddressPointer;
  /**
   *
   * @type {ApiSvcAddressPointer}
   * @memberof ApiSvcDeFiTransferTxnDetailsDTO
   */
  toAddress: ApiSvcAddressPointer;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcDeFiTransferTxnDetailsDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {ApiSvcDetailedMultiFeeOutDTO}
   * @memberof ApiSvcDeFiTransferTxnDetailsDTO
   */
  fee?: ApiSvcDetailedMultiFeeOutDTO;
}
/**
 *
 * @export
 * @interface ApiSvcDefiWalletDTO
 */
export interface ApiSvcDefiWalletDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  connectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  subsidiaryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  walletAddress: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  vaultAddress: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  tokenId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcDefiWalletDTO
   */
  isSyncEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  defaultInflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  defaultInflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  defaultOutflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  defaultOutflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  defaultFeeContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletDTO
   */
  defaultFeeCategory?: string;
  /**
   *
   * @type {ApiSvcWalletsFlags}
   * @memberof ApiSvcDefiWalletDTO
   */
  flags?: ApiSvcWalletsFlags;
}
/**
 *
 * @export
 * @interface ApiSvcDefiWalletInput
 */
export interface ApiSvcDefiWalletInput {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcDefiWalletInput
   */
  isSyncEnabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletInput
   */
  tokenId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletInput
   */
  vaultAddress: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletInput
   */
  walletAddress: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletInput
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletInput
   */
  groupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDefiWalletInput
   */
  connectionId?: string | null;
}
/**
 *
 * @export
 * @interface ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
 */
export interface ApiSvcDeprecatedCategorizeInvoiceTransactionDTO {
  /**
   *
   * @type {ApiSvcCategorizationTransactionTypeInvoice}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  type: ApiSvcCategorizationTransactionTypeInvoice;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {Array<ApiSvcCategorizeInvoiceDTO>}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  invoices: Array<ApiSvcCategorizeInvoiceDTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeUnappliedDTO>}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  unapplied?: Array<ApiSvcCategorizeUnappliedDTO>;
  /**
   *
   * @type {Array<ApiSvcCategorizeFeeDTO>}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  fees?: Array<ApiSvcCategorizeFeeDTO>;
  /**
   *
   * @type {Array<ApiSvcDeprecatedExchangeRateDTO>}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  exchangeRates: Array<ApiSvcDeprecatedExchangeRateDTO>;
  /**
   *
   * @type {ApiSvcInvoiceInputType}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  invoiceType: ApiSvcInvoiceInputType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  totalFmv: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDeprecatedCategorizeInvoiceTransactionDTO
   */
  accountingConnectionId?: string;
}

/**
 *
 * @export
 * @interface ApiSvcDeprecatedExchangeRateDTO
 */
export interface ApiSvcDeprecatedExchangeRateDTO {
  /**
   *
   * @type {ApiSvcExchangeRateSource}
   * @memberof ApiSvcDeprecatedExchangeRateDTO
   */
  source?: ApiSvcExchangeRateSource;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDeprecatedExchangeRateDTO
   */
  rate: string;
  /**
   * fiat ticker
   * @type {string}
   * @memberof ApiSvcDeprecatedExchangeRateDTO
   */
  fiat: string;
  /**
   * coin unit
   * @type {string}
   * @memberof ApiSvcDeprecatedExchangeRateDTO
   */
  unit: string;
  /**
   * coin ticker
   * @type {string}
   * @memberof ApiSvcDeprecatedExchangeRateDTO
   */
  coin: string;
}

/**
 *
 * @export
 * @interface ApiSvcDetailCategorizationAction
 */
export interface ApiSvcDetailCategorizationAction {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcDetailCategorizationAction
   */
  ignoreFailPricing?: boolean;
  /**
   *
   * @type {Array<ApiSvcDetailedCategorizationActionLines>}
   * @memberof ApiSvcDetailCategorizationAction
   */
  lines: Array<ApiSvcDetailedCategorizationActionLines>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailCategorizationAction
   */
  type: ApiSvcDetailCategorizationActionTypeEnum;
}

export const ApiSvcDetailCategorizationActionTypeEnum = {
  DetailedCategorization: 'DetailedCategorization',
} as const;

export type ApiSvcDetailCategorizationActionTypeEnum =
  typeof ApiSvcDetailCategorizationActionTypeEnum[keyof typeof ApiSvcDetailCategorizationActionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcDetailedCategorizationActionLines
 */
export interface ApiSvcDetailedCategorizationActionLines {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcDetailedCategorizationActionLines
   */
  metadataIds?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedCategorizationActionLines
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedCategorizationActionLines
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedCategorizationActionLines
   */
  lineQualifierExtractor?: string | null;
  /**
   *
   * @type {ApiSvcAssetExtractor}
   * @memberof ApiSvcDetailedCategorizationActionLines
   */
  assetExtractor: ApiSvcAssetExtractor;
  /**
   *
   * @type {ApiSvcValueExtractor}
   * @memberof ApiSvcDetailedCategorizationActionLines
   */
  valueExtractor: ApiSvcValueExtractor;
}

/**
 *
 * @export
 * @interface ApiSvcDetailedFeeDTO
 */
export interface ApiSvcDetailedFeeDTO {
  /**
   *
   * @type {ApiSvcAmountDTO}
   * @memberof ApiSvcDetailedFeeDTO
   */
  fee: ApiSvcAmountDTO;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcDetailedFeeDTO
   */
  cost: ApiSvcCostBasisDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedFeeDTO
   */
  feePayeeId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedFeeDTO
   */
  feeCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedFeeDTO
   */
  walletId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcDetailedFeeInput
 */
export interface ApiSvcDetailedFeeInput {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcDetailedFeeInput
   */
  imputed?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedFeeInput
   */
  walletId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedFeeInput
   */
  feeCategoryId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedFeeInput
   */
  feeContactId: string;
  /**
   *
   * @type {ApiSvcCostBasisInput}
   * @memberof ApiSvcDetailedFeeInput
   */
  costBasis: ApiSvcCostBasisInput;
  /**
   *
   * @type {ApiSvcUncheckedAmountInput}
   * @memberof ApiSvcDetailedFeeInput
   */
  amount?: ApiSvcUncheckedAmountInput | null;
}
/**
 *
 * @export
 * @interface ApiSvcDetailedForexDTO
 */
export interface ApiSvcDetailedForexDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedForexDTO
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedForexDTO
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedForexDTO
   */
  fiat: string;
}
/**
 *
 * @export
 * @interface ApiSvcDetailedMultiFeeOutDTO
 */
export interface ApiSvcDetailedMultiFeeOutDTO {
  /**
   *
   * @type {Array<ApiSvcFeeItemOutDTO>}
   * @memberof ApiSvcDetailedMultiFeeOutDTO
   */
  feeItems: Array<ApiSvcFeeItemOutDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedMultiFeeOutDTO
   */
  feeContactId: string;
}
/**
 *
 * @export
 * @interface ApiSvcDetailedTxnDTO
 */
export interface ApiSvcDetailedTxnDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedTxnDTO
   */
  orgId: string;
  /**
   *
   * @type {Array<ApiSvcDetailedTxnItemDTO>}
   * @memberof ApiSvcDetailedTxnDTO
   */
  items: Array<ApiSvcDetailedTxnItemDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedTxnDTO
   */
  notes?: string;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcDetailedTxnDTO
   */
  costBasis: ApiSvcCostBasisDTO;
}
/**
 *
 * @export
 * @interface ApiSvcDetailedTxnItemDTO
 */
export interface ApiSvcDetailedTxnItemDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedTxnItemDTO
   */
  contactId: string;
  /**
   *
   * @type {Array<ApiSvcDetailedTxnLineDTO>}
   * @memberof ApiSvcDetailedTxnItemDTO
   */
  lines: Array<ApiSvcDetailedTxnLineDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcDetailedTxnLineDTO
 */
export interface ApiSvcDetailedTxnLineDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedTxnLineDTO
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcDetailedTxnLineDTO
   */
  description?: string;
  /**
   *
   * @type {ApiSvcSimpleAmountDTO}
   * @memberof ApiSvcDetailedTxnLineDTO
   */
  amount: ApiSvcSimpleAmountDTO;
}
/**
 *
 * @export
 * @interface ApiSvcDisplayConfigDTO
 */
export interface ApiSvcDisplayConfigDTO {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcDisplayConfigDTO
   */
  useOrgTimezone: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcEmailAuthCodeParams
 */
export interface ApiSvcEmailAuthCodeParams {
  /**
   *
   * @type {string}
   * @memberof ApiSvcEmailAuthCodeParams
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcEmailAuthCodeParams
   */
  code: string;
}
/**
 *
 * @export
 * @interface ApiSvcEnterprisePaymentSettings
 */
export interface ApiSvcEnterprisePaymentSettings {
  /**
   *
   * @type {string}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  defaultForexCategoryId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  pricingSources: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  pricingTimezone: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  kycProvider: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  custodian: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  enabledCryptoCurrencies: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  enabledFiats: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  locked: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  remittanceContactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcEnterprisePaymentSettings
   */
  remittanceSenderName?: string;
}
/**
 *
 * @export
 * @interface ApiSvcEnterpriseWalletDTO
 */
export interface ApiSvcEnterpriseWalletDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcEnterpriseWalletDTO
   */
  cosigners: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcEnterpriseWalletDTO
   */
  minCosigners: number;
}
/**
 *
 * @export
 * @interface ApiSvcEnterpriseWalletInput
 */
export interface ApiSvcEnterpriseWalletInput {
  /**
   *
   * @type {number}
   * @memberof ApiSvcEnterpriseWalletInput
   */
  minCosigners: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcEnterpriseWalletInput
   */
  cosigners: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcError
 */
export interface ApiSvcError {
  /**
   *
   * @type {string}
   * @memberof ApiSvcError
   */
  stack?: string;
}
/**
 *
 * @export
 * @interface ApiSvcExchangeCreateParams
 */
export interface ApiSvcExchangeCreateParams {
  /**
   *
   * @type {Array<ApiSvcExchangePayload>}
   * @memberof ApiSvcExchangeCreateParams
   */
  exchanges: Array<ApiSvcExchangePayload>;
}
/**
 *
 * @export
 * @interface ApiSvcExchangePayload
 */
export interface ApiSvcExchangePayload {
  /**
   * The remoteExchangeId from wallet manager svc
   * @type {string}
   * @memberof ApiSvcExchangePayload
   */
  remoteExchangeId: string;
  /**
   *
   * @type {ApiSvcExchangePayloadExchange}
   * @memberof ApiSvcExchangePayload
   */
  exchange: ApiSvcExchangePayloadExchange;
}
/**
 * Wallet payload
 * @export
 * @interface ApiSvcExchangePayloadExchange
 */
export interface ApiSvcExchangePayloadExchange {
  /**
   *
   * @type {ApiSvcCreateConnectionDTO}
   * @memberof ApiSvcExchangePayloadExchange
   */
  createConnection: ApiSvcCreateConnectionDTO;
}
/**
 *
 * @export
 * @interface ApiSvcExchangeRateDTO
 */
export interface ApiSvcExchangeRateDTO {
  /**
   *
   * @type {number}
   * @memberof ApiSvcExchangeRateDTO
   */
  rate: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcExchangeRateDTO
   */
  dateTime: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateDTO
   */
  displayRate: string;
}
/**
 *
 * @export
 * @interface ApiSvcExchangeRateDetails
 */
export interface ApiSvcExchangeRateDetails {
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcExchangeRateDetails
   */
  exchangeRates?: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcExchangeRateDetails
   */
  exchangeRatesDirty?: boolean | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcExchangeRateDetails
   */
  exchangeRatesError?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcExchangeRateDetailsDTO
 */
export interface ApiSvcExchangeRateDetailsDTO {
  /**
   * this is the ticker, not the Id
   * @type {string}
   * @memberof ApiSvcExchangeRateDetailsDTO
   */
  coin: string;
  /**
   * this is name of the unit, not the Id
   * @type {string}
   * @memberof ApiSvcExchangeRateDetailsDTO
   */
  coinUnit: string;
  /**
   * CurrencyId of the asset
   * @type {string}
   * @memberof ApiSvcExchangeRateDetailsDTO
   */
  currencyId: string;
  /**
   * this is the ticker, not the Id
   * @type {string}
   * @memberof ApiSvcExchangeRateDetailsDTO
   */
  fiat: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateDetailsDTO
   */
  rate: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcExchangeRateDetailsDTO
   */
  failedOrIncomplete: boolean;
  /**
   * the unique pricing id
   * @type {string}
   * @memberof ApiSvcExchangeRateDetailsDTO
   */
  priceId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcExchangeRateFailedDTO
 */
export interface ApiSvcExchangeRateFailedDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateFailedDTO
   */
  fromAssetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateFailedDTO
   */
  toAssetId: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeFailed}
   * @memberof ApiSvcExchangeRateFailedDTO
   */
  source: ApiSvcExchangeRateTypeFailed;
}

/**
 *
 * @export
 * @interface ApiSvcExchangeRateInput
 */
export interface ApiSvcExchangeRateInput {
  /**
   *
   * @type {ApiSvcExchangeRateSource}
   * @memberof ApiSvcExchangeRateInput
   */
  source?: ApiSvcExchangeRateSource | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateInput
   */
  rate: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateInput
   */
  fiat: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateInput
   */
  unit: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateInput
   */
  coin: string;
}

/**
 *
 * @export
 * @interface ApiSvcExchangeRateManualDTO
 */
export interface ApiSvcExchangeRateManualDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateManualDTO
   */
  fromAssetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateManualDTO
   */
  toAssetId: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeManual}
   * @memberof ApiSvcExchangeRateManualDTO
   */
  source: ApiSvcExchangeRateTypeManual;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateManualDTO
   */
  rate: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateManualDTO
   */
  note: string;
}

/**
 *
 * @export
 * @interface ApiSvcExchangeRateOverrideDTO
 */
export interface ApiSvcExchangeRateOverrideDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateOverrideDTO
   */
  currencyId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateOverrideDTO
   */
  rate: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcExchangeRateSource = {
  Automatic: 'Automatic',
  Manual: 'Manual',
  Invoice: 'Invoice',
} as const;

export type ApiSvcExchangeRateSource = typeof ApiSvcExchangeRateSource[keyof typeof ApiSvcExchangeRateSource];

/**
 *
 * @export
 * @interface ApiSvcExchangeRateSystemSuccessDTO
 */
export interface ApiSvcExchangeRateSystemSuccessDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateSystemSuccessDTO
   */
  fromAssetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateSystemSuccessDTO
   */
  toAssetId: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeSuccess}
   * @memberof ApiSvcExchangeRateSystemSuccessDTO
   */
  source: ApiSvcExchangeRateTypeSuccess;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateSystemSuccessDTO
   */
  rate: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateSystemSuccessDTO
   */
  priceId: string;
}

/**
 *
 * @export
 * @interface ApiSvcExchangeRateSystemUnableToPriceDTO
 */
export interface ApiSvcExchangeRateSystemUnableToPriceDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateSystemUnableToPriceDTO
   */
  fromAssetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateSystemUnableToPriceDTO
   */
  toAssetId: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeUnableToPrice}
   * @memberof ApiSvcExchangeRateSystemUnableToPriceDTO
   */
  source: ApiSvcExchangeRateTypeUnableToPrice;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateSystemUnableToPriceDTO
   */
  priceId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcExchangeRateType = {
  SuccessfullyPriced: 'successfully-priced',
  FailedToPrice: 'failed-to-price',
  ManuallyPriced: 'manually-priced',
  UnableToPrice: 'unable-to-price',
} as const;

export type ApiSvcExchangeRateType = typeof ApiSvcExchangeRateType[keyof typeof ApiSvcExchangeRateType];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcExchangeRateTypeFailed = {
  FailedToPrice: 'failed-to-price',
} as const;

export type ApiSvcExchangeRateTypeFailed =
  typeof ApiSvcExchangeRateTypeFailed[keyof typeof ApiSvcExchangeRateTypeFailed];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcExchangeRateTypeManual = {
  ManuallyPriced: 'manually-priced',
} as const;

export type ApiSvcExchangeRateTypeManual =
  typeof ApiSvcExchangeRateTypeManual[keyof typeof ApiSvcExchangeRateTypeManual];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcExchangeRateTypeSuccess = {
  SuccessfullyPriced: 'successfully-priced',
} as const;

export type ApiSvcExchangeRateTypeSuccess =
  typeof ApiSvcExchangeRateTypeSuccess[keyof typeof ApiSvcExchangeRateTypeSuccess];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcExchangeRateTypeUnableToPrice = {
  UnableToPrice: 'unable-to-price',
} as const;

export type ApiSvcExchangeRateTypeUnableToPrice =
  typeof ApiSvcExchangeRateTypeUnableToPrice[keyof typeof ApiSvcExchangeRateTypeUnableToPrice];

/**
 *
 * @export
 * @interface ApiSvcExchangeRateV2DTO
 */
export interface ApiSvcExchangeRateV2DTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateV2DTO
   */
  fromAssetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateV2DTO
   */
  toAssetId: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeFailed}
   * @memberof ApiSvcExchangeRateV2DTO
   */
  source: ApiSvcExchangeRateTypeFailed;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateV2DTO
   */
  rate: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateV2DTO
   */
  note: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExchangeRateV2DTO
   */
  priceId: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcExchangeTransferType = {
  Deposit: 'deposit',
  Withdrawal: 'withdrawal',
} as const;

export type ApiSvcExchangeTransferType = typeof ApiSvcExchangeTransferType[keyof typeof ApiSvcExchangeTransferType];

/**
 *
 * @export
 * @interface ApiSvcExecContext
 */
export interface ApiSvcExecContext {
  /**
   *
   * @type {string}
   * @memberof ApiSvcExecContext
   */
  userId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcExecContext
   */
  orgs: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcExecContext
   */
  isServiceAccount: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcExecContext
   */
  isSuperAdmin?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcExecContext
   */
  scopes: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExecContext
   */
  traceId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcExpandedExchangeRateDTO
 */
export interface ApiSvcExpandedExchangeRateDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcExpandedExchangeRateDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExpandedExchangeRateDTO
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExpandedExchangeRateDTO
   */
  to: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeUnableToPrice}
   * @memberof ApiSvcExpandedExchangeRateDTO
   */
  type: ApiSvcExchangeRateTypeUnableToPrice;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExpandedExchangeRateDTO
   */
  priceId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExpandedExchangeRateDTO
   */
  rate: ApiSvcExpandedExchangeRateDTORateEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExpandedExchangeRateDTO
   */
  error?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcExpandedExchangeRateDTO
   */
  reason?: string;
}

export const ApiSvcExpandedExchangeRateDTORateEnum = {
  _0: '0',
} as const;

export type ApiSvcExpandedExchangeRateDTORateEnum =
  typeof ApiSvcExpandedExchangeRateDTORateEnum[keyof typeof ApiSvcExpandedExchangeRateDTORateEnum];

/**
 *
 * @export
 * @interface ApiSvcExternalAddressEnrollmentStatus
 */
export interface ApiSvcExternalAddressEnrollmentStatus {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcExternalAddressEnrollmentStatus
   */
  emailSent: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcExternalAddressEnrollmentStatus
   */
  enrolled: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcFailedToPriceExchangeRateDTO
 */
export interface ApiSvcFailedToPriceExchangeRateDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcFailedToPriceExchangeRateDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFailedToPriceExchangeRateDTO
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFailedToPriceExchangeRateDTO
   */
  to: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeFailed}
   * @memberof ApiSvcFailedToPriceExchangeRateDTO
   */
  type: ApiSvcExchangeRateTypeFailed;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFailedToPriceExchangeRateDTO
   */
  priceId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFailedToPriceExchangeRateDTO
   */
  error?: string;
}

/**
 *
 * @export
 * @interface ApiSvcFeatureFlag
 */
export interface ApiSvcFeatureFlag {
  /**
   *
   * @type {string}
   * @memberof ApiSvcFeatureFlag
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFeatureFlag
   */
  value: string;
}
/**
 *
 * @export
 * @interface ApiSvcFeeItemOutDTO
 */
export interface ApiSvcFeeItemOutDTO {
  /**
   *
   * @type {ApiSvcValueDetail}
   * @memberof ApiSvcFeeItemOutDTO
   */
  amount: ApiSvcValueDetail;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcFeeItemOutDTO
   */
  costBasis: ApiSvcCostBasisDTO;
}
/**
 *
 * @export
 * @interface ApiSvcFeeLineDTO
 */
export interface ApiSvcFeeLineDTO {
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcFeeLineDTO
   */
  sourceValue: ApiSvcRawValueDTO;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcFeeLineDTO
   */
  appliedExchangeRateValue?: ApiSvcRawValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFeeLineDTO
   */
  walletId?: string;
  /**
   *
   * @type {ApiSvcCategorizedLineTypeFeeLine}
   * @memberof ApiSvcFeeLineDTO
   */
  type: ApiSvcCategorizedLineTypeFeeLine;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFeeLineDTO
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFeeLineDTO
   */
  categoryId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcFeeLineDTO
   */
  isImputed?: boolean;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcFiatTickers = {
  Usd: 'USD',
  Gbp: 'GBP',
  Jpy: 'JPY',
  Eur: 'EUR',
  Sgd: 'SGD',
  Chf: 'CHF',
  Cad: 'CAD',
  Krw: 'KRW',
  Nok: 'NOK',
  Dkk: 'DKK',
  Nzd: 'NZD',
  Isk: 'ISK',
  Hkd: 'HKD',
  Cny: 'CNY',
  Pln: 'PLN',
  Aud: 'AUD',
  Ars: 'ARS',
  Brl: 'BRL',
  Clp: 'CLP',
  Cop: 'COP',
  Czk: 'CZK',
  Ils: 'ILS',
  Inr: 'INR',
  Mxn: 'MXN',
  Myr: 'MYR',
  Php: 'PHP',
  Sek: 'SEK',
  Thb: 'THB',
  Vnd: 'VND',
  Zar: 'ZAR',
  Rub: 'RUB',
  Idr: 'IDR',
  Ron: 'RON',
  Aed: 'AED',
  Bgn: 'BGN',
  Rsd: 'RSD',
  Byn: 'BYN',
  Uah: 'UAH',
  Ugx: 'UGX',
  Zwd: 'ZWD',
  Try: 'TRY',
  Omr: 'OMR',
  Kwd: 'KWD',
  Gtq: 'GTQ',
} as const;

export type ApiSvcFiatTickers = typeof ApiSvcFiatTickers[keyof typeof ApiSvcFiatTickers];

/**
 *
 * @export
 * @interface ApiSvcFiatValueDTO
 */
export interface ApiSvcFiatValueDTO {
  /**
   *
   * @type {number}
   * @memberof ApiSvcFiatValueDTO
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFiatValueDTO
   */
  displayValue: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFiatValueDTO
   */
  currency: string;
  /**
   *
   * @type {ApiSvcExchangeRateDTO}
   * @memberof ApiSvcFiatValueDTO
   */
  exchangeRate?: ApiSvcExchangeRateDTO;
}
/**
 *
 * @export
 * @interface ApiSvcFilePointer
 */
export interface ApiSvcFilePointer {
  /**
   *
   * @type {string}
   * @memberof ApiSvcFilePointer
   */
  fileId: string;
  /**
   *
   * @type {ApiSvcFilePointerStorageLocations}
   * @memberof ApiSvcFilePointer
   */
  storageLocation: ApiSvcFilePointerStorageLocations;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFilePointer
   */
  url: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcFilePointerStorageLocations = {
  Gcp: 'gcp',
  Test: 'test',
} as const;

export type ApiSvcFilePointerStorageLocations =
  typeof ApiSvcFilePointerStorageLocations[keyof typeof ApiSvcFilePointerStorageLocations];

/**
 *
 * @export
 * @interface ApiSvcFileRecord
 */
export interface ApiSvcFileRecord {
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  id: string;
  /**
   *
   * @type {ApiSvcRecordType}
   * @memberof ApiSvcFileRecord
   */
  recordType: ApiSvcRecordType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  recordPath: string;
  /**
   *
   * @type {ApiSvcRecordBucket}
   * @memberof ApiSvcFileRecord
   */
  recordBucket: ApiSvcRecordBucket;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  dateSelected?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  groupBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  assetType?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  updatedAt?: string;
  /**
   *
   * @type {ApiSvcOrgUserDTO}
   * @memberof ApiSvcFileRecord
   */
  updatedBy?: ApiSvcOrgUserDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  createdAt?: string;
  /**
   *
   * @type {ApiSvcOrgUserDTO}
   * @memberof ApiSvcFileRecord
   */
  createdBy?: ApiSvcOrgUserDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  deletedAt?: string;
  /**
   *
   * @type {ApiSvcOrgUserDTO}
   * @memberof ApiSvcFileRecord
   */
  deletedBy?: ApiSvcOrgUserDTO;
  /**
   *
   * @type {ApiSvcRecordBinaryContent}
   * @memberof ApiSvcFileRecord
   */
  recordBinaryContent?: ApiSvcRecordBinaryContent;
  /**
   *
   * @type {number}
   * @memberof ApiSvcFileRecord
   */
  expireAt?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcFileRecord
   */
  recordBytes?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcFileRecord
   */
  runId?: string;
}

/**
 *
 * @export
 * @interface ApiSvcGainLossConfig
 */
export interface ApiSvcGainLossConfig {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGainLossConfig
   */
  capitalizeTradingFees: boolean;
  /**
   *
   * @type {ApiSvcImpairmentMethodology}
   * @memberof ApiSvcGainLossConfig
   */
  impairmentMethodology?: ApiSvcImpairmentMethodology;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcGainLossConfig
   */
  wrappingTreatments?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGainLossConfig
   */
  impairmentWhitelist?: Array<string>;
  /**
   *
   * @type {ApiSvcInventoryMappingRule}
   * @memberof ApiSvcGainLossConfig
   */
  inventoryMappingRule?: ApiSvcInventoryMappingRule;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGainLossConfig
   */
  engineVersion?: number;
  /**
   *
   * @type {ApiSvcAssetValuationStrategies}
   * @memberof ApiSvcGainLossConfig
   */
  defaultValuationStrategy?: ApiSvcAssetValuationStrategies;
  /**
   *
   * @type {Array<ApiSvcAssetValuationStrategy>}
   * @memberof ApiSvcGainLossConfig
   */
  assetValuationStrategies?: Array<ApiSvcAssetValuationStrategy>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGainLossConfig
   */
  costBasisCarryForwardAcquiredSide?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGainLossConfig
   */
  processAcquisitionsBeforeDisposals?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGainLossConfig
   */
  useOriginalAcquisitionDateForWliTransfers?: boolean;
}

/**
 *
 * @export
 * @interface ApiSvcGainLossConfigCreateRequest
 */
export interface ApiSvcGainLossConfigCreateRequest {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  capitalizeTradingFees: boolean;
  /**
   *
   * @type {ApiSvcImpairmentMethodology}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  impairmentMethodology?: ApiSvcImpairmentMethodology;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  wrappingTreatments?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  impairmentWhitelist?: Array<string>;
  /**
   *
   * @type {ApiSvcInventoryMappingRuleRequest}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  inventoryMappingRule?: ApiSvcInventoryMappingRuleRequest;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  engineVersionOverride?: number;
  /**
   *
   * @type {ApiSvcAssetValuationStrategies}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  defaultValuationStrategy?: ApiSvcAssetValuationStrategies;
  /**
   *
   * @type {Array<ApiSvcAssetValuationStrategy>}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  assetValuationStrategies?: Array<ApiSvcAssetValuationStrategy>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  costBasisCarryForwardAcquiredSide?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  processAcquisitionsBeforeDisposals?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGainLossConfigCreateRequest
   */
  useOriginalAcquisitionDateForWliTransfers?: boolean;
}

/**
 *
 * @export
 * @interface ApiSvcGainLossRunFiles
 */
export interface ApiSvcGainLossRunFiles {
  /**
   *
   * @type {ApiSvcFilePointer}
   * @memberof ApiSvcGainLossRunFiles
   */
  actions: ApiSvcFilePointer;
  /**
   *
   * @type {ApiSvcFilePointer}
   * @memberof ApiSvcGainLossRunFiles
   */
  gainLoss: ApiSvcFilePointer;
  /**
   *
   * @type {ApiSvcFilePointer}
   * @memberof ApiSvcGainLossRunFiles
   */
  results: ApiSvcFilePointer;
}
/**
 *
 * @export
 * @interface ApiSvcGainLossSetupDTO
 */
export interface ApiSvcGainLossSetupDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGainLossSetupDTO
   */
  taxStrategy: ApiSvcGainLossSetupDTOTaxStrategyEnum;
  /**
   *
   * @type {Array<ApiSvcCostAverageOverride>}
   * @memberof ApiSvcGainLossSetupDTO
   */
  costAverageOverrides?: Array<ApiSvcCostAverageOverride>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGainLossSetupDTO
   */
  costAverageOverrideEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGainLossSetupDTO
   */
  taxStartDate?: string;
  /**
   *
   * @type {Array<ApiSvcStartingBalanceDetail>}
   * @memberof ApiSvcGainLossSetupDTO
   */
  startingBalanceDetails?: Array<ApiSvcStartingBalanceDetail>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGainLossSetupDTO
   */
  defaultToZeroCostBasis?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGainLossSetupDTO
   */
  excludedCoins?: Array<string>;
  /**
   *
   * @type {Array<ApiSvcSpecificIdentificationSelectionStrategy>}
   * @memberof ApiSvcGainLossSetupDTO
   */
  specIdStrategy?: Array<ApiSvcSpecificIdentificationSelectionStrategy>;
  /**
   *
   * @type {ApiSvcAssetValuationStrategies}
   * @memberof ApiSvcGainLossSetupDTO
   */
  defaultValuationStrategy?: ApiSvcAssetValuationStrategies;
  /**
   *
   * @type {Array<ApiSvcAssetBasedValuationConfig>}
   * @memberof ApiSvcGainLossSetupDTO
   */
  assetValuationStrategies?: Array<ApiSvcAssetBasedValuationConfig>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGainLossSetupDTO
   */
  matchDataSourceId?: string;
}

export const ApiSvcGainLossSetupDTOTaxStrategyEnum = {
  SpecificIdentification: 'SpecificIdentification',
  Fifo: 'FIFO',
  Lifo: 'LIFO',
  CostAverage: 'CostAverage',
  SelectedSpecificIdentification: 'SelectedSpecificIdentification',
} as const;

export type ApiSvcGainLossSetupDTOTaxStrategyEnum =
  typeof ApiSvcGainLossSetupDTOTaxStrategyEnum[keyof typeof ApiSvcGainLossSetupDTOTaxStrategyEnum];

/**
 *
 * @export
 * @interface ApiSvcGainLossSummary
 */
export interface ApiSvcGainLossSummary {
  /**
   *
   * @type {number}
   * @memberof ApiSvcGainLossSummary
   */
  stGainLossTotal: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGainLossSummary
   */
  undatedGainLossTotal: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGainLossSummary
   */
  ltGainLossTotal: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGainLossSummary
   */
  impairExpenseTotal: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGainLossSummary
   */
  impairExpenseReversalTotal: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGainLossSummary
   */
  wrappingAdjustmentExpenseTotal: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGainLossSummary
   */
  totalErrorCount: number;
}
/**
 *
 * @export
 * @interface ApiSvcGainLossSummaryResponse
 */
export interface ApiSvcGainLossSummaryResponse {
  /**
   *
   * @type {Array<ApiSvcGainLossSummary>}
   * @memberof ApiSvcGainLossSummaryResponse
   */
  summary: Array<ApiSvcGainLossSummary>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGainLossSummaryResponse
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface ApiSvcGeneratePricingReports200Response
 */
export interface ApiSvcGeneratePricingReports200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGeneratePricingReports200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGeneratePricingReports200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetCategories200Response
 */
export interface ApiSvcGetCategories200Response {
  /**
   *
   * @type {Array<ApiSvcCategoryDTO>}
   * @memberof ApiSvcGetCategories200Response
   */
  items: Array<ApiSvcCategoryDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetCategories200Response
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetCategories200Response
   */
  nextPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetCategories200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetCategories200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetClients200Response
 */
export interface ApiSvcGetClients200Response {
  /**
   *
   * @type {Array<ApiSvcOrgResponseDTO>}
   * @memberof ApiSvcGetClients200Response
   */
  items: Array<ApiSvcOrgResponseDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcGetClientsV2200Response
 */
export interface ApiSvcGetClientsV2200Response {
  /**
   *
   * @type {Array<ApiSvcOrgRelationshipsV2>}
   * @memberof ApiSvcGetClientsV2200Response
   */
  items: Array<ApiSvcOrgRelationshipsV2>;
}
/**
 *
 * @export
 * @interface ApiSvcGetConnection200Response
 */
export interface ApiSvcGetConnection200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  orgId: string;
  /**
   *
   * @type {ApiSvcConnectionType}
   * @memberof ApiSvcGetConnection200Response
   */
  type: ApiSvcConnectionType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  provider: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGetConnection200Response
   */
  lastSyncSEC?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetConnection200Response
   */
  isDisabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetConnection200Response
   */
  isDeleted: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetConnection200Response
   */
  isSetupComplete: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  walletId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  contactId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  accountId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  subAccountId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  s3Bucket?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  s3Prefix?: string;
  /**
   *
   * @type {ApiSvcConnectionCategory}
   * @memberof ApiSvcGetConnection200Response
   */
  category?: ApiSvcConnectionCategory;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGetConnection200Response
   */
  syncerVersion?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  feeAccountCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetConnection200Response
   */
  syncSubAccounts?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  accountCode?: string;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcGetConnection200Response
   */
  connectionSpecificFields?: object;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetConnection200Response
   */
  isDefault?: boolean;
  /**
   *
   * @type {ApiSvcConnectionFlags}
   * @memberof ApiSvcGetConnection200Response
   */
  flags?: ApiSvcConnectionFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  subAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnection200Response
   */
  message: string;
}

/**
 *
 * @export
 * @interface ApiSvcGetConnections200Response
 */
export interface ApiSvcGetConnections200Response {
  /**
   *
   * @type {Array<ApiSvcConnectionDTO>}
   * @memberof ApiSvcGetConnections200Response
   */
  items: Array<ApiSvcConnectionDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnections200Response
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnections200Response
   */
  nextPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnections200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetConnections200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetContacts200Response
 */
export interface ApiSvcGetContacts200Response {
  /**
   *
   * @type {Array<ApiSvcContactDTO>}
   * @memberof ApiSvcGetContacts200Response
   */
  items: Array<ApiSvcContactDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetContacts200Response
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetContacts200Response
   */
  nextPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetContacts200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetContacts200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetInventoryBalanceCheckReport200Response
 */
export interface ApiSvcGetInventoryBalanceCheckReport200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetInventoryBalanceCheckReport200Response
   */
  csv: string;
  /**
   *
   * @type {ApiSvcInventoryBalanceCheckReportResp}
   * @memberof ApiSvcGetInventoryBalanceCheckReport200Response
   */
  metadata: ApiSvcInventoryBalanceCheckReportResp;
}
/**
 *
 * @export
 * @interface ApiSvcGetOrg200Response
 */
export interface ApiSvcGetOrg200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetOrg200Response
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetOrg200Response
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetOrg200Response
   */
  flags?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetOrg200Response
   */
  timezone?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGetOrg200Response
   */
  baseCurrency?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetOrg200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetOrg200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetPrice200Response
 */
export interface ApiSvcGetPrice200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetPrice200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetPrice200Response
   */
  message: string;
  /**
   *
   * @type {ApiSvcPriceSvcPricingResultStatus}
   * @memberof ApiSvcGetPrice200Response
   */
  status: ApiSvcPriceSvcPricingResultStatus;
  /**
   *
   * @type {ApiSvcPriceSvcPrice}
   * @memberof ApiSvcGetPrice200Response
   */
  result?: ApiSvcPriceSvcPrice;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetPrice200Response
   */
  errors?: Array<string>;
  /**
   *
   * @type {Array<ApiSvcPriceSvcPricingStep>}
   * @memberof ApiSvcGetPrice200Response
   */
  failingSteps?: Array<ApiSvcPriceSvcPricingStep>;
}

/**
 *
 * @export
 * @interface ApiSvcGetPrice200ResponseAnyOf
 */
export interface ApiSvcGetPrice200ResponseAnyOf {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetPrice200ResponseAnyOf
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetReport200Response
 */
export interface ApiSvcGetReport200Response {
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcGetReport200Response
   */
  links: object;
  /**
   *
   * @type {ApiSvcReportRunRecordReportRunConfig}
   * @memberof ApiSvcGetReport200Response
   */
  data: ApiSvcReportRunRecordReportRunConfig;
}
/**
 *
 * @export
 * @interface ApiSvcGetReports200Response
 */
export interface ApiSvcGetReports200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetReports200Response
   */
  nextPageToken?: string;
  /**
   *
   * @type {Array<ApiSvcReportRunRecordReportRunConfig>}
   * @memberof ApiSvcGetReports200Response
   */
  items: Array<ApiSvcReportRunRecordReportRunConfig>;
}
/**
 *
 * @export
 * @interface ApiSvcGetRules200Response
 */
export interface ApiSvcGetRules200Response {
  /**
   *
   * @type {Array<ApiSvcTransferRuleDTO>}
   * @memberof ApiSvcGetRules200Response
   */
  items: Array<ApiSvcTransferRuleDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetRules200Response
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetRules200Response
   */
  nextPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetRules200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetRules200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetScimUser200Response
 */
export interface ApiSvcGetScimUser200Response {
  /**
   *
   * @type {ApiSvcGetScimUser200ResponseMeta}
   * @memberof ApiSvcGetScimUser200Response
   */
  meta: ApiSvcGetScimUser200ResponseMeta;
  /**
   *
   * @type {Array<any>}
   * @memberof ApiSvcGetScimUser200Response
   */
  groups: Array<any>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetScimUser200Response
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetScimUser200Response
   */
  locale: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetScimUser200Response
   */
  displayName: string;
  /**
   *
   * @type {Array<ApiSvcScimGetUsers200ResponseResourcesInnerEmailsInner>}
   * @memberof ApiSvcGetScimUser200Response
   */
  emails: Array<ApiSvcScimGetUsers200ResponseResourcesInnerEmailsInner>;
  /**
   *
   * @type {ApiSvcCreateUser201ResponseAnyOfName}
   * @memberof ApiSvcGetScimUser200Response
   */
  name: ApiSvcCreateUser201ResponseAnyOfName;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetScimUser200Response
   */
  userName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetScimUser200Response
   */
  id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetScimUser200Response
   */
  schemas: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcGetScimUser200ResponseMeta
 */
export interface ApiSvcGetScimUser200ResponseMeta {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetScimUser200ResponseMeta
   */
  organization: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetScimUser200ResponseMeta
   */
  resourceType: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetTokenFilteringRules200Response
 */
export interface ApiSvcGetTokenFilteringRules200Response {
  /**
   *
   * @type {Array<ApiSvcTokenFilteringRulesConfigWalletSyncPreferencesInner>}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  walletSyncPreferences?: Array<ApiSvcTokenFilteringRulesConfigWalletSyncPreferencesInner>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  filteredAddresses?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  erc20FilterThreshold?: string;
  /**
   *
   * @type {Array<ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner>}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  disabledDeployedByBlockHeightRules?: Array<ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner>;
  /**
   *
   * @type {Array<ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner>}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  allowDeployedBlockHeightRules?: Array<ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  disabledContractAddresses?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  disabledDeployedByAddresses?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  allowedDeployedByAddresses?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  allowedContractAddresses?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  traceId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  message: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetTokenFilteringRules200Response
   */
  success: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcGetTokenFilteringRules200ResponseAnyOf
 */
export interface ApiSvcGetTokenFilteringRules200ResponseAnyOf {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTokenFilteringRules200ResponseAnyOf
   */
  traceId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTokenFilteringRules200ResponseAnyOf
   */
  message: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetTokenFilteringRules200ResponseAnyOf
   */
  success: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcGetTokenV1200Response
 */
export interface ApiSvcGetTokenV1200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTokenV1200Response
   */
  access_token: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTokenV1200Response
   */
  token_type: ApiSvcGetTokenV1200ResponseTokenTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGetTokenV1200Response
   */
  expires_in: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTokenV1200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTokenV1200Response
   */
  message: string;
}

export const ApiSvcGetTokenV1200ResponseTokenTypeEnum = {
  Bearer: 'Bearer',
  Cookie: 'Cookie',
} as const;

export type ApiSvcGetTokenV1200ResponseTokenTypeEnum =
  typeof ApiSvcGetTokenV1200ResponseTokenTypeEnum[keyof typeof ApiSvcGetTokenV1200ResponseTokenTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcGetTransaction200Response
 */
export interface ApiSvcGetTransaction200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransaction200Response
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcGetTransaction200Response
   */
  created: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransaction200Response
   */
  txnType: string;
  /**
   *
   * @type {Array<ApiSvcTransactionActorDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  from: Array<ApiSvcTransactionActorDTO>;
  /**
   *
   * @type {Array<ApiSvcTransactionActorDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  to: Array<ApiSvcTransactionActorDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransaction200Response
   */
  reconciliationStatus: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransaction200Response
   */
  categorizationStatus: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransaction200Response
   */
  taxReconciliationStatus: string;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  fees: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  amountsWithoutFees: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  amountsWithFees: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  fullAmountSet: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcWalletValues>}
   * @memberof ApiSvcGetTransaction200Response
   */
  valuesByWallets: Array<ApiSvcWalletValues>;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  paidFees: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  exchangeRates?: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {Array<ApiSvcTransactionDetailsDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  details: Array<ApiSvcTransactionDetailsDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetTransaction200Response
   */
  isBlockchain: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetTransaction200Response
   */
  ignore: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetTransaction200Response
   */
  hasMatchedInvoices: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcGetTransaction200Response
   */
  readonly: boolean;
  /**
   *
   * @type {Array<ApiSvcWalletPointer>}
   * @memberof ApiSvcGetTransaction200Response
   */
  walletPointers: Array<ApiSvcWalletPointer>;
  /**
   *
   * @type {Array<ApiSvcAccountingDetailsDTO>}
   * @memberof ApiSvcGetTransaction200Response
   */
  accountingDetails?: Array<ApiSvcAccountingDetailsDTO>;
  /**
   *
   * @type {ApiSvcTaxTxnInfoDTO}
   * @memberof ApiSvcGetTransaction200Response
   */
  taxInfo?: ApiSvcTaxTxnInfoDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransaction200Response
   */
  memo?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetTransaction200Response
   */
  reconciledRemoteIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransaction200Response
   */
  combinedIntoTxnId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGetTransaction200Response
   */
  combinedSubTxnIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransaction200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransaction200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetTransactionAuditLog200Response
 */
export interface ApiSvcGetTransactionAuditLog200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionAuditLog200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionAuditLog200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionAuditLog200Response
   */
  traceId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetTransactionState200Response
 */
export interface ApiSvcGetTransactionState200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionState200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionState200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionState200Response
   */
  traceId?: string;
  /**
   *
   * @type {ApiSvcTransactionStateDTO}
   * @memberof ApiSvcGetTransactionState200Response
   */
  state: ApiSvcTransactionStateDTO;
  /**
   * this is the coin mapping for all the unique assets of the transaction
   * @type {Array<ApiSvcCoinMapping>}
   * @memberof ApiSvcGetTransactionState200Response
   */
  assets: Array<ApiSvcCoinMapping>;
  /**
   * friendly warnings on the txn
   * @type {Array<ApiSvcTxnWarnings>}
   * @memberof ApiSvcGetTransactionState200Response
   */
  warnings?: Array<ApiSvcTxnWarnings>;
  /**
   * default exchange rate set to fall back
   * @type {Array<ApiSvcManuallyPricedExchangeRateDTO>}
   * @memberof ApiSvcGetTransactionState200Response
   */
  defaultExchangeRateSet?: Array<ApiSvcManuallyPricedExchangeRateDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcGetTransactionStateDTO
 */
export interface ApiSvcGetTransactionStateDTO {
  /**
   *
   * @type {ApiSvcTransactionStateDTO}
   * @memberof ApiSvcGetTransactionStateDTO
   */
  state: ApiSvcTransactionStateDTO;
  /**
   * this is the coin mapping for all the unique assets of the transaction
   * @type {Array<ApiSvcCoinMapping>}
   * @memberof ApiSvcGetTransactionStateDTO
   */
  assets: Array<ApiSvcCoinMapping>;
  /**
   * friendly warnings on the txn
   * @type {Array<ApiSvcTxnWarnings>}
   * @memberof ApiSvcGetTransactionStateDTO
   */
  warnings?: Array<ApiSvcTxnWarnings>;
  /**
   * default exchange rate set to fall back
   * @type {Array<ApiSvcManuallyPricedExchangeRateDTO>}
   * @memberof ApiSvcGetTransactionStateDTO
   */
  defaultExchangeRateSet?: Array<ApiSvcManuallyPricedExchangeRateDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcGetTransactionsLite200Response
 */
export interface ApiSvcGetTransactionsLite200Response {
  /**
   *
   * @type {Array<ApiSvcTransactionLite>}
   * @memberof ApiSvcGetTransactionsLite200Response
   */
  txns: Array<ApiSvcTransactionLite>;
  /**
   *
   * @type {Array<ApiSvcCoinMapping>}
   * @memberof ApiSvcGetTransactionsLite200Response
   */
  coins: Array<ApiSvcCoinMapping>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionsLite200Response
   */
  olderPageToken?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionsLite200Response
   */
  newerPageToken?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionsLite200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionsLite200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetTransactionsPaginated200Response
 */
export interface ApiSvcGetTransactionsPaginated200Response {
  /**
   *
   * @type {Array<ApiSvcTransactionDTO>}
   * @memberof ApiSvcGetTransactionsPaginated200Response
   */
  items: Array<ApiSvcTransactionDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionsPaginated200Response
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionsPaginated200Response
   */
  nextPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionsPaginated200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetTransactionsPaginated200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetUserOrganizations200Response
 */
export interface ApiSvcGetUserOrganizations200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetUserOrganizations200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetUserOrganizations200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetWallet200Response
 */
export interface ApiSvcGetWallet200Response {
  /**
   *
   * @type {ApiSvcWalletResponseDTO}
   * @memberof ApiSvcGetWallet200Response
   */
  data: ApiSvcWalletResponseDTO;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcGetWallet200Response
   */
  _links: object;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetWallet200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetWallet200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetWalletRoles200Response
 */
export interface ApiSvcGetWalletRoles200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetWalletRoles200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetWalletRoles200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGetWallets200Response
 */
export interface ApiSvcGetWallets200Response {
  /**
   *
   * @type {Array<ApiSvcWalletResponseDTO>}
   * @memberof ApiSvcGetWallets200Response
   */
  items: Array<ApiSvcWalletResponseDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetWallets200Response
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetWallets200Response
   */
  nextPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetWallets200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGetWallets200Response
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcGroupWallet
 */
export interface ApiSvcGroupWallet {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGroupWallet
   */
  children?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGroupWallet
   */
  description?: string | null;
}
/**
 *
 * @export
 * @interface ApiSvcGroupWalletDTO
 */
export interface ApiSvcGroupWalletDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcGroupWalletDTO
   */
  description: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcGroupWalletDTO
   */
  children: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGroupWalletDTO
   */
  connectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcGroupWalletDTO
   */
  remoteId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcHardwareDevices = {
  Ledger: 'Ledger',
  Trezor: 'Trezor',
} as const;

export type ApiSvcHardwareDevices = typeof ApiSvcHardwareDevices[keyof typeof ApiSvcHardwareDevices];

/**
 *
 * @export
 * @interface ApiSvcHardwareWallet
 */
export interface ApiSvcHardwareWallet {
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWallet
   */
  derivationKey?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWallet
   */
  hd?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWallet
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWallet
   */
  path: string;
  /**
   *
   * @type {ApiSvcHardwareDevices}
   * @memberof ApiSvcHardwareWallet
   */
  deviceType: ApiSvcHardwareDevices;
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWallet
   */
  coin?: string | null;
}

/**
 *
 * @export
 * @interface ApiSvcHardwareWalletDTO
 */
export interface ApiSvcHardwareWalletDTO {
  /**
   *
   * @type {ApiSvcCoin}
   * @memberof ApiSvcHardwareWalletDTO
   */
  coin: ApiSvcCoin;
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWalletDTO
   */
  deviceName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWalletDTO
   */
  deviceType: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWalletDTO
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWalletDTO
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcHardwareWalletDTO
   */
  derivationKey?: string;
}

/**
 *
 * @export
 * @interface ApiSvcIetfEnterpriseUser
 */
export interface ApiSvcIetfEnterpriseUser {
  /**
   *
   * @type {string}
   * @memberof ApiSvcIetfEnterpriseUser
   */
  employeeNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcIetfEnterpriseUser
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcIetfEnterpriseUser
   */
  organization?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcIetfEnterpriseUser
   */
  division?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcIetfEnterpriseUser
   */
  department?: string;
  /**
   *
   * @type {ApiSvcIetfEnterpriseUserManager}
   * @memberof ApiSvcIetfEnterpriseUser
   */
  manager?: ApiSvcIetfEnterpriseUserManager;
}
/**
 *
 * @export
 * @interface ApiSvcIetfEnterpriseUserManager
 */
export interface ApiSvcIetfEnterpriseUserManager {
  /**
   *
   * @type {string}
   * @memberof ApiSvcIetfEnterpriseUserManager
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcIetfEnterpriseUserManager
   */
  $ref?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcIetfEnterpriseUserManager
   */
  displayName?: string;
}
/**
 *
 * @export
 * @interface ApiSvcIgnoreAction
 */
export interface ApiSvcIgnoreAction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcIgnoreAction
   */
  type: ApiSvcIgnoreActionTypeEnum;
}

export const ApiSvcIgnoreActionTypeEnum = {
  Ignore: 'Ignore',
} as const;

export type ApiSvcIgnoreActionTypeEnum = typeof ApiSvcIgnoreActionTypeEnum[keyof typeof ApiSvcIgnoreActionTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcIgnoreTypeDTO = {
  All: 'All',
  Ignored: 'Ignored',
  Unignored: 'Unignored',
} as const;

export type ApiSvcIgnoreTypeDTO = typeof ApiSvcIgnoreTypeDTO[keyof typeof ApiSvcIgnoreTypeDTO];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcImpairmentMethodology = {
  DailyLow: 'daily-low',
  DailyClose: 'daily-close',
  DailyOpen: 'daily-open',
  OrgDefault: 'org-default',
  MonthlyLow: 'monthly-low',
  MonthlyClose: 'monthly-close',
  MonthlyOpen: 'monthly-open',
} as const;

export type ApiSvcImpairmentMethodology = typeof ApiSvcImpairmentMethodology[keyof typeof ApiSvcImpairmentMethodology];

/**
 *
 * @export
 * @interface ApiSvcIndexedTransactionsUnionType
 */
export interface ApiSvcIndexedTransactionsUnionType {
  /**
   *
   * @type {ApiSvcTransactionsUnionType}
   * @memberof ApiSvcIndexedTransactionsUnionType
   */
  txn: ApiSvcTransactionsUnionType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcIndexedTransactionsUnionType
   */
  txnId: string;
}
/**
 *
 * @export
 * @interface ApiSvcInternalTransferCategorizationAction
 */
export interface ApiSvcInternalTransferCategorizationAction {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcInternalTransferCategorizationAction
   */
  ignoreFailPricing?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInternalTransferCategorizationAction
   */
  feeContactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInternalTransferCategorizationAction
   */
  type: ApiSvcInternalTransferCategorizationActionTypeEnum;
}

export const ApiSvcInternalTransferCategorizationActionTypeEnum = {
  InternalTransferCategorization: 'InternalTransferCategorization',
} as const;

export type ApiSvcInternalTransferCategorizationActionTypeEnum =
  typeof ApiSvcInternalTransferCategorizationActionTypeEnum[keyof typeof ApiSvcInternalTransferCategorizationActionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcInventoryAction
 */
export interface ApiSvcInventoryAction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  orgId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  runId?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryAction
   */
  timestampSEC?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  action?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  txnId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  eventId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  lotId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  asset?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  assetId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  assetUnitAdj?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  assetBalance?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryAction
   */
  lotAcquisitionTimestampSEC?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  txnExchangeRate?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  carryingValue?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  impairmentExpense?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  fairMarketValueDisposed?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  shortTermGainLoss?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  longTermGainLoss?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  undatedGainLoss?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  costBasisAcquired?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  costBasisRelieved?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  costAverageRate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcInventoryAction
   */
  isTrade?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  lineError?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  transferLotId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  originalLotId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  inventory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryAction
   */
  wallet?: string;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryActionResponse
 */
export interface ApiSvcInventoryActionResponse {
  /**
   *
   * @type {Array<ApiSvcInventoryAction>}
   * @memberof ApiSvcInventoryActionResponse
   */
  actions: Array<ApiSvcInventoryAction>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryActionResponse
   */
  nextPageToken?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcInventoryActionResponse
   */
  exportIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryBalanceCheckReportLine
 */
export interface ApiSvcInventoryBalanceCheckReportLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  org_name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  walletid: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  wallet_name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  asset_symbol: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  bitwave_balance_time: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  bitwave_balance: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  thirdparty_balance: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  difference: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryBalanceCheckReportLine
   */
  thirdparty_balance_time: string;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryBalanceCheckReportResp
 */
export interface ApiSvcInventoryBalanceCheckReportResp {
  /**
   *
   * @type {Array<ApiSvcInventoryBalanceCheckReportLine>}
   * @memberof ApiSvcInventoryBalanceCheckReportResp
   */
  lines: Array<ApiSvcInventoryBalanceCheckReportLine>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryBalanceCheckReportResp
   */
  pageToken?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcInventoryBalanceCheckReportResp
   */
  exportIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryBalanceResp
 */
export interface ApiSvcInventoryBalanceResp {
  /**
   *
   * @type {Array<ApiSvcInventoryShortBalanceLine>}
   * @memberof ApiSvcInventoryBalanceResp
   */
  lines: Array<ApiSvcInventoryShortBalanceLine>;
  /**
   *
   * @type {ApiSvcInventoryCounts}
   * @memberof ApiSvcInventoryBalanceResp
   */
  counts: ApiSvcInventoryCounts;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryCostBasisRollForwardLine
 */
export interface ApiSvcInventoryCostBasisRollForwardLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  asset: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  asset_id: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  starting_fiat_value: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  starting_qty_value: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  fiat_increases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  qty_increases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  qty_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  ending_fiat_value: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  ending_qty_value: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  fmv_disposed_fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  stGainLoss_fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  ltGainLoss_fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  impairExpense_fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  fiat_check_mustbe_zero: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  qty_check_mustbe_zero: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  undatedGainLoss_fiat_decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  current_price?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  fair_market_value?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  unrealized_gain_loss?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCostBasisRollForwardLine
   */
  impairExpenseReversal_fiat_increases?: number;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryCostBasisRollForwardResp
 */
export interface ApiSvcInventoryCostBasisRollForwardResp {
  /**
   *
   * @type {Array<ApiSvcInventoryCostBasisRollForwardLine>}
   * @memberof ApiSvcInventoryCostBasisRollForwardResp
   */
  lines: Array<ApiSvcInventoryCostBasisRollForwardLine>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryCostBasisRollForwardResp
   */
  pageToken?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcInventoryCostBasisRollForwardResp
   */
  exportIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryCounts
 */
export interface ApiSvcInventoryCounts {
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCounts
   */
  actions: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCounts
   */
  acquisitions: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCounts
   */
  dispositions: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCounts
   */
  uncategorized: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCounts
   */
  errored: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCounts
   */
  negativeBalanceError?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryCounts
   */
  lineErrors?: number;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryCountsResp
 */
export interface ApiSvcInventoryCountsResp {
  /**
   *
   * @type {ApiSvcInventoryCounts}
   * @memberof ApiSvcInventoryCountsResp
   */
  counts: ApiSvcInventoryCounts;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryEnhancedGainLossResp
 */
export interface ApiSvcInventoryEnhancedGainLossResp {
  /**
   *
   * @type {Array<ApiSvcInventoryJeReclassLine>}
   * @memberof ApiSvcInventoryEnhancedGainLossResp
   */
  lines: Array<ApiSvcInventoryJeReclassLine>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryEnhancedGainLossResp
   */
  pageToken?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcInventoryEnhancedGainLossResp
   */
  exportIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryJeReclassLine
 */
export interface ApiSvcInventoryJeReclassLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  asset: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  Reclass_Debit_Account: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  Reclass_Debit: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  Reclass_Credit_Account: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  Reclass_Credit: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  COLUMN_BREAK: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  period_change_in_digital_asset: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  starting_balance: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  increases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  decreases: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  gain_Loss_changes: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  impariment_changes: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryJeReclassLine
   */
  ending_balance: number;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryJeReclassResp
 */
export interface ApiSvcInventoryJeReclassResp {
  /**
   *
   * @type {Array<ApiSvcInventoryJeReclassLine>}
   * @memberof ApiSvcInventoryJeReclassResp
   */
  lines: Array<ApiSvcInventoryJeReclassLine>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryJeReclassResp
   */
  pageToken?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcInventoryJeReclassResp
   */
  exportIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryLot
 */
export interface ApiSvcInventoryLot {
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryLot
   */
  timestampSEC: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  lotId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  asset: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  qty: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  unitsAcquired: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  unitsDisposed: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  costBasisAcquired: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  costBasisRelieved: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  impairmentExpense: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  costBasis: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  carryingValue: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLot
   */
  inventory?: string;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryLotResponse
 */
export interface ApiSvcInventoryLotResponse {
  /**
   *
   * @type {Array<ApiSvcInventoryLot>}
   * @memberof ApiSvcInventoryLotResponse
   */
  lots: Array<ApiSvcInventoryLot>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryLotResponse
   */
  nextPageToken?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcInventoryLotResponse
   */
  exportIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryMappingRule
 */
export interface ApiSvcInventoryMappingRule {
  /**
   *
   * @type {ApiSvcInventoryMappingRuleType}
   * @memberof ApiSvcInventoryMappingRule
   */
  type: ApiSvcInventoryMappingRuleType;
}

/**
 *
 * @export
 * @interface ApiSvcInventoryMappingRuleRequest
 */
export interface ApiSvcInventoryMappingRuleRequest {
  /**
   *
   * @type {ApiSvcInventoryMappingRuleType}
   * @memberof ApiSvcInventoryMappingRuleRequest
   */
  type: ApiSvcInventoryMappingRuleType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryMappingRuleRequest
   */
  inventoryGroupId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcInventoryMappingRuleType = {
  GroupMapping: 'inventory-group-mapping',
  PerWallet: 'inventory-per-wallet',
} as const;

export type ApiSvcInventoryMappingRuleType =
  typeof ApiSvcInventoryMappingRuleType[keyof typeof ApiSvcInventoryMappingRuleType];

/**
 *
 * @export
 * @interface ApiSvcInventoryShortBalanceLine
 */
export interface ApiSvcInventoryShortBalanceLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryShortBalanceLine
   */
  asset: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryShortBalanceLine
   */
  assetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryShortBalanceLine
   */
  qty: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryShortBalanceLine
   */
  costBasisAcquired: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryShortBalanceLine
   */
  costBasisRelieved: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryShortBalanceLine
   */
  impairmentExpense: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryShortBalanceLine
   */
  impairmentExpenseReversal: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryShortBalanceLine
   */
  costBasis: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryShortBalanceLine
   */
  carryingValue: string | null;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryView
 */
export interface ApiSvcInventoryView {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryView
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryView
   */
  createdSEC: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryView
   */
  updatedSEC: number;
  /**
   *
   * @type {ApiSvcUserPointer}
   * @memberof ApiSvcInventoryView
   */
  createdBy: ApiSvcUserPointer;
  /**
   *
   * @type {ApiSvcUserPointer}
   * @memberof ApiSvcInventoryView
   */
  updatedBy: ApiSvcUserPointer;
  /**
   *
   * @type {ApiSvcTaxStrategy}
   * @memberof ApiSvcInventoryView
   */
  inventoryPickingStrategy: ApiSvcTaxStrategy;
  /**
   *
   * @type {ApiSvcGainLossConfig}
   * @memberof ApiSvcInventoryView
   */
  inventoryConfig: ApiSvcGainLossConfig;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcInventoryView
   */
  impair: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcInventoryView
   */
  ignoreNFTs?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryView
   */
  lastUpdateRunSEC?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryView
   */
  activeViewUpdateId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryView
   */
  activeRunId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcInventoryView
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcInventoryView
   */
  ignoreOrgWrappingTreatments: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryViewDTO
 */
export interface ApiSvcInventoryViewDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryViewDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryViewDTO
   */
  name: string;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryViewDownloadLinksResponse
 */
export interface ApiSvcInventoryViewDownloadLinksResponse {
  /**
   *
   * @type {ApiSvcInventoryViewDownloadLinksResponseLinks}
   * @memberof ApiSvcInventoryViewDownloadLinksResponse
   */
  _links: ApiSvcInventoryViewDownloadLinksResponseLinks;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryViewDownloadLinksResponseLinks
 */
export interface ApiSvcInventoryViewDownloadLinksResponseLinks {
  /**
   *
   * @type {ApiSvcLinkDTO}
   * @memberof ApiSvcInventoryViewDownloadLinksResponseLinks
   */
  gainLoss: ApiSvcLinkDTO;
  /**
   *
   * @type {ApiSvcLinkDTO}
   * @memberof ApiSvcInventoryViewDownloadLinksResponseLinks
   */
  results: ApiSvcLinkDTO;
  /**
   *
   * @type {ApiSvcLinkDTO}
   * @memberof ApiSvcInventoryViewDownloadLinksResponseLinks
   */
  actions: ApiSvcLinkDTO;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryViewResponse
 */
export interface ApiSvcInventoryViewResponse {
  /**
   *
   * @type {ApiSvcInventoryView}
   * @memberof ApiSvcInventoryViewResponse
   */
  item: ApiSvcInventoryView;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryViewUpdate
 */
export interface ApiSvcInventoryViewUpdate {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryViewUpdate
   */
  id?: string;
  /**
   *
   * @type {ApiSvcJobStatus}
   * @memberof ApiSvcInventoryViewUpdate
   */
  status: ApiSvcJobStatus;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryViewUpdate
   */
  inventoryViewId: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryViewUpdate
   */
  updateRequestedSEC: number;
  /**
   *
   * @type {ApiSvcUserPointer}
   * @memberof ApiSvcInventoryViewUpdate
   */
  updateRequestedBy: ApiSvcUserPointer;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInventoryViewUpdate
   */
  updateCompletedSEC?: number;
  /**
   *
   * @type {ApiSvcGainLossRunFiles}
   * @memberof ApiSvcInventoryViewUpdate
   */
  files?: ApiSvcGainLossRunFiles;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcInventoryViewUpdate
   */
  errors?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInventoryViewUpdate
   */
  gainLossRunId?: string;
}

/**
 *
 * @export
 * @interface ApiSvcInventoryViewUpdatesResult
 */
export interface ApiSvcInventoryViewUpdatesResult {
  /**
   *
   * @type {Array<ApiSvcInventoryViewUpdate>}
   * @memberof ApiSvcInventoryViewUpdatesResult
   */
  items: Array<ApiSvcInventoryViewUpdate>;
}
/**
 *
 * @export
 * @interface ApiSvcInventoryViewsResult
 */
export interface ApiSvcInventoryViewsResult {
  /**
   *
   * @type {Array<ApiSvcInventoryViewDTO>}
   * @memberof ApiSvcInventoryViewsResult
   */
  items: Array<ApiSvcInventoryViewDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcInvoiceCategorizationLineDTO
 */
export interface ApiSvcInvoiceCategorizationLineDTO {
  /**
   *
   * @type {ApiSvcCategorizedLineTypeInvoicePaymentLine}
   * @memberof ApiSvcInvoiceCategorizationLineDTO
   */
  type: ApiSvcCategorizedLineTypeInvoicePaymentLine;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceCategorizationLineDTO
   */
  invoiceId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceCategorizationLineDTO
   */
  walletId?: string;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcInvoiceCategorizationLineDTO
   */
  paidAmount: ApiSvcRawValueDTO;
}

/**
 *
 * @export
 * @interface ApiSvcInvoiceDTO
 */
export interface ApiSvcInvoiceDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  reference?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  status: string;
  /**
   *
   * @type {Array<ApiSvcInvoiceLineDTO>}
   * @memberof ApiSvcInvoiceDTO
   */
  lines: Array<ApiSvcInvoiceLineDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  dueDate?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInvoiceDTO
   */
  dueAmount: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInvoiceDTO
   */
  totalAmount: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  source: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcInvoiceDTO
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  contactId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceDTO
   */
  url?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcInvoiceDTO
   */
  hasMatchedTransactions?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInvoiceDTO
   */
  exchangeRate: number;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcInvoiceDTO
   */
  metadata?: object;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInvoiceDTO
   */
  lastUpdatedSEC?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcInvoiceInputType = {
  Bill: 'Bill',
  Invoice: 'Invoice',
} as const;

export type ApiSvcInvoiceInputType = typeof ApiSvcInvoiceInputType[keyof typeof ApiSvcInvoiceInputType];

/**
 *
 * @export
 * @interface ApiSvcInvoiceLineDTO
 */
export interface ApiSvcInvoiceLineDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceLineDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoiceLineDTO
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInvoiceLineDTO
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInvoiceLineDTO
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInvoiceLineDTO
   */
  unitAmount?: number;
}
/**
 *
 * @export
 * @interface ApiSvcInvoicePaymentCategorizationDTO
 */
export interface ApiSvcInvoicePaymentCategorizationDTO {
  /**
   *
   * @type {ApiSvcCategorizationTypeInvoicePayment}
   * @memberof ApiSvcInvoicePaymentCategorizationDTO
   */
  type: ApiSvcCategorizationTypeInvoicePayment;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcInvoicePaymentCategorizationDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcInvoicePaymentCategorizationDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentCategorizationDTO
   */
  totalAmount: string;
  /**
   *
   * @type {Array<ApiSvcInvoiceCategorizationLineDTO>}
   * @memberof ApiSvcInvoicePaymentCategorizationDTO
   */
  invoices: Array<ApiSvcInvoiceCategorizationLineDTO>;
  /**
   *
   * @type {Array<ApiSvcFeeLineDTO>}
   * @memberof ApiSvcInvoicePaymentCategorizationDTO
   */
  fees?: Array<ApiSvcFeeLineDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentCategorizationDTO
   */
  invoiceType: string;
  /**
   *
   * @type {ApiSvcInvoicePaymentLineForexDTO}
   * @memberof ApiSvcInvoicePaymentCategorizationDTO
   */
  forex?: ApiSvcInvoicePaymentLineForexDTO;
}

/**
 *
 * @export
 * @interface ApiSvcInvoicePaymentLineDTO
 */
export interface ApiSvcInvoicePaymentLineDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentLineDTO
   */
  invoiceId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentLineDTO
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentLineDTO
   */
  ticker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentLineDTO
   */
  contactId: string;
  /**
   *
   * @type {ApiSvcInvoicePaymentLineDTOCoin}
   * @memberof ApiSvcInvoicePaymentLineDTO
   */
  coin?: ApiSvcInvoicePaymentLineDTOCoin;
  /**
   *
   * @type {ApiSvcInvoicePaymentLineDTOUnit}
   * @memberof ApiSvcInvoicePaymentLineDTO
   */
  unit?: ApiSvcInvoicePaymentLineDTOUnit;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentLineDTO
   */
  coinAmount?: string;
  /**
   *
   * @type {ApiSvcDetailedForexDTO}
   * @memberof ApiSvcInvoicePaymentLineDTO
   */
  forex?: ApiSvcDetailedForexDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentLineDTO
   */
  walletId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcInvoicePaymentLineDTOCoin
 */
export interface ApiSvcInvoicePaymentLineDTOCoin {}
/**
 *
 * @export
 * @interface ApiSvcInvoicePaymentLineDTOUnit
 */
export interface ApiSvcInvoicePaymentLineDTOUnit {}
/**
 *
 * @export
 * @interface ApiSvcInvoicePaymentLineForexDTO
 */
export interface ApiSvcInvoicePaymentLineForexDTO {
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcInvoicePaymentLineForexDTO
   */
  sourceValue: ApiSvcRawValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentLineForexDTO
   */
  categoryId: string;
}
/**
 *
 * @export
 * @interface ApiSvcInvoicePaymentTxnDTO
 */
export interface ApiSvcInvoicePaymentTxnDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicePaymentTxnDTO
   */
  totalAmount: string;
  /**
   *
   * @type {Array<ApiSvcInvoicePaymentLineDTO>}
   * @memberof ApiSvcInvoicePaymentTxnDTO
   */
  invoices: Array<ApiSvcInvoicePaymentLineDTO>;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcInvoicePaymentTxnDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {Array<ApiSvcDetailedFeeDTO>}
   * @memberof ApiSvcInvoicePaymentTxnDTO
   */
  fees?: Array<ApiSvcDetailedFeeDTO>;
}
/**
 * This class allows for custom filtering implementation based on the InvoiceDTO
 * @export
 * @interface ApiSvcInvoicesDTOFilteredPaginated
 */
export interface ApiSvcInvoicesDTOFilteredPaginated {
  /**
   *
   * @type {Array<ApiSvcInvoiceDTO>}
   * @memberof ApiSvcInvoicesDTOFilteredPaginated
   */
  records: Array<ApiSvcInvoiceDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicesDTOFilteredPaginated
   */
  previousPageToken?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicesDTOFilteredPaginated
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface ApiSvcInvoicingSettings
 */
export interface ApiSvcInvoicingSettings {
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicingSettings
   */
  fromEmail: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicingSettings
   */
  fromName?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicingSettings
   */
  bitcoinAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicingSettings
   */
  bitcoinXPub?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicingSettings
   */
  bitcoinXPubPath?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcInvoicingSettings
   */
  bitcoinCurrentXPubIndex?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicingSettings
   */
  ethAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicingSettings
   */
  zecAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicingSettings
   */
  usdcAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcInvoicingSettings
   */
  usdtAddress?: string;
}
/**
 *
 * @export
 * @interface ApiSvcJobPointerInterface
 */
export interface ApiSvcJobPointerInterface {
  /**
   *
   * @type {ApiSvcJobPointerInterfaceLinks}
   * @memberof ApiSvcJobPointerInterface
   */
  _links: ApiSvcJobPointerInterfaceLinks;
  /**
   *
   * @type {string}
   * @memberof ApiSvcJobPointerInterface
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcJobPointerInterface
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcJobPointerInterface
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcJobPointerInterface
   */
  groupName: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcJobPointerInterface
   */
  cadenceSEC: number;
}
/**
 *
 * @export
 * @interface ApiSvcJobPointerInterfaceLinks
 */
export interface ApiSvcJobPointerInterfaceLinks {
  /**
   *
   * @type {ApiSvcJobPointerInterfaceLinksExec}
   * @memberof ApiSvcJobPointerInterfaceLinks
   */
  exec: ApiSvcJobPointerInterfaceLinksExec;
}
/**
 *
 * @export
 * @interface ApiSvcJobPointerInterfaceLinksExec
 */
export interface ApiSvcJobPointerInterfaceLinksExec {
  /**
   *
   * @type {string}
   * @memberof ApiSvcJobPointerInterfaceLinksExec
   */
  method: ApiSvcJobPointerInterfaceLinksExecMethodEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcJobPointerInterfaceLinksExec
   */
  href: string;
}

export const ApiSvcJobPointerInterfaceLinksExecMethodEnum = {
  Post: 'POST',
} as const;

export type ApiSvcJobPointerInterfaceLinksExecMethodEnum =
  typeof ApiSvcJobPointerInterfaceLinksExecMethodEnum[keyof typeof ApiSvcJobPointerInterfaceLinksExecMethodEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcJobStatus = {
  New: 'New',
  Running: 'Running',
  Errored: 'Errored',
  Complete: 'Complete',
  TimedOut: 'TimedOut',
  Cancelled: 'Cancelled',
} as const;

export type ApiSvcJobStatus = typeof ApiSvcJobStatus[keyof typeof ApiSvcJobStatus];

/**
 *
 * @export
 * @interface ApiSvcJobsPageResultDto
 */
export interface ApiSvcJobsPageResultDto {
  /**
   *
   * @type {string}
   * @memberof ApiSvcJobsPageResultDto
   */
  nextPageToken?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcJobsPageResultDto
   */
  service?: string;
  /**
   *
   * @type {Array<ApiSvcJobPointerInterface>}
   * @memberof ApiSvcJobsPageResultDto
   */
  jobs?: Array<ApiSvcJobPointerInterface>;
}
/**
 *
 * @export
 * @interface ApiSvcJwtBasedToken
 */
export interface ApiSvcJwtBasedToken {
  /**
   *
   * @type {string}
   * @memberof ApiSvcJwtBasedToken
   */
  jwt: string;
}
/**
 *
 * @export
 * @interface ApiSvcKeyBasedPassToken
 */
export interface ApiSvcKeyBasedPassToken {
  /**
   *
   * @type {string}
   * @memberof ApiSvcKeyBasedPassToken
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcKeyBasedPassToken
   */
  passphrase: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcKeyBasedPassToken
   */
  signingKey: string;
}
/**
 *
 * @export
 * @interface ApiSvcKeyBasedToken
 */
export interface ApiSvcKeyBasedToken {
  /**
   *
   * @type {string}
   * @memberof ApiSvcKeyBasedToken
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcKeyBasedToken
   */
  secret?: string;
}
/**
 *
 * @export
 * @interface ApiSvcLineDeltaDTO
 */
export interface ApiSvcLineDeltaDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcLineDeltaDTO
   */
  ticker: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcLineDeltaDTO
   */
  succeededCheck: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcLineDeltaDTO
   */
  isNegative: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLineDeltaDTO
   */
  localValue?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLineDeltaDTO
   */
  localFiatValue?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLineDeltaDTO
   */
  remoteValue?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLineDeltaDTO
   */
  remoteFiatValue?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcLineDeltaDTO
   */
  warnings?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcLinkDTO
 */
export interface ApiSvcLinkDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcLinkDTO
   */
  href: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLinkDTO
   */
  method: ApiSvcLinkDTOMethodEnum;
  /**
   *
   * @type {number}
   * @memberof ApiSvcLinkDTO
   */
  expiresAtSEC?: number;
}

export const ApiSvcLinkDTOMethodEnum = {
  Get: 'get',
  Post: 'post',
  Put: 'put',
} as const;

export type ApiSvcLinkDTOMethodEnum = typeof ApiSvcLinkDTOMethodEnum[keyof typeof ApiSvcLinkDTOMethodEnum];

/**
 *
 * @export
 * @interface ApiSvcLrcMarketplaceWallet
 */
export interface ApiSvcLrcMarketplaceWallet {
  /**
   *
   * @type {string}
   * @memberof ApiSvcLrcMarketplaceWallet
   */
  connectionId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLrcMarketplaceWallet
   */
  groupId?: string | null;
  /**
   *
   * @type {ApiSvcRollupConfig}
   * @memberof ApiSvcLrcMarketplaceWallet
   */
  rollupConfig?: ApiSvcRollupConfig | null;
  /**
   *
   * @type {number}
   * @memberof ApiSvcLrcMarketplaceWallet
   */
  submitterId: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcLrcMarketplaceWallet
   */
  affiliateId: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLrcMarketplaceWallet
   */
  description?: string | null;
}
/**
 *
 * @export
 * @interface ApiSvcLrcMarketplaceWalletDTO
 */
export interface ApiSvcLrcMarketplaceWalletDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcLrcMarketplaceWalletDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLrcMarketplaceWalletDTO
   */
  networkId: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcLrcMarketplaceWalletDTO
   */
  affiliateId: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcLrcMarketplaceWalletDTO
   */
  submitterId: number;
  /**
   *
   * @type {ApiSvcRollupConfigDTO}
   * @memberof ApiSvcLrcMarketplaceWalletDTO
   */
  rollupConfig?: ApiSvcRollupConfigDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLrcMarketplaceWalletDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcLrcMarketplaceWalletDTO
   */
  connectionId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcManualBlockchainWalletDTO
 */
export interface ApiSvcManualBlockchainWalletDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualBlockchainWalletDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualBlockchainWalletDTO
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualBlockchainWalletDTO
   */
  remoteId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcManualTransaction
 */
export interface ApiSvcManualTransaction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualTransaction
   */
  type: ApiSvcManualTransactionTypeEnum;
  /**
   *
   * @type {ApiSvcRemoteSystemId}
   * @memberof ApiSvcManualTransaction
   */
  remoteSystemId?: ApiSvcRemoteSystemId;
  /**
   *
   * @type {ApiSvcTransactionId}
   * @memberof ApiSvcManualTransaction
   */
  transactionId?: ApiSvcTransactionId;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualTransaction
   */
  connectionId?: string;
  /**
   *
   * @type {Array<ApiSvcTransactionMovementDetails>}
   * @memberof ApiSvcManualTransaction
   */
  from: Array<ApiSvcTransactionMovementDetails>;
  /**
   *
   * @type {Array<ApiSvcTransactionMovementDetails>}
   * @memberof ApiSvcManualTransaction
   */
  to: Array<ApiSvcTransactionMovementDetails>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualTransaction
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualTransaction
   */
  walletName?: string;
  /**
   *
   * @type {ApiSvcSources}
   * @memberof ApiSvcManualTransaction
   */
  source: ApiSvcSources;
  /**
   *
   * @type {ApiSvcManualTxnTypes}
   * @memberof ApiSvcManualTransaction
   */
  transactionType: ApiSvcManualTxnTypes;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcManualTransaction
   */
  amounts: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcManualTransaction
   */
  createdSEC: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcManualTransaction
   */
  isComplete: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualTransaction
   */
  contactId?: string;
  /**
   *
   * @type {ApiSvcTransferTransactionTransferAmount}
   * @memberof ApiSvcManualTransaction
   */
  fee?: ApiSvcTransferTransactionTransferAmount;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcManualTransaction
   */
  isOnChain?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcManualTransaction
   */
  isTaxExempt?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ApiSvcManualTransaction
   */
  metadata?: { [key: string]: string };
  /**
   *
   * @type {ApiSvcTxnOrigin}
   * @memberof ApiSvcManualTransaction
   */
  txnOrigin?: ApiSvcTxnOrigin;
}

export const ApiSvcManualTransactionTypeEnum = {
  Manual: 'manual',
} as const;

export type ApiSvcManualTransactionTypeEnum =
  typeof ApiSvcManualTransactionTypeEnum[keyof typeof ApiSvcManualTransactionTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcManualTxnTypes = {
  Deposit: 'deposit',
  Withdrawal: 'withdrawal',
  Other: 'other',
} as const;

export type ApiSvcManualTxnTypes = typeof ApiSvcManualTxnTypes[keyof typeof ApiSvcManualTxnTypes];

/**
 *
 * @export
 * @interface ApiSvcManualWallet
 */
export interface ApiSvcManualWallet {
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualWallet
   */
  description?: string | null;
}
/**
 *
 * @export
 * @interface ApiSvcManualWalletDTO
 */
export interface ApiSvcManualWalletDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualWalletDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualWalletDTO
   */
  remoteId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualWalletDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManualWalletDTO
   */
  connectionId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcManuallyPricedExchangeRateDTO
 */
export interface ApiSvcManuallyPricedExchangeRateDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcManuallyPricedExchangeRateDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManuallyPricedExchangeRateDTO
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManuallyPricedExchangeRateDTO
   */
  to: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeManual}
   * @memberof ApiSvcManuallyPricedExchangeRateDTO
   */
  type: ApiSvcExchangeRateTypeManual;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManuallyPricedExchangeRateDTO
   */
  priceId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManuallyPricedExchangeRateDTO
   */
  rate: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcManuallyPricedExchangeRateDTO
   */
  reason?: string;
}

/**
 *
 * @export
 * @interface ApiSvcMarketplaceAffiliateToken
 */
export interface ApiSvcMarketplaceAffiliateToken {
  /**
   *
   * @type {number}
   * @memberof ApiSvcMarketplaceAffiliateToken
   */
  affiliateId: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcMarketplaceAffiliateToken
   */
  submitterId: number;
}
/**
 *
 * @export
 * @interface ApiSvcMe200Response
 */
export interface ApiSvcMe200Response {
  /**
   *
   * @type {ApiSvcUserTosAcceptance}
   * @memberof ApiSvcMe200Response
   */
  tosAcceptance: ApiSvcUserTosAcceptance;
  /**
   *
   * @type {ApiSvcMe200ResponseFeatureFlags}
   * @memberof ApiSvcMe200Response
   */
  featureFlags: ApiSvcMe200ResponseFeatureFlags;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMe200Response
   */
  loginMethod: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMe200Response
   */
  photoUrl: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMe200Response
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMe200Response
   */
  id: string;
}
/**
 *
 * @export
 * @interface ApiSvcMe200ResponseFeatureFlags
 */
export interface ApiSvcMe200ResponseFeatureFlags {
  /**
   *
   * @type {string}
   * @memberof ApiSvcMe200ResponseFeatureFlags
   */
  'create-org': string;
}
/**
 * This is the internal designated type of the metadata.
 * @export
 * @enum {string}
 */

export const ApiSvcMetaDataType = {
  Segment: 'segment',
  Other: 'other',
} as const;

export type ApiSvcMetaDataType = typeof ApiSvcMetaDataType[keyof typeof ApiSvcMetaDataType];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcMetaType = {
  Segment: 'segment',
  Other: 'other',
} as const;

export type ApiSvcMetaType = typeof ApiSvcMetaType[keyof typeof ApiSvcMetaType];

/**
 *
 * @export
 * @interface ApiSvcMetadataComparison
 */
export interface ApiSvcMetadataComparison {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMetadataComparison
   */
  txnRecordRule?: boolean | null;
  /**
   *
   * @type {Array<ApiSvcMetadataComparisonGraphQLMetadataInner>}
   * @memberof ApiSvcMetadataComparison
   */
  metadata: Array<ApiSvcMetadataComparisonGraphQLMetadataInner>;
  /**
   *
   * @type {ApiSvcMetadataOperator}
   * @memberof ApiSvcMetadataComparison
   */
  operator: ApiSvcMetadataOperator;
}

/**
 *
 * @export
 * @interface ApiSvcMetadataComparisonGraphQL
 */
export interface ApiSvcMetadataComparisonGraphQL {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMetadataComparisonGraphQL
   */
  txnRecordRule?: boolean | null;
  /**
   *
   * @type {Array<ApiSvcMetadataComparisonGraphQLMetadataInner>}
   * @memberof ApiSvcMetadataComparisonGraphQL
   */
  metadata: Array<ApiSvcMetadataComparisonGraphQLMetadataInner>;
  /**
   *
   * @type {ApiSvcMetadataOperator}
   * @memberof ApiSvcMetadataComparisonGraphQL
   */
  operator: ApiSvcMetadataOperator;
}

/**
 *
 * @export
 * @interface ApiSvcMetadataComparisonGraphQLMetadataInner
 */
export interface ApiSvcMetadataComparisonGraphQLMetadataInner {
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataComparisonGraphQLMetadataInner
   */
  value?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataComparisonGraphQLMetadataInner
   */
  key: string;
}
/**
 *
 * @export
 * @interface ApiSvcMetadataDTO
 */
export interface ApiSvcMetadataDTO {
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcMetadataDTO
   */
  customData?: object | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataDTO
   */
  specialMetadataType?: string | null;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcMetadataDTO
   */
  properties?: object | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataDTO
   */
  connectionId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataDTO
   */
  remoteType: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataDTO
   */
  name: string;
  /**
   *
   * @type {ApiSvcMetaType}
   * @memberof ApiSvcMetadataDTO
   */
  metaType: ApiSvcMetaType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataDTO
   */
  source: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMetadataDTO
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataDTO
   */
  id: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcMetadataOperator = {
  And: 'AND',
  Nand: 'NAND',
  Or: 'OR',
  Nor: 'NOR',
  Xor: 'XOR',
} as const;

export type ApiSvcMetadataOperator = typeof ApiSvcMetadataOperator[keyof typeof ApiSvcMetadataOperator];

/**
 *
 * @export
 * @interface ApiSvcMetadataRecordDTO
 */
export interface ApiSvcMetadataRecordDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataRecordDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMetadataRecordDTO
   */
  connectionId: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMetadataRecordDTO
   */
  enabled: boolean;
  /**
   *
   * @type {ApiSvcMetadataRecordDTOSource}
   * @memberof ApiSvcMetadataRecordDTO
   */
  source: ApiSvcMetadataRecordDTOSource;
  /**
   *
   * @type {ApiSvcMetaDataType}
   * @memberof ApiSvcMetadataRecordDTO
   */
  metaType: ApiSvcMetaDataType;
  /**
   *
   * @type {ApiSvcMetadataDTO}
   * @memberof ApiSvcMetadataRecordDTO
   */
  data: ApiSvcMetadataDTO;
}

/**
 *
 * @export
 * @interface ApiSvcMetadataRecordDTOSource
 */
export interface ApiSvcMetadataRecordDTOSource {}
/**
 *
 * @export
 * @interface ApiSvcMultiContactInvoiceCategorizationDTO
 */
export interface ApiSvcMultiContactInvoiceCategorizationDTO {
  /**
   *
   * @type {ApiSvcCategorizationTypeMultiContactInvoice}
   * @memberof ApiSvcMultiContactInvoiceCategorizationDTO
   */
  type: ApiSvcCategorizationTypeMultiContactInvoice;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcMultiContactInvoiceCategorizationDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMultiContactInvoiceCategorizationDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiContactInvoiceCategorizationDTO
   */
  totalAmount: string;
  /**
   *
   * @type {Array<ApiSvcMultiContactInvoicePaymentLineDTO>}
   * @memberof ApiSvcMultiContactInvoiceCategorizationDTO
   */
  invoices: Array<ApiSvcMultiContactInvoicePaymentLineDTO>;
  /**
   *
   * @type {Array<ApiSvcFeeLineDTO>}
   * @memberof ApiSvcMultiContactInvoiceCategorizationDTO
   */
  fees?: Array<ApiSvcFeeLineDTO>;
  /**
   *
   * @type {Array<ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO>}
   * @memberof ApiSvcMultiContactInvoiceCategorizationDTO
   */
  unapplied?: Array<ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO>;
}

/**
 *
 * @export
 * @interface ApiSvcMultiContactInvoicePaymentLineDTO
 */
export interface ApiSvcMultiContactInvoicePaymentLineDTO {
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  sourceValue: ApiSvcRawValueDTO;
  /**
   * source value multiply by exchange rate not every categorization has it
   * @type {any}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  appliedExchangeRateValue?: any;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  walletId?: string;
  /**
   *
   * @type {ApiSvcCategorizedLineTypeMultiContactInvoicePaymentLine}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  type: ApiSvcCategorizedLineTypeMultiContactInvoicePaymentLine;
  /**
   *
   * @type {ApiSvcCategorizedLineDirection}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  direction: ApiSvcCategorizedLineDirection;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  invoiceId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  contactId: string;
  /**
   *
   * @type {ApiSvcInvoicePaymentLineForexDTO}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  forex?: ApiSvcInvoicePaymentLineForexDTO;
  /**
   *
   * @type {ApiSvcMultiContactInvoicePaymentLineDTOOpts}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  opts?: ApiSvcMultiContactInvoicePaymentLineDTOOpts;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTO
   */
  paidAmount: ApiSvcRawValueDTO;
}

/**
 *
 * @export
 * @interface ApiSvcMultiContactInvoicePaymentLineDTOOpts
 */
export interface ApiSvcMultiContactInvoicePaymentLineDTOOpts {
  /**
   *
   * @type {ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTOOpts
   */
  currencyRemoteId?: ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId;
  /**
   *
   * @type {number}
   * @memberof ApiSvcMultiContactInvoicePaymentLineDTOOpts
   */
  exchangeRateToFunctionalCurrency?: number;
}
/**
 *
 * @export
 * @interface ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId
 */
export interface ApiSvcMultiContactInvoicePaymentLineDTOOptsCurrencyRemoteId {}
/**
 *
 * @export
 * @interface ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
 */
export interface ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO {
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
   */
  sourceValue: ApiSvcRawValueDTO;
  /**
   * source value multiply by exchange rate not every categorization has it
   * @type {any}
   * @memberof ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
   */
  appliedExchangeRateValue?: any;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
   */
  walletId?: string;
  /**
   *
   * @type {ApiSvcCategorizedLineTypeMultiContactInvoiceUnappliedPaymentLine}
   * @memberof ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
   */
  type: ApiSvcCategorizedLineTypeMultiContactInvoiceUnappliedPaymentLine;
  /**
   *
   * @type {ApiSvcCategorizedLineDirection}
   * @memberof ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
   */
  direction: ApiSvcCategorizedLineDirection;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
   */
  paidAmount: ApiSvcRawValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
   */
  contactId: string;
  /**
   *
   * @type {ApiSvcInvoicePaymentLineForexDTO}
   * @memberof ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
   */
  forex?: ApiSvcInvoicePaymentLineForexDTO;
  /**
   *
   * @type {ApiSvcMultiContactInvoicePaymentLineDTOOpts}
   * @memberof ApiSvcMultiContactInvoiceUnappliedPaymentLineDTO
   */
  opts?: ApiSvcMultiContactInvoicePaymentLineDTOOpts;
}

/**
 *
 * @export
 * @interface ApiSvcMultiValueCategorizationDTO
 */
export interface ApiSvcMultiValueCategorizationDTO {
  /**
   *
   * @type {ApiSvcCategorizationTypeMultivalue}
   * @memberof ApiSvcMultiValueCategorizationDTO
   */
  type: ApiSvcCategorizationTypeMultivalue;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcMultiValueCategorizationDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMultiValueCategorizationDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {Array<ApiSvcMultiValueCategorizationItemDTO>}
   * @memberof ApiSvcMultiValueCategorizationDTO
   */
  items: Array<ApiSvcMultiValueCategorizationItemDTO>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcMultiValueCategorizationDTO
   */
  metadataIds?: Array<string>;
}

/**
 *
 * @export
 * @interface ApiSvcMultiValueCategorizationItemDTO
 */
export interface ApiSvcMultiValueCategorizationItemDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueCategorizationItemDTO
   */
  contactId: string;
  /**
   *
   * @type {Array<ApiSvcMultiValueLineDTO>}
   * @memberof ApiSvcMultiValueCategorizationItemDTO
   */
  lines: Array<ApiSvcMultiValueLineDTO>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcMultiValueCategorizationItemDTO
   */
  metadataIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcMultiValueLineDTO
 */
export interface ApiSvcMultiValueLineDTO {
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcMultiValueLineDTO
   */
  sourceValue: ApiSvcRawValueDTO;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcMultiValueLineDTO
   */
  appliedExchangeRateValue?: ApiSvcRawValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueLineDTO
   */
  walletId?: string;
  /**
   *
   * @type {ApiSvcCategorizedLineTypeMultiValueLine}
   * @memberof ApiSvcMultiValueLineDTO
   */
  type: ApiSvcCategorizedLineTypeMultiValueLine;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueLineDTO
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueLineDTO
   */
  description: string;
  /**
   *
   * @type {ApiSvcCategorizedLineDirection}
   * @memberof ApiSvcMultiValueLineDTO
   */
  direction: ApiSvcCategorizedLineDirection;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcMultiValueLineDTO
   */
  metadataIds?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcMultiValueLineDTO
   */
  txnLineId?: number;
  /**
   *
   * @type {ApiSvcMultiValueLineDTOAttributes}
   * @memberof ApiSvcMultiValueLineDTO
   */
  attributes?: ApiSvcMultiValueLineDTOAttributes;
}

/**
 *
 * @export
 * @interface ApiSvcMultiValueLineDTOAttributes
 */
export interface ApiSvcMultiValueLineDTOAttributes {
  /**
   * only applicable for dispositions
   * @type {boolean}
   * @memberof ApiSvcMultiValueLineDTOAttributes
   */
  forceZeroGainLoss?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMultiValueLineDTOAttributes
   */
  isFee?: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcMultiValueTxnDTO
 */
export interface ApiSvcMultiValueTxnDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnDTO
   */
  orgId: string;
  /**
   *
   * @type {Array<ApiSvcMultiValueTxnItemDTO>}
   * @memberof ApiSvcMultiValueTxnDTO
   */
  items: Array<ApiSvcMultiValueTxnItemDTO>;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcMultiValueTxnDTO
   */
  exchangeRates: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {Array<ApiSvcMetadataDTO>}
   * @memberof ApiSvcMultiValueTxnDTO
   */
  metadata?: Array<ApiSvcMetadataDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnDTO
   */
  notes?: string;
}
/**
 *
 * @export
 * @interface ApiSvcMultiValueTxnItemDTO
 */
export interface ApiSvcMultiValueTxnItemDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnItemDTO
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnItemDTO
   */
  transactionType: string;
  /**
   *
   * @type {Array<ApiSvcMultiValueTxnLineDTO>}
   * @memberof ApiSvcMultiValueTxnItemDTO
   */
  lines: Array<ApiSvcMultiValueTxnLineDTO>;
  /**
   *
   * @type {Array<ApiSvcMetadataDTO>}
   * @memberof ApiSvcMultiValueTxnItemDTO
   */
  metadata?: Array<ApiSvcMetadataDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcMultiValueTxnLineDTO
 */
export interface ApiSvcMultiValueTxnLineDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  categoryId: string;
  /**
   *
   * @type {ApiSvcValueDTO}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  sourceValue: ApiSvcValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  coin?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  unit?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  coinAmount?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  fiat: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  fiatAmount: string;
  /**
   *
   * @type {Array<ApiSvcMetadataDTO>}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  metadata?: Array<ApiSvcMetadataDTO>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  txnLineId?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  walletId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  isFee?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMultiValueTxnLineDTO
   */
  forceZeroGainLoss?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcMultisigAddressModes = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const;

export type ApiSvcMultisigAddressModes = typeof ApiSvcMultisigAddressModes[keyof typeof ApiSvcMultisigAddressModes];

/**
 *
 * @export
 * @interface ApiSvcMultisigWallet
 */
export interface ApiSvcMultisigWallet {
  /**
   *
   * @type {ApiSvcBitcoinMultisigDerivationMode}
   * @memberof ApiSvcMultisigWallet
   */
  customDerivationMode: ApiSvcBitcoinMultisigDerivationMode;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultisigWallet
   */
  customPrependPath?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMultisigWallet
   */
  customSortPublicKeys?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMultisigWallet
   */
  customUseChange?: boolean | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcMultisigWallet
   */
  hds: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcMultisigWallet
   */
  minCosigners: number;
  /**
   *
   * @type {ApiSvcMultisigWalletMode}
   * @memberof ApiSvcMultisigWallet
   */
  mode: ApiSvcMultisigWalletMode;
}

/**
 *
 * @export
 * @interface ApiSvcMultisigWalletDTO
 */
export interface ApiSvcMultisigWalletDTO {
  /**
   *
   * @type {ApiSvcMultisigAddressModes}
   * @memberof ApiSvcMultisigWalletDTO
   */
  mode: ApiSvcMultisigAddressModes;
  /**
   *
   * @type {number}
   * @memberof ApiSvcMultisigWalletDTO
   */
  minCosigners: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcMultisigWalletDTO
   */
  hds: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMultisigWalletDTO
   */
  customUseChange?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcMultisigWalletDTO
   */
  customSortPublicKeys?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcMultisigWalletDTO
   */
  customPrependPath?: string;
  /**
   *
   * @type {ApiSvcBitcoinMultisigDerivationMode}
   * @memberof ApiSvcMultisigWalletDTO
   */
  customDerivationMode?: ApiSvcBitcoinMultisigDerivationMode;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcMultisigWalletMode = {
  ElectrumLegacyBtc: 'ElectrumLegacyBTC',
  ElectrumP2ShSegwitBtc: 'ElectrumP2SHSegwitBTC',
  ElectrumSegwitBtc: 'ElectrumSegwitBTC',
  Bitcoincom: 'Bitcoincom',
  Custom: 'custom',
} as const;

export type ApiSvcMultisigWalletMode = typeof ApiSvcMultisigWalletMode[keyof typeof ApiSvcMultisigWalletMode];

/**
 *
 * @export
 * @interface ApiSvcName
 */
export interface ApiSvcName {
  /**
   *
   * @type {string}
   * @memberof ApiSvcName
   */
  givenName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcName
   */
  familyName: string;
}
/**
 *
 * @export
 * @interface ApiSvcNetworkAddressWallet
 */
export interface ApiSvcNetworkAddressWallet {
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcNetworkAddressWallet
   */
  metadata?: object | null;
  /**
   *
   * @type {ApiSvcRollupConfig}
   * @memberof ApiSvcNetworkAddressWallet
   */
  rollupConfig?: ApiSvcRollupConfig | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcNetworkAddressWallet
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcNetworkAddressWallet
   */
  address: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcNetworks = {
  Btc: 'btc',
  Bch: 'bch',
  Btg: 'btg',
  Eth: 'eth',
  Dash: 'dash',
  Eos: 'eos',
  Ltc: 'ltc',
  Xlm: 'xlm',
  Zec: 'zec',
  Trx: 'trx',
  One: 'one',
  Sol: 'sol',
  Dot: 'dot',
  Xtz: 'xtz',
  Scrt: 'scrt',
  Avax: 'avax',
  Avaxc: 'avaxc',
  Aion: 'aion',
  Icx: 'icx',
  Atom: 'atom',
  Band: 'band',
  Kava: 'kava',
  Iris: 'iris',
  Fil: 'fil',
  Rose: 'rose',
  Near: 'near',
  Bsv: 'bsv',
  Xvg: 'xvg',
  Polygon: 'polygon',
  Celo: 'celo',
  Etc: 'etc',
  Rvn: 'rvn',
  Doge: 'doge',
  Ada: 'ada',
  Mina: 'mina',
  Flow: 'flow',
  Ksm: 'ksm',
  Hbar: 'hbar',
  Xrp: 'xrp',
  Omni: 'omni',
  Bsc: 'bsc',
  Heco: 'heco',
  Terra: 'terra',
  Klay: 'klay',
  Algo: 'algo',
  Lrc: 'lrc',
  Casper: 'casper',
  Stx: 'stx',
  Bnb: 'bnb',
  Cosmos: 'cosmos',
  Ftm: 'ftm',
  Gnosis: 'gnosis',
  Op: 'op',
  Moon: 'moon',
  Icp: 'icp',
  Iost: 'iost',
  Iota: 'iota',
  Imx: 'imx',
  Ethw: 'ethw',
  Ronin: 'ronin',
  Shimmer: 'shimmer',
  Neo: 'neo',
  Qtum: 'qtum',
  Sgb: 'sgb',
  Vet: 'vet',
  Aurora: 'aurora',
  Apt: 'apt',
  Ont: 'ont',
  Sc: 'sc',
  Xem: 'xem',
  Xmr: 'xmr',
  Arb: 'arb',
  Osmo: 'osmo',
  Bld: 'bld',
  Aioz: 'aioz',
  Akt: 'akt',
  Mntl: 'mntl',
  Axl: 'axl',
  Bze: 'bze',
  Bcna: 'bcna',
  Btsg: 'btsg',
  Boot: 'boot',
  Canto: 'canto',
  Cro: 'cro',
  Inj: 'inj',
  Fet: 'fet',
  Ankr: 'ankr',
  Juno: 'juno',
  Kda: 'kda',
  Okt: 'okt',
  Zen: 'zen',
  Zil: 'zil',
  Kas: 'kas',
  Hot: 'hot',
  Audio: 'audio',
  Astr: 'astr',
  Bdx: 'bdx',
  Hnt: 'hnt',
  Regen: 'regen',
  Umee: 'umee',
  Evmos: 'evmos',
  Movr: 'movr',
  Dvpn: 'dvpn',
  Xprt: 'xprt',
  Egld: 'egld',
  Dgb: 'dgb',
  Sui: 'sui',
  Base: 'base',
  Beacon: 'beacon',
  Zeta: 'zeta',
  Dydx: 'dydx',
  Qkc: 'qkc',
  Xec: 'xec',
  Kmd: 'kmd',
  Dcr: 'dcr',
  Pivx: 'pivx',
  Canton: 'canton',
  Steem: 'steem',
  Lsk: 'lsk',
  Rsk: 'rsk',
  Zora: 'zora',
  Render: 'render',
  Iotx: 'iotx',
  Theta: 'theta',
  Rune: 'rune',
  Blast: 'blast',
  Ton: 'ton',
  Chiliz: 'chiliz',
  Sei: 'sei',
  Flare: 'flare',
  Manta: 'manta',
  Ordinals: 'ordinals',
  Obt: 'obt',
  Zksync: 'zksync',
  Linea: 'linea',
  Zkevm: 'zkevm',
  Polyx: 'polyx',
  Core: 'core',
  Kly: 'kly',
  Aleo: 'aleo',
} as const;

export type ApiSvcNetworks = typeof ApiSvcNetworks[keyof typeof ApiSvcNetworks];

/**
 *
 * @export
 * @interface ApiSvcOAuth2Response
 */
export interface ApiSvcOAuth2Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcOAuth2Response
   */
  access_token: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOAuth2Response
   */
  token_type: ApiSvcOAuth2ResponseTokenTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ApiSvcOAuth2Response
   */
  expires_in: number;
}

export const ApiSvcOAuth2ResponseTokenTypeEnum = {
  Bearer: 'Bearer',
  Cookie: 'Cookie',
} as const;

export type ApiSvcOAuth2ResponseTokenTypeEnum =
  typeof ApiSvcOAuth2ResponseTokenTypeEnum[keyof typeof ApiSvcOAuth2ResponseTokenTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcOAuth2TokenTypes = {
  IetfparamsoauthtokenTypejwt: 'urn:ietf:params:oauth:token-type:jwt',
  BitwaveparamsoauthtokenTypecookie: 'urn:bitwave:params:oauth:token-type:cookie',
} as const;

export type ApiSvcOAuth2TokenTypes = typeof ApiSvcOAuth2TokenTypes[keyof typeof ApiSvcOAuth2TokenTypes];

/**
 *
 * @export
 * @interface ApiSvcOAuthToken
 */
export interface ApiSvcOAuthToken {
  /**
   *
   * @type {string}
   * @memberof ApiSvcOAuthToken
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOAuthToken
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface ApiSvcOpSuccess
 */
export interface ApiSvcOpSuccess {
  /**
   *
   * @type {number}
   * @memberof ApiSvcOpSuccess
   */
  affectedCount?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOpSuccess
   */
  message?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcOpenTransactionStates = {
  New: 'new',
  ReadyToPrice: 'ready-to-price',
  FailedToPrice: 'failed-to-price',
  Priced: 'priced',
  Categorized: 'categorized',
  ReadyToSync: 'ready-to-sync',
  OpenNeedsReview: 'open-needs-review',
} as const;

export type ApiSvcOpenTransactionStates = typeof ApiSvcOpenTransactionStates[keyof typeof ApiSvcOpenTransactionStates];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcOrderingDirection = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type ApiSvcOrderingDirection = typeof ApiSvcOrderingDirection[keyof typeof ApiSvcOrderingDirection];

/**
 *
 * @export
 * @interface ApiSvcOrgDTO
 */
export interface ApiSvcOrgDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgDTO
   */
  name: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ApiSvcOrgDTO
   */
  roles: { [key: string]: number };
  /**
   *
   * @type {ApiSvcAccountingSetupDTO}
   * @memberof ApiSvcOrgDTO
   */
  accountingSetup?: ApiSvcAccountingSetupDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgDTO
   * @deprecated
   */
  accountingInventoryGroupId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcOrgDTO
   */
  accountingInventoryGroupIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgDTO
   */
  accountingERPId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgDTO
   */
  timezone?: string;
  /**
   *
   * @type {ApiSvcSubscriptionDetailsDTO}
   * @memberof ApiSvcOrgDTO
   */
  subscriptionDetails?: ApiSvcSubscriptionDetailsDTO;
  /**
   *
   * @type {number}
   * @memberof ApiSvcOrgDTO
   */
  provisionedAccountingUsers?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcOrgDTO
   */
  provisionedWalletUsers?: number;
  /**
   *
   * @type {ApiSvcTaxStrategy}
   * @memberof ApiSvcOrgDTO
   */
  taxStrategy?: ApiSvcTaxStrategy;
  /**
   *
   * @type {ApiSvcPricingSettingsDTO}
   * @memberof ApiSvcOrgDTO
   */
  pricingSettings?: ApiSvcPricingSettingsDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgDTO
   */
  baseCurrency?: string;
  /**
   *
   * @type {ApiSvcSSODTO}
   * @memberof ApiSvcOrgDTO
   */
  sso?: ApiSvcSSODTO;
  /**
   *
   * @type {Array<ApiSvcUserRole>}
   * @memberof ApiSvcOrgDTO
   */
  userRoles?: Array<ApiSvcUserRole>;
  /**
   *
   * @type {ApiSvcTaxConfigDTO}
   * @memberof ApiSvcOrgDTO
   */
  taxConfig?: ApiSvcTaxConfigDTO;
  /**
   *
   * @type {ApiSvcInvoicingSettings}
   * @memberof ApiSvcOrgDTO
   */
  invoicingSettings?: ApiSvcInvoicingSettings;
  /**
   *
   * @type {ApiSvcAccountingConfigDTO}
   * @memberof ApiSvcOrgDTO
   */
  accountingConfig?: ApiSvcAccountingConfigDTO;
  /**
   *
   * @type {Array<ApiSvcFeatureFlag>}
   * @memberof ApiSvcOrgDTO
   */
  flags?: Array<ApiSvcFeatureFlag>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgDTO
   */
  trialExpiry?: string;
  /**
   *
   * @type {ApiSvcDisplayConfigDTO}
   * @memberof ApiSvcOrgDTO
   */
  displayConfig?: ApiSvcDisplayConfigDTO;
  /**
   *
   * @type {ApiSvcTokenFilteringRulesConfig}
   * @memberof ApiSvcOrgDTO
   */
  tokenFilteringConfig?: ApiSvcTokenFilteringRulesConfig;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgDTO
   */
  hardCloseDate?: string;
  /**
   *
   * @type {ApiSvcEnterprisePaymentSettings}
   * @memberof ApiSvcOrgDTO
   */
  enterprisePaymentSettings?: ApiSvcEnterprisePaymentSettings;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcOrgDTO
   */
  scimEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcOrgDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcOrgDTO
   */
  engineVersion?: number;
}
/**
 *
 * @export
 * @interface ApiSvcOrgFeature
 */
export interface ApiSvcOrgFeature {
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgFeature
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgFeature
   */
  displayName: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcOrgRelationshipType = {
  Client: 'client',
  Employee: 'employee',
} as const;

export type ApiSvcOrgRelationshipType = typeof ApiSvcOrgRelationshipType[keyof typeof ApiSvcOrgRelationshipType];

/**
 *
 * @export
 * @interface ApiSvcOrgRelationshipsV2
 */
export interface ApiSvcOrgRelationshipsV2 {
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgRelationshipsV2
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgRelationshipsV2
   */
  orgName: string;
  /**
   *
   * @type {ApiSvcOrgRelationshipType}
   * @memberof ApiSvcOrgRelationshipsV2
   */
  relType: ApiSvcOrgRelationshipType;
}

/**
 *
 * @export
 * @interface ApiSvcOrgResponseDTO
 */
export interface ApiSvcOrgResponseDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgResponseDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgResponseDTO
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcOrgResponseDTO
   */
  flags?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgResponseDTO
   */
  timezone?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcOrgResponseDTO
   */
  baseCurrency?: number;
}
/**
 *
 * @export
 * @interface ApiSvcOrgSystemDetailsDTO
 */
export interface ApiSvcOrgSystemDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgSystemDetailsDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgSystemDetailsDTO
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcOrgSystemDetailsDTO
   */
  flags: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgSystemDetailsDTO
   */
  timezone: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcOrgSystemDetailsDTO
   */
  baseCurrency: number;
  /**
   *
   * @type {ApiSvcSsoSettings}
   * @memberof ApiSvcOrgSystemDetailsDTO
   */
  sso?: ApiSvcSsoSettings;
}
/**
 *
 * @export
 * @interface ApiSvcOrgUserDTO
 */
export interface ApiSvcOrgUserDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgUserDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgUserDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgUserDTO
   */
  email?: string;
  /**
   *
   * @type {Array<ApiSvcRolesDTO>}
   * @memberof ApiSvcOrgUserDTO
   */
  role?: Array<ApiSvcRolesDTO>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcOrgUserDTO
   */
  emailContactPreferences?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcOrgUserDTO
   */
  assertedIdentity?: string;
  /**
   *
   * @type {ApiSvcUserTosAcceptance}
   * @memberof ApiSvcOrgUserDTO
   */
  tosAcceptance?: ApiSvcUserTosAcceptance;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcOrgUserDTO
   */
  walletIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcPatchConnectionRequest
 */
export interface ApiSvcPatchConnectionRequest {
  /**
   *
   * @type {ApiSvcConnectionTypes}
   * @memberof ApiSvcPatchConnectionRequest
   */
  type?: ApiSvcConnectionTypes;
  /**
   *
   * @type {ApiSvcConnectionSyncStatus}
   * @memberof ApiSvcPatchConnectionRequest
   */
  status?: ApiSvcConnectionSyncStatus;
  /**
   *
   * @type {number}
   * @memberof ApiSvcPatchConnectionRequest
   */
  lastSyncSEC?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPatchConnectionRequest
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPatchConnectionRequest
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcPatchConnectionRequest
   */
  syncerVersion?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcPatchConnectionRequest
   */
  _disabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPatchConnectionRequest
   */
  subAccountName?: string;
}

/**
 *
 * @export
 * @interface ApiSvcPatchWalletRequest
 */
export interface ApiSvcPatchWalletRequest {
  /**
   *
   * @type {ApiSvcSyncStatus}
   * @memberof ApiSvcPatchWalletRequest
   */
  status?: ApiSvcSyncStatus;
  /**
   *
   * @type {number}
   * @memberof ApiSvcPatchWalletRequest
   */
  lastSyncSEC?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcPatchWalletRequest
   */
  userSyncEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcPatchWalletRequest
   */
  isBalanceMonitoringOnly?: boolean;
}

/**
 *
 * @export
 * @interface ApiSvcPendingExpandedExchangeRateDTO
 */
export interface ApiSvcPendingExpandedExchangeRateDTO {
  /**
   *
   * @type {ApiSvcExpandedExchangeRateDTO}
   * @memberof ApiSvcPendingExpandedExchangeRateDTO
   */
  exchangeRate: ApiSvcExpandedExchangeRateDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPendingExpandedExchangeRateDTO
   */
  changeReason: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPendingExpandedExchangeRateDTO
   */
  changeAuditLog?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcPointerTypes = {
  Address: 'address',
  Block: 'block',
} as const;

export type ApiSvcPointerTypes = typeof ApiSvcPointerTypes[keyof typeof ApiSvcPointerTypes];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcPriceSvcMessages = {
  Raw: 'raw',
  PricingSucceeded: 'pricing.succeeded',
  PricingSucceededDetailed: 'pricing.succeeded.detailed',
  PricingFailed: 'pricing.failed',
  PricingFailedDetails: 'pricing.failed.details',
  PricingStatic: 'pricing.static',
} as const;

export type ApiSvcPriceSvcMessages = typeof ApiSvcPriceSvcMessages[keyof typeof ApiSvcPriceSvcMessages];

/**
 *
 * @export
 * @interface ApiSvcPriceSvcMsg
 */
export interface ApiSvcPriceSvcMsg {
  /**
   *
   * @type {ApiSvcPriceSvcMessages}
   * @memberof ApiSvcPriceSvcMsg
   */
  messageId: ApiSvcPriceSvcMessages;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcPriceSvcMsg
   */
  params: Array<string>;
}

/**
 *
 * @export
 * @interface ApiSvcPriceSvcPrice
 */
export interface ApiSvcPriceSvcPrice {
  /**
   *
   * @type {string}
   * @memberof ApiSvcPriceSvcPrice
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPriceSvcPrice
   */
  price: string;
  /**
   *
   * @type {ApiSvcPriceSvcPriceDetails}
   * @memberof ApiSvcPriceSvcPrice
   */
  details: ApiSvcPriceSvcPriceDetails;
  /**
   *
   * @type {Array<ApiSvcPriceSvcPricingStep>}
   * @memberof ApiSvcPriceSvcPrice
   */
  steps: Array<ApiSvcPriceSvcPricingStep>;
}
/**
 *
 * @export
 * @interface ApiSvcPriceSvcPriceDetails
 */
export interface ApiSvcPriceSvcPriceDetails {
  /**
   *
   * @type {string}
   * @memberof ApiSvcPriceSvcPriceDetails
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPriceSvcPriceDetails
   */
  pricedActionDesc?: string;
}
/**
 *
 * @export
 * @interface ApiSvcPriceSvcPricingResult
 */
export interface ApiSvcPriceSvcPricingResult {
  /**
   *
   * @type {ApiSvcPriceSvcPricingResultStatus}
   * @memberof ApiSvcPriceSvcPricingResult
   */
  status: ApiSvcPriceSvcPricingResultStatus;
  /**
   *
   * @type {ApiSvcPriceSvcPrice}
   * @memberof ApiSvcPriceSvcPricingResult
   */
  result?: ApiSvcPriceSvcPrice;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcPriceSvcPricingResult
   */
  errors?: Array<string>;
  /**
   *
   * @type {Array<ApiSvcPriceSvcPricingStep>}
   * @memberof ApiSvcPriceSvcPricingResult
   */
  failingSteps?: Array<ApiSvcPriceSvcPricingStep>;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcPriceSvcPricingResultStatus = {
  PricedSuccessfully: 'priced-successfully',
  UnableToPrice: 'unable-to-price',
  DownstreamServiceFailure: 'downstream-service-failure',
} as const;

export type ApiSvcPriceSvcPricingResultStatus =
  typeof ApiSvcPriceSvcPricingResultStatus[keyof typeof ApiSvcPriceSvcPricingResultStatus];

/**
 *
 * @export
 * @interface ApiSvcPriceSvcPricingStep
 */
export interface ApiSvcPriceSvcPricingStep {
  /**
   *
   * @type {ApiSvcPriceSvcPricingStepTypes}
   * @memberof ApiSvcPriceSvcPricingStep
   */
  type: ApiSvcPriceSvcPricingStepTypes;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPriceSvcPricingStep
   */
  friendlyDesc: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPriceSvcPricingStep
   */
  friendlyDetails?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPriceSvcPricingStep
   */
  ruleId: string;
  /**
   *
   * @type {ApiSvcPriceSvcMsg}
   * @memberof ApiSvcPriceSvcPricingStep
   */
  message: ApiSvcPriceSvcMsg;
  /**
   *
   * @type {ApiSvcPriceSvcMsg}
   * @memberof ApiSvcPriceSvcPricingStep
   */
  details?: ApiSvcPriceSvcMsg;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcPriceSvcPricingStepTypes = {
  Match: 'match',
  FallThrough: 'fall-through',
} as const;

export type ApiSvcPriceSvcPricingStepTypes =
  typeof ApiSvcPriceSvcPricingStepTypes[keyof typeof ApiSvcPriceSvcPricingStepTypes];

/**
 *
 * @export
 * @interface ApiSvcPriceSvcTransactionPricesStateLiteDTO
 */
export interface ApiSvcPriceSvcTransactionPricesStateLiteDTO {
  /**
   *
   * @type {number}
   * @memberof ApiSvcPriceSvcTransactionPricesStateLiteDTO
   */
  version: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcPriceSvcTransactionPricesStateLiteDTO
   */
  timestampSEC?: number;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcPriceSvcTransactionPricesStateLiteDTO
   */
  systemExchangeRates?: object;
  /**
   *
   * @type {ApiSvcPriceSvcTransactionPricesStates}
   * @memberof ApiSvcPriceSvcTransactionPricesStateLiteDTO
   */
  state: ApiSvcPriceSvcTransactionPricesStates;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcPriceSvcTransactionPricesStates = {
  New: 'new',
  Pricing: 'pricing',
  Priced: 'priced',
  PricesDirtied: 'prices-dirtied',
  FailedToPrice: 'failed-to-price',
} as const;

export type ApiSvcPriceSvcTransactionPricesStates =
  typeof ApiSvcPriceSvcTransactionPricesStates[keyof typeof ApiSvcPriceSvcTransactionPricesStates];

/**
 *
 * @export
 * @interface ApiSvcPriceTransaction200Response
 */
export interface ApiSvcPriceTransaction200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcPriceTransaction200Response
   */
  code: string;
  /**
   *
   * @type {any}
   * @memberof ApiSvcPriceTransaction200Response
   */
  message: any;
  /**
   *
   * @type {number}
   * @memberof ApiSvcPriceTransaction200Response
   */
  version: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcPriceTransaction200Response
   */
  timestampSEC?: number;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcPriceTransaction200Response
   */
  systemExchangeRates?: object;
  /**
   *
   * @type {ApiSvcPriceSvcTransactionPricesStates}
   * @memberof ApiSvcPriceTransaction200Response
   */
  state: ApiSvcPriceSvcTransactionPricesStates;
}

/**
 *
 * @export
 * @interface ApiSvcPriceTransaction200ResponseAnyOf
 */
export interface ApiSvcPriceTransaction200ResponseAnyOf {
  /**
   *
   * @type {any}
   * @memberof ApiSvcPriceTransaction200ResponseAnyOf
   */
  message: any;
}
/**
 *
 * @export
 * @interface ApiSvcPricingReportParams
 */
export interface ApiSvcPricingReportParams {
  /**
   *
   * @type {number}
   * @memberof ApiSvcPricingReportParams
   */
  endTimeSec?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcPricingReportParams
   */
  startTimeSec?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPricingReportParams
   */
  ticker: string;
}
/**
 *
 * @export
 * @interface ApiSvcPricingSettingsDTO
 */
export interface ApiSvcPricingSettingsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcPricingSettingsDTO
   */
  preferredExchange?: string;
}
/**
 *
 * @export
 * @interface ApiSvcPrivateKeyToken
 */
export interface ApiSvcPrivateKeyToken {
  /**
   *
   * @type {string}
   * @memberof ApiSvcPrivateKeyToken
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcPrivateKeyToken
   */
  privateKey: string;
}
/**
 * on purpose we are not adding ticker here
 * @export
 * @interface ApiSvcRawValueDTO
 */
export interface ApiSvcRawValueDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRawValueDTO
   */
  currencyId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRawValueDTO
   */
  value: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcRecResult = {
  Succeeded: 'succeeded',
  Failed: 'failed',
  PartialSuccess: 'partial-success',
  QueuedToSync: 'queued-to-sync',
} as const;

export type ApiSvcRecResult = typeof ApiSvcRecResult[keyof typeof ApiSvcRecResult];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcReconciliationStatusDTO = {
  All: 'All',
  Unreconciled: 'Unreconciled',
  Reconciled: 'Reconciled',
} as const;

export type ApiSvcReconciliationStatusDTO =
  typeof ApiSvcReconciliationStatusDTO[keyof typeof ApiSvcReconciliationStatusDTO];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcRecordBinaryContent = {
  Csv: 'csv',
  Pdf: 'pdf',
  Png: 'png',
} as const;

export type ApiSvcRecordBinaryContent = typeof ApiSvcRecordBinaryContent[keyof typeof ApiSvcRecordBinaryContent];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcRecordBucket = {
  SodaReports: 'soda-reports',
  JournalReports: 'journal-reports',
  BalanceReports: 'balance-reports',
} as const;

export type ApiSvcRecordBucket = typeof ApiSvcRecordBucket[keyof typeof ApiSvcRecordBucket];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcRecordBucketSodaReports = {
  SodaReports: 'soda-reports',
} as const;

export type ApiSvcRecordBucketSodaReports =
  typeof ApiSvcRecordBucketSodaReports[keyof typeof ApiSvcRecordBucketSodaReports];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcRecordType = {
  Json: 'json',
  Binary: 'binary',
} as const;

export type ApiSvcRecordType = typeof ApiSvcRecordType[keyof typeof ApiSvcRecordType];

/**
 *
 * @export
 * @interface ApiSvcRelatedOrgsBalanceLine
 */
export interface ApiSvcRelatedOrgsBalanceLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsBalanceLine
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsBalanceLine
   */
  clientName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsBalanceLine
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsBalanceLine
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsBalanceLine
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsBalanceLine
   */
  value: string;
}
/**
 *
 * @export
 * @interface ApiSvcRelatedOrgsBalanceReportParams
 */
export interface ApiSvcRelatedOrgsBalanceReportParams {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRelatedOrgsBalanceReportParams
   */
  relatedOrgIds: Array<string>;
  /**
   *
   * @type {ApiSvcOrgRelationshipType}
   * @memberof ApiSvcRelatedOrgsBalanceReportParams
   */
  relatedOrgsType: ApiSvcOrgRelationshipType;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRelatedOrgsBalanceReportParams
   */
  relatedWalletIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsBalanceReportParams
   */
  pageToken?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRelatedOrgsBalanceReportParams
   */
  startTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRelatedOrgsBalanceReportParams
   */
  endTimestamp?: number;
}

/**
 *
 * @export
 * @interface ApiSvcRelatedOrgsTransactionsLine
 */
export interface ApiSvcRelatedOrgsTransactionsLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  orgName?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  symbol: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  amount?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  price?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  priceSource?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  feeAmount?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  feeAsset?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  to: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  operation: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  hash: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsLine
   */
  combinedParentTxnId: string;
}
/**
 *
 * @export
 * @interface ApiSvcRelatedOrgsTransactionsReportParams
 */
export interface ApiSvcRelatedOrgsTransactionsReportParams {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRelatedOrgsTransactionsReportParams
   */
  relatedOrgIds: Array<string>;
  /**
   *
   * @type {ApiSvcOrgRelationshipType}
   * @memberof ApiSvcRelatedOrgsTransactionsReportParams
   */
  relatedOrgsType: ApiSvcOrgRelationshipType;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRelatedOrgsTransactionsReportParams
   */
  relatedWalletIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRelatedOrgsTransactionsReportParams
   */
  pageToken?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRelatedOrgsTransactionsReportParams
   */
  startTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRelatedOrgsTransactionsReportParams
   */
  endTimestamp?: number;
}

/**
 *
 * @export
 * @interface ApiSvcRemoteSyncStatus
 */
export interface ApiSvcRemoteSyncStatus {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRemoteSyncStatus
   */
  itemId: string;
  /**
   *
   * @type {ApiSvcRecResult}
   * @memberof ApiSvcRemoteSyncStatus
   */
  res: ApiSvcRecResult;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRemoteSyncStatus
   */
  errors: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRemoteSyncStatus
   */
  remoteIds?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRemoteSyncStatus
   */
  warnings?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRemoteSyncStatus
   */
  unbookedLines?: Array<string>;
}

/**
 *
 * @export
 * @interface ApiSvcRemoteSyncStatusDTO
 */
export interface ApiSvcRemoteSyncStatusDTO {
  /**
   *
   * @type {ApiSvcRecResult}
   * @memberof ApiSvcRemoteSyncStatusDTO
   */
  result: ApiSvcRecResult;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRemoteSyncStatusDTO
   */
  errors?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRemoteSyncStatusDTO
   */
  remoteIds?: Array<string>;
}

/**
 *
 * @export
 * @interface ApiSvcRemoteSystemId
 */
export interface ApiSvcRemoteSystemId {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRemoteSystemId
   */
  remoteId: string;
  /**
   *
   * @type {ApiSvcSources}
   * @memberof ApiSvcRemoteSystemId
   */
  source: ApiSvcSources;
}

/**
 *
 * @export
 * @interface ApiSvcReportExecutionTask
 */
export interface ApiSvcReportExecutionTask {
  /**
   *
   * @type {string}
   * @memberof ApiSvcReportExecutionTask
   */
  reportId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcReportExecutionTask
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcReportExecutionTask
   */
  customerId: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcReportResultTypes = {
  GainLossReportResults: 'gain-loss-report-results',
  JournalEntrySaveDown: 'journal-entry-save-down',
  BalanceReport: 'balance-report',
  RelatedOrgsBalanceReport: 'related-orgs-balance-report',
  RelatedOrgsTransactionsReport: 'related-orgs-transactions-report',
} as const;

export type ApiSvcReportResultTypes = typeof ApiSvcReportResultTypes[keyof typeof ApiSvcReportResultTypes];

/**
 *
 * @export
 * @interface ApiSvcReportResults
 */
export interface ApiSvcReportResults {
  /**
   *
   * @type {ApiSvcReportResultTypes}
   * @memberof ApiSvcReportResults
   */
  type: ApiSvcReportResultTypes;
  /**
   *
   * @type {ApiSvcFilePointer}
   * @memberof ApiSvcReportResults
   */
  results: ApiSvcFilePointer;
}

/**
 *
 * @export
 * @interface ApiSvcReportRunConfig
 */
export interface ApiSvcReportRunConfig {
  /**
   *
   * @type {ApiSvcReportRunConfigTypes}
   * @memberof ApiSvcReportRunConfig
   */
  type: ApiSvcReportRunConfigTypes;
  /**
   *
   * @type {string}
   * @memberof ApiSvcReportRunConfig
   */
  scope: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcReportRunConfigTypes = {
  GainLossReport: 'gain-loss-report',
  JournalEntrySaveDown: 'journal-entry-save-down',
  BalanceReport: 'balance-report',
  RelatedOrgsBalanceReport: 'related-orgs-balance-report',
  RelatedOrgsTransactionsReport: 'related-orgs-transactions-report',
  SodaReport: 'soda-report',
  UnspecifiedReport: 'unspecified-report',
} as const;

export type ApiSvcReportRunConfigTypes = typeof ApiSvcReportRunConfigTypes[keyof typeof ApiSvcReportRunConfigTypes];

/**
 *
 * @export
 * @interface ApiSvcReportRunRecordReportRunConfig
 */
export interface ApiSvcReportRunRecordReportRunConfig {
  /**
   *
   * @type {string}
   * @memberof ApiSvcReportRunRecordReportRunConfig
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcReportRunRecordReportRunConfig
   */
  orgId: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcReportRunRecordReportRunConfig
   */
  createdSEC: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcReportRunRecordReportRunConfig
   */
  updatedSEC: number;
  /**
   *
   * @type {ApiSvcUserPointer}
   * @memberof ApiSvcReportRunRecordReportRunConfig
   */
  createdBy: ApiSvcUserPointer;
  /**
   *
   * @type {ApiSvcReportRunStatus}
   * @memberof ApiSvcReportRunRecordReportRunConfig
   */
  status: ApiSvcReportRunStatus;
  /**
   *
   * @type {ApiSvcReportRunConfig}
   * @memberof ApiSvcReportRunRecordReportRunConfig
   */
  config: ApiSvcReportRunConfig;
  /**
   *
   * @type {ApiSvcExecContext}
   * @memberof ApiSvcReportRunRecordReportRunConfig
   */
  execContext: ApiSvcExecContext;
  /**
   *
   * @type {ApiSvcReportResults}
   * @memberof ApiSvcReportRunRecordReportRunConfig
   */
  results?: ApiSvcReportResults;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcReportRunStatus = {
  New: 'new',
  Running: 'running',
  Succeeded: 'succeeded',
  Failed: 'failed',
  TimedOut: 'timed-out',
} as const;

export type ApiSvcReportRunStatus = typeof ApiSvcReportRunStatus[keyof typeof ApiSvcReportRunStatus];

/**
 *
 * @export
 * @interface ApiSvcReportsResponse
 */
export interface ApiSvcReportsResponse {
  /**
   *
   * @type {Array<ApiSvcCustomerReport>}
   * @memberof ApiSvcReportsResponse
   */
  items: Array<ApiSvcCustomerReport>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcReportsResponse
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface ApiSvcRippleInventoryCostBasisRollForwardLine
 */
export interface ApiSvcRippleInventoryCostBasisRollForwardLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  original_inventory: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  original_wallet: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  lotID: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  original_department: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  asset: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  units_beginning: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  carryingvalue_beginning: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  units_acquired: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  units_disposed: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  units_ending: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  costbasis_acquired: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  costbasis_relieved: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  impairExpense: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  carryingvalue_ending: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  fiat_check_mustbe_zero: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardLine
   */
  qty_check_mustbe_zero: number;
}
/**
 *
 * @export
 * @interface ApiSvcRippleInventoryCostBasisRollForwardResp
 */
export interface ApiSvcRippleInventoryCostBasisRollForwardResp {
  /**
   *
   * @type {Array<ApiSvcRippleInventoryCostBasisRollForwardLine>}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardResp
   */
  lines: Array<ApiSvcRippleInventoryCostBasisRollForwardLine>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardResp
   */
  pageToken?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRippleInventoryCostBasisRollForwardResp
   */
  exportIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcRolesDTO
 */
export interface ApiSvcRolesDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRolesDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRolesDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRolesDTO
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRolesDTO
   */
  scopes?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRolesDTO
   */
  editable: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcRollupConfig
 */
export interface ApiSvcRollupConfig {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfig
   */
  rollupAll?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfig
   */
  rollupRemittance?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfig
   */
  rollupTokenRemittance?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfig
   */
  rollupFee?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfig
   */
  rollupTokenRevenue?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfig
   */
  rollupRevenue?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfig
   */
  seperateByLog?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRollupConfig
   */
  logSelector?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfig
   */
  seperateByAccount?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRollupConfig
   */
  accountCheck?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRollupConfig
   */
  metadata?: string;
  /**
   *
   * @type {ApiSvcRollupPeriod}
   * @memberof ApiSvcRollupConfig
   */
  rollupPeriod: ApiSvcRollupPeriod;
  /**
   *
   * @type {ApiSvcRollupSelector}
   * @memberof ApiSvcRollupConfig
   */
  rollupSelector: ApiSvcRollupSelector;
}

/**
 *
 * @export
 * @interface ApiSvcRollupConfigDTO
 */
export interface ApiSvcRollupConfigDTO {
  /**
   *
   * @type {ApiSvcRollupSelector}
   * @memberof ApiSvcRollupConfigDTO
   */
  rollupSelector: ApiSvcRollupSelector;
  /**
   *
   * @type {ApiSvcRollupPeriod}
   * @memberof ApiSvcRollupConfigDTO
   */
  rollupPeriod: ApiSvcRollupPeriod;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRollupConfigDTO
   */
  metadata?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRollupConfigDTO
   */
  accountCheck?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfigDTO
   */
  seperateByAccount?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcRollupConfigDTO
   */
  logSelector?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfigDTO
   */
  seperateByLog?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfigDTO
   */
  rollupRevenue?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfigDTO
   */
  rollupTokenRevenue?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfigDTO
   */
  rollupFee?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfigDTO
   */
  rollupTokenRemittance?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfigDTO
   */
  rollupRemittance?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRollupConfigDTO
   */
  rollupAll?: boolean;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcRollupPeriod = {
  Hour: 'hour',
  Day: 'day',
  Month: 'month',
} as const;

export type ApiSvcRollupPeriod = typeof ApiSvcRollupPeriod[keyof typeof ApiSvcRollupPeriod];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcRollupSelector = {
  Revenue: 'revenue',
  Fees: 'fees',
  Custom: 'custom',
  FeeAndRevenue: 'feeAndRevenue',
} as const;

export type ApiSvcRollupSelector = typeof ApiSvcRollupSelector[keyof typeof ApiSvcRollupSelector];

/**
 *
 * @export
 * @interface ApiSvcRuleActionGraphQL
 */
export interface ApiSvcRuleActionGraphQL {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleActionGraphQL
   */
  type: ApiSvcRuleActionGraphQLTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRuleActionGraphQL
   */
  ignoreFailPricing?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleActionGraphQL
   */
  feeCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleActionGraphQL
   */
  feeContactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleActionGraphQL
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleActionGraphQL
   */
  categoryId: string;
  /**
   *
   * @type {Array<ApiSvcDetailedCategorizationActionLines>}
   * @memberof ApiSvcRuleActionGraphQL
   */
  lines: Array<ApiSvcDetailedCategorizationActionLines>;
  /**
   *
   * @type {Array<ApiSvcSplit>}
   * @memberof ApiSvcRuleActionGraphQL
   */
  feeSplits?: Array<ApiSvcSplit>;
  /**
   *
   * @type {Array<ApiSvcSplit>}
   * @memberof ApiSvcRuleActionGraphQL
   */
  splits: Array<ApiSvcSplit>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleActionGraphQL
   */
  deFiWalletId: string;
}

export const ApiSvcRuleActionGraphQLTypeEnum = {
  DeFiCategorization: 'DeFiCategorization',
} as const;

export type ApiSvcRuleActionGraphQLTypeEnum =
  typeof ApiSvcRuleActionGraphQLTypeEnum[keyof typeof ApiSvcRuleActionGraphQLTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcRuleComparison
 */
export interface ApiSvcRuleComparison {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleComparison
   */
  value: string;
  /**
   *
   * @type {ApiSvcComparison}
   * @memberof ApiSvcRuleComparison
   */
  comparison: ApiSvcComparison;
}

/**
 *
 * @export
 * @interface ApiSvcRuleComparisonDTO
 */
export interface ApiSvcRuleComparisonDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleComparisonDTO
   */
  value: string;
  /**
   *
   * @type {ApiSvcComparisonGraphQL}
   * @memberof ApiSvcRuleComparisonDTO
   */
  comparison: ApiSvcComparisonGraphQL;
}

/**
 *
 * @export
 * @interface ApiSvcRuleProcessingResult
 */
export interface ApiSvcRuleProcessingResult {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRuleProcessingResult
   */
  applied: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleProcessingResult
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleProcessingResult
   */
  ruleId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleProcessingResult
   */
  txnId: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRuleProcessingResult
   */
  autoReconcile: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRuleProcessingResult
   */
  error?: string;
}
/**
 *
 * @export
 * @interface ApiSvcRulesErrorResponse
 */
export interface ApiSvcRulesErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRulesErrorResponse
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRulesErrorResponse
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcRunESBalanceReportPreview200Response
 */
export interface ApiSvcRunESBalanceReportPreview200Response {
  /**
   *
   * @type {Array<ApiSvcAssetSummary>}
   * @memberof ApiSvcRunESBalanceReportPreview200Response
   */
  items: Array<ApiSvcAssetSummary>;
  /**
   *
   * @type {ApiSvcAssetSummary}
   * @memberof ApiSvcRunESBalanceReportPreview200Response
   */
  total: ApiSvcAssetSummary;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRunESBalanceReportPreview200Response
   */
  stack?: string;
}
/**
 *
 * @export
 * @interface ApiSvcRunRelatedOrgsTransctionsReportPreview200Response
 */
export interface ApiSvcRunRelatedOrgsTransctionsReportPreview200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRunRelatedOrgsTransctionsReportPreview200Response
   */
  nextPageToken: string;
  /**
   *
   * @type {Array<ApiSvcRelatedOrgsTransactionsLine>}
   * @memberof ApiSvcRunRelatedOrgsTransctionsReportPreview200Response
   */
  items: Array<ApiSvcRelatedOrgsTransactionsLine>;
}
/**
 *
 * @export
 * @interface ApiSvcRunReportParams
 */
export interface ApiSvcRunReportParams {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRunReportParams
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRunReportParams
   */
  to: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRunReportParams
   */
  walletId: string;
}
/**
 *
 * @export
 * @interface ApiSvcRunReportRequest
 */
export interface ApiSvcRunReportRequest {
  /**
   *
   * @type {ApiSvcRunScenarioParams}
   * @memberof ApiSvcRunReportRequest
   */
  gainLoss?: ApiSvcRunScenarioParams;
  /**
   *
   * @type {ApiSvcRelatedOrgsBalanceReportParams}
   * @memberof ApiSvcRunReportRequest
   */
  relatedOrgsBalanceReportParams?: ApiSvcRelatedOrgsBalanceReportParams;
  /**
   *
   * @type {ApiSvcRelatedOrgsTransactionsReportParams}
   * @memberof ApiSvcRunReportRequest
   */
  relatedOrgsTransactionsReportParams?: ApiSvcRelatedOrgsTransactionsReportParams;
}
/**
 *
 * @export
 * @interface ApiSvcRunScenarioParams
 */
export interface ApiSvcRunScenarioParams {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRunScenarioParams
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRunScenarioParams
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcRunScenarioParams
   */
  endDate?: string;
  /**
   *
   * @type {ApiSvcTaxStrategyTypeDTO}
   * @memberof ApiSvcRunScenarioParams
   */
  strategy?: ApiSvcTaxStrategyTypeDTO;
  /**
   *
   * @type {ApiSvcTaxConfigInputDTO}
   * @memberof ApiSvcRunScenarioParams
   */
  config?: ApiSvcTaxConfigInputDTO;
  /**
   *
   * @type {Array<ApiSvcExchangeRateOverrideDTO>}
   * @memberof ApiSvcRunScenarioParams
   */
  overrideExchangeRates?: Array<ApiSvcExchangeRateOverrideDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRunScenarioParams
   */
  impair?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcRunScenarioParams
   */
  ignoreNFTs?: boolean;
}

/**
 *
 * @export
 * @interface ApiSvcRunSharedOrgsBalancesReportPreview200Response
 */
export interface ApiSvcRunSharedOrgsBalancesReportPreview200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcRunSharedOrgsBalancesReportPreview200Response
   */
  nextPageToken: string;
  /**
   *
   * @type {Array<ApiSvcRelatedOrgsBalanceLine>}
   * @memberof ApiSvcRunSharedOrgsBalancesReportPreview200Response
   */
  items: Array<ApiSvcRelatedOrgsBalanceLine>;
}
/**
 *
 * @export
 * @interface ApiSvcSSODTO
 */
export interface ApiSvcSSODTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSSODTO
   */
  idpSignOnUrl: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSSODTO
   */
  idpIssuer: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSSODTO
   */
  idpCertificate: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSSODTO
   */
  defaultRoleId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcScimCreateRequest
 */
export interface ApiSvcScimCreateRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcScimCreateRequest
   */
  schemas?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimCreateRequest
   */
  userName: string;
  /**
   *
   * @type {ApiSvcName}
   * @memberof ApiSvcScimCreateRequest
   */
  name: ApiSvcName;
  /**
   *
   * @type {Array<ApiSvcScimEmail>}
   * @memberof ApiSvcScimCreateRequest
   */
  emails: Array<ApiSvcScimEmail>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimCreateRequest
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimCreateRequest
   */
  locale?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimCreateRequest
   */
  externalId: string;
  /**
   *
   * @type {Array<any>}
   * @memberof ApiSvcScimCreateRequest
   */
  groups: Array<any>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimCreateRequest
   */
  password?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcScimCreateRequest
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimCreateRequest
   */
  title?: string;
  /**
   *
   * @type {ApiSvcIetfEnterpriseUser}
   * @memberof ApiSvcScimCreateRequest
   */
  'urn:ietf:params:scim:schemas:extension:enterprise:2.0:User'?: ApiSvcIetfEnterpriseUser;
}
/**
 *
 * @export
 * @interface ApiSvcScimEmail
 */
export interface ApiSvcScimEmail {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcScimEmail
   */
  primary: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimEmail
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimEmail
   */
  type?: string;
}
/**
 *
 * @export
 * @interface ApiSvcScimGetUsers200Response
 */
export interface ApiSvcScimGetUsers200Response {
  /**
   *
   * @type {Array<ApiSvcScimGetUsers200ResponseResourcesInner>}
   * @memberof ApiSvcScimGetUsers200Response
   */
  Resources: Array<ApiSvcScimGetUsers200ResponseResourcesInner>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcScimGetUsers200Response
   */
  itemsPerPage: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcScimGetUsers200Response
   */
  startIndex: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcScimGetUsers200Response
   */
  totalResults: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcScimGetUsers200Response
   */
  schemas: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcScimGetUsers200ResponseResourcesInner
 */
export interface ApiSvcScimGetUsers200ResponseResourcesInner {
  /**
   *
   * @type {ApiSvcScimGetUsers200ResponseResourcesInnerMeta}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInner
   */
  meta: ApiSvcScimGetUsers200ResponseResourcesInnerMeta;
  /**
   *
   * @type {Array<any>}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInner
   */
  groups: Array<any>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInner
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInner
   */
  locale: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInner
   */
  displayName: string;
  /**
   *
   * @type {Array<ApiSvcScimGetUsers200ResponseResourcesInnerEmailsInner>}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInner
   */
  emails: Array<ApiSvcScimGetUsers200ResponseResourcesInnerEmailsInner>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInner
   */
  userName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInner
   */
  id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInner
   */
  schemas: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcScimGetUsers200ResponseResourcesInnerEmailsInner
 */
export interface ApiSvcScimGetUsers200ResponseResourcesInnerEmailsInner {
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInnerEmailsInner
   */
  value: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInnerEmailsInner
   */
  primary: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcScimGetUsers200ResponseResourcesInnerMeta
 */
export interface ApiSvcScimGetUsers200ResponseResourcesInnerMeta {
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimGetUsers200ResponseResourcesInnerMeta
   */
  resourceType: string;
}
/**
 *
 * @export
 * @interface ApiSvcScimUpdateRequest
 */
export interface ApiSvcScimUpdateRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcScimUpdateRequest
   */
  schemas?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimUpdateRequest
   */
  displayName?: string;
  /**
   *
   * @type {any}
   * @memberof ApiSvcScimUpdateRequest
   */
  meta?: any;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimUpdateRequest
   */
  locale?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimUpdateRequest
   */
  externalId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimUpdateRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcScimUpdateRequest
   */
  userName: string;
  /**
   *
   * @type {ApiSvcName}
   * @memberof ApiSvcScimUpdateRequest
   */
  name: ApiSvcName;
  /**
   *
   * @type {Array<ApiSvcScimEmail>}
   * @memberof ApiSvcScimUpdateRequest
   */
  emails: Array<ApiSvcScimEmail>;
  /**
   *
   * @type {Array<any>}
   * @memberof ApiSvcScimUpdateRequest
   */
  groups: Array<any>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcScimUpdateRequest
   */
  active: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcSellTxnDetailsDTO
 */
export interface ApiSvcSellTxnDetailsDTO {
  /**
   *
   * @type {number}
   * @memberof ApiSvcSellTxnDetailsDTO
   */
  dateTimeSec: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSellTxnDetailsDTO
   */
  source: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcSellTxnDetailsDTO
   */
  sourceAmount: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSellTxnDetailsDTO
   */
  target: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcSellTxnDetailsDTO
   */
  targetAmount: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcSellTxnDetailsDTO
   */
  exchangeRate: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSellTxnDetailsDTO
   */
  proceedsAccount?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcSellTxnDetailsDTO
   */
  fee?: number;
}
/**
 *
 * @export
 * @interface ApiSvcSendRemittanceAdviceDTO
 */
export interface ApiSvcSendRemittanceAdviceDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendRemittanceAdviceDTO
   */
  templateVersion?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendRemittanceAdviceDTO
   */
  exchangeRate: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendRemittanceAdviceDTO
   */
  tokenAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendRemittanceAdviceDTO
   */
  tokenTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendRemittanceAdviceDTO
   */
  paidAmount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendRemittanceAdviceDTO
   */
  toAddress: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendRemittanceAdviceDTO
   */
  txnHash: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcSendRemittanceAdviceDTO
   */
  ccAddresses?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendRemittanceAdviceDTO
   */
  email: string;
}
/**
 *
 * @export
 * @interface ApiSvcSendWhitelistnotificationEmailRequest
 */
export interface ApiSvcSendWhitelistnotificationEmailRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcSendWhitelistnotificationEmailRequest
   */
  emails: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendWhitelistnotificationEmailRequest
   */
  requesterName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSendWhitelistnotificationEmailRequest
   */
  whitelistedAddress: string;
}
/**
 *
 * @export
 * @interface ApiSvcSimpleAmountDTO
 */
export interface ApiSvcSimpleAmountDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleAmountDTO
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleAmountDTO
   */
  coin: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleAmountDTO
   */
  unit: string;
}
/**
 *
 * @export
 * @interface ApiSvcSimpleCategorizationAction
 */
export interface ApiSvcSimpleCategorizationAction {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcSimpleCategorizationAction
   */
  ignoreFailPricing?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleCategorizationAction
   */
  feeCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleCategorizationAction
   */
  feeContactId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleCategorizationAction
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleCategorizationAction
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleCategorizationAction
   */
  type: ApiSvcSimpleCategorizationActionTypeEnum;
}

export const ApiSvcSimpleCategorizationActionTypeEnum = {
  SimpleCategorization: 'SimpleCategorization',
} as const;

export type ApiSvcSimpleCategorizationActionTypeEnum =
  typeof ApiSvcSimpleCategorizationActionTypeEnum[keyof typeof ApiSvcSimpleCategorizationActionTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSimpleQueues = {
  TransactionSync: 'transaction-sync',
} as const;

export type ApiSvcSimpleQueues = typeof ApiSvcSimpleQueues[keyof typeof ApiSvcSimpleQueues];

/**
 *
 * @export
 * @interface ApiSvcSimpleSplitCategorizationAction
 */
export interface ApiSvcSimpleSplitCategorizationAction {
  /**
   *
   * @type {Array<ApiSvcSplit>}
   * @memberof ApiSvcSimpleSplitCategorizationAction
   */
  feeSplits?: Array<ApiSvcSplit>;
  /**
   *
   * @type {Array<ApiSvcSplit>}
   * @memberof ApiSvcSimpleSplitCategorizationAction
   */
  splits: Array<ApiSvcSplit>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleSplitCategorizationAction
   */
  type: ApiSvcSimpleSplitCategorizationActionTypeEnum;
}

export const ApiSvcSimpleSplitCategorizationActionTypeEnum = {
  SimpleSplitCategorization: 'SimpleSplitCategorization',
} as const;

export type ApiSvcSimpleSplitCategorizationActionTypeEnum =
  typeof ApiSvcSimpleSplitCategorizationActionTypeEnum[keyof typeof ApiSvcSimpleSplitCategorizationActionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcSimpleTradeTxnDetailsDTO
 */
export interface ApiSvcSimpleTradeTxnDetailsDTO {
  /**
   *
   * @type {ApiSvcAmountDTO}
   * @memberof ApiSvcSimpleTradeTxnDetailsDTO
   */
  acquired: ApiSvcAmountDTO;
  /**
   *
   * @type {ApiSvcAmountDTO}
   * @memberof ApiSvcSimpleTradeTxnDetailsDTO
   */
  disposed: ApiSvcAmountDTO;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcSimpleTradeTxnDetailsDTO
   */
  acquiredCost: ApiSvcCostBasisDTO;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcSimpleTradeTxnDetailsDTO
   */
  disposedCost: ApiSvcCostBasisDTO;
  /**
   *
   * @type {ApiSvcDetailedFeeDTO}
   * @memberof ApiSvcSimpleTradeTxnDetailsDTO
   */
  fee?: ApiSvcDetailedFeeDTO;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcSimpleTradeTxnDetailsDTO
   */
  systemCreated: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcSimpleTransactionDTO
 */
export interface ApiSvcSimpleTransactionDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleTransactionDTO
   */
  id: string;
  /**
   *
   * @type {ApiSvcExchangeRateDetails}
   * @memberof ApiSvcSimpleTransactionDTO
   */
  exchangeRates?: ApiSvcExchangeRateDetails;
  /**
   *
   * @type {ApiSvcTxnType}
   * @memberof ApiSvcSimpleTransactionDTO
   */
  typeGuess?: ApiSvcTxnType;
}

/**
 *
 * @export
 * @interface ApiSvcSimpleTxnDetailsDTO
 */
export interface ApiSvcSimpleTxnDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleTxnDetailsDTO
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleTxnDetailsDTO
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleTxnDetailsDTO
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSimpleTxnDetailsDTO
   */
  description?: string;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcSimpleTxnDetailsDTO
   */
  costBasis: ApiSvcCostBasisDTO;
}
/**
 *
 * @export
 * @interface ApiSvcSodaLine
 */
export interface ApiSvcSodaLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaLine
   */
  walletRoleId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaLine
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaLine
   */
  walletName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaLine
   */
  assetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaLine
   */
  assetTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaLine
   */
  quantity: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaLine
   */
  fairMarketValue: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaLine
   */
  bookBalance: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaLine
   */
  exchangeRate: string;
}
/**
 *
 * @export
 * @interface ApiSvcSodaReportResponse
 */
export interface ApiSvcSodaReportResponse {
  /**
   *
   * @type {number}
   * @memberof ApiSvcSodaReportResponse
   */
  reportAsOfSEC: number;
  /**
   *
   * @type {Array<ApiSvcSodaLine>}
   * @memberof ApiSvcSodaReportResponse
   */
  lines: Array<ApiSvcSodaLine>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaReportResponse
   */
  inventoryViewId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSodaReportResponse
   */
  runId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSortDirection = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const;

export type ApiSvcSortDirection = typeof ApiSvcSortDirection[keyof typeof ApiSvcSortDirection];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSources = {
  Custom: 'custom',
  Binance: 'binance',
  CoinbasePro: 'coinbasePro',
  Bitfinex: 'bitfinex',
  Kraken: 'kraken',
  ItBit: 'itBit',
  Bitstamp: 'bitstamp',
  BitGo: 'bitGo',
  Nydig: 'nydig',
  Bittrex: 'bittrex',
  Ftx: 'ftx',
  CoinbaseCustody: 'coinbaseCustody',
  Sfox: 'sfox',
  PrimeTrust: 'primeTrust',
  CoinbaseCommerce: 'coinbaseCommerce',
  Anchorage: 'anchorage',
  CoinbasePrime: 'coinbasePrime',
  Gemini: 'gemini',
  Fireblocks: 'fireblocks',
  CryptoDotCom: 'cryptoDotCom',
  Circle: 'Circle',
  Foundry: 'foundry',
  Loopring: 'loopring',
  Celo: 'celo',
  Polygon: 'polygon',
  Avalanche: 'avalanche',
  Stx: 'stx',
  Heco: 'heco',
  Bsc: 'bsc',
  Klay: 'klay',
  Flow: 'flow',
  Mina: 'mina',
  Sol: 'sol',
  Op: 'op',
  CoinbaseRetail: 'coinbaseRetail',
  Apt: 'apt',
} as const;

export type ApiSvcSources = typeof ApiSvcSources[keyof typeof ApiSvcSources];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSpecialMetadataType = {
  NetsuiteCustomRecordWallet: 'netsuite.customRecord.wallet',
} as const;

export type ApiSvcSpecialMetadataType = typeof ApiSvcSpecialMetadataType[keyof typeof ApiSvcSpecialMetadataType];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSpecialMetadataTypeNetsuiteWallet = {
  NetsuiteCustomRecordWallet: 'netsuite.customRecord.wallet',
} as const;

export type ApiSvcSpecialMetadataTypeNetsuiteWallet =
  typeof ApiSvcSpecialMetadataTypeNetsuiteWallet[keyof typeof ApiSvcSpecialMetadataTypeNetsuiteWallet];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSpecificIdentificationCoinSpendingStrategy = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const;

export type ApiSvcSpecificIdentificationCoinSpendingStrategy =
  typeof ApiSvcSpecificIdentificationCoinSpendingStrategy[keyof typeof ApiSvcSpecificIdentificationCoinSpendingStrategy];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSpecificIdentificationSelectionPreference = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;

export type ApiSvcSpecificIdentificationSelectionPreference =
  typeof ApiSvcSpecificIdentificationSelectionPreference[keyof typeof ApiSvcSpecificIdentificationSelectionPreference];

/**
 *
 * @export
 * @interface ApiSvcSpecificIdentificationSelectionStrategy
 */
export interface ApiSvcSpecificIdentificationSelectionStrategy {
  /**
   *
   * @type {ApiSvcSpecificIdentificationCoinSpendingStrategy}
   * @memberof ApiSvcSpecificIdentificationSelectionStrategy
   */
  spendingStrategy: ApiSvcSpecificIdentificationCoinSpendingStrategy;
  /**
   *
   * @type {ApiSvcSpecificIdentificationSelectionPreference}
   * @memberof ApiSvcSpecificIdentificationSelectionStrategy
   */
  selectionPreference: ApiSvcSpecificIdentificationSelectionPreference;
}

/**
 *
 * @export
 * @interface ApiSvcSplit
 */
export interface ApiSvcSplit {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcSplit
   */
  metadataIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSplit
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSplit
   */
  contactId: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcSplit
   */
  percentage?: number;
}
/**
 *
 * @export
 * @interface ApiSvcSso200Response
 */
export interface ApiSvcSso200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSso200Response
   */
  idpSignOnUrl: string;
}
/**
 *
 * @export
 * @interface ApiSvcSsoSettings
 */
export interface ApiSvcSsoSettings {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcSsoSettings
   */
  use: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSsoSettings
   */
  idpSignOnUrl: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSsoSettings
   */
  idpIssuer: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSsoSettings
   */
  idpCertificate: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcSsoSettings
   */
  requireSso?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSsoSettings
   */
  defaultRoleId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcStakingTxnDetailsDTO
 */
export interface ApiSvcStakingTxnDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcStakingTxnDetailsDTO
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcStakingTxnDetailsDTO
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcStakingTxnDetailsDTO
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcStakingTxnDetailsDTO
   */
  description?: string;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcStakingTxnDetailsDTO
   */
  costBasis: ApiSvcCostBasisDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcStakingTxnDetailsDTO
   */
  stakingTxnType: string;
}
/**
 *
 * @export
 * @interface ApiSvcStart200Response
 */
export interface ApiSvcStart200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcStart200Response
   */
  executionId: string;
}
/**
 *
 * @export
 * @interface ApiSvcStartingBalanceDetail
 */
export interface ApiSvcStartingBalanceDetail {
  /**
   *
   * @type {string}
   * @memberof ApiSvcStartingBalanceDetail
   */
  ticker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcStartingBalanceDetail
   */
  averageCost: string;
}
/**
 *
 * @export
 * @interface ApiSvcSubTransaction
 */
export interface ApiSvcSubTransaction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSubTransaction
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSubTransaction
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSubTransaction
   */
  to: string;
  /**
   *
   * @type {ApiSvcTxnCryptoAmount}
   * @memberof ApiSvcSubTransaction
   */
  amount: ApiSvcTxnCryptoAmount;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcSubTransaction
   */
  isFee?: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcSubscriptionDetailsDTO
 */
export interface ApiSvcSubscriptionDetailsDTO {
  /**
   *
   * @type {number}
   * @memberof ApiSvcSubscriptionDetailsDTO
   */
  accountingSeats: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcSubscriptionDetailsDTO
   */
  walletSeats: number;
}
/**
 *
 * @export
 * @interface ApiSvcSubsidiary
 */
export interface ApiSvcSubsidiary {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSubsidiary
   */
  id: string;
  /**
   *
   * @type {ApiSvcSubsidiaryRelationship}
   * @memberof ApiSvcSubsidiary
   */
  subType: ApiSvcSubsidiaryRelationship;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSubsidiary
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSubsidiary
   */
  parentSubsidiaryId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSubsidiaryRelationship = {
  Fbo: 'fbo',
  Subsidiary: 'subsidiary',
  Root: 'root',
} as const;

export type ApiSvcSubsidiaryRelationship =
  typeof ApiSvcSubsidiaryRelationship[keyof typeof ApiSvcSubsidiaryRelationship];

/**
 *
 * @export
 * @interface ApiSvcSuccessPricedExchangeRateDTO
 */
export interface ApiSvcSuccessPricedExchangeRateDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcSuccessPricedExchangeRateDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSuccessPricedExchangeRateDTO
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSuccessPricedExchangeRateDTO
   */
  to: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeSuccess}
   * @memberof ApiSvcSuccessPricedExchangeRateDTO
   */
  type: ApiSvcExchangeRateTypeSuccess;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSuccessPricedExchangeRateDTO
   */
  priceId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcSuccessPricedExchangeRateDTO
   */
  rate: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSuspendedTransactionStates = {
  Ignored: 'ignored',
  Deleted: 'deleted',
  Combined: 'combined',
} as const;

export type ApiSvcSuspendedTransactionStates =
  typeof ApiSvcSuspendedTransactionStates[keyof typeof ApiSvcSuspendedTransactionStates];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcSyncStatus = {
  NUMBER_1: 1,
  NUMBER_10: 10,
  NUMBER_11: 11,
  NUMBER_20: 20,
  NUMBER_99: 99,
} as const;

export type ApiSvcSyncStatus = typeof ApiSvcSyncStatus[keyof typeof ApiSvcSyncStatus];

/**
 *
 * @export
 * @interface ApiSvcTSOAContactsResponse
 */
export interface ApiSvcTSOAContactsResponse {
  /**
   *
   * @type {Array<ApiSvcContactDTO>}
   * @memberof ApiSvcTSOAContactsResponse
   */
  items: Array<ApiSvcContactDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOAContactsResponse
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOAContactsResponse
   */
  nextPage?: string;
}
/**
 *
 * @export
 * @interface ApiSvcTSOAErrorResponse
 */
export interface ApiSvcTSOAErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOAErrorResponse
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOAErrorResponse
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcTSOARulesResponse
 */
export interface ApiSvcTSOARulesResponse {
  /**
   *
   * @type {Array<ApiSvcTransferRuleDTO>}
   * @memberof ApiSvcTSOARulesResponse
   */
  items: Array<ApiSvcTransferRuleDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOARulesResponse
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOARulesResponse
   */
  nextPage?: string;
}
/**
 *
 * @export
 * @interface ApiSvcTSOATransactionsLiteResponse
 */
export interface ApiSvcTSOATransactionsLiteResponse {
  /**
   *
   * @type {Array<ApiSvcTransactionLite>}
   * @memberof ApiSvcTSOATransactionsLiteResponse
   */
  txns: Array<ApiSvcTransactionLite>;
  /**
   *
   * @type {Array<ApiSvcCoinMapping>}
   * @memberof ApiSvcTSOATransactionsLiteResponse
   */
  coins: Array<ApiSvcCoinMapping>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOATransactionsLiteResponse
   */
  olderPageToken?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOATransactionsLiteResponse
   */
  newerPageToken?: string;
}
/**
 *
 * @export
 * @interface ApiSvcTSOATransactionsResponse
 */
export interface ApiSvcTSOATransactionsResponse {
  /**
   *
   * @type {Array<ApiSvcTransactionDTO>}
   * @memberof ApiSvcTSOATransactionsResponse
   */
  items: Array<ApiSvcTransactionDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOATransactionsResponse
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTSOATransactionsResponse
   */
  nextPage?: string;
}
/**
 *
 * @export
 * @interface ApiSvcTaskResult
 */
export interface ApiSvcTaskResult {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTaskResult
   */
  success: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTaskResult
   */
  errors: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTaskResult
   */
  deleteTask: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcTaxAcquisitionDetailsDTO
 */
export interface ApiSvcTaxAcquisitionDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxAcquisitionDetailsDTO
   */
  acquisitionCostInBaseCurrency: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxAcquisitionDetailsDTO
   */
  acquisitionDate: string;
}
/**
 *
 * @export
 * @interface ApiSvcTaxConfigDTO
 */
export interface ApiSvcTaxConfigDTO {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTaxConfigDTO
   */
  capitalizeTradingFees: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcTaxConfigInputDTO
 */
export interface ApiSvcTaxConfigInputDTO {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTaxConfigInputDTO
   */
  capitalizeFees: boolean;
  /**
   *
   * @type {any}
   * @memberof ApiSvcTaxConfigInputDTO
   */
  wrappingTreatments?: any;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTaxConfigInputDTO
   */
  specIdNetZero?: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcTaxDispositionDetailsDTO
 */
export interface ApiSvcTaxDispositionDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxDispositionDetailsDTO
   */
  dispositionProceedsInBaseCurrency: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxDispositionDetailsDTO
   */
  dispositionDate: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTaxGroupingType = {
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type ApiSvcTaxGroupingType = typeof ApiSvcTaxGroupingType[keyof typeof ApiSvcTaxGroupingType];

/**
 *
 * @export
 * @interface ApiSvcTaxStartingBalanceExchangeRates
 */
export interface ApiSvcTaxStartingBalanceExchangeRates {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxStartingBalanceExchangeRates
   */
  ticker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxStartingBalanceExchangeRates
   */
  averageCost: string;
}
/**
 *
 * @export
 * @interface ApiSvcTaxStrategy
 */
export interface ApiSvcTaxStrategy {
  /**
   *
   * @type {ApiSvcTaxStrategyType}
   * @memberof ApiSvcTaxStrategy
   */
  type: ApiSvcTaxStrategyType;
  /**
   *
   * @type {ApiSvcTaxGroupingType}
   * @memberof ApiSvcTaxStrategy
   */
  grouping: ApiSvcTaxGroupingType;
  /**
   *
   * @type {Array<ApiSvcCoin>}
   * @memberof ApiSvcTaxStrategy
   */
  excludeCoins?: Array<ApiSvcCoin>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTaxStrategy
   */
  taxStartDateSEC?: number;
  /**
   *
   * @type {Array<ApiSvcTaxStartingBalanceExchangeRates>}
   * @memberof ApiSvcTaxStrategy
   */
  startingBalancesExchangeRates?: Array<ApiSvcTaxStartingBalanceExchangeRates>;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTaxStrategyType = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const;

export type ApiSvcTaxStrategyType = typeof ApiSvcTaxStrategyType[keyof typeof ApiSvcTaxStrategyType];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTaxStrategyTypeDTO = {
  Fifo: 'FIFO',
  Lifo: 'LIFO',
  CostAverage: 'CostAverage',
  SpecificIdentification: 'SpecificIdentification',
} as const;

export type ApiSvcTaxStrategyTypeDTO = typeof ApiSvcTaxStrategyTypeDTO[keyof typeof ApiSvcTaxStrategyTypeDTO];

/**
 *
 * @export
 * @interface ApiSvcTaxTxnInfoDTO
 */
export interface ApiSvcTaxTxnInfoDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxTxnInfoDTO
   */
  ticker: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTaxTxnInfoDTO
   */
  wallets: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxTxnInfoDTO
   */
  amount: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTaxTxnInfoDTO
   */
  cost?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTaxTxnInfoDTO
   */
  saleValue?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxTxnInfoDTO
   */
  exchangeRate?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTaxTxnInfoDTO
   */
  gainLoss?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTaxTxnInfoDTO
   */
  isGainLossComplete: boolean;
}
/**
 *
 * @export
 * @interface ApiSvcTokenFilteringRulesConfig
 */
export interface ApiSvcTokenFilteringRulesConfig {
  /**
   *
   * @type {Array<ApiSvcTokenFilteringRulesConfigWalletSyncPreferencesInner>}
   * @memberof ApiSvcTokenFilteringRulesConfig
   */
  walletSyncPreferences?: Array<ApiSvcTokenFilteringRulesConfigWalletSyncPreferencesInner>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTokenFilteringRulesConfig
   */
  filteredAddresses?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTokenFilteringRulesConfig
   */
  erc20FilterThreshold?: string;
  /**
   *
   * @type {Array<ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner>}
   * @memberof ApiSvcTokenFilteringRulesConfig
   */
  disabledDeployedByBlockHeightRules?: Array<ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner>;
  /**
   *
   * @type {Array<ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner>}
   * @memberof ApiSvcTokenFilteringRulesConfig
   */
  allowDeployedBlockHeightRules?: Array<ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTokenFilteringRulesConfig
   */
  disabledContractAddresses?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTokenFilteringRulesConfig
   */
  disabledDeployedByAddresses?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTokenFilteringRulesConfig
   */
  allowedDeployedByAddresses?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTokenFilteringRulesConfig
   */
  allowedContractAddresses?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner
 */
export interface ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner {
  /**
   *
   * @type {ApiSvcBlockHeightEvaluator}
   * @memberof ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner
   */
  evaluator: ApiSvcBlockHeightEvaluator;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTokenFilteringRulesConfigDisabledDeployedByBlockHeightRulesInner
   */
  blockNumber: string;
}

/**
 *
 * @export
 * @interface ApiSvcTokenFilteringRulesConfigWalletSyncPreferencesInner
 */
export interface ApiSvcTokenFilteringRulesConfigWalletSyncPreferencesInner {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTokenFilteringRulesConfigWalletSyncPreferencesInner
   */
  syncAllLogEvents?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTokenFilteringRulesConfigWalletSyncPreferencesInner
   */
  walletId: string;
}
/**
 *
 * @export
 * @interface ApiSvcTradeAssetDTO
 */
export interface ApiSvcTradeAssetDTO {
  /**
   *
   * @type {ApiSvcAmountDTO}
   * @memberof ApiSvcTradeAssetDTO
   */
  asset: ApiSvcAmountDTO;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcTradeAssetDTO
   */
  fairMarketValue: ApiSvcCostBasisDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeAssetDTO
   */
  walletId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcTradeCategorizationAction
 */
export interface ApiSvcTradeCategorizationAction {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTradeCategorizationAction
   */
  ignoreFailPricing?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeCategorizationAction
   */
  feeContactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeCategorizationAction
   */
  type: ApiSvcTradeCategorizationActionTypeEnum;
}

export const ApiSvcTradeCategorizationActionTypeEnum = {
  TradeCategorization: 'TradeCategorization',
} as const;

export type ApiSvcTradeCategorizationActionTypeEnum =
  typeof ApiSvcTradeCategorizationActionTypeEnum[keyof typeof ApiSvcTradeCategorizationActionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcTradeCategorizationDTO
 */
export interface ApiSvcTradeCategorizationDTO {
  /**
   *
   * @type {ApiSvcCategorizationTypeTrade}
   * @memberof ApiSvcTradeCategorizationDTO
   */
  type: ApiSvcCategorizationTypeTrade;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcTradeCategorizationDTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTradeCategorizationDTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeCategorizationDTO
   */
  accountingConnectionId?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTradeCategorizationDTO
   */
  dateTimeSec: number;
  /**
   *
   * @type {Array<ApiSvcTradeLineDTO>}
   * @memberof ApiSvcTradeCategorizationDTO
   */
  disposedAssets: Array<ApiSvcTradeLineDTO>;
  /**
   *
   * @type {Array<ApiSvcTradeLineDTO>}
   * @memberof ApiSvcTradeCategorizationDTO
   */
  acquiredAssets: Array<ApiSvcTradeLineDTO>;
  /**
   *
   * @type {ApiSvcTradeFeeDTO}
   * @memberof ApiSvcTradeCategorizationDTO
   */
  fees?: ApiSvcTradeFeeDTO;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTradeCategorizationDTO
   */
  carryForwardCostBasis?: boolean;
}

/**
 *
 * @export
 * @interface ApiSvcTradeFeeDTO
 */
export interface ApiSvcTradeFeeDTO {
  /**
   *
   * @type {Array<ApiSvcTradeAssetDTO>}
   * @memberof ApiSvcTradeFeeDTO
   */
  assets: Array<ApiSvcTradeAssetDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeFeeDTO
   */
  feeContactId: string;
}
/**
 *
 * @export
 * @interface ApiSvcTradeLineDTO
 */
export interface ApiSvcTradeLineDTO {
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcTradeLineDTO
   */
  sourceValue: ApiSvcRawValueDTO;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcTradeLineDTO
   */
  appliedExchangeRateValue?: ApiSvcRawValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeLineDTO
   */
  walletId?: string;
  /**
   *
   * @type {ApiSvcCategorizedLineTypeTradeLine}
   * @memberof ApiSvcTradeLineDTO
   */
  type: ApiSvcCategorizedLineTypeTradeLine;
  /**
   *
   * @type {ApiSvcCategorizedLineDirection}
   * @memberof ApiSvcTradeLineDTO
   */
  direction: ApiSvcCategorizedLineDirection;
}

/**
 *
 * @export
 * @interface ApiSvcTradeTransaction
 */
export interface ApiSvcTradeTransaction {
  /**
   *
   * @type {ApiSvcRemoteSystemId}
   * @memberof ApiSvcTradeTransaction
   */
  remoteSystemId?: ApiSvcRemoteSystemId;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeTransaction
   */
  type: ApiSvcTradeTransactionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeTransaction
   */
  connectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeTransaction
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeTransaction
   */
  walletName?: string;
  /**
   *
   * @type {ApiSvcSources}
   * @memberof ApiSvcTradeTransaction
   */
  source: ApiSvcSources;
  /**
   *
   * @type {ApiSvcTradeType}
   * @memberof ApiSvcTradeTransaction
   */
  tradeType: ApiSvcTradeType;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcTradeTransaction
   */
  acquiredAssets: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcTradeTransaction
   */
  disposedAssets: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcTradeTransaction
   */
  fees: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTradeTransaction
   */
  createdSEC: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTradeTransaction
   */
  isComplete: boolean;
  /**
   *
   * @type {ApiSvcTxnOrigin}
   * @memberof ApiSvcTradeTransaction
   */
  txnOrigin?: ApiSvcTxnOrigin;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTradeTransaction
   */
  contactId?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ApiSvcTradeTransaction
   */
  metadata?: { [key: string]: string };
}

export const ApiSvcTradeTransactionTypeEnum = {
  Trade: 'trade',
} as const;

export type ApiSvcTradeTransactionTypeEnum =
  typeof ApiSvcTradeTransactionTypeEnum[keyof typeof ApiSvcTradeTransactionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcTradeTxnDetailsDTO
 */
export interface ApiSvcTradeTxnDetailsDTO {
  /**
   *
   * @type {Array<ApiSvcTradeAssetDTO>}
   * @memberof ApiSvcTradeTxnDetailsDTO
   */
  acquiredAssets: Array<ApiSvcTradeAssetDTO>;
  /**
   *
   * @type {Array<ApiSvcTradeAssetDTO>}
   * @memberof ApiSvcTradeTxnDetailsDTO
   */
  disposedAssets: Array<ApiSvcTradeAssetDTO>;
  /**
   *
   * @type {ApiSvcTradeFeeDTO}
   * @memberof ApiSvcTradeTxnDetailsDTO
   */
  tradeFee?: ApiSvcTradeFeeDTO;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTradeTxnDetailsDTO
   */
  systemCreated: boolean;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcTradeTxnDetailsDTO
   */
  exchangeRates?: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTradeTxnDetailsDTO
   */
  carryForwardCostBasis?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTradeType = {
  Buy: 'buy',
  Sell: 'sell',
} as const;

export type ApiSvcTradeType = typeof ApiSvcTradeType[keyof typeof ApiSvcTradeType];

/**
 *
 * @export
 * @interface ApiSvcTransactionActorDTO
 */
export interface ApiSvcTransactionActorDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionActorDTO
   */
  address: string;
  /**
   *
   * @type {ApiSvcAmountDTO}
   * @memberof ApiSvcTransactionActorDTO
   */
  amount: ApiSvcAmountDTO;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionActorLiteDTO
 */
export interface ApiSvcTransactionActorLiteDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionActorLiteDTO
   */
  address: string;
  /**
   *
   * @type {ApiSvcAmountLiteDTO}
   * @memberof ApiSvcTransactionActorLiteDTO
   */
  amount: ApiSvcAmountLiteDTO;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionDTO
 */
export interface ApiSvcTransactionDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionDTO
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransactionDTO
   */
  created: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionDTO
   */
  txnType: string;
  /**
   *
   * @type {Array<ApiSvcTransactionActorDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  from: Array<ApiSvcTransactionActorDTO>;
  /**
   *
   * @type {Array<ApiSvcTransactionActorDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  to: Array<ApiSvcTransactionActorDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionDTO
   */
  reconciliationStatus: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionDTO
   */
  categorizationStatus: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionDTO
   */
  taxReconciliationStatus: string;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  fees: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  amountsWithoutFees: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  amountsWithFees: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  fullAmountSet: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcWalletValues>}
   * @memberof ApiSvcTransactionDTO
   */
  valuesByWallets: Array<ApiSvcWalletValues>;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  paidFees: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  exchangeRates?: Array<ApiSvcExchangeRateDetailsDTO>;
  /**
   *
   * @type {Array<ApiSvcTransactionDetailsDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  details: Array<ApiSvcTransactionDetailsDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionDTO
   */
  isBlockchain: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionDTO
   */
  ignore: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionDTO
   */
  hasMatchedInvoices: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionDTO
   */
  readonly: boolean;
  /**
   *
   * @type {Array<ApiSvcWalletPointer>}
   * @memberof ApiSvcTransactionDTO
   */
  walletPointers: Array<ApiSvcWalletPointer>;
  /**
   *
   * @type {Array<ApiSvcAccountingDetailsDTO>}
   * @memberof ApiSvcTransactionDTO
   */
  accountingDetails?: Array<ApiSvcAccountingDetailsDTO>;
  /**
   *
   * @type {ApiSvcTaxTxnInfoDTO}
   * @memberof ApiSvcTransactionDTO
   */
  taxInfo?: ApiSvcTaxTxnInfoDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionDTO
   */
  memo?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransactionDTO
   */
  reconciledRemoteIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionDTO
   */
  combinedIntoTxnId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransactionDTO
   */
  combinedSubTxnIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionData
 */
export interface ApiSvcTransactionData {
  /**
   *
   * @type {any}
   * @memberof ApiSvcTransactionData
   */
  simple?: any;
  /**
   *
   * @type {any}
   * @memberof ApiSvcTransactionData
   */
  sell?: any;
  /**
   *
   * @type {any}
   * @memberof ApiSvcTransactionData
   */
  transfer?: any;
  /**
   *
   * @type {any}
   * @memberof ApiSvcTransactionData
   */
  accountTransfer?: any;
  /**
   *
   * @type {any}
   * @memberof ApiSvcTransactionData
   */
  staking?: any;
  /**
   *
   * @type {any}
   * @memberof ApiSvcTransactionData
   */
  detailed?: any;
  /**
   *
   * @type {any}
   * @memberof ApiSvcTransactionData
   */
  multivalue?: any;
  /**
   *
   * @type {any}
   * @memberof ApiSvcTransactionData
   */
  invoice?: any;
  /**
   *
   * @type {any}
   * @memberof ApiSvcTransactionData
   */
  trade?: any;
  /**
   *
   * @type {ApiSvcAdvanceDeFiTransactionInput}
   * @memberof ApiSvcTransactionData
   */
  advanceDeFi?: ApiSvcAdvanceDeFiTransactionInput;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionData
   */
  accountingConnectionId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionDetailsDTO
 */
export interface ApiSvcTransactionDetailsDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransactionDetailsDTO
   */
  from: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransactionDetailsDTO
   */
  to: Array<string>;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcTransactionDetailsDTO
   */
  amounts: Array<ApiSvcAmountDTO>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTransactionDirection = {
  Inbound: 'Inbound',
  Outbound: 'Outbound',
  All: 'All',
  Na: 'NA',
  Empty: 'Empty',
} as const;

export type ApiSvcTransactionDirection = typeof ApiSvcTransactionDirection[keyof typeof ApiSvcTransactionDirection];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTransactionDirectionGraphQL = {
  Inbound: 'Inbound',
  Outbound: 'Outbound',
  All: 'All',
  Na: 'NA',
  Empty: 'Empty',
} as const;

export type ApiSvcTransactionDirectionGraphQL =
  typeof ApiSvcTransactionDirectionGraphQL[keyof typeof ApiSvcTransactionDirectionGraphQL];

/**
 *
 * @export
 * @interface ApiSvcTransactionId
 */
export interface ApiSvcTransactionId {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionId
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionId
   */
  txId: string;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionLite
 */
export interface ApiSvcTransactionLite {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionLite
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransactionLite
   */
  created: number;
  /**
   *
   * @type {ApiSvcTxnType}
   * @memberof ApiSvcTransactionLite
   */
  type: ApiSvcTxnType;
  /**
   *
   * @type {ApiSvcCategorizationStatusDTO}
   * @memberof ApiSvcTransactionLite
   */
  categorizationStatus: ApiSvcCategorizationStatusDTO;
  /**
   *
   * @type {ApiSvcReconciliationStatusDTO}
   * @memberof ApiSvcTransactionLite
   */
  reconciliationStatus: ApiSvcReconciliationStatusDTO;
  /**
   *
   * @type {ApiSvcExchangeRateDetails}
   * @memberof ApiSvcTransactionLite
   */
  exchangeRates?: ApiSvcExchangeRateDetails;
  /**
   *
   * @type {Array<ApiSvcAccountingDetailsDTO>}
   * @memberof ApiSvcTransactionLite
   */
  accountingDetails?: Array<ApiSvcAccountingDetailsDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionLite
   */
  categorizationSummary?: string;
  /**
   *
   * @type {Array<ApiSvcWalletAmountsLite>}
   * @memberof ApiSvcTransactionLite
   */
  walletAmounts: Array<ApiSvcWalletAmountsLite>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionLite
   */
  ignore: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionLite
   */
  memo?: string;
  /**
   *
   * @type {Array<ApiSvcTransactionActorLiteDTO>}
   * @memberof ApiSvcTransactionLite
   */
  from: Array<ApiSvcTransactionActorLiteDTO>;
  /**
   *
   * @type {Array<ApiSvcTransactionActorLiteDTO>}
   * @memberof ApiSvcTransactionLite
   */
  to: Array<ApiSvcTransactionActorLiteDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionLite
   */
  hasMatchedInvoices: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionLite
   */
  readonly: boolean;
  /**
   *
   * @type {Array<ApiSvcTxnViewLinkDTO>}
   * @memberof ApiSvcTransactionLite
   */
  viewLinks: Array<ApiSvcTxnViewLinkDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionLite
   */
  isCombined: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionLite
   */
  isCombinedSubTransaction: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionLite
   */
  errored?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransactionLite
   */
  methods: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionLite
   */
  accountingConnectionId?: string;
  /**
   *
   * @type {Array<ApiSvcTxnLineLiteDTO>}
   * @memberof ApiSvcTransactionLite
   */
  txnLines: Array<ApiSvcTxnLineLiteDTO>;
  /**
   *
   * @type {Array<ApiSvcAmountLiteDTO>}
   * @memberof ApiSvcTransactionLite
   */
  fullAmountSet: Array<ApiSvcAmountLiteDTO>;
  /**
   *
   * @type {Array<ApiSvcAmountLiteDTO>}
   * @memberof ApiSvcTransactionLite
   */
  fullAmountSetWithoutFees: Array<ApiSvcAmountLiteDTO>;
  /**
   *
   * @type {Array<{ [key: string]: string; }>}
   * @memberof ApiSvcTransactionLite
   */
  metadata?: Array<{ [key: string]: string }>;
  /**
   *
   * @type {Array<ApiSvcAmountLiteDTO>}
   * @memberof ApiSvcTransactionLite
   */
  paidFees?: Array<ApiSvcAmountLiteDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionLite
   */
  isManual?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionLite
   */
  walletId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionLite
   */
  walletName?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionLite
   */
  ticker?: string;
}

/**
 *
 * @export
 * @interface ApiSvcTransactionMovementDetails
 */
export interface ApiSvcTransactionMovementDetails {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionMovementDetails
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionMovementDetails
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionMovementDetails
   */
  ticker?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransactionMovementDetails
   */
  coinId?: number;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionStateDTO
 */
export interface ApiSvcTransactionStateDTO {
  /**
   *
   * @type {ApiSvcTransactionStateTypes}
   * @memberof ApiSvcTransactionStateDTO
   */
  type: ApiSvcTransactionStateTypes;
  /**
   *
   * @type {ApiSvcTransactionStates}
   * @memberof ApiSvcTransactionStateDTO
   */
  state: ApiSvcTransactionStates;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransactionStateDTO
   */
  version: number;
  /**
   *
   * @type {ApiSvcTransactionStateDTOTxn}
   * @memberof ApiSvcTransactionStateDTO
   */
  txn?: ApiSvcTransactionStateDTOTxn;
  /**
   *
   * @type {ApiSvcTransactionStateDTOMetadata}
   * @memberof ApiSvcTransactionStateDTO
   */
  metadata?: ApiSvcTransactionStateDTOMetadata;
  /**
   *
   * @type {ApiSvcTransactionStateDTOPrice}
   * @memberof ApiSvcTransactionStateDTO
   */
  price?: ApiSvcTransactionStateDTOPrice;
  /**
   *
   * @type {ApiSvcTransactionStateDTOCategorization}
   * @memberof ApiSvcTransactionStateDTO
   */
  categorization?: ApiSvcTransactionStateDTOCategorization;
  /**
   *
   * @type {ApiSvcTransactionStateDTONeedsReview}
   * @memberof ApiSvcTransactionStateDTO
   */
  needsReview?: ApiSvcTransactionStateDTONeedsReview;
  /**
   *
   * @type {ApiSvcTransactionStateDTOSync}
   * @memberof ApiSvcTransactionStateDTO
   */
  sync?: ApiSvcTransactionStateDTOSync;
  /**
   *
   * @type {ApiSvcTransactionStateDTOSuspend}
   * @memberof ApiSvcTransactionStateDTO
   */
  suspend?: ApiSvcTransactionStateDTOSuspend;
}

/**
 *
 * @export
 * @interface ApiSvcTransactionStateDTOCategorization
 */
export interface ApiSvcTransactionStateDTOCategorization {
  /**
   *
   * @type {ApiSvcCategorizationDTO}
   * @memberof ApiSvcTransactionStateDTOCategorization
   */
  categorizationDetails: ApiSvcCategorizationDTO;
  /**
   *
   * @type {ApiSvcCategorizationMethodology}
   * @memberof ApiSvcTransactionStateDTOCategorization
   */
  categorizationMethod: ApiSvcCategorizationMethodology;
}

/**
 *
 * @export
 * @interface ApiSvcTransactionStateDTOMetadata
 */
export interface ApiSvcTransactionStateDTOMetadata {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransactionStateDTOMetadata
   */
  userGeneratedLabelIds?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransactionStateDTOMetadata
   */
  systemGeneratedLabelIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionStateDTOMetadata
   */
  memo?: string;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionStateDTONeedsReview
 */
export interface ApiSvcTransactionStateDTONeedsReview {
  /**
   *
   * @type {Array<ApiSvcPendingExpandedExchangeRateDTO>}
   * @memberof ApiSvcTransactionStateDTONeedsReview
   */
  pendingExchangeRates?: Array<ApiSvcPendingExpandedExchangeRateDTO>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransactionStateDTONeedsReview
   */
  pendingWalletIds?: Array<string>;
  /**
   *
   * @type {Array<ApiSvcTxnValueLineDTO>}
   * @memberof ApiSvcTransactionStateDTONeedsReview
   */
  pendingTxnLines?: Array<ApiSvcTxnValueLineDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionStateDTOPrice
 */
export interface ApiSvcTransactionStateDTOPrice {
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransactionStateDTOPrice
   */
  transactionPriceVersion?: number;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcTransactionStateDTOPrice
   */
  overrideExchangeRates?: object;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcTransactionStateDTOPrice
   */
  exchangeRates: object;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionStateDTOSuspend
 */
export interface ApiSvcTransactionStateDTOSuspend {
  /**
   *
   * @type {ApiSvcCombinedTxnDTO}
   * @memberof ApiSvcTransactionStateDTOSuspend
   */
  combinedDetails: ApiSvcCombinedTxnDTO;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionStateDTOSync
 */
export interface ApiSvcTransactionStateDTOSync {
  /**
   *
   * @type {Array<ApiSvcRemoteSyncStatusDTO>}
   * @memberof ApiSvcTransactionStateDTOSync
   */
  remoteSyncStatuses: Array<ApiSvcRemoteSyncStatusDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcTransactionStateDTOTxn
 */
export interface ApiSvcTransactionStateDTOTxn {
  /**
   *
   * @type {ApiSvcCombinationDetailsDTO}
   * @memberof ApiSvcTransactionStateDTOTxn
   */
  combinationDetails?: ApiSvcCombinationDetailsDTO;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransactionStateDTOTxn
   */
  txnTimestampMS?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransactionStateDTOTxn
   */
  txnTimestampSEC: number;
  /**
   *
   * @type {ApiSvcTransactionType}
   * @memberof ApiSvcTransactionStateDTOTxn
   */
  txnType: ApiSvcTransactionType;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionStateDTOTxn
   */
  isTxnLineValuesMisMatched?: boolean;
  /**
   *
   * @type {Array<ApiSvcTxnValueLineDTO>}
   * @memberof ApiSvcTransactionStateDTOTxn
   */
  txnLines: Array<ApiSvcTxnValueLineDTO>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransactionStateDTOTxn
   */
  walletIds: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionStateDTOTxn
   */
  transactionId: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTransactionStateTransitions = {
  MarkReadyToSync: 'mark-ready-to-sync',
  MarkNotReadyToSync: 'mark-not-ready-to-sync',
  MarkSynced: 'mark-synced',
  UnMarkSynced: 'un-mark-synced',
  Ignore: 'ignore',
  UnIgnore: 'un-ignore',
} as const;

export type ApiSvcTransactionStateTransitions =
  typeof ApiSvcTransactionStateTransitions[keyof typeof ApiSvcTransactionStateTransitions];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTransactionStateTypes = {
  Open: 'open',
  Closed: 'closed',
  Suspended: 'suspended',
} as const;

export type ApiSvcTransactionStateTypes = typeof ApiSvcTransactionStateTypes[keyof typeof ApiSvcTransactionStateTypes];

/**
 *
 * @export
 * @interface ApiSvcTransactionStateUpdateDTO
 */
export interface ApiSvcTransactionStateUpdateDTO {
  /**
   *
   * @type {ApiSvcTransactionStateTransitions}
   * @memberof ApiSvcTransactionStateUpdateDTO
   */
  update: ApiSvcTransactionStateTransitions;
}

/**
 *
 * @export
 * @interface ApiSvcTransactionStates
 */
export interface ApiSvcTransactionStates {}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTransactionType = {
  Send: 'send',
  Receive: 'receive',
  Trade: 'trade',
  Transfer: 'transfer',
  ContractExecution: 'contract-execution',
  Unknown: 'unknown',
} as const;

export type ApiSvcTransactionType = typeof ApiSvcTransactionType[keyof typeof ApiSvcTransactionType];

/**
 *
 * @export
 * @interface ApiSvcTransactionsUnionType
 */
export interface ApiSvcTransactionsUnionType {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionsUnionType
   */
  type: ApiSvcTransactionsUnionTypeTypeEnum;
  /**
   *
   * @type {ApiSvcRemoteSystemId}
   * @memberof ApiSvcTransactionsUnionType
   */
  remoteSystemId?: ApiSvcRemoteSystemId;
  /**
   *
   * @type {ApiSvcTransactionId}
   * @memberof ApiSvcTransactionsUnionType
   */
  transactionId?: ApiSvcTransactionId;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionsUnionType
   */
  connectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionsUnionType
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionsUnionType
   */
  walletName?: string;
  /**
   *
   * @type {ApiSvcSources}
   * @memberof ApiSvcTransactionsUnionType
   */
  source: ApiSvcSources;
  /**
   *
   * @type {ApiSvcExchangeTransferType}
   * @memberof ApiSvcTransactionsUnionType
   */
  transferType: ApiSvcExchangeTransferType;
  /**
   *
   * @type {ApiSvcTransferTransactionTransferAmount}
   * @memberof ApiSvcTransactionsUnionType
   */
  transferAmount: ApiSvcTransferTransactionTransferAmount;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransactionsUnionType
   */
  createdSEC: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionsUnionType
   */
  isComplete: boolean;
  /**
   *
   * @type {ApiSvcTxnOrigin}
   * @memberof ApiSvcTransactionsUnionType
   */
  txnOrigin?: ApiSvcTxnOrigin;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionsUnionType
   */
  contactId?: string;
  /**
   *
   * @type {ApiSvcCryptoExchangeTransferMeta}
   * @memberof ApiSvcTransactionsUnionType
   */
  meta?: ApiSvcCryptoExchangeTransferMeta;
  /**
   *
   * @type {ApiSvcTransferTransactionTransferAmount}
   * @memberof ApiSvcTransactionsUnionType
   */
  fee?: ApiSvcTransferTransactionTransferAmount;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionsUnionType
   */
  isOnChain?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ApiSvcTransactionsUnionType
   */
  metadata?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionsUnionType
   */
  isTrade?: boolean;
  /**
   *
   * @type {ApiSvcTradeType}
   * @memberof ApiSvcTransactionsUnionType
   */
  tradeType: ApiSvcTradeType;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcTransactionsUnionType
   */
  acquiredAssets: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcTransactionsUnionType
   */
  disposedAssets: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcTransactionsUnionType
   */
  fees: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {Array<ApiSvcTransactionMovementDetails>}
   * @memberof ApiSvcTransactionsUnionType
   */
  from: Array<ApiSvcTransactionMovementDetails>;
  /**
   *
   * @type {Array<ApiSvcTransactionMovementDetails>}
   * @memberof ApiSvcTransactionsUnionType
   */
  to: Array<ApiSvcTransactionMovementDetails>;
  /**
   *
   * @type {ApiSvcManualTxnTypes}
   * @memberof ApiSvcTransactionsUnionType
   */
  transactionType: ApiSvcManualTxnTypes;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcTransactionsUnionType
   */
  amounts: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransactionsUnionType
   */
  isTaxExempt?: boolean;
  /**
   *
   * @type {ApiSvcBlockchainTransactionDiscoveringAddress}
   * @memberof ApiSvcTransactionsUnionType
   */
  discoveringAddress: ApiSvcBlockchainTransactionDiscoveringAddress;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionsUnionType
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionsUnionType
   */
  block: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionsUnionType
   */
  id: string;
  /**
   *
   * @type {ApiSvcAccountBasedBlockchainTransaction}
   * @memberof ApiSvcTransactionsUnionType
   */
  txn: ApiSvcAccountBasedBlockchainTransaction;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransactionsUnionType
   */
  fullTransactionDetailsLink?: string;
}

export const ApiSvcTransactionsUnionTypeTypeEnum = {
  Blockchain: 'blockchain',
} as const;

export type ApiSvcTransactionsUnionTypeTypeEnum =
  typeof ApiSvcTransactionsUnionTypeTypeEnum[keyof typeof ApiSvcTransactionsUnionTypeTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcTransferAssetDTO
 */
export interface ApiSvcTransferAssetDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferAssetDTO
   */
  walletId: string;
  /**
   *
   * @type {ApiSvcAssetValueDTO}
   * @memberof ApiSvcTransferAssetDTO
   */
  asset: ApiSvcAssetValueDTO;
}
/**
 *
 * @export
 * @interface ApiSvcTransferCategorizationV1DTO
 */
export interface ApiSvcTransferCategorizationV1DTO {
  /**
   *
   * @type {ApiSvcCategorizationTypeTransfer}
   * @memberof ApiSvcTransferCategorizationV1DTO
   */
  type: ApiSvcCategorizationTypeTransfer;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcTransferCategorizationV1DTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferCategorizationV1DTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferCategorizationV1DTO
   */
  notes: string;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcTransferCategorizationV1DTO
   */
  feeCostBasis?: ApiSvcRawValueDTO;
  /**
   *
   * @type {Array<ApiSvcFeeLineDTO>}
   * @memberof ApiSvcTransferCategorizationV1DTO
   */
  fees?: Array<ApiSvcFeeLineDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferCategorizationV1DTO
   */
  feeContactId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferCategorizationV1DTO
   */
  feeWalletId?: string;
}

/**
 *
 * @export
 * @interface ApiSvcTransferCategorizationV2DTO
 */
export interface ApiSvcTransferCategorizationV2DTO {
  /**
   *
   * @type {ApiSvcCategorizationTypeTransfer}
   * @memberof ApiSvcTransferCategorizationV2DTO
   */
  type: ApiSvcCategorizationTypeTransfer;
  /**
   *
   * @type {ApiSvcCategorizationMethod}
   * @memberof ApiSvcTransferCategorizationV2DTO
   */
  categorizationMethod: ApiSvcCategorizationMethod;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferCategorizationV2DTO
   */
  forceCategorize?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferCategorizationV2DTO
   */
  notes: string;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcTransferCategorizationV2DTO
   */
  feeCostBasis?: ApiSvcRawValueDTO;
  /**
   *
   * @type {Array<ApiSvcFeeLineDTO>}
   * @memberof ApiSvcTransferCategorizationV2DTO
   */
  fees?: Array<ApiSvcFeeLineDTO>;
  /**
   *
   * @type {Array<ApiSvcTransferLineDTO>}
   * @memberof ApiSvcTransferCategorizationV2DTO
   */
  disposedAssets: Array<ApiSvcTransferLineDTO>;
  /**
   *
   * @type {Array<ApiSvcTransferLineDTO>}
   * @memberof ApiSvcTransferCategorizationV2DTO
   */
  acquiredAssets: Array<ApiSvcTransferLineDTO>;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTransferDirection = {
  Incoming: 'Incoming',
  Outgoing: 'Outgoing',
} as const;

export type ApiSvcTransferDirection = typeof ApiSvcTransferDirection[keyof typeof ApiSvcTransferDirection];

/**
 *
 * @export
 * @interface ApiSvcTransferLineDTO
 */
export interface ApiSvcTransferLineDTO {
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcTransferLineDTO
   */
  sourceValue: ApiSvcRawValueDTO;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcTransferLineDTO
   */
  appliedExchangeRateValue?: ApiSvcRawValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferLineDTO
   */
  walletId?: string;
  /**
   *
   * @type {ApiSvcCategorizedLineTypeTransferLine}
   * @memberof ApiSvcTransferLineDTO
   */
  type: ApiSvcCategorizedLineTypeTransferLine;
  /**
   *
   * @type {ApiSvcCategorizedLineDirection}
   * @memberof ApiSvcTransferLineDTO
   */
  direction: ApiSvcCategorizedLineDirection;
}

/**
 *
 * @export
 * @interface ApiSvcTransferRuleDTO
 */
export interface ApiSvcTransferRuleDTO {
  /**
   *
   * @type {ApiSvcMetadataComparisonGraphQL}
   * @memberof ApiSvcTransferRuleDTO
   */
  metadataRule?: ApiSvcMetadataComparisonGraphQL;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcTransferRuleDTO
   */
  includesCurrency?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  methodId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  accountingConnectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  type: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferRuleDTO
   */
  multiToken?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferRuleDTO
   */
  autoCategorizeFee?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferRuleDTO
   */
  collapseValues?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferRuleDTO
   */
  autoReconcile?: boolean;
  /**
   *
   * @type {ApiSvcTransactionDirectionGraphQL}
   * @memberof ApiSvcTransferRuleDTO
   */
  direction: ApiSvcTransactionDirectionGraphQL;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  walletId?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransferRuleDTO
   */
  afterDateSEC?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransferRuleDTO
   */
  beforeDateSEC?: number;
  /**
   *
   * @type {Array<ApiSvcRuleComparisonDTO>}
   * @memberof ApiSvcTransferRuleDTO
   */
  valueRules?: Array<ApiSvcRuleComparisonDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  toAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  fromAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  coin?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferRuleDTO
   */
  disabled: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  description?: string;
  /**
   *
   * @type {ApiSvcRuleActionGraphQL}
   * @memberof ApiSvcTransferRuleDTO
   */
  action: ApiSvcRuleActionGraphQL;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransferRuleDTO
   */
  priority: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferRuleDTO
   */
  id: string;
  /**
   *
   * @type {ApiSvcCoin}
   * @memberof ApiSvcTransferRuleDTO
   */
  coinValue?: ApiSvcCoin;
}

/**
 *
 * @export
 * @interface ApiSvcTransferTransaction
 */
export interface ApiSvcTransferTransaction {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTransaction
   */
  type: ApiSvcTransferTransactionTypeEnum;
  /**
   *
   * @type {ApiSvcRemoteSystemId}
   * @memberof ApiSvcTransferTransaction
   */
  remoteSystemId?: ApiSvcRemoteSystemId;
  /**
   *
   * @type {ApiSvcTransactionId}
   * @memberof ApiSvcTransferTransaction
   */
  transactionId?: ApiSvcTransactionId;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTransaction
   */
  connectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTransaction
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTransaction
   */
  walletName?: string;
  /**
   *
   * @type {ApiSvcSources}
   * @memberof ApiSvcTransferTransaction
   */
  source: ApiSvcSources;
  /**
   *
   * @type {ApiSvcExchangeTransferType}
   * @memberof ApiSvcTransferTransaction
   */
  transferType: ApiSvcExchangeTransferType;
  /**
   *
   * @type {ApiSvcTransferTransactionTransferAmount}
   * @memberof ApiSvcTransferTransaction
   */
  transferAmount: ApiSvcTransferTransactionTransferAmount;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransferTransaction
   */
  createdSEC: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferTransaction
   */
  isComplete: boolean;
  /**
   *
   * @type {ApiSvcTxnOrigin}
   * @memberof ApiSvcTransferTransaction
   */
  txnOrigin?: ApiSvcTxnOrigin;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTransaction
   */
  contactId?: string;
  /**
   *
   * @type {ApiSvcCryptoExchangeTransferMeta}
   * @memberof ApiSvcTransferTransaction
   */
  meta?: ApiSvcCryptoExchangeTransferMeta;
  /**
   *
   * @type {ApiSvcTransferTransactionTransferAmount}
   * @memberof ApiSvcTransferTransaction
   */
  fee?: ApiSvcTransferTransactionTransferAmount;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferTransaction
   */
  isOnChain?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ApiSvcTransferTransaction
   */
  metadata?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcTransferTransaction
   */
  isTrade?: boolean;
}

export const ApiSvcTransferTransactionTypeEnum = {
  Transfer: 'transfer',
} as const;

export type ApiSvcTransferTransactionTypeEnum =
  typeof ApiSvcTransferTransactionTypeEnum[keyof typeof ApiSvcTransferTransactionTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcTransferTransactionTransferAmount
 */
export interface ApiSvcTransferTransactionTransferAmount {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTransactionTransferAmount
   */
  type: ApiSvcTransferTransactionTransferAmountTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTransactionTransferAmount
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTransactionTransferAmount
   */
  ticker: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTransferTransactionTransferAmount
   */
  coinId?: number;
}

export const ApiSvcTransferTransactionTransferAmountTypeEnum = {
  Crypto: 'crypto',
} as const;

export type ApiSvcTransferTransactionTransferAmountTypeEnum =
  typeof ApiSvcTransferTransactionTransferAmountTypeEnum[keyof typeof ApiSvcTransferTransactionTransferAmountTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcTransferTxnDetailsDTO
 */
export interface ApiSvcTransferTxnDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTxnDetailsDTO
   */
  notes: string;
  /**
   *
   * @type {ApiSvcCostBasisDTO}
   * @memberof ApiSvcTransferTxnDetailsDTO
   */
  feeCostBasis?: ApiSvcCostBasisDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTxnDetailsDTO
   */
  feeContactId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTransferTxnDetailsDTO
   */
  feeWalletId?: string;
  /**
   *
   * @type {Array<ApiSvcExchangeRateDetailsDTO>}
   * @memberof ApiSvcTransferTxnDetailsDTO
   */
  exchangeRates?: Array<ApiSvcExchangeRateDetailsDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcTxnCategorizeRow
 */
export interface ApiSvcTxnCategorizeRow {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCategorizeRow
   */
  transactionId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCategorizeRow
   */
  transactionLineId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCategorizeRow
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCategorizeRow
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCategorizeRow
   */
  feeCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCategorizeRow
   */
  feeContactId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcTxnCreateResponse
 */
export interface ApiSvcTxnCreateResponse {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCreateResponse
   */
  id: string;
}
/**
 *
 * @export
 * @interface ApiSvcTxnCryptoAmount
 */
export interface ApiSvcTxnCryptoAmount {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCryptoAmount
   */
  type: ApiSvcTxnCryptoAmountTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCryptoAmount
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnCryptoAmount
   */
  ticker?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTxnCryptoAmount
   */
  coinId?: number;
}

export const ApiSvcTxnCryptoAmountTypeEnum = {
  Crypto: 'crypto',
} as const;

export type ApiSvcTxnCryptoAmountTypeEnum =
  typeof ApiSvcTxnCryptoAmountTypeEnum[keyof typeof ApiSvcTxnCryptoAmountTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcTxnErrorResponse
 */
export interface ApiSvcTxnErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnErrorResponse
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnErrorResponse
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnErrorResponse
   */
  traceId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcTxnFiatAmount
 */
export interface ApiSvcTxnFiatAmount {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnFiatAmount
   */
  type: ApiSvcTxnFiatAmountTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnFiatAmount
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnFiatAmount
   */
  ticker: string;
}

export const ApiSvcTxnFiatAmountTypeEnum = {
  Fiat: 'fiat',
} as const;

export type ApiSvcTxnFiatAmountTypeEnum = typeof ApiSvcTxnFiatAmountTypeEnum[keyof typeof ApiSvcTxnFiatAmountTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcTxnLineLiteDTO
 */
export interface ApiSvcTxnLineLiteDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  txnLineId?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  subId?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  dateTimeSEC?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  dateTime?: string;
  /**
   *
   * @type {ApiSvcTxnLineOperation}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  operation?: ApiSvcTxnLineOperation;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  assetId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  feeAssetId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  walletId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  to?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  feeAmount?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  feeExchangeRate?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  feeValueInBaseCurrency?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  amount?: string;
  /**
   *
   * @type {ApiSvcTxnOrigin}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  txnOrigin?: ApiSvcTxnOrigin;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLineLiteDTO
   */
  accountingConnection?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTxnLineOperation = {
  Withdraw: 'WITHDRAW',
  Deposit: 'DEPOSIT',
  Buy: 'BUY',
  Sell: 'SELL',
  Fee: 'FEE',
  Transfer: 'TRANSFER',
} as const;

export type ApiSvcTxnLineOperation = typeof ApiSvcTxnLineOperation[keyof typeof ApiSvcTxnLineOperation];

/**
 *
 * @export
 * @interface ApiSvcTxnLiteRecordResult
 */
export interface ApiSvcTxnLiteRecordResult {
  /**
   *
   * @type {Array<ApiSvcTransactionLite>}
   * @memberof ApiSvcTxnLiteRecordResult
   */
  items: Array<ApiSvcTransactionLite>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLiteRecordResult
   */
  olderPageToken?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnLiteRecordResult
   */
  newerPageToken?: string;
  /**
   *
   * @type {Array<ApiSvcCoinMapping>}
   * @memberof ApiSvcTxnLiteRecordResult
   */
  coins: Array<ApiSvcCoinMapping>;
}
/**
 * who or what service submitted the txn
 * @export
 * @enum {string}
 */

export const ApiSvcTxnOrigin = {
  ManualSystem: 'manual-system',
  ManualFeed: 'manual-feed',
  ManualImport: 'manual-import',
  ManualUser: 'manual-user',
  ManualApi: 'manual-api',
  AutoExchangeSyncer: 'auto-exchange-syncer',
  AutoBlockchainSyncer: 'auto-blockchain-syncer',
  AutoBalanceDelta: 'auto-balance-delta',
  AutoSystem: 'auto-system',
  NotSpecified: 'not-specified',
} as const;

export type ApiSvcTxnOrigin = typeof ApiSvcTxnOrigin[keyof typeof ApiSvcTxnOrigin];

/**
 *
 * @export
 * @interface ApiSvcTxnPriceResult
 */
export interface ApiSvcTxnPriceResult {
  /**
   *
   * @type {number}
   * @memberof ApiSvcTxnPriceResult
   */
  retryAfter?: number;
  /**
   *
   * @type {ApiSvcSimpleTransactionDTO}
   * @memberof ApiSvcTxnPriceResult
   */
  txn: ApiSvcSimpleTransactionDTO;
}
/**
 *
 * @export
 * @interface ApiSvcTxnResponse
 */
export interface ApiSvcTxnResponse {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnResponse
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnResponse
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnResponse
   */
  traceId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTxnType = {
  Send: 'Send',
  Receive: 'Receive',
  Trade: 'Trade',
  Transfer: 'Transfer',
  ContractExecution: 'ContractExecution',
  Unknown: 'Unknown',
} as const;

export type ApiSvcTxnType = typeof ApiSvcTxnType[keyof typeof ApiSvcTxnType];

/**
 *
 * @export
 * @interface ApiSvcTxnValueLineDTO
 */
export interface ApiSvcTxnValueLineDTO {
  /**
   * the txn hash of the main txn
   * @type {string}
   * @memberof ApiSvcTxnValueLineDTO
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcTxnValueLineDTO
   */
  txnLineId: number;
  /**
   * unified timestamp
   * @type {number}
   * @memberof ApiSvcTxnValueLineDTO
   */
  dateTimeSEC: number;
  /**
   * unified timestamp, in milli seconds
   * @type {number}
   * @memberof ApiSvcTxnValueLineDTO
   */
  dateTimeMS?: number;
  /**
   *
   * @type {ApiSvcTxnLineOperation}
   * @memberof ApiSvcTxnValueLineDTO
   */
  operation: ApiSvcTxnLineOperation;
  /**
   *
   * @type {ApiSvcTxnOrigin}
   * @memberof ApiSvcTxnValueLineDTO
   */
  txnOrigin?: ApiSvcTxnOrigin;
  /**
   * timestamp of the txn itself
   * @type {number}
   * @memberof ApiSvcTxnValueLineDTO
   */
  originalTxnTimestampSEC?: number;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcTxnValueLineDTO
   */
  amount: ApiSvcRawValueDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnValueLineDTO
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnValueLineDTO
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnValueLineDTO
   */
  to?: string;
  /**
   *
   * @type {ApiSvcExpandedExchangeRateDTO}
   * @memberof ApiSvcTxnValueLineDTO
   */
  exchangeRate?: ApiSvcExpandedExchangeRateDTO;
  /**
   *
   * @type {ApiSvcRawValueDTO}
   * @memberof ApiSvcTxnValueLineDTO
   */
  value?: ApiSvcRawValueDTO;
}

/**
 *
 * @export
 * @interface ApiSvcTxnViewLinkDTO
 */
export interface ApiSvcTxnViewLinkDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnViewLinkDTO
   */
  link: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcTxnViewLinkDTO
   */
  name: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcTxnWarnings = {
  CategorizationMissingCategorizationWalletId: 'categorization-missing-categorization-wallet-id',
  CategorizationLegacyCategorization: 'categorization-legacy-categorization',
  TxnMismatchedTxnlinesValues: 'txn-mismatched-txnlines-values',
  CategorizationFeeInferringCategoryId: 'categorization-fee-inferring-category-id',
} as const;

export type ApiSvcTxnWarnings = typeof ApiSvcTxnWarnings[keyof typeof ApiSvcTxnWarnings];

/**
 *
 * @export
 * @interface ApiSvcUnableToPriceExchangeRateDTO
 */
export interface ApiSvcUnableToPriceExchangeRateDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUnableToPriceExchangeRateDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUnableToPriceExchangeRateDTO
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUnableToPriceExchangeRateDTO
   */
  to: string;
  /**
   *
   * @type {ApiSvcExchangeRateTypeUnableToPrice}
   * @memberof ApiSvcUnableToPriceExchangeRateDTO
   */
  type: ApiSvcExchangeRateTypeUnableToPrice;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUnableToPriceExchangeRateDTO
   */
  priceId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUnableToPriceExchangeRateDTO
   */
  rate: ApiSvcUnableToPriceExchangeRateDTORateEnum;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUnableToPriceExchangeRateDTO
   */
  reason?: string;
}

export const ApiSvcUnableToPriceExchangeRateDTORateEnum = {
  _0: '0',
} as const;

export type ApiSvcUnableToPriceExchangeRateDTORateEnum =
  typeof ApiSvcUnableToPriceExchangeRateDTORateEnum[keyof typeof ApiSvcUnableToPriceExchangeRateDTORateEnum];

/**
 *
 * @export
 * @interface ApiSvcUncheckedAmountInput
 */
export interface ApiSvcUncheckedAmountInput {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUncheckedAmountInput
   */
  unit: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUncheckedAmountInput
   */
  coin: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUncheckedAmountInput
   */
  value: string;
}
/**
 *
 * @export
 * @interface ApiSvcUndeleteTransactions200Response
 */
export interface ApiSvcUndeleteTransactions200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUndeleteTransactions200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUndeleteTransactions200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUndeleteTransactions200Response
   */
  traceId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcUndeleteTransactionsDTO
 */
export interface ApiSvcUndeleteTransactionsDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcUndeleteTransactionsDTO
   */
  txnIds: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcUpdateInvoicesFromRemoteSourceDTO
 */
export interface ApiSvcUpdateInvoicesFromRemoteSourceDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcUpdateInvoicesFromRemoteSourceDTO
   */
  invoiceIds: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcUpdateMetadataFromRemoteSourceDTO
 */
export interface ApiSvcUpdateMetadataFromRemoteSourceDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcUpdateMetadataFromRemoteSourceDTO
   */
  metadataIds: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcUpdateNetsuiteWalletCustomRecord
 */
export interface ApiSvcUpdateNetsuiteWalletCustomRecord {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcUpdateNetsuiteWalletCustomRecord
   */
  propagateToRemoteSystem?: boolean;
  /**
   *
   * @type {ApiSvcSpecialMetadataTypeNetsuiteWallet}
   * @memberof ApiSvcUpdateNetsuiteWalletCustomRecord
   */
  type: ApiSvcSpecialMetadataTypeNetsuiteWallet;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcUpdateNetsuiteWalletCustomRecord
   */
  isValidated: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcUpdateNetsuiteWalletCustomRecord
   */
  lastValidatedSEC: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateNetsuiteWalletCustomRecord
   */
  networkId: string;
}

/**
 *
 * @export
 * @interface ApiSvcUpdateReportSnapshotRequest
 */
export interface ApiSvcUpdateReportSnapshotRequest {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateReportSnapshotRequest
   */
  title: string;
}
/**
 *
 * @export
 * @interface ApiSvcUpdateTransactionStatus200Response
 */
export interface ApiSvcUpdateTransactionStatus200Response {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateTransactionStatus200Response
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateTransactionStatus200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateTransactionStatus200Response
   */
  traceId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcUpdateWalletBalanceRequest
 */
export interface ApiSvcUpdateWalletBalanceRequest {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateWalletBalanceRequest
   */
  ticker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateWalletBalanceRequest
   */
  amount: string;
}
/**
 *
 * @export
 * @interface ApiSvcUpdateWalletBalancesRequest
 */
export interface ApiSvcUpdateWalletBalancesRequest {
  /**
   *
   * @type {Array<ApiSvcUpdateWalletBalanceRequest>}
   * @memberof ApiSvcUpdateWalletBalancesRequest
   */
  balances: Array<ApiSvcUpdateWalletBalanceRequest>;
  /**
   *
   * @type {number}
   * @memberof ApiSvcUpdateWalletBalancesRequest
   */
  timestampSEC: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcUpdateWalletBalancesRequest
   */
  balanceTimestampSEC?: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateWalletBalancesRequest
   */
  blockId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateWalletBalancesRequest
   */
  balanceSource?: string;
  /**
   *
   * @type {ApiSvcBalanceType}
   * @memberof ApiSvcUpdateWalletBalancesRequest
   */
  balanceType?: ApiSvcBalanceType;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpdateWalletBalancesRequest
   */
  remoteBalanceType?: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcUpdateWalletBalancesRequest
   */
  txnCount?: number;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ApiSvcUpdateWalletBalancesRequest
   */
  metadata?: { [key: string]: string };
}

/**
 *
 * @export
 * @interface ApiSvcUpsertTransactionRequest
 */
export interface ApiSvcUpsertTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  type: ApiSvcUpsertTransactionRequestTypeEnum;
  /**
   *
   * @type {ApiSvcRemoteSystemId}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  remoteSystemId?: ApiSvcRemoteSystemId;
  /**
   *
   * @type {ApiSvcTransactionId}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  transactionId?: ApiSvcTransactionId;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  connectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  walletName?: string;
  /**
   *
   * @type {ApiSvcSources}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  source: ApiSvcSources;
  /**
   *
   * @type {ApiSvcExchangeTransferType}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  transferType: ApiSvcExchangeTransferType;
  /**
   *
   * @type {ApiSvcTransferTransactionTransferAmount}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  transferAmount: ApiSvcTransferTransactionTransferAmount;
  /**
   *
   * @type {number}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  createdSEC: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  isComplete: boolean;
  /**
   *
   * @type {ApiSvcTxnOrigin}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  txnOrigin?: ApiSvcTxnOrigin;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  contactId?: string;
  /**
   *
   * @type {ApiSvcCryptoExchangeTransferMeta}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  meta?: ApiSvcCryptoExchangeTransferMeta;
  /**
   *
   * @type {ApiSvcTransferTransactionTransferAmount}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  fee?: ApiSvcTransferTransactionTransferAmount;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  isOnChain?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  metadata?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  isTrade?: boolean;
  /**
   *
   * @type {ApiSvcTradeType}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  tradeType: ApiSvcTradeType;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  acquiredAssets: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  disposedAssets: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  fees: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {Array<ApiSvcTransactionMovementDetails>}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  from: Array<ApiSvcTransactionMovementDetails>;
  /**
   *
   * @type {Array<ApiSvcTransactionMovementDetails>}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  to: Array<ApiSvcTransactionMovementDetails>;
  /**
   *
   * @type {ApiSvcManualTxnTypes}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  transactionType: ApiSvcManualTxnTypes;
  /**
   *
   * @type {Array<ApiSvcTransferTransactionTransferAmount>}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  amounts: Array<ApiSvcTransferTransactionTransferAmount>;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  isTaxExempt?: boolean;
  /**
   *
   * @type {ApiSvcBlockchainTransactionDiscoveringAddress}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  discoveringAddress: ApiSvcBlockchainTransactionDiscoveringAddress;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  block: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  id: string;
  /**
   *
   * @type {ApiSvcAccountBasedBlockchainTransaction}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  txn: ApiSvcAccountBasedBlockchainTransaction;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUpsertTransactionRequest
   */
  fullTransactionDetailsLink?: string;
}

export const ApiSvcUpsertTransactionRequestTypeEnum = {
  Blockchain: 'blockchain',
} as const;

export type ApiSvcUpsertTransactionRequestTypeEnum =
  typeof ApiSvcUpsertTransactionRequestTypeEnum[keyof typeof ApiSvcUpsertTransactionRequestTypeEnum];

/**
 *
 * @export
 * @interface ApiSvcUserIdentity
 */
export interface ApiSvcUserIdentity {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserIdentity
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserIdentity
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserIdentity
   */
  assertedIdentity?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcUserIdentity
   */
  roles?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcUserIdentity
   */
  walletIds?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiSvcUserPointer
 */
export interface ApiSvcUserPointer {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserPointer
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserPointer
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserPointer
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ApiSvcUserRole
 */
export interface ApiSvcUserRole {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserRole
   */
  userId: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcUserRole
   */
  role: number;
}
/**
 *
 * @export
 * @interface ApiSvcUserTosAcceptance
 */
export interface ApiSvcUserTosAcceptance {
  /**
   * Latest version of the TOS that has been accepted
   * @type {string}
   * @memberof ApiSvcUserTosAcceptance
   */
  tosVersion: string;
  /**
   * Date of TOS acceptance, in seconds since Unix epoch
   * @type {number}
   * @memberof ApiSvcUserTosAcceptance
   */
  acceptedOn: number;
}
/**
 *
 * @export
 * @interface ApiSvcUserWallet
 */
export interface ApiSvcUserWallet {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserWallet
   */
  connectType: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserWallet
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserWallet
   */
  networkId: string;
}
/**
 *
 * @export
 * @interface ApiSvcUserWalletDTO
 */
export interface ApiSvcUserWalletDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserWalletDTO
   */
  networkId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserWalletDTO
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUserWalletDTO
   */
  connectType: string;
}
/**
 *
 * @export
 * @interface ApiSvcUsernameBasedToken
 */
export interface ApiSvcUsernameBasedToken {
  /**
   *
   * @type {string}
   * @memberof ApiSvcUsernameBasedToken
   */
  user: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcUsernameBasedToken
   */
  password: string;
}
/**
 *
 * @export
 * @interface ApiSvcValueBase
 */
export interface ApiSvcValueBase {
  /**
   *
   * @type {number}
   * @memberof ApiSvcValueBase
   */
  type: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcValueBase
   */
  value: string;
  /**
   * prefers to use currencyId method instead
   * @type {string}
   * @memberof ApiSvcValueBase
   */
  valueIdentifier: string;
}
/**
 *
 * @export
 * @interface ApiSvcValueDTO
 */
export interface ApiSvcValueDTO {
  /**
   *
   * @type {ApiSvcValueDTOTypes}
   * @memberof ApiSvcValueDTO
   */
  type: ApiSvcValueDTOTypes;
  /**
   *
   * @type {number}
   * @memberof ApiSvcValueDTO
   */
  amount: number;
  /**
   * this is the bw ticker, e.g. ETH, BTC
   * @type {string}
   * @memberof ApiSvcValueDTO
   */
  ticker: string;
  /**
   * only for fiat type, the fiat ticker
   * @type {string}
   * @memberof ApiSvcValueDTO
   */
  fiat?: string;
  /**
   * only for coin type, the coin ticker
   * @type {string}
   * @memberof ApiSvcValueDTO
   */
  coin?: string;
  /**
   * only for coin type, the coin unit name
   * @type {string}
   * @memberof ApiSvcValueDTO
   */
  coinUnit?: string;
  /**
   * optional, could optimize processing if known, e.g. COIN.10
   * @type {string}
   * @memberof ApiSvcValueDTO
   */
  assetId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcValueDTOTypes = {
  Fiat: 'Fiat',
  Coin: 'Coin',
} as const;

export type ApiSvcValueDTOTypes = typeof ApiSvcValueDTOTypes[keyof typeof ApiSvcValueDTOTypes];

/**
 *
 * @export
 * @interface ApiSvcValueDetail
 */
export interface ApiSvcValueDetail {
  /**
   *
   * @type {string}
   * @memberof ApiSvcValueDetail
   */
  symbol: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcValueDetail
   */
  value: string;
}
/**
 *
 * @export
 * @interface ApiSvcValueDetailsDTO
 */
export interface ApiSvcValueDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcValueDetailsDTO
   */
  symbol: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcValueDetailsDTO
   */
  value: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcValueExtractor = {
  Royalty: 'royalty',
  Commission: 'commission',
  Amount: 'amount',
  Fee: 'fee',
  Revenue: 'revenue',
  LrcFee: 'lrcFee',
  TotalFee: 'totalFee',
} as const;

export type ApiSvcValueExtractor = typeof ApiSvcValueExtractor[keyof typeof ApiSvcValueExtractor];

/**
 *
 * @export
 * @interface ApiSvcVarianceReport
 */
export interface ApiSvcVarianceReport {
  /**
   *
   * @type {Array<ApiSvcVarianceReportLine>}
   * @memberof ApiSvcVarianceReport
   */
  lines: Array<ApiSvcVarianceReportLine>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReport
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReport
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReport
   */
  walletId: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReport
   */
  coinId: string | null;
}
/**
 *
 * @export
 * @interface ApiSvcVarianceReportCreationParams
 */
export interface ApiSvcVarianceReportCreationParams {
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportCreationParams
   */
  coinId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportCreationParams
   */
  walletId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportCreationParams
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportCreationParams
   */
  orgId: string;
}
/**
 *
 * @export
 * @interface ApiSvcVarianceReportLine
 */
export interface ApiSvcVarianceReportLine {
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  accountId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  adjustmentActionSummary: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcVarianceReportLine
   */
  adjustmentAmount: number | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  adjustmentRequired: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcVarianceReportLine
   */
  amount: number | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  amountTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  blockchainId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  categoryId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  coinId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  contactId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  cost: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  costTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcVarianceReportLine
   */
  externalBalance: number | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  fee: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  feeTicker: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  fromAddress: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  memo: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  metadataActionAmt: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  metadataCoinId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  metadataDate: string;
  /**
   *
   * @type {number}
   * @memberof ApiSvcVarianceReportLine
   */
  metadataExternalBalance: number | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  metadataRegisterBalance: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  metadataSummaryNotes: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  metadataWalletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  registerBalance: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  remoteContactId: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcVarianceReportLine
   */
  taxExempt: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  time: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  toAddress: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  tradeId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  transactionType: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLine
   */
  import: string;
}
/**
 *
 * @export
 * @interface ApiSvcVarianceReportLineV2
 */
export interface ApiSvcVarianceReportLineV2 {
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLineV2
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLineV2
   */
  assetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLineV2
   */
  assetSymbol: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLineV2
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportLineV2
   */
  externalBalanceTimestamp: string | null;
  /**
   *
   * @type {number}
   * @memberof ApiSvcVarianceReportLineV2
   */
  registerBalance: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcVarianceReportLineV2
   */
  externalBalance: number | null;
  /**
   *
   * @type {number}
   * @memberof ApiSvcVarianceReportLineV2
   */
  balanceDifference: number | null;
}
/**
 *
 * @export
 * @interface ApiSvcVarianceReportV2
 */
export interface ApiSvcVarianceReportV2 {
  /**
   *
   * @type {Array<ApiSvcVarianceReportLineV2>}
   * @memberof ApiSvcVarianceReportV2
   */
  lines: Array<ApiSvcVarianceReportLineV2>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportV2
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportV2
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportV2
   */
  walletId: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcVarianceReportV2
   */
  coinId: string | null;
}
/**
 *
 * @export
 * @interface ApiSvcWalletAmountsLite
 */
export interface ApiSvcWalletAmountsLite {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletAmountsLite
   */
  walletId: string;
  /**
   *
   * @type {Array<ApiSvcAmountLiteDTO>}
   * @memberof ApiSvcWalletAmountsLite
   */
  amounts: Array<ApiSvcAmountLiteDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcWalletBalanceDTO
 */
export interface ApiSvcWalletBalanceDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletBalanceDTO
   */
  balanceId?: string;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcWalletBalanceDTO
   */
  balances: Array<ApiSvcAmountDTO>;
  /**
   *
   * @type {ApiSvcFiatValueDTO}
   * @memberof ApiSvcWalletBalanceDTO
   */
  totalFiatValue?: ApiSvcFiatValueDTO;
}
/**
 *
 * @export
 * @interface ApiSvcWalletCaptureInviteParams
 */
export interface ApiSvcWalletCaptureInviteParams {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletCaptureInviteParams
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletCaptureInviteParams
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletCaptureInviteParams
   */
  inviteLink: string;
}
/**
 *
 * @export
 * @interface ApiSvcWalletFlagsType
 */
export interface ApiSvcWalletFlagsType {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletFlagsType
   */
  trackingMode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletFlagsType
   */
  upsertV2?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletFlagsType
   */
  syncerVersion?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletFlagsType
   */
  syncEndDateSEC?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletFlagsType
   */
  syncStartDateSEC?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletFlagsType
   */
  solanaInflationStakingRewardsEndTime?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletFlagsType
   */
  solanaInflationStakingRewardsStartTime?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletFlagsType
   */
  solanaInflationStakingRewardsEnabled?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcWalletIdTypes = {
  Wallet: 'wallet',
  Enterprise: 'enterprise',
  Exchange: 'exchange',
  MultisigWatch: 'multisig-watch',
  HardwareWallet: 'hardware-wallet',
  UserWallet: 'user-wallet',
  Manual: 'manual',
  ManualBlockchainWallet: 'manual-blockchain-wallet',
  Connection: 'connection',
  AccountBasedBlockchainWallet: 'account-based-blockchain-wallet',
  Group: 'group',
  DefiWallet: 'defi-wallet',
  CeloWallet: 'celo-wallet',
  PolygonWallet: 'polygon-wallet',
  AvalancheWallet: 'avalanche-wallet',
  BscWallet: 'bsc-wallet',
  HecoWallet: 'heco-wallet',
  StxWallet: 'stx-wallet',
  OptimismWallet: 'optimism-wallet',
} as const;

export type ApiSvcWalletIdTypes = typeof ApiSvcWalletIdTypes[keyof typeof ApiSvcWalletIdTypes];

/**
 *
 * @export
 * @interface ApiSvcWalletInput
 */
export interface ApiSvcWalletInput {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  walletRoleId?: string;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcWalletInput
   */
  metadata?: object | null;
  /**
   *
   * @type {ApiSvcWalletFlagsType}
   * @memberof ApiSvcWalletInput
   */
  flags?: ApiSvcWalletFlagsType | null;
  /**
   *
   * @type {ApiSvcDefiWalletInput}
   * @memberof ApiSvcWalletInput
   */
  defi?: ApiSvcDefiWalletInput | null;
  /**
   *
   * @type {ApiSvcLrcMarketplaceWallet}
   * @memberof ApiSvcWalletInput
   */
  lrcMarketplace?: ApiSvcLrcMarketplaceWallet | null;
  /**
   *
   * @type {ApiSvcGroupWallet}
   * @memberof ApiSvcWalletInput
   */
  group?: ApiSvcGroupWallet | null;
  /**
   *
   * @type {ApiSvcNetworkAddressWallet}
   * @memberof ApiSvcWalletInput
   */
  accountBasedBlockchain?: ApiSvcNetworkAddressWallet | null;
  /**
   *
   * @type {ApiSvcAddressWallet}
   * @memberof ApiSvcWalletInput
   */
  addressBasedBlockchain?: ApiSvcAddressWallet | null;
  /**
   *
   * @type {ApiSvcUserWallet}
   * @memberof ApiSvcWalletInput
   */
  user?: ApiSvcUserWallet | null;
  /**
   *
   * @type {ApiSvcManualWallet}
   * @memberof ApiSvcWalletInput
   */
  manual?: ApiSvcManualWallet | null;
  /**
   *
   * @type {ApiSvcHardwareWallet}
   * @memberof ApiSvcWalletInput
   */
  hardware?: ApiSvcHardwareWallet | null;
  /**
   *
   * @type {ApiSvcMultisigWallet}
   * @memberof ApiSvcWalletInput
   */
  multisig?: ApiSvcMultisigWallet | null;
  /**
   *
   * @type {ApiSvcEnterpriseWalletInput}
   * @memberof ApiSvcWalletInput
   */
  eh?: ApiSvcEnterpriseWalletInput | null;
  /**
   *
   * @type {ApiSvcWatch}
   * @memberof ApiSvcWalletInput
   */
  watch?: ApiSvcWatch | null;
  /**
   *
   * @type {Array<ApiSvcWatch>}
   * @memberof ApiSvcWalletInput
   */
  watches?: Array<ApiSvcWatch> | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  defaultFeeCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  defaultFeeContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  defaultOutflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  defaultOutflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  defaultInflowContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  defaultInflowCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  accountingConnection?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  subsidiaryId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletInput
   */
  name: string;
}
/**
 *
 * @export
 * @interface ApiSvcWalletManagerCreateStatus
 */
export interface ApiSvcWalletManagerCreateStatus {
  /**
   * True if wallet creation succeeded
   * @type {boolean}
   * @memberof ApiSvcWalletManagerCreateStatus
   */
  success: boolean;
  /**
   * Id of the wallet/connection that was newly created
   * @type {string}
   * @memberof ApiSvcWalletManagerCreateStatus
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletManagerCreateStatus
   */
  failureReason?: string;
}
/**
 *
 * @export
 * @interface ApiSvcWalletPayload
 */
export interface ApiSvcWalletPayload {
  /**
   * The remoteWalletId from wallet manager svc
   * @type {string}
   * @memberof ApiSvcWalletPayload
   */
  remoteWalletId: string;
  /**
   *
   * @type {ApiSvcWalletInput}
   * @memberof ApiSvcWalletPayload
   */
  wallet: ApiSvcWalletInput;
  /**
   * Permissions
   * @type {Array<ApiSvcWalletPermissionInput>}
   * @memberof ApiSvcWalletPayload
   */
  prems: Array<ApiSvcWalletPermissionInput>;
}
/**
 *
 * @export
 * @interface ApiSvcWalletPermissionInput
 */
export interface ApiSvcWalletPermissionInput {
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletPermissionInput
   */
  role: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletPermissionInput
   */
  userId: string;
}
/**
 *
 * @export
 * @interface ApiSvcWalletPointer
 */
export interface ApiSvcWalletPointer {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletPointer
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletPointer
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletPointer
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletPointer
   */
  networkId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcWalletPointer
   */
  addresses: Array<string>;
  /**
   * this means the wallet pointer is tied to an accounting categorization
   * @type {string}
   * @memberof ApiSvcWalletPointer
   */
  accountingDetailTransactionId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcWalletResponse
 */
export interface ApiSvcWalletResponse {
  /**
   *
   * @type {ApiSvcWalletResponseDTO}
   * @memberof ApiSvcWalletResponse
   */
  data: ApiSvcWalletResponseDTO;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcWalletResponse
   */
  _links: object;
}
/**
 *
 * @export
 * @interface ApiSvcWalletResponseDTO
 */
export interface ApiSvcWalletResponseDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcWalletResponseDTO
   */
  addresses: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  orgId: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  remoteId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  disabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletResponseDTO
   */
  type: number;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  networkId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  connectionId?: string;
  /**
   *
   * @type {ApiSvcRollupConfigDTO}
   * @memberof ApiSvcWalletResponseDTO
   */
  rollupConfig?: ApiSvcRollupConfigDTO;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof ApiSvcWalletResponseDTO
   */
  metadata?: object;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  isSyncEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  isBalanceMonitoringOnly: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  isSyncEnabledSystem: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  isSyncEnabledUser: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletResponseDTO
   */
  lastSuccessfulSyncSEC?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletResponseDTO
   */
  lastSuccessfulBalanceCheckSEC?: number;
  /**
   *
   * @type {ApiSvcBalanceCheckResultDTO}
   * @memberof ApiSvcWalletResponseDTO
   */
  latestBalanceCheck?: ApiSvcBalanceCheckResultDTO;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletResponseDTO
   */
  createdSEC?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  categorizeTransactions?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  accrualCadence?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  syncToLatestInteraction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  generateAccruals?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletResponseDTO
   */
  categorizeAccruals?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  defaultAccrualContact?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  defaultAccrualCategory?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  subsidiaryId?: string;
  /**
   *
   * @type {ApiSvcWalletsFlags}
   * @memberof ApiSvcWalletResponseDTO
   */
  flags?: ApiSvcWalletsFlags;
  /**
   *
   * @type {ApiSvcWalletBalanceDTO}
   * @memberof ApiSvcWalletResponseDTO
   */
  balance?: ApiSvcWalletBalanceDTO;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletResponseDTO
   */
  walletRoleId?: string;
}
/**
 *
 * @export
 * @interface ApiSvcWalletRole
 */
export interface ApiSvcWalletRole {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletRole
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletRole
   */
  name: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcWalletSortOrder = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type ApiSvcWalletSortOrder = typeof ApiSvcWalletSortOrder[keyof typeof ApiSvcWalletSortOrder];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcWalletTrackingModes = {
  BalanceDelta: 'balance_delta',
  Balance: 'balance',
  TransactionBalance: 'transaction_balance',
} as const;

export type ApiSvcWalletTrackingModes = typeof ApiSvcWalletTrackingModes[keyof typeof ApiSvcWalletTrackingModes];

/**
 *
 * @export
 * @enum {string}
 */

export const ApiSvcWalletTypes = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
  NUMBER_11: 11,
  NUMBER_12: 12,
  NUMBER_13: 13,
  NUMBER_14: 14,
  NUMBER_15: 15,
  NUMBER_17: 17,
  NUMBER_20: 20,
  NUMBER_21: 21,
  NUMBER_22: 22,
  NUMBER_999: 999,
} as const;

export type ApiSvcWalletTypes = typeof ApiSvcWalletTypes[keyof typeof ApiSvcWalletTypes];

/**
 *
 * @export
 * @interface ApiSvcWalletValues
 */
export interface ApiSvcWalletValues {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletValues
   */
  walletId: string;
  /**
   *
   * @type {Array<ApiSvcAmountDTO>}
   * @memberof ApiSvcWalletValues
   */
  amounts: Array<ApiSvcAmountDTO>;
}
/**
 *
 * @export
 * @interface ApiSvcWalletsCreateParams
 */
export interface ApiSvcWalletsCreateParams {
  /**
   *
   * @type {Array<ApiSvcWalletPayload>}
   * @memberof ApiSvcWalletsCreateParams
   */
  wallets: Array<ApiSvcWalletPayload>;
}
/**
 *
 * @export
 * @interface ApiSvcWalletsErrorResponse
 */
export interface ApiSvcWalletsErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletsErrorResponse
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletsErrorResponse
   */
  message: string;
}
/**
 *
 * @export
 * @interface ApiSvcWalletsFlags
 */
export interface ApiSvcWalletsFlags {
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletsFlags
   */
  solanaInflationStakingRewardsEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletsFlags
   */
  solanaInflationStakingRewardsStartTime?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletsFlags
   */
  solanaInflationStakingRewardsEndTime?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletsFlags
   */
  solanaHistoricalAccountEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletsFlags
   */
  syncStartDateSEC?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletsFlags
   */
  syncEndDateSEC?: number;
  /**
   *
   * @type {number}
   * @memberof ApiSvcWalletsFlags
   */
  syncerVersion?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApiSvcWalletsFlags
   */
  upsertV2?: boolean;
  /**
   *
   * @type {ApiSvcWalletTrackingModes}
   * @memberof ApiSvcWalletsFlags
   */
  trackingMode?: ApiSvcWalletTrackingModes;
}

/**
 *
 * @export
 * @interface ApiSvcWalletsResponse
 */
export interface ApiSvcWalletsResponse {
  /**
   *
   * @type {Array<ApiSvcWalletResponseDTO>}
   * @memberof ApiSvcWalletsResponse
   */
  items: Array<ApiSvcWalletResponseDTO>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletsResponse
   */
  previousPage?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWalletsResponse
   */
  nextPage?: string;
}
/**
 *
 * @export
 * @interface ApiSvcWatch
 */
export interface ApiSvcWatch {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatch
   */
  derivationKey?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcWatch
   */
  addresses?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatch
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatch
   */
  coin: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatch
   */
  type: string;
}
/**
 *
 * @export
 * @interface ApiSvcWatchDTO
 */
export interface ApiSvcWatchDTO {
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatchDTO
   */
  type: string;
  /**
   *
   * @type {ApiSvcCoin}
   * @memberof ApiSvcWatchDTO
   */
  coin: ApiSvcCoin;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatchDTO
   */
  address?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApiSvcWatchDTO
   */
  addresses?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatchDTO
   */
  derivationKey?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatchDTO
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatchDTO
   */
  connectionId?: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcWatchDTO
   */
  remoteId?: string;
}

/**
 *
 * @export
 * @interface ApiSvcXApiKeyToken
 */
export interface ApiSvcXApiKeyToken {
  /**
   *
   * @type {string}
   * @memberof ApiSvcXApiKeyToken
   */
  xApiKey: string;
  /**
   *
   * @type {string}
   * @memberof ApiSvcXApiKeyToken
   */
  subAccountName: string;
}

/**
 * AddressEnrollmentApi - axios parameter creator
 * @export
 */
export const AddressEnrollmentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} amount
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmAddressPennyTest: async (
      orgId: string,
      contactId: string,
      address: string,
      amount: string,
      paymentCurrency: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('confirmAddressPennyTest', 'orgId', orgId);
      // verify required parameter 'contactId' is not null or undefined
      assertParamExists('confirmAddressPennyTest', 'contactId', contactId);
      // verify required parameter 'address' is not null or undefined
      assertParamExists('confirmAddressPennyTest', 'address', address);
      // verify required parameter 'amount' is not null or undefined
      assertParamExists('confirmAddressPennyTest', 'amount', amount);
      // verify required parameter 'paymentCurrency' is not null or undefined
      assertParamExists('confirmAddressPennyTest', 'paymentCurrency', paymentCurrency);
      const localVarPath =
        `/addressEnrollment/enrollAddress/org/{orgId}/contactId/{contactId}/address/{address}/amount/{amount}`
          .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
          .replace(`{${'contactId'}}`, encodeURIComponent(String(contactId)))
          .replace(`{${'address'}}`, encodeURIComponent(String(address)))
          .replace(`{${'amount'}}`, encodeURIComponent(String(amount)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (paymentCurrency !== undefined) {
        localVarQueryParameter['paymentCurrency'] = paymentCurrency;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} signature
     * @param {string} signatureData
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmAddressSignature: async (
      orgId: string,
      contactId: string,
      address: string,
      signature: string,
      signatureData: string,
      paymentCurrency: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('confirmAddressSignature', 'orgId', orgId);
      // verify required parameter 'contactId' is not null or undefined
      assertParamExists('confirmAddressSignature', 'contactId', contactId);
      // verify required parameter 'address' is not null or undefined
      assertParamExists('confirmAddressSignature', 'address', address);
      // verify required parameter 'signature' is not null or undefined
      assertParamExists('confirmAddressSignature', 'signature', signature);
      // verify required parameter 'signatureData' is not null or undefined
      assertParamExists('confirmAddressSignature', 'signatureData', signatureData);
      // verify required parameter 'paymentCurrency' is not null or undefined
      assertParamExists('confirmAddressSignature', 'paymentCurrency', paymentCurrency);
      const localVarPath =
        `/addressEnrollment/enrollAddress/org/{orgId}/contactId/{contactId}/address/{address}/signature/{signature}`
          .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
          .replace(`{${'contactId'}}`, encodeURIComponent(String(contactId)))
          .replace(`{${'address'}}`, encodeURIComponent(String(address)))
          .replace(`{${'signature'}}`, encodeURIComponent(String(signature)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (signatureData !== undefined) {
        localVarQueryParameter['signatureData'] = signatureData;
      }

      if (paymentCurrency !== undefined) {
        localVarQueryParameter['paymentCurrency'] = paymentCurrency;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} address
     * @param {string} contactId
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddressStatus: async (
      orgId: string,
      address: string,
      contactId: string,
      paymentCurrency: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAddressStatus', 'orgId', orgId);
      // verify required parameter 'address' is not null or undefined
      assertParamExists('getAddressStatus', 'address', address);
      // verify required parameter 'contactId' is not null or undefined
      assertParamExists('getAddressStatus', 'contactId', contactId);
      // verify required parameter 'paymentCurrency' is not null or undefined
      assertParamExists('getAddressStatus', 'paymentCurrency', paymentCurrency);
      const localVarPath = `/addressEnrollment/getAddressStatus/org/{orgId}/address/{address}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'address'}}`, encodeURIComponent(String(address)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (contactId !== undefined) {
        localVarQueryParameter['contactId'] = contactId;
      }

      if (paymentCurrency !== undefined) {
        localVarQueryParameter['paymentCurrency'] = paymentCurrency;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddressStatusExternal: async (
      orgId: string,
      contactId: string,
      address: string,
      paymentCurrency: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAddressStatusExternal', 'orgId', orgId);
      // verify required parameter 'contactId' is not null or undefined
      assertParamExists('getAddressStatusExternal', 'contactId', contactId);
      // verify required parameter 'address' is not null or undefined
      assertParamExists('getAddressStatusExternal', 'address', address);
      // verify required parameter 'paymentCurrency' is not null or undefined
      assertParamExists('getAddressStatusExternal', 'paymentCurrency', paymentCurrency);
      const localVarPath =
        `/addressEnrollment/getAddressStatusExternal/org/{orgId}/contactId/{contactId}/address/{address}`
          .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
          .replace(`{${'contactId'}}`, encodeURIComponent(String(contactId)))
          .replace(`{${'address'}}`, encodeURIComponent(String(address)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (paymentCurrency !== undefined) {
        localVarQueryParameter['paymentCurrency'] = paymentCurrency;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} emailAddress
     * @param {string} paymentCurrency
     * @param {Array<string>} [ccAddresses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initiateAddressEnrollment: async (
      orgId: string,
      contactId: string,
      address: string,
      emailAddress: string,
      paymentCurrency: string,
      ccAddresses?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('initiateAddressEnrollment', 'orgId', orgId);
      // verify required parameter 'contactId' is not null or undefined
      assertParamExists('initiateAddressEnrollment', 'contactId', contactId);
      // verify required parameter 'address' is not null or undefined
      assertParamExists('initiateAddressEnrollment', 'address', address);
      // verify required parameter 'emailAddress' is not null or undefined
      assertParamExists('initiateAddressEnrollment', 'emailAddress', emailAddress);
      // verify required parameter 'paymentCurrency' is not null or undefined
      assertParamExists('initiateAddressEnrollment', 'paymentCurrency', paymentCurrency);
      const localVarPath =
        `/addressEnrollment/initiateAddressEnrollment/org/{orgId}/contactId/{contactId}/address/{address}`
          .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
          .replace(`{${'contactId'}}`, encodeURIComponent(String(contactId)))
          .replace(`{${'address'}}`, encodeURIComponent(String(address)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (emailAddress !== undefined) {
        localVarQueryParameter['emailAddress'] = emailAddress;
      }

      if (paymentCurrency !== undefined) {
        localVarQueryParameter['paymentCurrency'] = paymentCurrency;
      }

      if (ccAddresses) {
        localVarQueryParameter['ccAddresses'] = ccAddresses;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AddressEnrollmentApi - functional programming interface
 * @export
 */
export const AddressEnrollmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AddressEnrollmentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} amount
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmAddressPennyTest(
      orgId: string,
      contactId: string,
      address: string,
      amount: string,
      paymentCurrency: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmAddressPennyTest(
        orgId,
        contactId,
        address,
        amount,
        paymentCurrency,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} signature
     * @param {string} signatureData
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmAddressSignature(
      orgId: string,
      contactId: string,
      address: string,
      signature: string,
      signatureData: string,
      paymentCurrency: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmAddressSignature(
        orgId,
        contactId,
        address,
        signature,
        signatureData,
        paymentCurrency,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} address
     * @param {string} contactId
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAddressStatus(
      orgId: string,
      address: string,
      contactId: string,
      paymentCurrency: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcAddressEnrollmentStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressStatus(
        orgId,
        address,
        contactId,
        paymentCurrency,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAddressStatusExternal(
      orgId: string,
      contactId: string,
      address: string,
      paymentCurrency: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcExternalAddressEnrollmentStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressStatusExternal(
        orgId,
        contactId,
        address,
        paymentCurrency,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} emailAddress
     * @param {string} paymentCurrency
     * @param {Array<string>} [ccAddresses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async initiateAddressEnrollment(
      orgId: string,
      contactId: string,
      address: string,
      emailAddress: string,
      paymentCurrency: string,
      ccAddresses?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.initiateAddressEnrollment(
        orgId,
        contactId,
        address,
        emailAddress,
        paymentCurrency,
        ccAddresses,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AddressEnrollmentApi - factory interface
 * @export
 */
export const AddressEnrollmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AddressEnrollmentApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} amount
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmAddressPennyTest(
      orgId: string,
      contactId: string,
      address: string,
      amount: string,
      paymentCurrency: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .confirmAddressPennyTest(orgId, contactId, address, amount, paymentCurrency, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} signature
     * @param {string} signatureData
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmAddressSignature(
      orgId: string,
      contactId: string,
      address: string,
      signature: string,
      signatureData: string,
      paymentCurrency: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .confirmAddressSignature(orgId, contactId, address, signature, signatureData, paymentCurrency, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} address
     * @param {string} contactId
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddressStatus(
      orgId: string,
      address: string,
      contactId: string,
      paymentCurrency: string,
      options?: any
    ): AxiosPromise<ApiSvcAddressEnrollmentStatus> {
      return localVarFp
        .getAddressStatus(orgId, address, contactId, paymentCurrency, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} paymentCurrency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddressStatusExternal(
      orgId: string,
      contactId: string,
      address: string,
      paymentCurrency: string,
      options?: any
    ): AxiosPromise<ApiSvcExternalAddressEnrollmentStatus> {
      return localVarFp
        .getAddressStatusExternal(orgId, contactId, address, paymentCurrency, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} contactId
     * @param {string} address
     * @param {string} emailAddress
     * @param {string} paymentCurrency
     * @param {Array<string>} [ccAddresses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initiateAddressEnrollment(
      orgId: string,
      contactId: string,
      address: string,
      emailAddress: string,
      paymentCurrency: string,
      ccAddresses?: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .initiateAddressEnrollment(orgId, contactId, address, emailAddress, paymentCurrency, ccAddresses, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AddressEnrollmentApi - object-oriented interface
 * @export
 * @class AddressEnrollmentApi
 * @extends {BaseAPI}
 */
export class AddressEnrollmentApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {string} contactId
   * @param {string} address
   * @param {string} amount
   * @param {string} paymentCurrency
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressEnrollmentApi
   */
  public confirmAddressPennyTest(
    orgId: string,
    contactId: string,
    address: string,
    amount: string,
    paymentCurrency: string,
    options?: AxiosRequestConfig
  ) {
    return AddressEnrollmentApiFp(this.configuration)
      .confirmAddressPennyTest(orgId, contactId, address, amount, paymentCurrency, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} contactId
   * @param {string} address
   * @param {string} signature
   * @param {string} signatureData
   * @param {string} paymentCurrency
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressEnrollmentApi
   */
  public confirmAddressSignature(
    orgId: string,
    contactId: string,
    address: string,
    signature: string,
    signatureData: string,
    paymentCurrency: string,
    options?: AxiosRequestConfig
  ) {
    return AddressEnrollmentApiFp(this.configuration)
      .confirmAddressSignature(orgId, contactId, address, signature, signatureData, paymentCurrency, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} address
   * @param {string} contactId
   * @param {string} paymentCurrency
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressEnrollmentApi
   */
  public getAddressStatus(
    orgId: string,
    address: string,
    contactId: string,
    paymentCurrency: string,
    options?: AxiosRequestConfig
  ) {
    return AddressEnrollmentApiFp(this.configuration)
      .getAddressStatus(orgId, address, contactId, paymentCurrency, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} contactId
   * @param {string} address
   * @param {string} paymentCurrency
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressEnrollmentApi
   */
  public getAddressStatusExternal(
    orgId: string,
    contactId: string,
    address: string,
    paymentCurrency: string,
    options?: AxiosRequestConfig
  ) {
    return AddressEnrollmentApiFp(this.configuration)
      .getAddressStatusExternal(orgId, contactId, address, paymentCurrency, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} contactId
   * @param {string} address
   * @param {string} emailAddress
   * @param {string} paymentCurrency
   * @param {Array<string>} [ccAddresses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressEnrollmentApi
   */
  public initiateAddressEnrollment(
    orgId: string,
    contactId: string,
    address: string,
    emailAddress: string,
    paymentCurrency: string,
    ccAddresses?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return AddressEnrollmentApiFp(this.configuration)
      .initiateAddressEnrollment(orgId, contactId, address, emailAddress, paymentCurrency, ccAddresses, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Provides you with an authentication token to access the Bitwave API.
     * @param {string} grantType
     * @param {ApiSvcOAuth2TokenTypes} [requestedTokenType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken: async (
      grantType: string,
      requestedTokenType?: ApiSvcOAuth2TokenTypes,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'grantType' is not null or undefined
      assertParamExists('getToken', 'grantType', grantType);
      const localVarPath = `/v2/oauth/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (grantType !== undefined) {
        localVarQueryParameter['grant_type'] = grantType;
      }

      if (requestedTokenType !== undefined) {
        localVarQueryParameter['requested_token_type'] = requestedTokenType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getTokenV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/oauth/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration);
  return {
    /**
     * Provides you with an authentication token to access the Bitwave API.
     * @param {string} grantType
     * @param {ApiSvcOAuth2TokenTypes} [requestedTokenType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToken(
      grantType: string,
      requestedTokenType?: ApiSvcOAuth2TokenTypes,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetTokenV1200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(grantType, requestedTokenType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getTokenV1(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetTokenV1200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenV1(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthenticationApiFp(configuration);
  return {
    /**
     * Provides you with an authentication token to access the Bitwave API.
     * @param {string} grantType
     * @param {ApiSvcOAuth2TokenTypes} [requestedTokenType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken(
      grantType: string,
      requestedTokenType?: ApiSvcOAuth2TokenTypes,
      options?: any
    ): AxiosPromise<ApiSvcGetTokenV1200Response> {
      return localVarFp.getToken(grantType, requestedTokenType, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getTokenV1(options?: any): AxiosPromise<ApiSvcGetTokenV1200Response> {
      return localVarFp.getTokenV1(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
  /**
   * Provides you with an authentication token to access the Bitwave API.
   * @param {string} grantType
   * @param {ApiSvcOAuth2TokenTypes} [requestedTokenType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public getToken(grantType: string, requestedTokenType?: ApiSvcOAuth2TokenTypes, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .getToken(grantType, requestedTokenType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public getTokenV1(options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .getTokenV1(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateCategoryRequest} apiSvcCreateCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCategory: async (
      orgId: string,
      apiSvcCreateCategoryRequest: ApiSvcCreateCategoryRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createCategory', 'orgId', orgId);
      // verify required parameter 'apiSvcCreateCategoryRequest' is not null or undefined
      assertParamExists('createCategory', 'apiSvcCreateCategoryRequest', apiSvcCreateCategoryRequest);
      const localVarPath = `/org/{orgId}/categories`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCreateCategoryRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategories: async (
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getCategories', 'orgId', orgId);
      const localVarPath = `/org/{orgId}/categories`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateCategoryRequest} apiSvcCreateCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCategory(
      orgId: string,
      apiSvcCreateCategoryRequest: ApiSvcCreateCategoryRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCreateCategory200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCategory(
        orgId,
        apiSvcCreateCategoryRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategories(
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetCategories200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(
        orgId,
        pageLimit,
        paginationToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CategoriesApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateCategoryRequest} apiSvcCreateCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCategory(
      orgId: string,
      apiSvcCreateCategoryRequest: ApiSvcCreateCategoryRequest,
      options?: any
    ): AxiosPromise<ApiSvcCreateCategory200Response> {
      return localVarFp
        .createCategory(orgId, apiSvcCreateCategoryRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategories(
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      options?: any
    ): AxiosPromise<ApiSvcGetCategories200Response> {
      return localVarFp
        .getCategories(orgId, pageLimit, paginationToken, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {ApiSvcCreateCategoryRequest} apiSvcCreateCategoryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public createCategory(
    orgId: string,
    apiSvcCreateCategoryRequest: ApiSvcCreateCategoryRequest,
    options?: AxiosRequestConfig
  ) {
    return CategoriesApiFp(this.configuration)
      .createCategory(orgId, apiSvcCreateCategoryRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {number} [pageLimit]
   * @param {string} [paginationToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public getCategories(orgId: string, pageLimit?: number, paginationToken?: string, options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .getCategories(orgId, pageLimit, paginationToken, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ClientDataApi - axios parameter creator
 * @export
 */
export const ClientDataApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} clientId
     * @param {string} accountId
     * @param {string} transactionId
     * @param {ApiSvcCreateTransferRecord} apiSvcCreateTransferRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClientTransaction: async (
      orgId: string,
      clientId: string,
      accountId: string,
      transactionId: string,
      apiSvcCreateTransferRecord: ApiSvcCreateTransferRecord,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createClientTransaction', 'orgId', orgId);
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists('createClientTransaction', 'clientId', clientId);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('createClientTransaction', 'accountId', accountId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('createClientTransaction', 'transactionId', transactionId);
      // verify required parameter 'apiSvcCreateTransferRecord' is not null or undefined
      assertParamExists('createClientTransaction', 'apiSvcCreateTransferRecord', apiSvcCreateTransferRecord);
      const localVarPath = `/orgs/{orgId}/clients/{clientId}/accounts/{accountId}/transactions/{transactionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'clientId'}}`, encodeURIComponent(String(clientId)))
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCreateTransferRecord,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateGainLossReport: async (
      orgId: string,
      customerId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('generateGainLossReport', 'orgId', orgId);
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists('generateGainLossReport', 'customerId', customerId);
      const localVarPath = `/orgs/{orgId}/customers/{customerId}/reports/gainLossReport`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'customerId'}}`, encodeURIComponent(String(customerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} clientId
     * @param {boolean} [missingCostBasis]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientTransactions: async (
      orgId: string,
      clientId: string,
      missingCostBasis?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientTransactions', 'orgId', orgId);
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists('getClientTransactions', 'clientId', clientId);
      const localVarPath = `/orgs/{orgId}/clients/{clientId}/transactions`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'clientId'}}`, encodeURIComponent(String(clientId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (missingCostBasis !== undefined) {
        localVarQueryParameter['missingCostBasis'] = missingCostBasis;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClients', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/clients`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsV2: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientsV2', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/v2/clients`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} clientOrgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsWallets: async (
      orgId: string,
      clientOrgId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientsWallets', 'orgId', orgId);
      // verify required parameter 'clientOrgId' is not null or undefined
      assertParamExists('getClientsWallets', 'clientOrgId', clientOrgId);
      const localVarPath = `/orgs/{orgId}/clients/{clientOrgId}/wallets`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'clientOrgId'}}`, encodeURIComponent(String(clientOrgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerReports: async (
      orgId: string,
      customerId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getCustomerReports', 'orgId', orgId);
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists('getCustomerReports', 'customerId', customerId);
      const localVarPath = `/orgs/{orgId}/customers/{customerId}/reports`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'customerId'}}`, encodeURIComponent(String(customerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployeesV2: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getEmployeesV2', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/v2/employees`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClientDataApi - functional programming interface
 * @export
 */
export const ClientDataApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClientDataApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} clientId
     * @param {string} accountId
     * @param {string} transactionId
     * @param {ApiSvcCreateTransferRecord} apiSvcCreateTransferRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createClientTransaction(
      orgId: string,
      clientId: string,
      accountId: string,
      transactionId: string,
      apiSvcCreateTransferRecord: ApiSvcCreateTransferRecord,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCreateClientTransaction200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createClientTransaction(
        orgId,
        clientId,
        accountId,
        transactionId,
        apiSvcCreateTransferRecord,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateGainLossReport(
      orgId: string,
      customerId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcReportExecutionTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateGainLossReport(orgId, customerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} clientId
     * @param {boolean} [missingCostBasis]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientTransactions(
      orgId: string,
      clientId: string,
      missingCostBasis?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcTxnLiteRecordResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientTransactions(
        orgId,
        clientId,
        missingCostBasis,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClients(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetClients200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClients(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientsV2(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetClientsV2200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientsV2(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} clientOrgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientsWallets(
      orgId: string,
      clientOrgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiSvcWalletResponseDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientsWallets(orgId, clientOrgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerReports(
      orgId: string,
      customerId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcReportsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerReports(orgId, customerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmployeesV2(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetClientsV2200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeesV2(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ClientDataApi - factory interface
 * @export
 */
export const ClientDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ClientDataApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} clientId
     * @param {string} accountId
     * @param {string} transactionId
     * @param {ApiSvcCreateTransferRecord} apiSvcCreateTransferRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClientTransaction(
      orgId: string,
      clientId: string,
      accountId: string,
      transactionId: string,
      apiSvcCreateTransferRecord: ApiSvcCreateTransferRecord,
      options?: any
    ): AxiosPromise<ApiSvcCreateClientTransaction200Response> {
      return localVarFp
        .createClientTransaction(orgId, clientId, accountId, transactionId, apiSvcCreateTransferRecord, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateGainLossReport(orgId: string, customerId: string, options?: any): AxiosPromise<ApiSvcReportExecutionTask> {
      return localVarFp.generateGainLossReport(orgId, customerId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} clientId
     * @param {boolean} [missingCostBasis]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientTransactions(
      orgId: string,
      clientId: string,
      missingCostBasis?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcTxnLiteRecordResult> {
      return localVarFp
        .getClientTransactions(orgId, clientId, missingCostBasis, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients(orgId: string, options?: any): AxiosPromise<ApiSvcGetClients200Response> {
      return localVarFp.getClients(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsV2(orgId: string, options?: any): AxiosPromise<ApiSvcGetClientsV2200Response> {
      return localVarFp.getClientsV2(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} clientOrgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsWallets(orgId: string, clientOrgId: string, options?: any): AxiosPromise<Array<ApiSvcWalletResponseDTO>> {
      return localVarFp.getClientsWallets(orgId, clientOrgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerReports(orgId: string, customerId: string, options?: any): AxiosPromise<ApiSvcReportsResponse> {
      return localVarFp.getCustomerReports(orgId, customerId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployeesV2(orgId: string, options?: any): AxiosPromise<ApiSvcGetClientsV2200Response> {
      return localVarFp.getEmployeesV2(orgId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ClientDataApi - object-oriented interface
 * @export
 * @class ClientDataApi
 * @extends {BaseAPI}
 */
export class ClientDataApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {string} clientId
   * @param {string} accountId
   * @param {string} transactionId
   * @param {ApiSvcCreateTransferRecord} apiSvcCreateTransferRecord
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientDataApi
   */
  public createClientTransaction(
    orgId: string,
    clientId: string,
    accountId: string,
    transactionId: string,
    apiSvcCreateTransferRecord: ApiSvcCreateTransferRecord,
    options?: AxiosRequestConfig
  ) {
    return ClientDataApiFp(this.configuration)
      .createClientTransaction(orgId, clientId, accountId, transactionId, apiSvcCreateTransferRecord, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} customerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientDataApi
   */
  public generateGainLossReport(orgId: string, customerId: string, options?: AxiosRequestConfig) {
    return ClientDataApiFp(this.configuration)
      .generateGainLossReport(orgId, customerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} clientId
   * @param {boolean} [missingCostBasis]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientDataApi
   */
  public getClientTransactions(
    orgId: string,
    clientId: string,
    missingCostBasis?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ClientDataApiFp(this.configuration)
      .getClientTransactions(orgId, clientId, missingCostBasis, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientDataApi
   */
  public getClients(orgId: string, options?: AxiosRequestConfig) {
    return ClientDataApiFp(this.configuration)
      .getClients(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientDataApi
   */
  public getClientsV2(orgId: string, options?: AxiosRequestConfig) {
    return ClientDataApiFp(this.configuration)
      .getClientsV2(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} clientOrgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientDataApi
   */
  public getClientsWallets(orgId: string, clientOrgId: string, options?: AxiosRequestConfig) {
    return ClientDataApiFp(this.configuration)
      .getClientsWallets(orgId, clientOrgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} customerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientDataApi
   */
  public getCustomerReports(orgId: string, customerId: string, options?: AxiosRequestConfig) {
    return ClientDataApiFp(this.configuration)
      .getCustomerReports(orgId, customerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientDataApi
   */
  public getEmployeesV2(orgId: string, options?: AxiosRequestConfig) {
    return ClientDataApiFp(this.configuration)
      .getEmployeesV2(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ConnectionsApi - axios parameter creator
 * @export
 */
export const ConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns information regarding a particular connection for your Bitwave organization.
     * @param {string} orgId
     * @param {string} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnection: async (
      orgId: string,
      connectionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getConnection', 'orgId', orgId);
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists('getConnection', 'connectionId', connectionId);
      const localVarPath = `/orgs/{orgId}/connections/{connectionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'connectionId'}}`, encodeURIComponent(String(connectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all connections associated with an organization.
     * @param {string} orgId
     * @param {ApiSvcConnectionType} [type]
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {boolean} [overrideCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnections: async (
      orgId: string,
      type?: ApiSvcConnectionType,
      pageLimit?: number,
      paginationToken?: string,
      overrideCache?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getConnections', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/connections`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (overrideCache !== undefined) {
        localVarQueryParameter['overrideCache'] = overrideCache;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} connectionId
     * @param {string} metadataId
     * @param {ApiSvcUpdateNetsuiteWalletCustomRecord} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConnectionMetadata: async (
      orgId: string,
      connectionId: string,
      metadataId: string,
      body: ApiSvcUpdateNetsuiteWalletCustomRecord,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateConnectionMetadata', 'orgId', orgId);
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists('updateConnectionMetadata', 'connectionId', connectionId);
      // verify required parameter 'metadataId' is not null or undefined
      assertParamExists('updateConnectionMetadata', 'metadataId', metadataId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateConnectionMetadata', 'body', body);
      const localVarPath = `/orgs/{orgId}/connection/{connectionId}/metadata/{metadataId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'connectionId'}}`, encodeURIComponent(String(connectionId)))
        .replace(`{${'metadataId'}}`, encodeURIComponent(String(metadataId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} connectionId
     * @param {ApiSvcUpdateMetadataFromRemoteSourceDTO} apiSvcUpdateMetadataFromRemoteSourceDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConnectionMetadataFromRemoteSource: async (
      orgId: string,
      connectionId: string,
      apiSvcUpdateMetadataFromRemoteSourceDTO: ApiSvcUpdateMetadataFromRemoteSourceDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateConnectionMetadataFromRemoteSource', 'orgId', orgId);
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists('updateConnectionMetadataFromRemoteSource', 'connectionId', connectionId);
      // verify required parameter 'apiSvcUpdateMetadataFromRemoteSourceDTO' is not null or undefined
      assertParamExists(
        'updateConnectionMetadataFromRemoteSource',
        'apiSvcUpdateMetadataFromRemoteSourceDTO',
        apiSvcUpdateMetadataFromRemoteSourceDTO
      );
      const localVarPath = `/orgs/{orgId}/connection/{connectionId}/metadata`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'connectionId'}}`, encodeURIComponent(String(connectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcUpdateMetadataFromRemoteSourceDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} connectionId
     * @param {ApiSvcPatchConnectionRequest} apiSvcPatchConnectionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePartial: async (
      orgId: string,
      connectionId: string,
      apiSvcPatchConnectionRequest: ApiSvcPatchConnectionRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updatePartial', 'orgId', orgId);
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists('updatePartial', 'connectionId', connectionId);
      // verify required parameter 'apiSvcPatchConnectionRequest' is not null or undefined
      assertParamExists('updatePartial', 'apiSvcPatchConnectionRequest', apiSvcPatchConnectionRequest);
      const localVarPath = `/orgs/{orgId}/connections/{connectionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'connectionId'}}`, encodeURIComponent(String(connectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcPatchConnectionRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateConnectionDTO} apiSvcCreateConnectionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateConnection: async (
      orgId: string,
      apiSvcCreateConnectionDTO: ApiSvcCreateConnectionDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('validateConnection', 'orgId', orgId);
      // verify required parameter 'apiSvcCreateConnectionDTO' is not null or undefined
      assertParamExists('validateConnection', 'apiSvcCreateConnectionDTO', apiSvcCreateConnectionDTO);
      const localVarPath = `/orgs/{orgId}/connections/validate`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCreateConnectionDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ConnectionsApi - functional programming interface
 * @export
 */
export const ConnectionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ConnectionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns information regarding a particular connection for your Bitwave organization.
     * @param {string} orgId
     * @param {string} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConnection(
      orgId: string,
      connectionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetConnection200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConnection(orgId, connectionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all connections associated with an organization.
     * @param {string} orgId
     * @param {ApiSvcConnectionType} [type]
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {boolean} [overrideCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConnections(
      orgId: string,
      type?: ApiSvcConnectionType,
      pageLimit?: number,
      paginationToken?: string,
      overrideCache?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetConnections200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConnections(
        orgId,
        type,
        pageLimit,
        paginationToken,
        overrideCache,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} connectionId
     * @param {string} metadataId
     * @param {ApiSvcUpdateNetsuiteWalletCustomRecord} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateConnectionMetadata(
      orgId: string,
      connectionId: string,
      metadataId: string,
      body: ApiSvcUpdateNetsuiteWalletCustomRecord,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionMetadata(
        orgId,
        connectionId,
        metadataId,
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} connectionId
     * @param {ApiSvcUpdateMetadataFromRemoteSourceDTO} apiSvcUpdateMetadataFromRemoteSourceDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateConnectionMetadataFromRemoteSource(
      orgId: string,
      connectionId: string,
      apiSvcUpdateMetadataFromRemoteSourceDTO: ApiSvcUpdateMetadataFromRemoteSourceDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiSvcMetadataRecordDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionMetadataFromRemoteSource(
        orgId,
        connectionId,
        apiSvcUpdateMetadataFromRemoteSourceDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} connectionId
     * @param {ApiSvcPatchConnectionRequest} apiSvcPatchConnectionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePartial(
      orgId: string,
      connectionId: string,
      apiSvcPatchConnectionRequest: ApiSvcPatchConnectionRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartial(
        orgId,
        connectionId,
        apiSvcPatchConnectionRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateConnectionDTO} apiSvcCreateConnectionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateConnection(
      orgId: string,
      apiSvcCreateConnectionDTO: ApiSvcCreateConnectionDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcAccountValidationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateConnection(
        orgId,
        apiSvcCreateConnectionDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ConnectionsApi - factory interface
 * @export
 */
export const ConnectionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ConnectionsApiFp(configuration);
  return {
    /**
     * Returns information regarding a particular connection for your Bitwave organization.
     * @param {string} orgId
     * @param {string} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnection(orgId: string, connectionId: string, options?: any): AxiosPromise<ApiSvcGetConnection200Response> {
      return localVarFp.getConnection(orgId, connectionId, options).then((request) => request(axios, basePath));
    },
    /**
     * Returns all connections associated with an organization.
     * @param {string} orgId
     * @param {ApiSvcConnectionType} [type]
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {boolean} [overrideCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnections(
      orgId: string,
      type?: ApiSvcConnectionType,
      pageLimit?: number,
      paginationToken?: string,
      overrideCache?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcGetConnections200Response> {
      return localVarFp
        .getConnections(orgId, type, pageLimit, paginationToken, overrideCache, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} connectionId
     * @param {string} metadataId
     * @param {ApiSvcUpdateNetsuiteWalletCustomRecord} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConnectionMetadata(
      orgId: string,
      connectionId: string,
      metadataId: string,
      body: ApiSvcUpdateNetsuiteWalletCustomRecord,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateConnectionMetadata(orgId, connectionId, metadataId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} connectionId
     * @param {ApiSvcUpdateMetadataFromRemoteSourceDTO} apiSvcUpdateMetadataFromRemoteSourceDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConnectionMetadataFromRemoteSource(
      orgId: string,
      connectionId: string,
      apiSvcUpdateMetadataFromRemoteSourceDTO: ApiSvcUpdateMetadataFromRemoteSourceDTO,
      options?: any
    ): AxiosPromise<Array<ApiSvcMetadataRecordDTO>> {
      return localVarFp
        .updateConnectionMetadataFromRemoteSource(orgId, connectionId, apiSvcUpdateMetadataFromRemoteSourceDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} connectionId
     * @param {ApiSvcPatchConnectionRequest} apiSvcPatchConnectionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePartial(
      orgId: string,
      connectionId: string,
      apiSvcPatchConnectionRequest: ApiSvcPatchConnectionRequest,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .updatePartial(orgId, connectionId, apiSvcPatchConnectionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateConnectionDTO} apiSvcCreateConnectionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateConnection(
      orgId: string,
      apiSvcCreateConnectionDTO: ApiSvcCreateConnectionDTO,
      options?: any
    ): AxiosPromise<ApiSvcAccountValidationResponse> {
      return localVarFp
        .validateConnection(orgId, apiSvcCreateConnectionDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ConnectionsApi - object-oriented interface
 * @export
 * @class ConnectionsApi
 * @extends {BaseAPI}
 */
export class ConnectionsApi extends BaseAPI {
  /**
   * Returns information regarding a particular connection for your Bitwave organization.
   * @param {string} orgId
   * @param {string} connectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public getConnection(orgId: string, connectionId: string, options?: AxiosRequestConfig) {
    return ConnectionsApiFp(this.configuration)
      .getConnection(orgId, connectionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all connections associated with an organization.
   * @param {string} orgId
   * @param {ApiSvcConnectionType} [type]
   * @param {number} [pageLimit]
   * @param {string} [paginationToken]
   * @param {boolean} [overrideCache]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public getConnections(
    orgId: string,
    type?: ApiSvcConnectionType,
    pageLimit?: number,
    paginationToken?: string,
    overrideCache?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .getConnections(orgId, type, pageLimit, paginationToken, overrideCache, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} connectionId
   * @param {string} metadataId
   * @param {ApiSvcUpdateNetsuiteWalletCustomRecord} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public updateConnectionMetadata(
    orgId: string,
    connectionId: string,
    metadataId: string,
    body: ApiSvcUpdateNetsuiteWalletCustomRecord,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .updateConnectionMetadata(orgId, connectionId, metadataId, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} connectionId
   * @param {ApiSvcUpdateMetadataFromRemoteSourceDTO} apiSvcUpdateMetadataFromRemoteSourceDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public updateConnectionMetadataFromRemoteSource(
    orgId: string,
    connectionId: string,
    apiSvcUpdateMetadataFromRemoteSourceDTO: ApiSvcUpdateMetadataFromRemoteSourceDTO,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .updateConnectionMetadataFromRemoteSource(orgId, connectionId, apiSvcUpdateMetadataFromRemoteSourceDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} connectionId
   * @param {ApiSvcPatchConnectionRequest} apiSvcPatchConnectionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public updatePartial(
    orgId: string,
    connectionId: string,
    apiSvcPatchConnectionRequest: ApiSvcPatchConnectionRequest,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .updatePartial(orgId, connectionId, apiSvcPatchConnectionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcCreateConnectionDTO} apiSvcCreateConnectionDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectionsApi
   */
  public validateConnection(
    orgId: string,
    apiSvcCreateConnectionDTO: ApiSvcCreateConnectionDTO,
    options?: AxiosRequestConfig
  ) {
    return ConnectionsApiFp(this.configuration)
      .validateConnection(orgId, apiSvcCreateConnectionDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns all the contacts for an organization within Bitwave. Contacts can either be created manually within Bitwave or imported from your ERP software via Bitwave integration.
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContacts: async (
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getContacts', 'orgId', orgId);
      const localVarPath = `/contacts/{orgId}`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns all the contacts for an organization within Bitwave. Contacts can either be created manually within Bitwave or imported from your ERP software via Bitwave integration.
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContacts(
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetContacts200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContacts(orgId, pageLimit, paginationToken, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ContactsApiFp(configuration);
  return {
    /**
     * Returns all the contacts for an organization within Bitwave. Contacts can either be created manually within Bitwave or imported from your ERP software via Bitwave integration.
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContacts(
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      options?: any
    ): AxiosPromise<ApiSvcGetContacts200Response> {
      return localVarFp
        .getContacts(orgId, pageLimit, paginationToken, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
  /**
   * Returns all the contacts for an organization within Bitwave. Contacts can either be created manually within Bitwave or imported from your ERP software via Bitwave integration.
   * @param {string} orgId
   * @param {number} [pageLimit]
   * @param {string} [paginationToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getContacts(orgId: string, pageLimit?: number, paginationToken?: string, options?: AxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .getContacts(orgId, pageLimit, paginationToken, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcVarianceReportCreationParams} apiSvcVarianceReportCreationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVarianceReport: async (
      orgId: string,
      apiSvcVarianceReportCreationParams: ApiSvcVarianceReportCreationParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createVarianceReport', 'orgId', orgId);
      // verify required parameter 'apiSvcVarianceReportCreationParams' is not null or undefined
      assertParamExists(
        'createVarianceReport',
        'apiSvcVarianceReportCreationParams',
        apiSvcVarianceReportCreationParams
      );
      const localVarPath = `/orgs/{orgId}/variance`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcVarianceReportCreationParams,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcVarianceReportCreationParams} apiSvcVarianceReportCreationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createVarianceReport(
      orgId: string,
      apiSvcVarianceReportCreationParams: ApiSvcVarianceReportCreationParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCreateVarianceReport200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createVarianceReport(
        orgId,
        apiSvcVarianceReportCreationParams,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcVarianceReportCreationParams} apiSvcVarianceReportCreationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVarianceReport(
      orgId: string,
      apiSvcVarianceReportCreationParams: ApiSvcVarianceReportCreationParams,
      options?: any
    ): AxiosPromise<ApiSvcCreateVarianceReport200Response> {
      return localVarFp
        .createVarianceReport(orgId, apiSvcVarianceReportCreationParams, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {ApiSvcVarianceReportCreationParams} apiSvcVarianceReportCreationParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createVarianceReport(
    orgId: string,
    apiSvcVarianceReportCreationParams: ApiSvcVarianceReportCreationParams,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .createVarianceReport(orgId, apiSvcVarianceReportCreationParams, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} exportId
     * @param {boolean} rawUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExport: async (
      orgId: string,
      exportId: string,
      rawUrl: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getExport', 'orgId', orgId);
      // verify required parameter 'exportId' is not null or undefined
      assertParamExists('getExport', 'exportId', exportId);
      // verify required parameter 'rawUrl' is not null or undefined
      assertParamExists('getExport', 'rawUrl', rawUrl);
      const localVarPath = `/v2/orgs/{orgId}/exports/{exportId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'exportId'}}`, encodeURIComponent(String(exportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (rawUrl !== undefined) {
        localVarQueryParameter['rawUrl'] = rawUrl;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} exportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportPost: async (orgId: string, exportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getExportPost', 'orgId', orgId);
      // verify required parameter 'exportId' is not null or undefined
      assertParamExists('getExportPost', 'exportId', exportId);
      const localVarPath = `/v2/orgs/{orgId}/exports/{exportId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'exportId'}}`, encodeURIComponent(String(exportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listExports: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('listExports', 'orgId', orgId);
      const localVarPath = `/v2/orgs/{orgId}/exports`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportParams} apiSvcRunReportParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runExport: async (
      orgId: string,
      apiSvcRunReportParams: ApiSvcRunReportParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('runExport', 'orgId', orgId);
      // verify required parameter 'apiSvcRunReportParams' is not null or undefined
      assertParamExists('runExport', 'apiSvcRunReportParams', apiSvcRunReportParams);
      const localVarPath = `/v2/orgs/{orgId}/exports`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(apiSvcRunReportParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} exportId
     * @param {boolean} rawUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExport(
      orgId: string,
      exportId: string,
      rawUrl: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExport(orgId, exportId, rawUrl, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} exportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExportPost(
      orgId: string,
      exportId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExportPost(orgId, exportId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listExports(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listExports(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportParams} apiSvcRunReportParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runExport(
      orgId: string,
      apiSvcRunReportParams: ApiSvcRunReportParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runExport(orgId, apiSvcRunReportParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ExportApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} exportId
     * @param {boolean} rawUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExport(orgId: string, exportId: string, rawUrl: boolean, options?: any): AxiosPromise<any> {
      return localVarFp.getExport(orgId, exportId, rawUrl, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} exportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportPost(orgId: string, exportId: string, options?: any): AxiosPromise<any> {
      return localVarFp.getExportPost(orgId, exportId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listExports(orgId: string, options?: any): AxiosPromise<any> {
      return localVarFp.listExports(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportParams} apiSvcRunReportParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runExport(orgId: string, apiSvcRunReportParams: ApiSvcRunReportParams, options?: any): AxiosPromise<any> {
      return localVarFp.runExport(orgId, apiSvcRunReportParams, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {string} exportId
   * @param {boolean} rawUrl
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public getExport(orgId: string, exportId: string, rawUrl: boolean, options?: AxiosRequestConfig) {
    return ExportApiFp(this.configuration)
      .getExport(orgId, exportId, rawUrl, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} exportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public getExportPost(orgId: string, exportId: string, options?: AxiosRequestConfig) {
    return ExportApiFp(this.configuration)
      .getExportPost(orgId, exportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public listExports(orgId: string, options?: AxiosRequestConfig) {
    return ExportApiFp(this.configuration)
      .listExports(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRunReportParams} apiSvcRunReportParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public runExport(orgId: string, apiSvcRunReportParams: ApiSvcRunReportParams, options?: AxiosRequestConfig) {
    return ExportApiFp(this.configuration)
      .runExport(orgId, apiSvcRunReportParams, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InventoryApi - axios parameter creator
 * @export
 */
export const InventoryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} inventoryViewUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelViewUpdate: async (
      orgId: string,
      inventoryViewId: string,
      inventoryViewUpdateId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('cancelViewUpdate', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('cancelViewUpdate', 'inventoryViewId', inventoryViewId);
      // verify required parameter 'inventoryViewUpdateId' is not null or undefined
      assertParamExists('cancelViewUpdate', 'inventoryViewUpdateId', inventoryViewUpdateId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/{inventoryViewUpdateId}/cancel`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)))
        .replace(`{${'inventoryViewUpdateId'}}`, encodeURIComponent(String(inventoryViewUpdateId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateInventoryViewRequest} apiSvcCreateInventoryViewRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createView: async (
      orgId: string,
      apiSvcCreateInventoryViewRequest: ApiSvcCreateInventoryViewRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createView', 'orgId', orgId);
      // verify required parameter 'apiSvcCreateInventoryViewRequest' is not null or undefined
      assertParamExists('createView', 'apiSvcCreateInventoryViewRequest', apiSvcCreateInventoryViewRequest);
      const localVarPath = `/orgs/{orgId}/inventory-views`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCreateInventoryViewRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteView: async (
      orgId: string,
      inventoryViewId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('deleteView', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('deleteView', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} colId
     * @param {boolean} showEmptyLots
     * @param {string} [asOf]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActionColumnUniqueValues: async (
      orgId: string,
      inventoryViewId: string,
      colId: string,
      showEmptyLots: boolean,
      asOf?: string,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getActionColumnUniqueValues', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getActionColumnUniqueValues', 'inventoryViewId', inventoryViewId);
      // verify required parameter 'colId' is not null or undefined
      assertParamExists('getActionColumnUniqueValues', 'colId', colId);
      // verify required parameter 'showEmptyLots' is not null or undefined
      assertParamExists('getActionColumnUniqueValues', 'showEmptyLots', showEmptyLots);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/actions/columns/{colId}/unique`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)))
        .replace(`{${'colId'}}`, encodeURIComponent(String(colId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (showEmptyLots !== undefined) {
        localVarQueryParameter['showEmptyLots'] = showEmptyLots;
      }

      if (asOf !== undefined) {
        localVarQueryParameter['asOf'] = asOf;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActionColumns: async (
      orgId: string,
      inventoryViewId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getActionColumns', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getActionColumns', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/actions/columns`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActionsJeReport: async (
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getActionsJeReport', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getActionsJeReport', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/actions-je-report`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActionsTrialBalanceReport: async (
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getActionsTrialBalanceReport', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getActionsTrialBalanceReport', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/actions-tb-report`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdvancedActionsReport: async (
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAdvancedActionsReport', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getAdvancedActionsReport', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/advanced-action-report`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [useInventory]
     * @param {boolean} [useWallet]
     * @param {boolean} [useLot]
     * @param {boolean} [exportResults]
     * @param {boolean} [includeFmv]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdvancedCostBasisRollForward: async (
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      useInventory?: boolean,
      useWallet?: boolean,
      useLot?: boolean,
      exportResults?: boolean,
      includeFmv?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAdvancedCostBasisRollForward', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getAdvancedCostBasisRollForward', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/advanced-cost-basis-roll-forward`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (useInventory !== undefined) {
        localVarQueryParameter['useInventory'] = useInventory;
      }

      if (useWallet !== undefined) {
        localVarQueryParameter['useWallet'] = useWallet;
      }

      if (useLot !== undefined) {
        localVarQueryParameter['useLot'] = useLot;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      if (includeFmv !== undefined) {
        localVarQueryParameter['includeFmv'] = includeFmv;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryBalanceCheckReport: async (
      orgId: string,
      endDate?: string,
      exportResults?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getInventoryBalanceCheckReport', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/inventory-views/reports/balance-check-report`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {number} [pageSize]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryBalanceCheckReportPaginated: async (
      orgId: string,
      endDate?: string,
      exportResults?: boolean,
      pageSize?: number,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getInventoryBalanceCheckReportPaginated', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/inventory-views/reports/balance-check-report-paginated`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {boolean} [includeFmv]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryCostBasisRollForward: async (
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      includeFmv?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getInventoryCostBasisRollForward', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getInventoryCostBasisRollForward', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/cost-basis-roll-forward`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      if (includeFmv !== undefined) {
        localVarQueryParameter['includeFmv'] = includeFmv;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {Array<string>} [inventory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryEnhancedGainLoss: async (
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      inventory?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getInventoryEnhancedGainLoss', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getInventoryEnhancedGainLoss', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/enhanced-gain-loss`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      if (inventory) {
        localVarQueryParameter['inventory'] = inventory;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryJeReclass: async (
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getInventoryJeReclass', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getInventoryJeReclass', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/je-reclass`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryRows: async (
      orgId: string,
      inventoryViewId: string,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getInventoryRows', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getInventoryRows', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/rows`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} colId
     * @param {boolean} showEmptyLots
     * @param {string} [asOf]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLotColumnUniqueValues: async (
      orgId: string,
      inventoryViewId: string,
      colId: string,
      showEmptyLots: boolean,
      asOf?: string,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getLotColumnUniqueValues', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getLotColumnUniqueValues', 'inventoryViewId', inventoryViewId);
      // verify required parameter 'colId' is not null or undefined
      assertParamExists('getLotColumnUniqueValues', 'colId', colId);
      // verify required parameter 'showEmptyLots' is not null or undefined
      assertParamExists('getLotColumnUniqueValues', 'showEmptyLots', showEmptyLots);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/lots/columns/{colId}/unique`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)))
        .replace(`{${'colId'}}`, encodeURIComponent(String(colId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (showEmptyLots !== undefined) {
        localVarQueryParameter['showEmptyLots'] = showEmptyLots;
      }

      if (asOf !== undefined) {
        localVarQueryParameter['asOf'] = asOf;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLotColumns: async (
      orgId: string,
      inventoryViewId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getLotColumns', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getLotColumns', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/lots/columns`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRippleInventoryCostBasisRollForward: async (
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getRippleInventoryCostBasisRollForward', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getRippleInventoryCostBasisRollForward', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/ripple-cost-basis-roll-forward`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSageErpJeReport: async (
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getSageErpJeReport', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getSageErpJeReport', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/sage-erp-je-report`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSodaReport: async (
      orgId: string,
      inventoryViewId: string,
      endDate?: string,
      exportResults?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getSodaReport', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getSodaReport', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/reports/soda-report`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} inventoryViewUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpdateDownloadLinks: async (
      orgId: string,
      inventoryViewId: string,
      inventoryViewUpdateId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getUpdateDownloadLinks', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getUpdateDownloadLinks', 'inventoryViewId', inventoryViewId);
      // verify required parameter 'inventoryViewUpdateId' is not null or undefined
      assertParamExists('getUpdateDownloadLinks', 'inventoryViewUpdateId', inventoryViewUpdateId);
      const localVarPath =
        `/orgs/{orgId}/inventory-views/{inventoryViewId}/updates/{inventoryViewUpdateId}/download-links`
          .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
          .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)))
          .replace(`{${'inventoryViewUpdateId'}}`, encodeURIComponent(String(inventoryViewUpdateId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getView: async (orgId: string, inventoryViewId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getView', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getView', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {string} [pageToken]
     * @param {Array<string>} [runId]
     * @param {Array<number>} [timestampSEC]
     * @param {Array<string>} [action]
     * @param {Array<string>} [status]
     * @param {Array<string>} [txnId]
     * @param {Array<string>} [eventId]
     * @param {Array<string>} [lotId]
     * @param {Array<string>} [asset]
     * @param {Array<string>} [assetId]
     * @param {Array<string>} [assetUnitAdj]
     * @param {Array<string>} [assetBalance]
     * @param {Array<number>} [lotAcquisitionTimestampSEC]
     * @param {Array<string>} [txnExchangeRate]
     * @param {Array<string>} [carryingValue]
     * @param {Array<string>} [impairmentExpense]
     * @param {Array<string>} [fairMarketValueDisposed]
     * @param {Array<string>} [shortTermGainLoss]
     * @param {Array<string>} [longTermGainLoss]
     * @param {Array<string>} [undatedGainLoss]
     * @param {Array<string>} [costBasisAcquired]
     * @param {Array<string>} [costBasisRelieved]
     * @param {Array<string>} [costAverageRate]
     * @param {Array<boolean>} [isTrade]
     * @param {Array<string>} [lineError]
     * @param {string} [sortField]
     * @param {ApiSvcSortDirection} [sortDirection]
     * @param {boolean} [exportResults]
     * @param {Array<string>} [inventory]
     * @param {Array<string>} [wallet]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewActions: async (
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      pageToken?: string,
      runId?: Array<string>,
      timestampSEC?: Array<number>,
      action?: Array<string>,
      status?: Array<string>,
      txnId?: Array<string>,
      eventId?: Array<string>,
      lotId?: Array<string>,
      asset?: Array<string>,
      assetId?: Array<string>,
      assetUnitAdj?: Array<string>,
      assetBalance?: Array<string>,
      lotAcquisitionTimestampSEC?: Array<number>,
      txnExchangeRate?: Array<string>,
      carryingValue?: Array<string>,
      impairmentExpense?: Array<string>,
      fairMarketValueDisposed?: Array<string>,
      shortTermGainLoss?: Array<string>,
      longTermGainLoss?: Array<string>,
      undatedGainLoss?: Array<string>,
      costBasisAcquired?: Array<string>,
      costBasisRelieved?: Array<string>,
      costAverageRate?: Array<string>,
      isTrade?: Array<boolean>,
      lineError?: Array<string>,
      sortField?: string,
      sortDirection?: ApiSvcSortDirection,
      exportResults?: boolean,
      inventory?: Array<string>,
      wallet?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getViewActions', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getViewActions', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/actions`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (asOf !== undefined) {
        localVarQueryParameter['asOf'] = asOf;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (runId) {
        localVarQueryParameter['runId'] = runId;
      }

      if (timestampSEC) {
        localVarQueryParameter['timestampSEC'] = timestampSEC;
      }

      if (action) {
        localVarQueryParameter['action'] = action;
      }

      if (status) {
        localVarQueryParameter['status'] = status;
      }

      if (txnId) {
        localVarQueryParameter['txnId'] = txnId;
      }

      if (eventId) {
        localVarQueryParameter['eventId'] = eventId;
      }

      if (lotId) {
        localVarQueryParameter['lotId'] = lotId;
      }

      if (asset) {
        localVarQueryParameter['asset'] = asset;
      }

      if (assetId) {
        localVarQueryParameter['assetId'] = assetId;
      }

      if (assetUnitAdj) {
        localVarQueryParameter['assetUnitAdj'] = assetUnitAdj;
      }

      if (assetBalance) {
        localVarQueryParameter['assetBalance'] = assetBalance;
      }

      if (lotAcquisitionTimestampSEC) {
        localVarQueryParameter['lotAcquisitionTimestampSEC'] = lotAcquisitionTimestampSEC;
      }

      if (txnExchangeRate) {
        localVarQueryParameter['txnExchangeRate'] = txnExchangeRate;
      }

      if (carryingValue) {
        localVarQueryParameter['carryingValue'] = carryingValue;
      }

      if (impairmentExpense) {
        localVarQueryParameter['impairmentExpense'] = impairmentExpense;
      }

      if (fairMarketValueDisposed) {
        localVarQueryParameter['fairMarketValueDisposed'] = fairMarketValueDisposed;
      }

      if (shortTermGainLoss) {
        localVarQueryParameter['shortTermGainLoss'] = shortTermGainLoss;
      }

      if (longTermGainLoss) {
        localVarQueryParameter['longTermGainLoss'] = longTermGainLoss;
      }

      if (undatedGainLoss) {
        localVarQueryParameter['undatedGainLoss'] = undatedGainLoss;
      }

      if (costBasisAcquired) {
        localVarQueryParameter['costBasisAcquired'] = costBasisAcquired;
      }

      if (costBasisRelieved) {
        localVarQueryParameter['costBasisRelieved'] = costBasisRelieved;
      }

      if (costAverageRate) {
        localVarQueryParameter['costAverageRate'] = costAverageRate;
      }

      if (isTrade) {
        localVarQueryParameter['isTrade'] = isTrade;
      }

      if (lineError) {
        localVarQueryParameter['lineError'] = lineError;
      }

      if (sortField !== undefined) {
        localVarQueryParameter['sort_field'] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter['sort_direction'] = sortDirection;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      if (inventory) {
        localVarQueryParameter['inventory'] = inventory;
      }

      if (wallet) {
        localVarQueryParameter['wallet'] = wallet;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {boolean} [groupByInventory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewBalance: async (
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      groupByInventory?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getViewBalance', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getViewBalance', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/balance`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (asOf !== undefined) {
        localVarQueryParameter['asOf'] = asOf;
      }

      if (groupByInventory !== undefined) {
        localVarQueryParameter['groupByInventory'] = groupByInventory;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewCounts: async (
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getViewCounts', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getViewCounts', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/counts`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (asOf !== undefined) {
        localVarQueryParameter['asOf'] = asOf;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {boolean} [showEmptyLots]
     * @param {string} [pageToken]
     * @param {Array<number>} [timestampSEC]
     * @param {Array<string>} [lotId]
     * @param {Array<string>} [asset]
     * @param {Array<number>} [qty]
     * @param {Array<number>} [unitsAcquired]
     * @param {Array<number>} [unitsDisposed]
     * @param {Array<number>} [costBasisAcquired]
     * @param {Array<number>} [costBasisRelieved]
     * @param {Array<number>} [impairmentExpense]
     * @param {Array<number>} [costBasis]
     * @param {Array<number>} [carryingValue]
     * @param {string} [sortField]
     * @param {ApiSvcSortDirection} [sortDirection]
     * @param {boolean} [exportResults]
     * @param {Array<string>} [inventory]
     * @param {boolean} [groupByInventory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewLots: async (
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      showEmptyLots?: boolean,
      pageToken?: string,
      timestampSEC?: Array<number>,
      lotId?: Array<string>,
      asset?: Array<string>,
      qty?: Array<number>,
      unitsAcquired?: Array<number>,
      unitsDisposed?: Array<number>,
      costBasisAcquired?: Array<number>,
      costBasisRelieved?: Array<number>,
      impairmentExpense?: Array<number>,
      costBasis?: Array<number>,
      carryingValue?: Array<number>,
      sortField?: string,
      sortDirection?: ApiSvcSortDirection,
      exportResults?: boolean,
      inventory?: Array<string>,
      groupByInventory?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getViewLots', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getViewLots', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/lots`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (asOf !== undefined) {
        localVarQueryParameter['asOf'] = asOf;
      }

      if (showEmptyLots !== undefined) {
        localVarQueryParameter['showEmptyLots'] = showEmptyLots;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (timestampSEC) {
        localVarQueryParameter['timestampSEC'] = timestampSEC;
      }

      if (lotId) {
        localVarQueryParameter['lotId'] = lotId;
      }

      if (asset) {
        localVarQueryParameter['asset'] = asset;
      }

      if (qty) {
        localVarQueryParameter['qty'] = qty;
      }

      if (unitsAcquired) {
        localVarQueryParameter['unitsAcquired'] = unitsAcquired;
      }

      if (unitsDisposed) {
        localVarQueryParameter['unitsDisposed'] = unitsDisposed;
      }

      if (costBasisAcquired) {
        localVarQueryParameter['costBasisAcquired'] = costBasisAcquired;
      }

      if (costBasisRelieved) {
        localVarQueryParameter['costBasisRelieved'] = costBasisRelieved;
      }

      if (impairmentExpense) {
        localVarQueryParameter['impairmentExpense'] = impairmentExpense;
      }

      if (costBasis) {
        localVarQueryParameter['costBasis'] = costBasis;
      }

      if (carryingValue) {
        localVarQueryParameter['carryingValue'] = carryingValue;
      }

      if (sortField !== undefined) {
        localVarQueryParameter['sort_field'] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter['sort_direction'] = sortDirection;
      }

      if (exportResults !== undefined) {
        localVarQueryParameter['exportResults'] = exportResults;
      }

      if (inventory) {
        localVarQueryParameter['inventory'] = inventory;
      }

      if (groupByInventory !== undefined) {
        localVarQueryParameter['groupByInventory'] = groupByInventory;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewUpdates: async (
      orgId: string,
      inventoryViewId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getViewUpdates', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getViewUpdates', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/updates`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViews: async (orgId: string, pageToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getViews', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/inventory-views`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} startDate
     * @param {string} endDate
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsGainLossSummary: async (
      orgId: string,
      inventoryViewId: string,
      startDate: string,
      endDate: string,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getsGainLossSummary', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('getsGainLossSummary', 'inventoryViewId', inventoryViewId);
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists('getsGainLossSummary', 'startDate', startDate);
      // verify required parameter 'endDate' is not null or undefined
      assertParamExists('getsGainLossSummary', 'endDate', endDate);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/gain-loss-summary`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerViewUpdate: async (
      orgId: string,
      inventoryViewId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('triggerViewUpdate', 'orgId', orgId);
      // verify required parameter 'inventoryViewId' is not null or undefined
      assertParamExists('triggerViewUpdate', 'inventoryViewId', inventoryViewId);
      const localVarPath = `/orgs/{orgId}/inventory-views/{inventoryViewId}/updates`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'inventoryViewId'}}`, encodeURIComponent(String(inventoryViewId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InventoryApi - functional programming interface
 * @export
 */
export const InventoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InventoryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} inventoryViewUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelViewUpdate(
      orgId: string,
      inventoryViewId: string,
      inventoryViewUpdateId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCancelResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelViewUpdate(
        orgId,
        inventoryViewId,
        inventoryViewUpdateId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateInventoryViewRequest} apiSvcCreateInventoryViewRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createView(
      orgId: string,
      apiSvcCreateInventoryViewRequest: ApiSvcCreateInventoryViewRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCreateResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createView(
        orgId,
        apiSvcCreateInventoryViewRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteView(
      orgId: string,
      inventoryViewId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteView(orgId, inventoryViewId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} colId
     * @param {boolean} showEmptyLots
     * @param {string} [asOf]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActionColumnUniqueValues(
      orgId: string,
      inventoryViewId: string,
      colId: string,
      showEmptyLots: boolean,
      asOf?: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcColumnUniqueValueResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActionColumnUniqueValues(
        orgId,
        inventoryViewId,
        colId,
        showEmptyLots,
        asOf,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActionColumns(
      orgId: string,
      inventoryViewId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActionColumns(orgId, inventoryViewId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActionsJeReport(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCsvExportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActionsJeReport(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActionsTrialBalanceReport(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCsvExportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActionsTrialBalanceReport(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdvancedActionsReport(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCsvExportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvancedActionsReport(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [useInventory]
     * @param {boolean} [useWallet]
     * @param {boolean} [useLot]
     * @param {boolean} [exportResults]
     * @param {boolean} [includeFmv]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdvancedCostBasisRollForward(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      useInventory?: boolean,
      useWallet?: boolean,
      useLot?: boolean,
      exportResults?: boolean,
      includeFmv?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcAdvancedCostBasisRollForwardResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvancedCostBasisRollForward(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        useInventory,
        useWallet,
        useLot,
        exportResults,
        includeFmv,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInventoryBalanceCheckReport(
      orgId: string,
      endDate?: string,
      exportResults?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetInventoryBalanceCheckReport200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryBalanceCheckReport(
        orgId,
        endDate,
        exportResults,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {number} [pageSize]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInventoryBalanceCheckReportPaginated(
      orgId: string,
      endDate?: string,
      exportResults?: boolean,
      pageSize?: number,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryBalanceCheckReportResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryBalanceCheckReportPaginated(
        orgId,
        endDate,
        exportResults,
        pageSize,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {boolean} [includeFmv]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInventoryCostBasisRollForward(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      includeFmv?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryCostBasisRollForwardResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryCostBasisRollForward(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        exportResults,
        includeFmv,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {Array<string>} [inventory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInventoryEnhancedGainLoss(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      inventory?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryEnhancedGainLossResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryEnhancedGainLoss(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        exportResults,
        inventory,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInventoryJeReclass(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryJeReclassResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryJeReclass(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        exportResults,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInventoryRows(
      orgId: string,
      inventoryViewId: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryViewsResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryRows(
        orgId,
        inventoryViewId,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} colId
     * @param {boolean} showEmptyLots
     * @param {string} [asOf]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLotColumnUniqueValues(
      orgId: string,
      inventoryViewId: string,
      colId: string,
      showEmptyLots: boolean,
      asOf?: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcColumnUniqueValueResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLotColumnUniqueValues(
        orgId,
        inventoryViewId,
        colId,
        showEmptyLots,
        asOf,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLotColumns(
      orgId: string,
      inventoryViewId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLotColumns(orgId, inventoryViewId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRippleInventoryCostBasisRollForward(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcRippleInventoryCostBasisRollForwardResp>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRippleInventoryCostBasisRollForward(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        exportResults,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSageErpJeReport(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCsvExportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSageErpJeReport(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSodaReport(
      orgId: string,
      inventoryViewId: string,
      endDate?: string,
      exportResults?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcSodaReportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSodaReport(
        orgId,
        inventoryViewId,
        endDate,
        exportResults,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} inventoryViewUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUpdateDownloadLinks(
      orgId: string,
      inventoryViewId: string,
      inventoryViewUpdateId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryViewDownloadLinksResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUpdateDownloadLinks(
        orgId,
        inventoryViewId,
        inventoryViewUpdateId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getView(
      orgId: string,
      inventoryViewId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryViewResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getView(orgId, inventoryViewId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {string} [pageToken]
     * @param {Array<string>} [runId]
     * @param {Array<number>} [timestampSEC]
     * @param {Array<string>} [action]
     * @param {Array<string>} [status]
     * @param {Array<string>} [txnId]
     * @param {Array<string>} [eventId]
     * @param {Array<string>} [lotId]
     * @param {Array<string>} [asset]
     * @param {Array<string>} [assetId]
     * @param {Array<string>} [assetUnitAdj]
     * @param {Array<string>} [assetBalance]
     * @param {Array<number>} [lotAcquisitionTimestampSEC]
     * @param {Array<string>} [txnExchangeRate]
     * @param {Array<string>} [carryingValue]
     * @param {Array<string>} [impairmentExpense]
     * @param {Array<string>} [fairMarketValueDisposed]
     * @param {Array<string>} [shortTermGainLoss]
     * @param {Array<string>} [longTermGainLoss]
     * @param {Array<string>} [undatedGainLoss]
     * @param {Array<string>} [costBasisAcquired]
     * @param {Array<string>} [costBasisRelieved]
     * @param {Array<string>} [costAverageRate]
     * @param {Array<boolean>} [isTrade]
     * @param {Array<string>} [lineError]
     * @param {string} [sortField]
     * @param {ApiSvcSortDirection} [sortDirection]
     * @param {boolean} [exportResults]
     * @param {Array<string>} [inventory]
     * @param {Array<string>} [wallet]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getViewActions(
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      pageToken?: string,
      runId?: Array<string>,
      timestampSEC?: Array<number>,
      action?: Array<string>,
      status?: Array<string>,
      txnId?: Array<string>,
      eventId?: Array<string>,
      lotId?: Array<string>,
      asset?: Array<string>,
      assetId?: Array<string>,
      assetUnitAdj?: Array<string>,
      assetBalance?: Array<string>,
      lotAcquisitionTimestampSEC?: Array<number>,
      txnExchangeRate?: Array<string>,
      carryingValue?: Array<string>,
      impairmentExpense?: Array<string>,
      fairMarketValueDisposed?: Array<string>,
      shortTermGainLoss?: Array<string>,
      longTermGainLoss?: Array<string>,
      undatedGainLoss?: Array<string>,
      costBasisAcquired?: Array<string>,
      costBasisRelieved?: Array<string>,
      costAverageRate?: Array<string>,
      isTrade?: Array<boolean>,
      lineError?: Array<string>,
      sortField?: string,
      sortDirection?: ApiSvcSortDirection,
      exportResults?: boolean,
      inventory?: Array<string>,
      wallet?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryActionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getViewActions(
        orgId,
        inventoryViewId,
        asOf,
        pageToken,
        runId,
        timestampSEC,
        action,
        status,
        txnId,
        eventId,
        lotId,
        asset,
        assetId,
        assetUnitAdj,
        assetBalance,
        lotAcquisitionTimestampSEC,
        txnExchangeRate,
        carryingValue,
        impairmentExpense,
        fairMarketValueDisposed,
        shortTermGainLoss,
        longTermGainLoss,
        undatedGainLoss,
        costBasisAcquired,
        costBasisRelieved,
        costAverageRate,
        isTrade,
        lineError,
        sortField,
        sortDirection,
        exportResults,
        inventory,
        wallet,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {boolean} [groupByInventory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getViewBalance(
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      groupByInventory?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryBalanceResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getViewBalance(
        orgId,
        inventoryViewId,
        asOf,
        groupByInventory,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getViewCounts(
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryCountsResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getViewCounts(orgId, inventoryViewId, asOf, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {boolean} [showEmptyLots]
     * @param {string} [pageToken]
     * @param {Array<number>} [timestampSEC]
     * @param {Array<string>} [lotId]
     * @param {Array<string>} [asset]
     * @param {Array<number>} [qty]
     * @param {Array<number>} [unitsAcquired]
     * @param {Array<number>} [unitsDisposed]
     * @param {Array<number>} [costBasisAcquired]
     * @param {Array<number>} [costBasisRelieved]
     * @param {Array<number>} [impairmentExpense]
     * @param {Array<number>} [costBasis]
     * @param {Array<number>} [carryingValue]
     * @param {string} [sortField]
     * @param {ApiSvcSortDirection} [sortDirection]
     * @param {boolean} [exportResults]
     * @param {Array<string>} [inventory]
     * @param {boolean} [groupByInventory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getViewLots(
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      showEmptyLots?: boolean,
      pageToken?: string,
      timestampSEC?: Array<number>,
      lotId?: Array<string>,
      asset?: Array<string>,
      qty?: Array<number>,
      unitsAcquired?: Array<number>,
      unitsDisposed?: Array<number>,
      costBasisAcquired?: Array<number>,
      costBasisRelieved?: Array<number>,
      impairmentExpense?: Array<number>,
      costBasis?: Array<number>,
      carryingValue?: Array<number>,
      sortField?: string,
      sortDirection?: ApiSvcSortDirection,
      exportResults?: boolean,
      inventory?: Array<string>,
      groupByInventory?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryLotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getViewLots(
        orgId,
        inventoryViewId,
        asOf,
        showEmptyLots,
        pageToken,
        timestampSEC,
        lotId,
        asset,
        qty,
        unitsAcquired,
        unitsDisposed,
        costBasisAcquired,
        costBasisRelieved,
        impairmentExpense,
        costBasis,
        carryingValue,
        sortField,
        sortDirection,
        exportResults,
        inventory,
        groupByInventory,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getViewUpdates(
      orgId: string,
      inventoryViewId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryViewUpdatesResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getViewUpdates(orgId, inventoryViewId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getViews(
      orgId: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInventoryViewsResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getViews(orgId, pageToken, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} startDate
     * @param {string} endDate
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getsGainLossSummary(
      orgId: string,
      inventoryViewId: string,
      startDate: string,
      endDate: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGainLossSummaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getsGainLossSummary(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async triggerViewUpdate(
      orgId: string,
      inventoryViewId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCreateResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.triggerViewUpdate(orgId, inventoryViewId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InventoryApi - factory interface
 * @export
 */
export const InventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InventoryApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} inventoryViewUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelViewUpdate(
      orgId: string,
      inventoryViewId: string,
      inventoryViewUpdateId: string,
      options?: any
    ): AxiosPromise<ApiSvcCancelResult> {
      return localVarFp
        .cancelViewUpdate(orgId, inventoryViewId, inventoryViewUpdateId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateInventoryViewRequest} apiSvcCreateInventoryViewRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createView(
      orgId: string,
      apiSvcCreateInventoryViewRequest: ApiSvcCreateInventoryViewRequest,
      options?: any
    ): AxiosPromise<ApiSvcCreateResult> {
      return localVarFp
        .createView(orgId, apiSvcCreateInventoryViewRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteView(orgId: string, inventoryViewId: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteView(orgId, inventoryViewId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} colId
     * @param {boolean} showEmptyLots
     * @param {string} [asOf]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActionColumnUniqueValues(
      orgId: string,
      inventoryViewId: string,
      colId: string,
      showEmptyLots: boolean,
      asOf?: string,
      pageToken?: string,
      options?: any
    ): AxiosPromise<ApiSvcColumnUniqueValueResponse> {
      return localVarFp
        .getActionColumnUniqueValues(orgId, inventoryViewId, colId, showEmptyLots, asOf, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActionColumns(orgId: string, inventoryViewId: string, options?: any): AxiosPromise<Array<string>> {
      return localVarFp.getActionColumns(orgId, inventoryViewId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActionsJeReport(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<ApiSvcCsvExportResponse> {
      return localVarFp
        .getActionsJeReport(orgId, inventoryViewId, startDate, endDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActionsTrialBalanceReport(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<ApiSvcCsvExportResponse> {
      return localVarFp
        .getActionsTrialBalanceReport(orgId, inventoryViewId, startDate, endDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdvancedActionsReport(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<ApiSvcCsvExportResponse> {
      return localVarFp
        .getAdvancedActionsReport(orgId, inventoryViewId, startDate, endDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [useInventory]
     * @param {boolean} [useWallet]
     * @param {boolean} [useLot]
     * @param {boolean} [exportResults]
     * @param {boolean} [includeFmv]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdvancedCostBasisRollForward(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      useInventory?: boolean,
      useWallet?: boolean,
      useLot?: boolean,
      exportResults?: boolean,
      includeFmv?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcAdvancedCostBasisRollForwardResp> {
      return localVarFp
        .getAdvancedCostBasisRollForward(
          orgId,
          inventoryViewId,
          startDate,
          endDate,
          useInventory,
          useWallet,
          useLot,
          exportResults,
          includeFmv,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryBalanceCheckReport(
      orgId: string,
      endDate?: string,
      exportResults?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcGetInventoryBalanceCheckReport200Response> {
      return localVarFp
        .getInventoryBalanceCheckReport(orgId, endDate, exportResults, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {number} [pageSize]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryBalanceCheckReportPaginated(
      orgId: string,
      endDate?: string,
      exportResults?: boolean,
      pageSize?: number,
      pageToken?: string,
      options?: any
    ): AxiosPromise<ApiSvcInventoryBalanceCheckReportResp> {
      return localVarFp
        .getInventoryBalanceCheckReportPaginated(orgId, endDate, exportResults, pageSize, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {boolean} [includeFmv]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryCostBasisRollForward(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      includeFmv?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcInventoryCostBasisRollForwardResp> {
      return localVarFp
        .getInventoryCostBasisRollForward(
          orgId,
          inventoryViewId,
          startDate,
          endDate,
          exportResults,
          includeFmv,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {Array<string>} [inventory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryEnhancedGainLoss(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      inventory?: Array<string>,
      options?: any
    ): AxiosPromise<ApiSvcInventoryEnhancedGainLossResp> {
      return localVarFp
        .getInventoryEnhancedGainLoss(orgId, inventoryViewId, startDate, endDate, exportResults, inventory, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryJeReclass(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcInventoryJeReclassResp> {
      return localVarFp
        .getInventoryJeReclass(orgId, inventoryViewId, startDate, endDate, exportResults, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryRows(
      orgId: string,
      inventoryViewId: string,
      pageToken?: string,
      options?: any
    ): AxiosPromise<ApiSvcInventoryViewsResult> {
      return localVarFp
        .getInventoryRows(orgId, inventoryViewId, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} colId
     * @param {boolean} showEmptyLots
     * @param {string} [asOf]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLotColumnUniqueValues(
      orgId: string,
      inventoryViewId: string,
      colId: string,
      showEmptyLots: boolean,
      asOf?: string,
      pageToken?: string,
      options?: any
    ): AxiosPromise<ApiSvcColumnUniqueValueResponse> {
      return localVarFp
        .getLotColumnUniqueValues(orgId, inventoryViewId, colId, showEmptyLots, asOf, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLotColumns(orgId: string, inventoryViewId: string, options?: any): AxiosPromise<Array<string>> {
      return localVarFp.getLotColumns(orgId, inventoryViewId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRippleInventoryCostBasisRollForward(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      exportResults?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcRippleInventoryCostBasisRollForwardResp> {
      return localVarFp
        .getRippleInventoryCostBasisRollForward(orgId, inventoryViewId, startDate, endDate, exportResults, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSageErpJeReport(
      orgId: string,
      inventoryViewId: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<ApiSvcCsvExportResponse> {
      return localVarFp
        .getSageErpJeReport(orgId, inventoryViewId, startDate, endDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [endDate]
     * @param {boolean} [exportResults]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSodaReport(
      orgId: string,
      inventoryViewId: string,
      endDate?: string,
      exportResults?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcSodaReportResponse> {
      return localVarFp
        .getSodaReport(orgId, inventoryViewId, endDate, exportResults, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} inventoryViewUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpdateDownloadLinks(
      orgId: string,
      inventoryViewId: string,
      inventoryViewUpdateId: string,
      options?: any
    ): AxiosPromise<ApiSvcInventoryViewDownloadLinksResponse> {
      return localVarFp
        .getUpdateDownloadLinks(orgId, inventoryViewId, inventoryViewUpdateId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getView(orgId: string, inventoryViewId: string, options?: any): AxiosPromise<ApiSvcInventoryViewResponse> {
      return localVarFp.getView(orgId, inventoryViewId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {string} [pageToken]
     * @param {Array<string>} [runId]
     * @param {Array<number>} [timestampSEC]
     * @param {Array<string>} [action]
     * @param {Array<string>} [status]
     * @param {Array<string>} [txnId]
     * @param {Array<string>} [eventId]
     * @param {Array<string>} [lotId]
     * @param {Array<string>} [asset]
     * @param {Array<string>} [assetId]
     * @param {Array<string>} [assetUnitAdj]
     * @param {Array<string>} [assetBalance]
     * @param {Array<number>} [lotAcquisitionTimestampSEC]
     * @param {Array<string>} [txnExchangeRate]
     * @param {Array<string>} [carryingValue]
     * @param {Array<string>} [impairmentExpense]
     * @param {Array<string>} [fairMarketValueDisposed]
     * @param {Array<string>} [shortTermGainLoss]
     * @param {Array<string>} [longTermGainLoss]
     * @param {Array<string>} [undatedGainLoss]
     * @param {Array<string>} [costBasisAcquired]
     * @param {Array<string>} [costBasisRelieved]
     * @param {Array<string>} [costAverageRate]
     * @param {Array<boolean>} [isTrade]
     * @param {Array<string>} [lineError]
     * @param {string} [sortField]
     * @param {ApiSvcSortDirection} [sortDirection]
     * @param {boolean} [exportResults]
     * @param {Array<string>} [inventory]
     * @param {Array<string>} [wallet]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewActions(
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      pageToken?: string,
      runId?: Array<string>,
      timestampSEC?: Array<number>,
      action?: Array<string>,
      status?: Array<string>,
      txnId?: Array<string>,
      eventId?: Array<string>,
      lotId?: Array<string>,
      asset?: Array<string>,
      assetId?: Array<string>,
      assetUnitAdj?: Array<string>,
      assetBalance?: Array<string>,
      lotAcquisitionTimestampSEC?: Array<number>,
      txnExchangeRate?: Array<string>,
      carryingValue?: Array<string>,
      impairmentExpense?: Array<string>,
      fairMarketValueDisposed?: Array<string>,
      shortTermGainLoss?: Array<string>,
      longTermGainLoss?: Array<string>,
      undatedGainLoss?: Array<string>,
      costBasisAcquired?: Array<string>,
      costBasisRelieved?: Array<string>,
      costAverageRate?: Array<string>,
      isTrade?: Array<boolean>,
      lineError?: Array<string>,
      sortField?: string,
      sortDirection?: ApiSvcSortDirection,
      exportResults?: boolean,
      inventory?: Array<string>,
      wallet?: Array<string>,
      options?: any
    ): AxiosPromise<ApiSvcInventoryActionResponse> {
      return localVarFp
        .getViewActions(
          orgId,
          inventoryViewId,
          asOf,
          pageToken,
          runId,
          timestampSEC,
          action,
          status,
          txnId,
          eventId,
          lotId,
          asset,
          assetId,
          assetUnitAdj,
          assetBalance,
          lotAcquisitionTimestampSEC,
          txnExchangeRate,
          carryingValue,
          impairmentExpense,
          fairMarketValueDisposed,
          shortTermGainLoss,
          longTermGainLoss,
          undatedGainLoss,
          costBasisAcquired,
          costBasisRelieved,
          costAverageRate,
          isTrade,
          lineError,
          sortField,
          sortDirection,
          exportResults,
          inventory,
          wallet,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {boolean} [groupByInventory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewBalance(
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      groupByInventory?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcInventoryBalanceResp> {
      return localVarFp
        .getViewBalance(orgId, inventoryViewId, asOf, groupByInventory, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewCounts(
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      options?: any
    ): AxiosPromise<ApiSvcInventoryCountsResp> {
      return localVarFp
        .getViewCounts(orgId, inventoryViewId, asOf, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} [asOf]
     * @param {boolean} [showEmptyLots]
     * @param {string} [pageToken]
     * @param {Array<number>} [timestampSEC]
     * @param {Array<string>} [lotId]
     * @param {Array<string>} [asset]
     * @param {Array<number>} [qty]
     * @param {Array<number>} [unitsAcquired]
     * @param {Array<number>} [unitsDisposed]
     * @param {Array<number>} [costBasisAcquired]
     * @param {Array<number>} [costBasisRelieved]
     * @param {Array<number>} [impairmentExpense]
     * @param {Array<number>} [costBasis]
     * @param {Array<number>} [carryingValue]
     * @param {string} [sortField]
     * @param {ApiSvcSortDirection} [sortDirection]
     * @param {boolean} [exportResults]
     * @param {Array<string>} [inventory]
     * @param {boolean} [groupByInventory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewLots(
      orgId: string,
      inventoryViewId: string,
      asOf?: string,
      showEmptyLots?: boolean,
      pageToken?: string,
      timestampSEC?: Array<number>,
      lotId?: Array<string>,
      asset?: Array<string>,
      qty?: Array<number>,
      unitsAcquired?: Array<number>,
      unitsDisposed?: Array<number>,
      costBasisAcquired?: Array<number>,
      costBasisRelieved?: Array<number>,
      impairmentExpense?: Array<number>,
      costBasis?: Array<number>,
      carryingValue?: Array<number>,
      sortField?: string,
      sortDirection?: ApiSvcSortDirection,
      exportResults?: boolean,
      inventory?: Array<string>,
      groupByInventory?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcInventoryLotResponse> {
      return localVarFp
        .getViewLots(
          orgId,
          inventoryViewId,
          asOf,
          showEmptyLots,
          pageToken,
          timestampSEC,
          lotId,
          asset,
          qty,
          unitsAcquired,
          unitsDisposed,
          costBasisAcquired,
          costBasisRelieved,
          impairmentExpense,
          costBasis,
          carryingValue,
          sortField,
          sortDirection,
          exportResults,
          inventory,
          groupByInventory,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewUpdates(
      orgId: string,
      inventoryViewId: string,
      options?: any
    ): AxiosPromise<ApiSvcInventoryViewUpdatesResult> {
      return localVarFp.getViewUpdates(orgId, inventoryViewId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViews(orgId: string, pageToken?: string, options?: any): AxiosPromise<ApiSvcInventoryViewsResult> {
      return localVarFp.getViews(orgId, pageToken, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {string} startDate
     * @param {string} endDate
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsGainLossSummary(
      orgId: string,
      inventoryViewId: string,
      startDate: string,
      endDate: string,
      pageToken?: string,
      options?: any
    ): AxiosPromise<ApiSvcGainLossSummaryResponse> {
      return localVarFp
        .getsGainLossSummary(orgId, inventoryViewId, startDate, endDate, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} inventoryViewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerViewUpdate(orgId: string, inventoryViewId: string, options?: any): AxiosPromise<ApiSvcCreateResult> {
      return localVarFp.triggerViewUpdate(orgId, inventoryViewId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * InventoryApi - object-oriented interface
 * @export
 * @class InventoryApi
 * @extends {BaseAPI}
 */
export class InventoryApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} inventoryViewUpdateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public cancelViewUpdate(
    orgId: string,
    inventoryViewId: string,
    inventoryViewUpdateId: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .cancelViewUpdate(orgId, inventoryViewId, inventoryViewUpdateId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcCreateInventoryViewRequest} apiSvcCreateInventoryViewRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public createView(
    orgId: string,
    apiSvcCreateInventoryViewRequest: ApiSvcCreateInventoryViewRequest,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .createView(orgId, apiSvcCreateInventoryViewRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public deleteView(orgId: string, inventoryViewId: string, options?: AxiosRequestConfig) {
    return InventoryApiFp(this.configuration)
      .deleteView(orgId, inventoryViewId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} colId
   * @param {boolean} showEmptyLots
   * @param {string} [asOf]
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getActionColumnUniqueValues(
    orgId: string,
    inventoryViewId: string,
    colId: string,
    showEmptyLots: boolean,
    asOf?: string,
    pageToken?: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getActionColumnUniqueValues(orgId, inventoryViewId, colId, showEmptyLots, asOf, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getActionColumns(orgId: string, inventoryViewId: string, options?: AxiosRequestConfig) {
    return InventoryApiFp(this.configuration)
      .getActionColumns(orgId, inventoryViewId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getActionsJeReport(
    orgId: string,
    inventoryViewId: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getActionsJeReport(orgId, inventoryViewId, startDate, endDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getActionsTrialBalanceReport(
    orgId: string,
    inventoryViewId: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getActionsTrialBalanceReport(orgId, inventoryViewId, startDate, endDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getAdvancedActionsReport(
    orgId: string,
    inventoryViewId: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getAdvancedActionsReport(orgId, inventoryViewId, startDate, endDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {boolean} [useInventory]
   * @param {boolean} [useWallet]
   * @param {boolean} [useLot]
   * @param {boolean} [exportResults]
   * @param {boolean} [includeFmv]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getAdvancedCostBasisRollForward(
    orgId: string,
    inventoryViewId: string,
    startDate?: string,
    endDate?: string,
    useInventory?: boolean,
    useWallet?: boolean,
    useLot?: boolean,
    exportResults?: boolean,
    includeFmv?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getAdvancedCostBasisRollForward(
        orgId,
        inventoryViewId,
        startDate,
        endDate,
        useInventory,
        useWallet,
        useLot,
        exportResults,
        includeFmv,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} [endDate]
   * @param {boolean} [exportResults]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getInventoryBalanceCheckReport(
    orgId: string,
    endDate?: string,
    exportResults?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getInventoryBalanceCheckReport(orgId, endDate, exportResults, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} [endDate]
   * @param {boolean} [exportResults]
   * @param {number} [pageSize]
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getInventoryBalanceCheckReportPaginated(
    orgId: string,
    endDate?: string,
    exportResults?: boolean,
    pageSize?: number,
    pageToken?: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getInventoryBalanceCheckReportPaginated(orgId, endDate, exportResults, pageSize, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {boolean} [exportResults]
   * @param {boolean} [includeFmv]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getInventoryCostBasisRollForward(
    orgId: string,
    inventoryViewId: string,
    startDate?: string,
    endDate?: string,
    exportResults?: boolean,
    includeFmv?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getInventoryCostBasisRollForward(orgId, inventoryViewId, startDate, endDate, exportResults, includeFmv, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {boolean} [exportResults]
   * @param {Array<string>} [inventory]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getInventoryEnhancedGainLoss(
    orgId: string,
    inventoryViewId: string,
    startDate?: string,
    endDate?: string,
    exportResults?: boolean,
    inventory?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getInventoryEnhancedGainLoss(orgId, inventoryViewId, startDate, endDate, exportResults, inventory, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {boolean} [exportResults]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getInventoryJeReclass(
    orgId: string,
    inventoryViewId: string,
    startDate?: string,
    endDate?: string,
    exportResults?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getInventoryJeReclass(orgId, inventoryViewId, startDate, endDate, exportResults, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getInventoryRows(orgId: string, inventoryViewId: string, pageToken?: string, options?: AxiosRequestConfig) {
    return InventoryApiFp(this.configuration)
      .getInventoryRows(orgId, inventoryViewId, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} colId
   * @param {boolean} showEmptyLots
   * @param {string} [asOf]
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getLotColumnUniqueValues(
    orgId: string,
    inventoryViewId: string,
    colId: string,
    showEmptyLots: boolean,
    asOf?: string,
    pageToken?: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getLotColumnUniqueValues(orgId, inventoryViewId, colId, showEmptyLots, asOf, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getLotColumns(orgId: string, inventoryViewId: string, options?: AxiosRequestConfig) {
    return InventoryApiFp(this.configuration)
      .getLotColumns(orgId, inventoryViewId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {boolean} [exportResults]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getRippleInventoryCostBasisRollForward(
    orgId: string,
    inventoryViewId: string,
    startDate?: string,
    endDate?: string,
    exportResults?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getRippleInventoryCostBasisRollForward(orgId, inventoryViewId, startDate, endDate, exportResults, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getSageErpJeReport(
    orgId: string,
    inventoryViewId: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getSageErpJeReport(orgId, inventoryViewId, startDate, endDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [endDate]
   * @param {boolean} [exportResults]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getSodaReport(
    orgId: string,
    inventoryViewId: string,
    endDate?: string,
    exportResults?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getSodaReport(orgId, inventoryViewId, endDate, exportResults, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} inventoryViewUpdateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getUpdateDownloadLinks(
    orgId: string,
    inventoryViewId: string,
    inventoryViewUpdateId: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getUpdateDownloadLinks(orgId, inventoryViewId, inventoryViewUpdateId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getView(orgId: string, inventoryViewId: string, options?: AxiosRequestConfig) {
    return InventoryApiFp(this.configuration)
      .getView(orgId, inventoryViewId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [asOf]
   * @param {string} [pageToken]
   * @param {Array<string>} [runId]
   * @param {Array<number>} [timestampSEC]
   * @param {Array<string>} [action]
   * @param {Array<string>} [status]
   * @param {Array<string>} [txnId]
   * @param {Array<string>} [eventId]
   * @param {Array<string>} [lotId]
   * @param {Array<string>} [asset]
   * @param {Array<string>} [assetId]
   * @param {Array<string>} [assetUnitAdj]
   * @param {Array<string>} [assetBalance]
   * @param {Array<number>} [lotAcquisitionTimestampSEC]
   * @param {Array<string>} [txnExchangeRate]
   * @param {Array<string>} [carryingValue]
   * @param {Array<string>} [impairmentExpense]
   * @param {Array<string>} [fairMarketValueDisposed]
   * @param {Array<string>} [shortTermGainLoss]
   * @param {Array<string>} [longTermGainLoss]
   * @param {Array<string>} [undatedGainLoss]
   * @param {Array<string>} [costBasisAcquired]
   * @param {Array<string>} [costBasisRelieved]
   * @param {Array<string>} [costAverageRate]
   * @param {Array<boolean>} [isTrade]
   * @param {Array<string>} [lineError]
   * @param {string} [sortField]
   * @param {ApiSvcSortDirection} [sortDirection]
   * @param {boolean} [exportResults]
   * @param {Array<string>} [inventory]
   * @param {Array<string>} [wallet]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getViewActions(
    orgId: string,
    inventoryViewId: string,
    asOf?: string,
    pageToken?: string,
    runId?: Array<string>,
    timestampSEC?: Array<number>,
    action?: Array<string>,
    status?: Array<string>,
    txnId?: Array<string>,
    eventId?: Array<string>,
    lotId?: Array<string>,
    asset?: Array<string>,
    assetId?: Array<string>,
    assetUnitAdj?: Array<string>,
    assetBalance?: Array<string>,
    lotAcquisitionTimestampSEC?: Array<number>,
    txnExchangeRate?: Array<string>,
    carryingValue?: Array<string>,
    impairmentExpense?: Array<string>,
    fairMarketValueDisposed?: Array<string>,
    shortTermGainLoss?: Array<string>,
    longTermGainLoss?: Array<string>,
    undatedGainLoss?: Array<string>,
    costBasisAcquired?: Array<string>,
    costBasisRelieved?: Array<string>,
    costAverageRate?: Array<string>,
    isTrade?: Array<boolean>,
    lineError?: Array<string>,
    sortField?: string,
    sortDirection?: ApiSvcSortDirection,
    exportResults?: boolean,
    inventory?: Array<string>,
    wallet?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getViewActions(
        orgId,
        inventoryViewId,
        asOf,
        pageToken,
        runId,
        timestampSEC,
        action,
        status,
        txnId,
        eventId,
        lotId,
        asset,
        assetId,
        assetUnitAdj,
        assetBalance,
        lotAcquisitionTimestampSEC,
        txnExchangeRate,
        carryingValue,
        impairmentExpense,
        fairMarketValueDisposed,
        shortTermGainLoss,
        longTermGainLoss,
        undatedGainLoss,
        costBasisAcquired,
        costBasisRelieved,
        costAverageRate,
        isTrade,
        lineError,
        sortField,
        sortDirection,
        exportResults,
        inventory,
        wallet,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [asOf]
   * @param {boolean} [groupByInventory]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getViewBalance(
    orgId: string,
    inventoryViewId: string,
    asOf?: string,
    groupByInventory?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getViewBalance(orgId, inventoryViewId, asOf, groupByInventory, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [asOf]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getViewCounts(orgId: string, inventoryViewId: string, asOf?: string, options?: AxiosRequestConfig) {
    return InventoryApiFp(this.configuration)
      .getViewCounts(orgId, inventoryViewId, asOf, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} [asOf]
   * @param {boolean} [showEmptyLots]
   * @param {string} [pageToken]
   * @param {Array<number>} [timestampSEC]
   * @param {Array<string>} [lotId]
   * @param {Array<string>} [asset]
   * @param {Array<number>} [qty]
   * @param {Array<number>} [unitsAcquired]
   * @param {Array<number>} [unitsDisposed]
   * @param {Array<number>} [costBasisAcquired]
   * @param {Array<number>} [costBasisRelieved]
   * @param {Array<number>} [impairmentExpense]
   * @param {Array<number>} [costBasis]
   * @param {Array<number>} [carryingValue]
   * @param {string} [sortField]
   * @param {ApiSvcSortDirection} [sortDirection]
   * @param {boolean} [exportResults]
   * @param {Array<string>} [inventory]
   * @param {boolean} [groupByInventory]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getViewLots(
    orgId: string,
    inventoryViewId: string,
    asOf?: string,
    showEmptyLots?: boolean,
    pageToken?: string,
    timestampSEC?: Array<number>,
    lotId?: Array<string>,
    asset?: Array<string>,
    qty?: Array<number>,
    unitsAcquired?: Array<number>,
    unitsDisposed?: Array<number>,
    costBasisAcquired?: Array<number>,
    costBasisRelieved?: Array<number>,
    impairmentExpense?: Array<number>,
    costBasis?: Array<number>,
    carryingValue?: Array<number>,
    sortField?: string,
    sortDirection?: ApiSvcSortDirection,
    exportResults?: boolean,
    inventory?: Array<string>,
    groupByInventory?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getViewLots(
        orgId,
        inventoryViewId,
        asOf,
        showEmptyLots,
        pageToken,
        timestampSEC,
        lotId,
        asset,
        qty,
        unitsAcquired,
        unitsDisposed,
        costBasisAcquired,
        costBasisRelieved,
        impairmentExpense,
        costBasis,
        carryingValue,
        sortField,
        sortDirection,
        exportResults,
        inventory,
        groupByInventory,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getViewUpdates(orgId: string, inventoryViewId: string, options?: AxiosRequestConfig) {
    return InventoryApiFp(this.configuration)
      .getViewUpdates(orgId, inventoryViewId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getViews(orgId: string, pageToken?: string, options?: AxiosRequestConfig) {
    return InventoryApiFp(this.configuration)
      .getViews(orgId, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {string} startDate
   * @param {string} endDate
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public getsGainLossSummary(
    orgId: string,
    inventoryViewId: string,
    startDate: string,
    endDate: string,
    pageToken?: string,
    options?: AxiosRequestConfig
  ) {
    return InventoryApiFp(this.configuration)
      .getsGainLossSummary(orgId, inventoryViewId, startDate, endDate, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} inventoryViewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryApi
   */
  public triggerViewUpdate(orgId: string, inventoryViewId: string, options?: AxiosRequestConfig) {
    return InventoryApiFp(this.configuration)
      .triggerViewUpdate(orgId, inventoryViewId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {boolean} [includeDisabled]
     * @param {string} [contactId]
     * @param {string} [lastRef]
     * @param {number} [pageSize]
     * @param {any} [orderDirection]
     * @param {string} [orderKey]
     * @param {string} [filterString]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvoices: async (
      orgId: string,
      includeDisabled?: boolean,
      contactId?: string,
      lastRef?: string,
      pageSize?: number,
      orderDirection?: any,
      orderKey?: string,
      filterString?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getInvoices', 'orgId', orgId);
      const localVarPath = `/invoices/{orgId}`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (contactId !== undefined) {
        localVarQueryParameter['contactId'] = contactId;
      }

      if (lastRef !== undefined) {
        localVarQueryParameter['lastRef'] = lastRef;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (orderDirection !== undefined) {
        localVarQueryParameter['orderDirection'] = orderDirection;
      }

      if (orderKey !== undefined) {
        localVarQueryParameter['orderKey'] = orderKey;
      }

      if (filterString !== undefined) {
        localVarQueryParameter['filterString'] = filterString;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {ApiSvcSendRemittanceAdviceDTO} apiSvcSendRemittanceAdviceDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendRemittanceAdvice: async (
      orgId: string,
      invoiceId: string,
      apiSvcSendRemittanceAdviceDTO: ApiSvcSendRemittanceAdviceDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('sendRemittanceAdvice', 'orgId', orgId);
      // verify required parameter 'invoiceId' is not null or undefined
      assertParamExists('sendRemittanceAdvice', 'invoiceId', invoiceId);
      // verify required parameter 'apiSvcSendRemittanceAdviceDTO' is not null or undefined
      assertParamExists('sendRemittanceAdvice', 'apiSvcSendRemittanceAdviceDTO', apiSvcSendRemittanceAdviceDTO);
      const localVarPath = `/invoices/{orgId}/invoice/{invoiceId}/email/remittance-advice`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'invoiceId'}}`, encodeURIComponent(String(invoiceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcSendRemittanceAdviceDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcUpdateInvoicesFromRemoteSourceDTO} apiSvcUpdateInvoicesFromRemoteSourceDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvoicesFromRemoteSource: async (
      orgId: string,
      apiSvcUpdateInvoicesFromRemoteSourceDTO: ApiSvcUpdateInvoicesFromRemoteSourceDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateInvoicesFromRemoteSource', 'orgId', orgId);
      // verify required parameter 'apiSvcUpdateInvoicesFromRemoteSourceDTO' is not null or undefined
      assertParamExists(
        'updateInvoicesFromRemoteSource',
        'apiSvcUpdateInvoicesFromRemoteSourceDTO',
        apiSvcUpdateInvoicesFromRemoteSourceDTO
      );
      const localVarPath = `/invoices/{orgId}`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcUpdateInvoicesFromRemoteSourceDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {boolean} [includeDisabled]
     * @param {string} [contactId]
     * @param {string} [lastRef]
     * @param {number} [pageSize]
     * @param {any} [orderDirection]
     * @param {string} [orderKey]
     * @param {string} [filterString]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvoices(
      orgId: string,
      includeDisabled?: boolean,
      contactId?: string,
      lastRef?: string,
      pageSize?: number,
      orderDirection?: any,
      orderKey?: string,
      filterString?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcInvoicesDTOFilteredPaginated>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoices(
        orgId,
        includeDisabled,
        contactId,
        lastRef,
        pageSize,
        orderDirection,
        orderKey,
        filterString,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {ApiSvcSendRemittanceAdviceDTO} apiSvcSendRemittanceAdviceDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendRemittanceAdvice(
      orgId: string,
      invoiceId: string,
      apiSvcSendRemittanceAdviceDTO: ApiSvcSendRemittanceAdviceDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendRemittanceAdvice(
        orgId,
        invoiceId,
        apiSvcSendRemittanceAdviceDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcUpdateInvoicesFromRemoteSourceDTO} apiSvcUpdateInvoicesFromRemoteSourceDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInvoicesFromRemoteSource(
      orgId: string,
      apiSvcUpdateInvoicesFromRemoteSourceDTO: ApiSvcUpdateInvoicesFromRemoteSourceDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiSvcInvoiceDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvoicesFromRemoteSource(
        orgId,
        apiSvcUpdateInvoicesFromRemoteSourceDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InvoicesApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {boolean} [includeDisabled]
     * @param {string} [contactId]
     * @param {string} [lastRef]
     * @param {number} [pageSize]
     * @param {any} [orderDirection]
     * @param {string} [orderKey]
     * @param {string} [filterString]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvoices(
      orgId: string,
      includeDisabled?: boolean,
      contactId?: string,
      lastRef?: string,
      pageSize?: number,
      orderDirection?: any,
      orderKey?: string,
      filterString?: string,
      options?: any
    ): AxiosPromise<ApiSvcInvoicesDTOFilteredPaginated> {
      return localVarFp
        .getInvoices(
          orgId,
          includeDisabled,
          contactId,
          lastRef,
          pageSize,
          orderDirection,
          orderKey,
          filterString,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} invoiceId
     * @param {ApiSvcSendRemittanceAdviceDTO} apiSvcSendRemittanceAdviceDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendRemittanceAdvice(
      orgId: string,
      invoiceId: string,
      apiSvcSendRemittanceAdviceDTO: ApiSvcSendRemittanceAdviceDTO,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .sendRemittanceAdvice(orgId, invoiceId, apiSvcSendRemittanceAdviceDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcUpdateInvoicesFromRemoteSourceDTO} apiSvcUpdateInvoicesFromRemoteSourceDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvoicesFromRemoteSource(
      orgId: string,
      apiSvcUpdateInvoicesFromRemoteSourceDTO: ApiSvcUpdateInvoicesFromRemoteSourceDTO,
      options?: any
    ): AxiosPromise<Array<ApiSvcInvoiceDTO>> {
      return localVarFp
        .updateInvoicesFromRemoteSource(orgId, apiSvcUpdateInvoicesFromRemoteSourceDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {boolean} [includeDisabled]
   * @param {string} [contactId]
   * @param {string} [lastRef]
   * @param {number} [pageSize]
   * @param {any} [orderDirection]
   * @param {string} [orderKey]
   * @param {string} [filterString]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvoicesApi
   */
  public getInvoices(
    orgId: string,
    includeDisabled?: boolean,
    contactId?: string,
    lastRef?: string,
    pageSize?: number,
    orderDirection?: any,
    orderKey?: string,
    filterString?: string,
    options?: AxiosRequestConfig
  ) {
    return InvoicesApiFp(this.configuration)
      .getInvoices(
        orgId,
        includeDisabled,
        contactId,
        lastRef,
        pageSize,
        orderDirection,
        orderKey,
        filterString,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} invoiceId
   * @param {ApiSvcSendRemittanceAdviceDTO} apiSvcSendRemittanceAdviceDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvoicesApi
   */
  public sendRemittanceAdvice(
    orgId: string,
    invoiceId: string,
    apiSvcSendRemittanceAdviceDTO: ApiSvcSendRemittanceAdviceDTO,
    options?: AxiosRequestConfig
  ) {
    return InvoicesApiFp(this.configuration)
      .sendRemittanceAdvice(orgId, invoiceId, apiSvcSendRemittanceAdviceDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcUpdateInvoicesFromRemoteSourceDTO} apiSvcUpdateInvoicesFromRemoteSourceDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvoicesApi
   */
  public updateInvoicesFromRemoteSource(
    orgId: string,
    apiSvcUpdateInvoicesFromRemoteSourceDTO: ApiSvcUpdateInvoicesFromRemoteSourceDTO,
    options?: AxiosRequestConfig
  ) {
    return InvoicesApiFp(this.configuration)
      .updateInvoicesFromRemoteSource(orgId, apiSvcUpdateInvoicesFromRemoteSourceDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Callback for the workflow step
     * @param {string} workflowDefinitionId
     * @param {string} workflow
     * @param {string} step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callback: async (
      workflowDefinitionId: string,
      workflow: string,
      step: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'workflowDefinitionId' is not null or undefined
      assertParamExists('callback', 'workflowDefinitionId', workflowDefinitionId);
      // verify required parameter 'workflow' is not null or undefined
      assertParamExists('callback', 'workflow', workflow);
      // verify required parameter 'step' is not null or undefined
      assertParamExists('callback', 'step', step);
      const localVarPath = `/jobs/callback/workflowDefinitions/{workflowDefinitionId}/workflows/{workflow}/steps/{step}`
        .replace(`{${'workflowDefinitionId'}}`, encodeURIComponent(String(workflowDefinitionId)))
        .replace(`{${'workflow'}}`, encodeURIComponent(String(workflow)))
        .replace(`{${'step'}}`, encodeURIComponent(String(step)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a list of jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/jobs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Starts a workflow instance running
     * @param {string} workflowDefinitionId
     * @param {string} jobId
     * @param {string} [taskFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    start: async (
      workflowDefinitionId: string,
      jobId: string,
      taskFilter?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'workflowDefinitionId' is not null or undefined
      assertParamExists('start', 'workflowDefinitionId', workflowDefinitionId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('start', 'jobId', jobId);
      const localVarPath = `/jobs/{workflowDefinitionId}/{jobId}/start`
        .replace(`{${'workflowDefinitionId'}}`, encodeURIComponent(String(workflowDefinitionId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (taskFilter !== undefined) {
        localVarQueryParameter['taskFilter'] = taskFilter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration);
  return {
    /**
     * Callback for the workflow step
     * @param {string} workflowDefinitionId
     * @param {string} workflow
     * @param {string} step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callback(
      workflowDefinitionId: string,
      workflow: string,
      step: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.callback(workflowDefinitionId, workflow, step, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Gets a list of jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJob(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcJobsPageResultDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJob(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Starts a workflow instance running
     * @param {string} workflowDefinitionId
     * @param {string} jobId
     * @param {string} [taskFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async start(
      workflowDefinitionId: string,
      jobId: string,
      taskFilter?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcStart200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.start(workflowDefinitionId, jobId, taskFilter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = JobsApiFp(configuration);
  return {
    /**
     * Callback for the workflow step
     * @param {string} workflowDefinitionId
     * @param {string} workflow
     * @param {string} step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callback(workflowDefinitionId: string, workflow: string, step: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .callback(workflowDefinitionId, workflow, step, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a list of jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob(options?: any): AxiosPromise<ApiSvcJobsPageResultDto> {
      return localVarFp.getJob(options).then((request) => request(axios, basePath));
    },
    /**
     * Starts a workflow instance running
     * @param {string} workflowDefinitionId
     * @param {string} jobId
     * @param {string} [taskFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    start(
      workflowDefinitionId: string,
      jobId: string,
      taskFilter?: string,
      options?: any
    ): AxiosPromise<ApiSvcStart200Response> {
      return localVarFp
        .start(workflowDefinitionId, jobId, taskFilter, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   * Callback for the workflow step
   * @param {string} workflowDefinitionId
   * @param {string} workflow
   * @param {string} step
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public callback(workflowDefinitionId: string, workflow: string, step: string, options?: AxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .callback(workflowDefinitionId, workflow, step, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a list of jobs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getJob(options?: AxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .getJob(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Starts a workflow instance running
   * @param {string} workflowDefinitionId
   * @param {string} jobId
   * @param {string} [taskFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public start(workflowDefinitionId: string, jobId: string, taskFilter?: string, options?: AxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .start(workflowDefinitionId, jobId, taskFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateSubsidiaryRequest} apiSvcCreateSubsidiaryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubsidiary: async (
      orgId: string,
      apiSvcCreateSubsidiaryRequest: ApiSvcCreateSubsidiaryRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createSubsidiary', 'orgId', orgId);
      // verify required parameter 'apiSvcCreateSubsidiaryRequest' is not null or undefined
      assertParamExists('createSubsidiary', 'apiSvcCreateSubsidiaryRequest', apiSvcCreateSubsidiaryRequest);
      const localVarPath = `/orgs/{orgId}/subsidiaries`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCreateSubsidiaryRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcNetworks} networkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTokenFilteringRules: async (
      orgId: string,
      networkId: ApiSvcNetworks,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getTokenFilteringRules', 'orgId', orgId);
      // verify required parameter 'networkId' is not null or undefined
      assertParamExists('getTokenFilteringRules', 'networkId', networkId);
      const localVarPath = `/orgSyncRules/{orgId}/tokenFilterRules/{networkId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'networkId'}}`, encodeURIComponent(String(networkId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all organizations associated with a user\'s account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserOrganizations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/organizations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSubsidiaries: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('listSubsidiaries', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/subsidiaries`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateSubsidiaryRequest} apiSvcCreateSubsidiaryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSubsidiary(
      orgId: string,
      apiSvcCreateSubsidiaryRequest: ApiSvcCreateSubsidiaryRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcSubsidiary>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSubsidiary(
        orgId,
        apiSvcCreateSubsidiaryRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcNetworks} networkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTokenFilteringRules(
      orgId: string,
      networkId: ApiSvcNetworks,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetTokenFilteringRules200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenFilteringRules(orgId, networkId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all organizations associated with a user\'s account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserOrganizations(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetUserOrganizations200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOrganizations(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listSubsidiaries(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiSvcSubsidiary>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listSubsidiaries(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OrganizationsApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateSubsidiaryRequest} apiSvcCreateSubsidiaryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubsidiary(
      orgId: string,
      apiSvcCreateSubsidiaryRequest: ApiSvcCreateSubsidiaryRequest,
      options?: any
    ): AxiosPromise<ApiSvcSubsidiary> {
      return localVarFp
        .createSubsidiary(orgId, apiSvcCreateSubsidiaryRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcNetworks} networkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTokenFilteringRules(
      orgId: string,
      networkId: ApiSvcNetworks,
      options?: any
    ): AxiosPromise<ApiSvcGetTokenFilteringRules200Response> {
      return localVarFp.getTokenFilteringRules(orgId, networkId, options).then((request) => request(axios, basePath));
    },
    /**
     * Returns all organizations associated with a user\'s account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserOrganizations(options?: any): AxiosPromise<ApiSvcGetUserOrganizations200Response> {
      return localVarFp.getUserOrganizations(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSubsidiaries(orgId: string, options?: any): AxiosPromise<Array<ApiSvcSubsidiary>> {
      return localVarFp.listSubsidiaries(orgId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {ApiSvcCreateSubsidiaryRequest} apiSvcCreateSubsidiaryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationsApi
   */
  public createSubsidiary(
    orgId: string,
    apiSvcCreateSubsidiaryRequest: ApiSvcCreateSubsidiaryRequest,
    options?: AxiosRequestConfig
  ) {
    return OrganizationsApiFp(this.configuration)
      .createSubsidiary(orgId, apiSvcCreateSubsidiaryRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcNetworks} networkId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationsApi
   */
  public getTokenFilteringRules(orgId: string, networkId: ApiSvcNetworks, options?: AxiosRequestConfig) {
    return OrganizationsApiFp(this.configuration)
      .getTokenFilteringRules(orgId, networkId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all organizations associated with a user\'s account.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationsApi
   */
  public getUserOrganizations(options?: AxiosRequestConfig) {
    return OrganizationsApiFp(this.configuration)
      .getUserOrganizations(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationsApi
   */
  public listSubsidiaries(orgId: string, options?: AxiosRequestConfig) {
    return OrganizationsApiFp(this.configuration)
      .listSubsidiaries(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrgsApi - axios parameter creator
 * @export
 */
export const OrgsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} fromOrgId
     * @param {string} toOrgId
     * @param {ApiSvcCloneOrgConfigDTO} apiSvcCloneOrgConfigDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloneOrg: async (
      fromOrgId: string,
      toOrgId: string,
      apiSvcCloneOrgConfigDTO: ApiSvcCloneOrgConfigDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'fromOrgId' is not null or undefined
      assertParamExists('cloneOrg', 'fromOrgId', fromOrgId);
      // verify required parameter 'toOrgId' is not null or undefined
      assertParamExists('cloneOrg', 'toOrgId', toOrgId);
      // verify required parameter 'apiSvcCloneOrgConfigDTO' is not null or undefined
      assertParamExists('cloneOrg', 'apiSvcCloneOrgConfigDTO', apiSvcCloneOrgConfigDTO);
      const localVarPath = `/orgs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fromOrgId !== undefined) {
        localVarQueryParameter['fromOrgId'] = fromOrgId;
      }

      if (toOrgId !== undefined) {
        localVarQueryParameter['toOrgId'] = toOrgId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCloneOrgConfigDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrg: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getOrg', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrgsApi - functional programming interface
 * @export
 */
export const OrgsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrgsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} fromOrgId
     * @param {string} toOrgId
     * @param {ApiSvcCloneOrgConfigDTO} apiSvcCloneOrgConfigDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cloneOrg(
      fromOrgId: string,
      toOrgId: string,
      apiSvcCloneOrgConfigDTO: ApiSvcCloneOrgConfigDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cloneOrg(
        fromOrgId,
        toOrgId,
        apiSvcCloneOrgConfigDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrg(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetOrg200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrg(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OrgsApi - factory interface
 * @export
 */
export const OrgsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = OrgsApiFp(configuration);
  return {
    /**
     *
     * @param {string} fromOrgId
     * @param {string} toOrgId
     * @param {ApiSvcCloneOrgConfigDTO} apiSvcCloneOrgConfigDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloneOrg(
      fromOrgId: string,
      toOrgId: string,
      apiSvcCloneOrgConfigDTO: ApiSvcCloneOrgConfigDTO,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .cloneOrg(fromOrgId, toOrgId, apiSvcCloneOrgConfigDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrg(orgId: string, options?: any): AxiosPromise<ApiSvcGetOrg200Response> {
      return localVarFp.getOrg(orgId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrgsApi - object-oriented interface
 * @export
 * @class OrgsApi
 * @extends {BaseAPI}
 */
export class OrgsApi extends BaseAPI {
  /**
   *
   * @param {string} fromOrgId
   * @param {string} toOrgId
   * @param {ApiSvcCloneOrgConfigDTO} apiSvcCloneOrgConfigDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrgsApi
   */
  public cloneOrg(
    fromOrgId: string,
    toOrgId: string,
    apiSvcCloneOrgConfigDTO: ApiSvcCloneOrgConfigDTO,
    options?: AxiosRequestConfig
  ) {
    return OrgsApiFp(this.configuration)
      .cloneOrg(fromOrgId, toOrgId, apiSvcCloneOrgConfigDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrgsApi
   */
  public getOrg(orgId: string, options?: AxiosRequestConfig) {
    return OrgsApiFp(this.configuration)
      .getOrg(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PricesApi - axios parameter creator
 * @export
 */
export const PricesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcPricingReportParams} apiSvcPricingReportParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePricingReports: async (
      orgId: string,
      apiSvcPricingReportParams: ApiSvcPricingReportParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('generatePricingReports', 'orgId', orgId);
      // verify required parameter 'apiSvcPricingReportParams' is not null or undefined
      assertParamExists('generatePricingReports', 'apiSvcPricingReportParams', apiSvcPricingReportParams);
      const localVarPath = `/orgs/{orgId}/pricing-report`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcPricingReportParams,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} priceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrice: async (orgId: string, priceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getPrice', 'orgId', orgId);
      // verify required parameter 'priceId' is not null or undefined
      assertParamExists('getPrice', 'priceId', priceId);
      const localVarPath = `/orgs/{orgId}/prices/{priceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'priceId'}}`, encodeURIComponent(String(priceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceTransaction: async (
      orgId: string,
      transactionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('priceTransaction', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('priceTransaction', 'transactionId', transactionId);
      const localVarPath = `/orgs/{orgId}/transactions/{transactionId}/price`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PricesApi - functional programming interface
 * @export
 */
export const PricesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PricesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcPricingReportParams} apiSvcPricingReportParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generatePricingReports(
      orgId: string,
      apiSvcPricingReportParams: ApiSvcPricingReportParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGeneratePricingReports200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generatePricingReports(
        orgId,
        apiSvcPricingReportParams,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} priceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrice(
      orgId: string,
      priceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetPrice200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrice(orgId, priceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async priceTransaction(
      orgId: string,
      transactionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcPriceTransaction200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.priceTransaction(orgId, transactionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PricesApi - factory interface
 * @export
 */
export const PricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PricesApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcPricingReportParams} apiSvcPricingReportParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePricingReports(
      orgId: string,
      apiSvcPricingReportParams: ApiSvcPricingReportParams,
      options?: any
    ): AxiosPromise<ApiSvcGeneratePricingReports200Response> {
      return localVarFp
        .generatePricingReports(orgId, apiSvcPricingReportParams, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} priceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrice(orgId: string, priceId: string, options?: any): AxiosPromise<ApiSvcGetPrice200Response> {
      return localVarFp.getPrice(orgId, priceId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceTransaction(
      orgId: string,
      transactionId: string,
      options?: any
    ): AxiosPromise<ApiSvcPriceTransaction200Response> {
      return localVarFp.priceTransaction(orgId, transactionId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * PricesApi - object-oriented interface
 * @export
 * @class PricesApi
 * @extends {BaseAPI}
 */
export class PricesApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {ApiSvcPricingReportParams} apiSvcPricingReportParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricesApi
   */
  public generatePricingReports(
    orgId: string,
    apiSvcPricingReportParams: ApiSvcPricingReportParams,
    options?: AxiosRequestConfig
  ) {
    return PricesApiFp(this.configuration)
      .generatePricingReports(orgId, apiSvcPricingReportParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} priceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricesApi
   */
  public getPrice(orgId: string, priceId: string, options?: AxiosRequestConfig) {
    return PricesApiFp(this.configuration)
      .getPrice(orgId, priceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} transactionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricesApi
   */
  public priceTransaction(orgId: string, transactionId: string, options?: AxiosRequestConfig) {
    return PricesApiFp(this.configuration)
      .priceTransaction(orgId, transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * QueuesApi - axios parameter creator
 * @export
 */
export const QueuesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ApiSvcSimpleQueues} queueId
     * @param {any} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleTask: async (
      queueId: ApiSvcSimpleQueues,
      body: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'queueId' is not null or undefined
      assertParamExists('handleTask', 'queueId', queueId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('handleTask', 'body', body);
      const localVarPath = `/simple-queues/{queueId}`.replace(`{${'queueId'}}`, encodeURIComponent(String(queueId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QueuesApi - functional programming interface
 * @export
 */
export const QueuesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = QueuesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ApiSvcSimpleQueues} queueId
     * @param {any} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleTask(
      queueId: ApiSvcSimpleQueues,
      body: any,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handleTask(queueId, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * QueuesApi - factory interface
 * @export
 */
export const QueuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = QueuesApiFp(configuration);
  return {
    /**
     *
     * @param {ApiSvcSimpleQueues} queueId
     * @param {any} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleTask(queueId: ApiSvcSimpleQueues, body: any, options?: any): AxiosPromise<void> {
      return localVarFp.handleTask(queueId, body, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * QueuesApi - object-oriented interface
 * @export
 * @class QueuesApi
 * @extends {BaseAPI}
 */
export class QueuesApi extends BaseAPI {
  /**
   *
   * @param {ApiSvcSimpleQueues} queueId
   * @param {any} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QueuesApi
   */
  public handleTask(queueId: ApiSvcSimpleQueues, body: any, options?: AxiosRequestConfig) {
    return QueuesApiFp(this.configuration)
      .handleTask(queueId, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReportingApi - axios parameter creator
 * @export
 */
export const ReportingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {ApiSvcRecordBinaryContent} binaryContent
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFileSnapshot: async (
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      binaryContent: ApiSvcRecordBinaryContent,
      file: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createFileSnapshot', 'orgId', orgId);
      // verify required parameter 'recordBucket' is not null or undefined
      assertParamExists('createFileSnapshot', 'recordBucket', recordBucket);
      // verify required parameter 'binaryContent' is not null or undefined
      assertParamExists('createFileSnapshot', 'binaryContent', binaryContent);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('createFileSnapshot', 'file', file);
      const localVarPath = `/v2/orgs/{orgId}/reports/snapshots/{recordBucket}/{binaryContent}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'recordBucket'}}`, encodeURIComponent(String(recordBucket)))
        .replace(`{${'binaryContent'}}`, encodeURIComponent(String(binaryContent)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {ApiSvcCreateSodaReportSnapshotRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSnapshot: async (
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      body: ApiSvcCreateSodaReportSnapshotRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createSnapshot', 'orgId', orgId);
      // verify required parameter 'recordBucket' is not null or undefined
      assertParamExists('createSnapshot', 'recordBucket', recordBucket);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createSnapshot', 'body', body);
      const localVarPath = `/v2/orgs/{orgId}/reports/snapshots/{recordBucket}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'recordBucket'}}`, encodeURIComponent(String(recordBucket)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSnapshot: async (
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('deleteSnapshot', 'orgId', orgId);
      // verify required parameter 'recordBucket' is not null or undefined
      assertParamExists('deleteSnapshot', 'recordBucket', recordBucket);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteSnapshot', 'id', id);
      const localVarPath = `/v2/orgs/{orgId}/reports/snapshots/{recordBucket}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'recordBucket'}}`, encodeURIComponent(String(recordBucket)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileSnapshot: async (
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getFileSnapshot', 'orgId', orgId);
      // verify required parameter 'recordBucket' is not null or undefined
      assertParamExists('getFileSnapshot', 'recordBucket', recordBucket);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getFileSnapshot', 'id', id);
      const localVarPath = `/v2/orgs/{orgId}/reports/snapshots/{recordBucket}/file/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'recordBucket'}}`, encodeURIComponent(String(recordBucket)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reportId
     * @param {boolean} [includeDownloadUrls]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport: async (
      orgId: string,
      reportId: string,
      includeDownloadUrls?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getReport', 'orgId', orgId);
      // verify required parameter 'reportId' is not null or undefined
      assertParamExists('getReport', 'reportId', reportId);
      const localVarPath = `/v2/orgs/{orgId}/reports/{reportId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'reportId'}}`, encodeURIComponent(String(reportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (includeDownloadUrls !== undefined) {
        localVarQueryParameter['includeDownloadUrls'] = includeDownloadUrls;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcReportRunConfigTypes} type
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReports: async (
      orgId: string,
      type: ApiSvcReportRunConfigTypes,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getReports', 'orgId', orgId);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getReports', 'type', type);
      const localVarPath = `/v2/orgs/{orgId}/reports`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSnapshot: async (
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getSnapshot', 'orgId', orgId);
      // verify required parameter 'recordBucket' is not null or undefined
      assertParamExists('getSnapshot', 'recordBucket', recordBucket);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSnapshot', 'id', id);
      const localVarPath = `/v2/orgs/{orgId}/reports/snapshots/{recordBucket}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'recordBucket'}}`, encodeURIComponent(String(recordBucket)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSnapshots: async (
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getSnapshots', 'orgId', orgId);
      // verify required parameter 'recordBucket' is not null or undefined
      assertParamExists('getSnapshots', 'recordBucket', recordBucket);
      const localVarPath = `/v2/orgs/{orgId}/reports/snapshots/{recordBucket}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'recordBucket'}}`, encodeURIComponent(String(recordBucket)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    persistSnapshot: async (
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('persistSnapshot', 'orgId', orgId);
      // verify required parameter 'recordBucket' is not null or undefined
      assertParamExists('persistSnapshot', 'recordBucket', recordBucket);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('persistSnapshot', 'id', id);
      const localVarPath = `/v2/orgs/{orgId}/reports/snapshots/{recordBucket}/{id}/persist`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'recordBucket'}}`, encodeURIComponent(String(recordBucket)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    run: async (
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('run', 'orgId', orgId);
      // verify required parameter 'apiSvcRunReportRequest' is not null or undefined
      assertParamExists('run', 'apiSvcRunReportRequest', apiSvcRunReportRequest);
      const localVarPath = `/v2/orgs/{orgId}/reports`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcRunReportRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runESBalanceReportPreview: async (
      orgId: string,
      date: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('runESBalanceReportPreview', 'orgId', orgId);
      // verify required parameter 'date' is not null or undefined
      assertParamExists('runESBalanceReportPreview', 'date', date);
      const localVarPath = `/v2/orgs/{orgId}/reports/esBalanceReport/preview`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runRelatedOrgsTransactionsReportSave: async (
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('runRelatedOrgsTransactionsReportSave', 'orgId', orgId);
      // verify required parameter 'apiSvcRunReportRequest' is not null or undefined
      assertParamExists('runRelatedOrgsTransactionsReportSave', 'apiSvcRunReportRequest', apiSvcRunReportRequest);
      const localVarPath = `/v2/orgs/{orgId}/reports/relatedOrgsTransactions/save`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcRunReportRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runRelatedOrgsTransctionsReportPreview: async (
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('runRelatedOrgsTransctionsReportPreview', 'orgId', orgId);
      // verify required parameter 'apiSvcRunReportRequest' is not null or undefined
      assertParamExists('runRelatedOrgsTransctionsReportPreview', 'apiSvcRunReportRequest', apiSvcRunReportRequest);
      const localVarPath = `/v2/orgs/{orgId}/reports/relatedOrgsTransactions/preview`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcRunReportRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runSharedOrgsBalancesReportPreview: async (
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('runSharedOrgsBalancesReportPreview', 'orgId', orgId);
      // verify required parameter 'apiSvcRunReportRequest' is not null or undefined
      assertParamExists('runSharedOrgsBalancesReportPreview', 'apiSvcRunReportRequest', apiSvcRunReportRequest);
      const localVarPath = `/v2/orgs/{orgId}/reports/relatedOrgsBalances/preview`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcRunReportRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runSharedOrgsBalancesReportSave: async (
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('runSharedOrgsBalancesReportSave', 'orgId', orgId);
      // verify required parameter 'apiSvcRunReportRequest' is not null or undefined
      assertParamExists('runSharedOrgsBalancesReportSave', 'apiSvcRunReportRequest', apiSvcRunReportRequest);
      const localVarPath = `/v2/orgs/{orgId}/reports/relatedOrgsBalances/save`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcRunReportRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {ApiSvcUpdateReportSnapshotRequest} apiSvcUpdateReportSnapshotRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSnapshot: async (
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      apiSvcUpdateReportSnapshotRequest: ApiSvcUpdateReportSnapshotRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateSnapshot', 'orgId', orgId);
      // verify required parameter 'recordBucket' is not null or undefined
      assertParamExists('updateSnapshot', 'recordBucket', recordBucket);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSnapshot', 'id', id);
      // verify required parameter 'apiSvcUpdateReportSnapshotRequest' is not null or undefined
      assertParamExists('updateSnapshot', 'apiSvcUpdateReportSnapshotRequest', apiSvcUpdateReportSnapshotRequest);
      const localVarPath = `/v2/orgs/{orgId}/reports/snapshots/{recordBucket}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'recordBucket'}}`, encodeURIComponent(String(recordBucket)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcUpdateReportSnapshotRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportingApi - functional programming interface
 * @export
 */
export const ReportingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReportingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {ApiSvcRecordBinaryContent} binaryContent
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFileSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      binaryContent: ApiSvcRecordBinaryContent,
      file: File,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcFileRecord>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFileSnapshot(
        orgId,
        recordBucket,
        binaryContent,
        file,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {ApiSvcCreateSodaReportSnapshotRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      body: ApiSvcCreateSodaReportSnapshotRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcFileRecord>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSnapshot(orgId, recordBucket, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSnapshot(orgId, recordBucket, id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFileSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFileSnapshot(orgId, recordBucket, id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reportId
     * @param {boolean} [includeDownloadUrls]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReport(
      orgId: string,
      reportId: string,
      includeDownloadUrls?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetReport200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(
        orgId,
        reportId,
        includeDownloadUrls,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcReportRunConfigTypes} type
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReports(
      orgId: string,
      type: ApiSvcReportRunConfigTypes,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetReports200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReports(orgId, type, pageToken, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshot(orgId, recordBucket, id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSnapshots(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiSvcFileRecord>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshots(orgId, recordBucket, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async persistSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.persistSnapshot(orgId, recordBucket, id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async run(
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.run(orgId, apiSvcRunReportRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runESBalanceReportPreview(
      orgId: string,
      date: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcRunESBalanceReportPreview200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runESBalanceReportPreview(orgId, date, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runRelatedOrgsTransactionsReportSave(
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runRelatedOrgsTransactionsReportSave(
        orgId,
        apiSvcRunReportRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runRelatedOrgsTransctionsReportPreview(
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ApiSvcRunRelatedOrgsTransctionsReportPreview200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runRelatedOrgsTransctionsReportPreview(
        orgId,
        apiSvcRunReportRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runSharedOrgsBalancesReportPreview(
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcRunSharedOrgsBalancesReportPreview200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runSharedOrgsBalancesReportPreview(
        orgId,
        apiSvcRunReportRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runSharedOrgsBalancesReportSave(
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runSharedOrgsBalancesReportSave(
        orgId,
        apiSvcRunReportRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {ApiSvcUpdateReportSnapshotRequest} apiSvcUpdateReportSnapshotRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      apiSvcUpdateReportSnapshotRequest: ApiSvcUpdateReportSnapshotRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSnapshot(
        orgId,
        recordBucket,
        id,
        apiSvcUpdateReportSnapshotRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReportingApi - factory interface
 * @export
 */
export const ReportingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ReportingApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {ApiSvcRecordBinaryContent} binaryContent
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFileSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      binaryContent: ApiSvcRecordBinaryContent,
      file: File,
      options?: any
    ): AxiosPromise<ApiSvcFileRecord> {
      return localVarFp
        .createFileSnapshot(orgId, recordBucket, binaryContent, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {ApiSvcCreateSodaReportSnapshotRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      body: ApiSvcCreateSodaReportSnapshotRequest,
      options?: any
    ): AxiosPromise<ApiSvcFileRecord> {
      return localVarFp.createSnapshot(orgId, recordBucket, body, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSnapshot(orgId: string, recordBucket: ApiSvcRecordBucket, id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteSnapshot(orgId, recordBucket, id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileSnapshot(orgId: string, recordBucket: ApiSvcRecordBucket, id: string, options?: any): AxiosPromise<string> {
      return localVarFp.getFileSnapshot(orgId, recordBucket, id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reportId
     * @param {boolean} [includeDownloadUrls]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport(
      orgId: string,
      reportId: string,
      includeDownloadUrls?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcGetReport200Response> {
      return localVarFp
        .getReport(orgId, reportId, includeDownloadUrls, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcReportRunConfigTypes} type
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReports(
      orgId: string,
      type: ApiSvcReportRunConfigTypes,
      pageToken?: string,
      options?: any
    ): AxiosPromise<ApiSvcGetReports200Response> {
      return localVarFp.getReports(orgId, type, pageToken, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSnapshot(orgId: string, recordBucket: ApiSvcRecordBucket, id: string, options?: any): AxiosPromise<any> {
      return localVarFp.getSnapshot(orgId, recordBucket, id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSnapshots(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      options?: any
    ): AxiosPromise<Array<ApiSvcFileRecord>> {
      return localVarFp.getSnapshots(orgId, recordBucket, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    persistSnapshot(orgId: string, recordBucket: ApiSvcRecordBucket, id: string, options?: any): AxiosPromise<void> {
      return localVarFp.persistSnapshot(orgId, recordBucket, id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    run(orgId: string, apiSvcRunReportRequest: ApiSvcRunReportRequest, options?: any): AxiosPromise<string> {
      return localVarFp.run(orgId, apiSvcRunReportRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runESBalanceReportPreview(
      orgId: string,
      date: string,
      options?: any
    ): AxiosPromise<ApiSvcRunESBalanceReportPreview200Response> {
      return localVarFp.runESBalanceReportPreview(orgId, date, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runRelatedOrgsTransactionsReportSave(
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .runRelatedOrgsTransactionsReportSave(orgId, apiSvcRunReportRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runRelatedOrgsTransctionsReportPreview(
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options?: any
    ): AxiosPromise<ApiSvcRunRelatedOrgsTransctionsReportPreview200Response> {
      return localVarFp
        .runRelatedOrgsTransctionsReportPreview(orgId, apiSvcRunReportRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runSharedOrgsBalancesReportPreview(
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options?: any
    ): AxiosPromise<ApiSvcRunSharedOrgsBalancesReportPreview200Response> {
      return localVarFp
        .runSharedOrgsBalancesReportPreview(orgId, apiSvcRunReportRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runSharedOrgsBalancesReportSave(
      orgId: string,
      apiSvcRunReportRequest: ApiSvcRunReportRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .runSharedOrgsBalancesReportSave(orgId, apiSvcRunReportRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcRecordBucket} recordBucket
     * @param {string} id
     * @param {ApiSvcUpdateReportSnapshotRequest} apiSvcUpdateReportSnapshotRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSnapshot(
      orgId: string,
      recordBucket: ApiSvcRecordBucket,
      id: string,
      apiSvcUpdateReportSnapshotRequest: ApiSvcUpdateReportSnapshotRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateSnapshot(orgId, recordBucket, id, apiSvcUpdateReportSnapshotRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReportingApi - object-oriented interface
 * @export
 * @class ReportingApi
 * @extends {BaseAPI}
 */
export class ReportingApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRecordBucket} recordBucket
   * @param {ApiSvcRecordBinaryContent} binaryContent
   * @param {File} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public createFileSnapshot(
    orgId: string,
    recordBucket: ApiSvcRecordBucket,
    binaryContent: ApiSvcRecordBinaryContent,
    file: File,
    options?: AxiosRequestConfig
  ) {
    return ReportingApiFp(this.configuration)
      .createFileSnapshot(orgId, recordBucket, binaryContent, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRecordBucket} recordBucket
   * @param {ApiSvcCreateSodaReportSnapshotRequest} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public createSnapshot(
    orgId: string,
    recordBucket: ApiSvcRecordBucket,
    body: ApiSvcCreateSodaReportSnapshotRequest,
    options?: AxiosRequestConfig
  ) {
    return ReportingApiFp(this.configuration)
      .createSnapshot(orgId, recordBucket, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRecordBucket} recordBucket
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public deleteSnapshot(orgId: string, recordBucket: ApiSvcRecordBucket, id: string, options?: AxiosRequestConfig) {
    return ReportingApiFp(this.configuration)
      .deleteSnapshot(orgId, recordBucket, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRecordBucket} recordBucket
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public getFileSnapshot(orgId: string, recordBucket: ApiSvcRecordBucket, id: string, options?: AxiosRequestConfig) {
    return ReportingApiFp(this.configuration)
      .getFileSnapshot(orgId, recordBucket, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} reportId
   * @param {boolean} [includeDownloadUrls]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public getReport(orgId: string, reportId: string, includeDownloadUrls?: boolean, options?: AxiosRequestConfig) {
    return ReportingApiFp(this.configuration)
      .getReport(orgId, reportId, includeDownloadUrls, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcReportRunConfigTypes} type
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public getReports(orgId: string, type: ApiSvcReportRunConfigTypes, pageToken?: string, options?: AxiosRequestConfig) {
    return ReportingApiFp(this.configuration)
      .getReports(orgId, type, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRecordBucket} recordBucket
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public getSnapshot(orgId: string, recordBucket: ApiSvcRecordBucket, id: string, options?: AxiosRequestConfig) {
    return ReportingApiFp(this.configuration)
      .getSnapshot(orgId, recordBucket, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRecordBucket} recordBucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public getSnapshots(orgId: string, recordBucket: ApiSvcRecordBucket, options?: AxiosRequestConfig) {
    return ReportingApiFp(this.configuration)
      .getSnapshots(orgId, recordBucket, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRecordBucket} recordBucket
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public persistSnapshot(orgId: string, recordBucket: ApiSvcRecordBucket, id: string, options?: AxiosRequestConfig) {
    return ReportingApiFp(this.configuration)
      .persistSnapshot(orgId, recordBucket, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public run(orgId: string, apiSvcRunReportRequest: ApiSvcRunReportRequest, options?: AxiosRequestConfig) {
    return ReportingApiFp(this.configuration)
      .run(orgId, apiSvcRunReportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} date
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public runESBalanceReportPreview(orgId: string, date: string, options?: AxiosRequestConfig) {
    return ReportingApiFp(this.configuration)
      .runESBalanceReportPreview(orgId, date, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public runRelatedOrgsTransactionsReportSave(
    orgId: string,
    apiSvcRunReportRequest: ApiSvcRunReportRequest,
    options?: AxiosRequestConfig
  ) {
    return ReportingApiFp(this.configuration)
      .runRelatedOrgsTransactionsReportSave(orgId, apiSvcRunReportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public runRelatedOrgsTransctionsReportPreview(
    orgId: string,
    apiSvcRunReportRequest: ApiSvcRunReportRequest,
    options?: AxiosRequestConfig
  ) {
    return ReportingApiFp(this.configuration)
      .runRelatedOrgsTransctionsReportPreview(orgId, apiSvcRunReportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public runSharedOrgsBalancesReportPreview(
    orgId: string,
    apiSvcRunReportRequest: ApiSvcRunReportRequest,
    options?: AxiosRequestConfig
  ) {
    return ReportingApiFp(this.configuration)
      .runSharedOrgsBalancesReportPreview(orgId, apiSvcRunReportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRunReportRequest} apiSvcRunReportRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public runSharedOrgsBalancesReportSave(
    orgId: string,
    apiSvcRunReportRequest: ApiSvcRunReportRequest,
    options?: AxiosRequestConfig
  ) {
    return ReportingApiFp(this.configuration)
      .runSharedOrgsBalancesReportSave(orgId, apiSvcRunReportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcRecordBucket} recordBucket
   * @param {string} id
   * @param {ApiSvcUpdateReportSnapshotRequest} apiSvcUpdateReportSnapshotRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportingApi
   */
  public updateSnapshot(
    orgId: string,
    recordBucket: ApiSvcRecordBucket,
    id: string,
    apiSvcUpdateReportSnapshotRequest: ApiSvcUpdateReportSnapshotRequest,
    options?: AxiosRequestConfig
  ) {
    return ReportingApiFp(this.configuration)
      .updateSnapshot(orgId, recordBucket, id, apiSvcUpdateReportSnapshotRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RulesApi - axios parameter creator
 * @export
 */
export const RulesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns specific rule for an organization within Bitwave based upon the ruleId.
     * @param {string} orgId
     * @param {string} ruleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRuleById: async (orgId: string, ruleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getRuleById', 'orgId', orgId);
      // verify required parameter 'ruleId' is not null or undefined
      assertParamExists('getRuleById', 'ruleId', ruleId);
      const localVarPath = `/org/{orgId}/rules/{ruleId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'ruleId'}}`, encodeURIComponent(String(ruleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all the rules for an organization within Bitwave.
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRules: async (
      orgId: string,
      pageLimit?: number,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getRules', 'orgId', orgId);
      const localVarPath = `/org/{orgId}/rules`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RulesApi - functional programming interface
 * @export
 */
export const RulesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RulesApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns specific rule for an organization within Bitwave based upon the ruleId.
     * @param {string} orgId
     * @param {string} ruleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRuleById(
      orgId: string,
      ruleId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetRules200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRuleById(orgId, ruleId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all the rules for an organization within Bitwave.
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRules(
      orgId: string,
      pageLimit?: number,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetRules200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRules(orgId, pageLimit, pageToken, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RulesApi - factory interface
 * @export
 */
export const RulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = RulesApiFp(configuration);
  return {
    /**
     * Returns specific rule for an organization within Bitwave based upon the ruleId.
     * @param {string} orgId
     * @param {string} ruleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRuleById(orgId: string, ruleId: string, options?: any): AxiosPromise<ApiSvcGetRules200Response> {
      return localVarFp.getRuleById(orgId, ruleId, options).then((request) => request(axios, basePath));
    },
    /**
     * Returns all the rules for an organization within Bitwave.
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRules(
      orgId: string,
      pageLimit?: number,
      pageToken?: string,
      options?: any
    ): AxiosPromise<ApiSvcGetRules200Response> {
      return localVarFp.getRules(orgId, pageLimit, pageToken, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * RulesApi - object-oriented interface
 * @export
 * @class RulesApi
 * @extends {BaseAPI}
 */
export class RulesApi extends BaseAPI {
  /**
   * Returns specific rule for an organization within Bitwave based upon the ruleId.
   * @param {string} orgId
   * @param {string} ruleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RulesApi
   */
  public getRuleById(orgId: string, ruleId: string, options?: AxiosRequestConfig) {
    return RulesApiFp(this.configuration)
      .getRuleById(orgId, ruleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all the rules for an organization within Bitwave.
   * @param {string} orgId
   * @param {number} [pageLimit]
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RulesApi
   */
  public getRules(orgId: string, pageLimit?: number, pageToken?: string, options?: AxiosRequestConfig) {
    return RulesApiFp(this.configuration)
      .getRules(orgId, pageLimit, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SCIMApi - axios parameter creator
 * @export
 */
export const SCIMApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcScimCreateRequest} apiSvcScimCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      orgId: string,
      apiSvcScimCreateRequest: ApiSvcScimCreateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createUser', 'orgId', orgId);
      // verify required parameter 'apiSvcScimCreateRequest' is not null or undefined
      assertParamExists('createUser', 'apiSvcScimCreateRequest', apiSvcScimCreateRequest);
      const localVarPath = `/orgs/{orgId}/scim/v2/Users`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcScimCreateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScimUser: async (userId: string, orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getScimUser', 'userId', userId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getScimUser', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/scim/v2/Users/{userId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scimGetUsers: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('scimGetUsers', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/scim/v2/Users`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} orgId
     * @param {ApiSvcScimUpdateRequest} apiSvcScimUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scimUpdateUser: async (
      userId: string,
      orgId: string,
      apiSvcScimUpdateRequest: ApiSvcScimUpdateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('scimUpdateUser', 'userId', userId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('scimUpdateUser', 'orgId', orgId);
      // verify required parameter 'apiSvcScimUpdateRequest' is not null or undefined
      assertParamExists('scimUpdateUser', 'apiSvcScimUpdateRequest', apiSvcScimUpdateRequest);
      const localVarPath = `/orgs/{orgId}/scim/v2/Users/{userId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcScimUpdateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SCIMApi - functional programming interface
 * @export
 */
export const SCIMApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SCIMApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcScimCreateRequest} apiSvcScimCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      orgId: string,
      apiSvcScimCreateRequest: ApiSvcScimCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCreateUser201Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(orgId, apiSvcScimCreateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} userId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScimUser(
      userId: string,
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetScimUser200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScimUser(userId, orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scimGetUsers(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcScimGetUsers200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scimGetUsers(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} userId
     * @param {string} orgId
     * @param {ApiSvcScimUpdateRequest} apiSvcScimUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scimUpdateUser(
      userId: string,
      orgId: string,
      apiSvcScimUpdateRequest: ApiSvcScimUpdateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetScimUser200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scimUpdateUser(
        userId,
        orgId,
        apiSvcScimUpdateRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SCIMApi - factory interface
 * @export
 */
export const SCIMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SCIMApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcScimCreateRequest} apiSvcScimCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(
      orgId: string,
      apiSvcScimCreateRequest: ApiSvcScimCreateRequest,
      options?: any
    ): AxiosPromise<ApiSvcCreateUser201Response> {
      return localVarFp.createUser(orgId, apiSvcScimCreateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScimUser(userId: string, orgId: string, options?: any): AxiosPromise<ApiSvcGetScimUser200Response> {
      return localVarFp.getScimUser(userId, orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scimGetUsers(orgId: string, options?: any): AxiosPromise<ApiSvcScimGetUsers200Response> {
      return localVarFp.scimGetUsers(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {string} orgId
     * @param {ApiSvcScimUpdateRequest} apiSvcScimUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scimUpdateUser(
      userId: string,
      orgId: string,
      apiSvcScimUpdateRequest: ApiSvcScimUpdateRequest,
      options?: any
    ): AxiosPromise<ApiSvcGetScimUser200Response> {
      return localVarFp
        .scimUpdateUser(userId, orgId, apiSvcScimUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SCIMApi - object-oriented interface
 * @export
 * @class SCIMApi
 * @extends {BaseAPI}
 */
export class SCIMApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {ApiSvcScimCreateRequest} apiSvcScimCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SCIMApi
   */
  public createUser(orgId: string, apiSvcScimCreateRequest: ApiSvcScimCreateRequest, options?: AxiosRequestConfig) {
    return SCIMApiFp(this.configuration)
      .createUser(orgId, apiSvcScimCreateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SCIMApi
   */
  public getScimUser(userId: string, orgId: string, options?: AxiosRequestConfig) {
    return SCIMApiFp(this.configuration)
      .getScimUser(userId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SCIMApi
   */
  public scimGetUsers(orgId: string, options?: AxiosRequestConfig) {
    return SCIMApiFp(this.configuration)
      .scimGetUsers(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userId
   * @param {string} orgId
   * @param {ApiSvcScimUpdateRequest} apiSvcScimUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SCIMApi
   */
  public scimUpdateUser(
    userId: string,
    orgId: string,
    apiSvcScimUpdateRequest: ApiSvcScimUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return SCIMApiFp(this.configuration)
      .scimUpdateUser(userId, orgId, apiSvcScimUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TokensApi - axios parameter creator
 * @export
 */
export const TokensApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableScimToken: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('disableScimToken', 'orgId', orgId);
      const localVarPath = `/v2/orgs/{orgId}/tokens/scim-token`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateScimToken: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('generateScimToken', 'orgId', orgId);
      const localVarPath = `/v2/orgs/{orgId}/tokens/scim-token`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TokensApi - functional programming interface
 * @export
 */
export const TokensApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TokensApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableScimToken(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disableScimToken(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateScimToken(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateScimToken(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TokensApi - factory interface
 * @export
 */
export const TokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TokensApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableScimToken(orgId: string, options?: any): AxiosPromise<void> {
      return localVarFp.disableScimToken(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateScimToken(orgId: string, options?: any): AxiosPromise<string> {
      return localVarFp.generateScimToken(orgId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * TokensApi - object-oriented interface
 * @export
 * @class TokensApi
 * @extends {BaseAPI}
 */
export class TokensApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokensApi
   */
  public disableScimToken(orgId: string, options?: AxiosRequestConfig) {
    return TokensApiFp(this.configuration)
      .disableScimToken(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokensApi
   */
  public generateScimToken(orgId: string, options?: AxiosRequestConfig) {
    return TokensApiFp(this.configuration)
      .generateScimToken(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (orgId: string, transactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('_delete', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('_delete', 'transactionId', transactionId);
      const localVarPath = `/txns/{orgId}/{transactionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Categorizes your transaction within Bitwave. Some of the categories within Bitwave include Simple, Account Transfer, Detailed, Invoice/Bill Payment, and DeFi.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {ApiSvcTransactionData} apiSvcTransactionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categorizeTransaction: async (
      orgId: string,
      transactionId: string,
      apiSvcTransactionData: ApiSvcTransactionData,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('categorizeTransaction', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('categorizeTransaction', 'transactionId', transactionId);
      // verify required parameter 'apiSvcTransactionData' is not null or undefined
      assertParamExists('categorizeTransaction', 'apiSvcTransactionData', apiSvcTransactionData);
      const localVarPath = `/txns/categorize/{orgId}/{transactionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(apiSvcTransactionData, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {ApiSvcTransactionData} apiSvcTransactionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categorizeTransactionContext: async (
      orgId: string,
      transactionId: string,
      apiSvcTransactionData: ApiSvcTransactionData,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('categorizeTransactionContext', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('categorizeTransactionContext', 'transactionId', transactionId);
      // verify required parameter 'apiSvcTransactionData' is not null or undefined
      assertParamExists('categorizeTransactionContext', 'apiSvcTransactionData', apiSvcTransactionData);
      const localVarPath = `/txns/contextCategorize/{orgId}/{transactionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(apiSvcTransactionData, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {Array<Array<ApiSvcTxnCategorizeRow>>} apiSvcTxnCategorizeRow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categorizeTransactions: async (
      orgId: string,
      apiSvcTxnCategorizeRow: Array<Array<ApiSvcTxnCategorizeRow>>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('categorizeTransactions', 'orgId', orgId);
      // verify required parameter 'apiSvcTxnCategorizeRow' is not null or undefined
      assertParamExists('categorizeTransactions', 'apiSvcTxnCategorizeRow', apiSvcTxnCategorizeRow);
      const localVarPath = `/txns/categorizeBulk/{orgId}`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcTxnCategorizeRow,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create Transaction batch
     * @param {string} orgId
     * @param {Array<ApiSvcCreateTransactionDTO>} apiSvcCreateTransactionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransactionBatch: async (
      orgId: string,
      apiSvcCreateTransactionDTO: Array<ApiSvcCreateTransactionDTO>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createTransactionBatch', 'orgId', orgId);
      // verify required parameter 'apiSvcCreateTransactionDTO' is not null or undefined
      assertParamExists('createTransactionBatch', 'apiSvcCreateTransactionDTO', apiSvcCreateTransactionDTO);
      const localVarPath = `/txns/orgs/{orgId}/transactions`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCreateTransactionDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Provides you with additional transaction details, such as time, address, and transaction amount.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {boolean} [includeExchangeRates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction: async (
      orgId: string,
      transactionId: string,
      includeExchangeRates?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getTransaction', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('getTransaction', 'transactionId', transactionId);
      const localVarPath = `/txns/{orgId}/{transactionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (includeExchangeRates !== undefined) {
        localVarQueryParameter['includeExchangeRates'] = includeExchangeRates;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all the transactions for your Bitwave organization.
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {string} [categorizationStatus]
     * @param {boolean} [includeExchangeRates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionsPaginated: async (
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      categorizationStatus?: string,
      includeExchangeRates?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getTransactionsPaginated', 'orgId', orgId);
      const localVarPath = `/txns/{orgId}`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (categorizationStatus !== undefined) {
        localVarQueryParameter['categorizationStatus'] = categorizationStatus;
      }

      if (includeExchangeRates !== undefined) {
        localVarQueryParameter['includeExchangeRates'] = includeExchangeRates;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enables you to create a transaction manually into Bitwave.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {string} sourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    head: async (
      orgId: string,
      transactionId: string,
      sourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('head', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('head', 'transactionId', transactionId);
      // verify required parameter 'sourceId' is not null or undefined
      assertParamExists('head', 'sourceId', sourceId);
      const localVarPath = `/txns/{orgId}/{transactionId}/{sourceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)))
        .replace(`{${'sourceId'}}`, encodeURIComponent(String(sourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceTransactionSec: async (
      orgId: string,
      transactionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('priceTransactionSec', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('priceTransactionSec', 'transactionId', transactionId);
      const localVarPath = `/txns/price_sec/{orgId}/{transactionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enables you to create a transaction manually into Bitwave.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {string} sourceId
     * @param {ApiSvcUpsertTransactionRequest} apiSvcUpsertTransactionRequest
     * @param {boolean} [uncategorize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertTransaction: async (
      orgId: string,
      transactionId: string,
      sourceId: string,
      apiSvcUpsertTransactionRequest: ApiSvcUpsertTransactionRequest,
      uncategorize?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('upsertTransaction', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('upsertTransaction', 'transactionId', transactionId);
      // verify required parameter 'sourceId' is not null or undefined
      assertParamExists('upsertTransaction', 'sourceId', sourceId);
      // verify required parameter 'apiSvcUpsertTransactionRequest' is not null or undefined
      assertParamExists('upsertTransaction', 'apiSvcUpsertTransactionRequest', apiSvcUpsertTransactionRequest);
      const localVarPath = `/txns/{orgId}/{transactionId}/{sourceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)))
        .replace(`{${'sourceId'}}`, encodeURIComponent(String(sourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (uncategorize !== undefined) {
        localVarQueryParameter['uncategorize'] = uncategorize;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcUpsertTransactionRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} sourceId
     * @param {Array<ApiSvcIndexedTransactionsUnionType>} apiSvcIndexedTransactionsUnionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertTransactions: async (
      orgId: string,
      sourceId: string,
      apiSvcIndexedTransactionsUnionType: Array<ApiSvcIndexedTransactionsUnionType>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('upsertTransactions', 'orgId', orgId);
      // verify required parameter 'sourceId' is not null or undefined
      assertParamExists('upsertTransactions', 'sourceId', sourceId);
      // verify required parameter 'apiSvcIndexedTransactionsUnionType' is not null or undefined
      assertParamExists('upsertTransactions', 'apiSvcIndexedTransactionsUnionType', apiSvcIndexedTransactionsUnionType);
      const localVarPath = `/txns/orgs/{orgId}/sources/{sourceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'sourceId'}}`, encodeURIComponent(String(sourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcIndexedTransactionsUnionType,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      orgId: string,
      transactionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(orgId, transactionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Categorizes your transaction within Bitwave. Some of the categories within Bitwave include Simple, Account Transfer, Detailed, Invoice/Bill Payment, and DeFi.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {ApiSvcTransactionData} apiSvcTransactionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categorizeTransaction(
      orgId: string,
      transactionId: string,
      apiSvcTransactionData: ApiSvcTransactionData,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCategorizeTransaction200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categorizeTransaction(
        orgId,
        transactionId,
        apiSvcTransactionData,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {ApiSvcTransactionData} apiSvcTransactionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categorizeTransactionContext(
      orgId: string,
      transactionId: string,
      apiSvcTransactionData: ApiSvcTransactionData,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCategorizeTransaction200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categorizeTransactionContext(
        orgId,
        transactionId,
        apiSvcTransactionData,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {Array<Array<ApiSvcTxnCategorizeRow>>} apiSvcTxnCategorizeRow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categorizeTransactions(
      orgId: string,
      apiSvcTxnCategorizeRow: Array<Array<ApiSvcTxnCategorizeRow>>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCategorizeTransactions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categorizeTransactions(
        orgId,
        apiSvcTxnCategorizeRow,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create Transaction batch
     * @param {string} orgId
     * @param {Array<ApiSvcCreateTransactionDTO>} apiSvcCreateTransactionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTransactionBatch(
      orgId: string,
      apiSvcCreateTransactionDTO: Array<ApiSvcCreateTransactionDTO>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCreateTransactionBatch200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTransactionBatch(
        orgId,
        apiSvcCreateTransactionDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Provides you with additional transaction details, such as time, address, and transaction amount.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {boolean} [includeExchangeRates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransaction(
      orgId: string,
      transactionId: string,
      includeExchangeRates?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetTransaction200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(
        orgId,
        transactionId,
        includeExchangeRates,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all the transactions for your Bitwave organization.
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {string} [categorizationStatus]
     * @param {boolean} [includeExchangeRates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionsPaginated(
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      categorizationStatus?: string,
      includeExchangeRates?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetTransactionsPaginated200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionsPaginated(
        orgId,
        pageLimit,
        paginationToken,
        categorizationStatus,
        includeExchangeRates,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enables you to create a transaction manually into Bitwave.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {string} sourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async head(
      orgId: string,
      transactionId: string,
      sourceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.head(orgId, transactionId, sourceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async priceTransactionSec(
      orgId: string,
      transactionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcTxnPriceResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.priceTransactionSec(orgId, transactionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enables you to create a transaction manually into Bitwave.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {string} sourceId
     * @param {ApiSvcUpsertTransactionRequest} apiSvcUpsertTransactionRequest
     * @param {boolean} [uncategorize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertTransaction(
      orgId: string,
      transactionId: string,
      sourceId: string,
      apiSvcUpsertTransactionRequest: ApiSvcUpsertTransactionRequest,
      uncategorize?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCategorizeTransaction200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertTransaction(
        orgId,
        transactionId,
        sourceId,
        apiSvcUpsertTransactionRequest,
        uncategorize,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} sourceId
     * @param {Array<ApiSvcIndexedTransactionsUnionType>} apiSvcIndexedTransactionsUnionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertTransactions(
      orgId: string,
      sourceId: string,
      apiSvcIndexedTransactionsUnionType: Array<ApiSvcIndexedTransactionsUnionType>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiSvcTxnResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertTransactions(
        orgId,
        sourceId,
        apiSvcIndexedTransactionsUnionType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TransactionsApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(orgId: string, transactionId: string, options?: any): AxiosPromise<void> {
      return localVarFp._delete(orgId, transactionId, options).then((request) => request(axios, basePath));
    },
    /**
     * Categorizes your transaction within Bitwave. Some of the categories within Bitwave include Simple, Account Transfer, Detailed, Invoice/Bill Payment, and DeFi.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {ApiSvcTransactionData} apiSvcTransactionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categorizeTransaction(
      orgId: string,
      transactionId: string,
      apiSvcTransactionData: ApiSvcTransactionData,
      options?: any
    ): AxiosPromise<ApiSvcCategorizeTransaction200Response> {
      return localVarFp
        .categorizeTransaction(orgId, transactionId, apiSvcTransactionData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {ApiSvcTransactionData} apiSvcTransactionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categorizeTransactionContext(
      orgId: string,
      transactionId: string,
      apiSvcTransactionData: ApiSvcTransactionData,
      options?: any
    ): AxiosPromise<ApiSvcCategorizeTransaction200Response> {
      return localVarFp
        .categorizeTransactionContext(orgId, transactionId, apiSvcTransactionData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {Array<Array<ApiSvcTxnCategorizeRow>>} apiSvcTxnCategorizeRow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categorizeTransactions(
      orgId: string,
      apiSvcTxnCategorizeRow: Array<Array<ApiSvcTxnCategorizeRow>>,
      options?: any
    ): AxiosPromise<ApiSvcCategorizeTransactions200Response> {
      return localVarFp
        .categorizeTransactions(orgId, apiSvcTxnCategorizeRow, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create Transaction batch
     * @param {string} orgId
     * @param {Array<ApiSvcCreateTransactionDTO>} apiSvcCreateTransactionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransactionBatch(
      orgId: string,
      apiSvcCreateTransactionDTO: Array<ApiSvcCreateTransactionDTO>,
      options?: any
    ): AxiosPromise<ApiSvcCreateTransactionBatch200Response> {
      return localVarFp
        .createTransactionBatch(orgId, apiSvcCreateTransactionDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Provides you with additional transaction details, such as time, address, and transaction amount.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {boolean} [includeExchangeRates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction(
      orgId: string,
      transactionId: string,
      includeExchangeRates?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcGetTransaction200Response> {
      return localVarFp
        .getTransaction(orgId, transactionId, includeExchangeRates, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns all the transactions for your Bitwave organization.
     * @param {string} orgId
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {string} [categorizationStatus]
     * @param {boolean} [includeExchangeRates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionsPaginated(
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      categorizationStatus?: string,
      includeExchangeRates?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcGetTransactionsPaginated200Response> {
      return localVarFp
        .getTransactionsPaginated(
          orgId,
          pageLimit,
          paginationToken,
          categorizationStatus,
          includeExchangeRates,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Enables you to create a transaction manually into Bitwave.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {string} sourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    head(orgId: string, transactionId: string, sourceId: string, options?: any): AxiosPromise<void> {
      return localVarFp.head(orgId, transactionId, sourceId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceTransactionSec(orgId: string, transactionId: string, options?: any): AxiosPromise<ApiSvcTxnPriceResult> {
      return localVarFp.priceTransactionSec(orgId, transactionId, options).then((request) => request(axios, basePath));
    },
    /**
     * Enables you to create a transaction manually into Bitwave.
     * @param {string} orgId
     * @param {string} transactionId
     * @param {string} sourceId
     * @param {ApiSvcUpsertTransactionRequest} apiSvcUpsertTransactionRequest
     * @param {boolean} [uncategorize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertTransaction(
      orgId: string,
      transactionId: string,
      sourceId: string,
      apiSvcUpsertTransactionRequest: ApiSvcUpsertTransactionRequest,
      uncategorize?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcCategorizeTransaction200Response> {
      return localVarFp
        .upsertTransaction(orgId, transactionId, sourceId, apiSvcUpsertTransactionRequest, uncategorize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} sourceId
     * @param {Array<ApiSvcIndexedTransactionsUnionType>} apiSvcIndexedTransactionsUnionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertTransactions(
      orgId: string,
      sourceId: string,
      apiSvcIndexedTransactionsUnionType: Array<ApiSvcIndexedTransactionsUnionType>,
      options?: any
    ): AxiosPromise<Array<ApiSvcTxnResponse>> {
      return localVarFp
        .upsertTransactions(orgId, sourceId, apiSvcIndexedTransactionsUnionType, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {string} transactionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public _delete(orgId: string, transactionId: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      ._delete(orgId, transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Categorizes your transaction within Bitwave. Some of the categories within Bitwave include Simple, Account Transfer, Detailed, Invoice/Bill Payment, and DeFi.
   * @param {string} orgId
   * @param {string} transactionId
   * @param {ApiSvcTransactionData} apiSvcTransactionData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public categorizeTransaction(
    orgId: string,
    transactionId: string,
    apiSvcTransactionData: ApiSvcTransactionData,
    options?: AxiosRequestConfig
  ) {
    return TransactionsApiFp(this.configuration)
      .categorizeTransaction(orgId, transactionId, apiSvcTransactionData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} transactionId
   * @param {ApiSvcTransactionData} apiSvcTransactionData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public categorizeTransactionContext(
    orgId: string,
    transactionId: string,
    apiSvcTransactionData: ApiSvcTransactionData,
    options?: AxiosRequestConfig
  ) {
    return TransactionsApiFp(this.configuration)
      .categorizeTransactionContext(orgId, transactionId, apiSvcTransactionData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {Array<Array<ApiSvcTxnCategorizeRow>>} apiSvcTxnCategorizeRow
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public categorizeTransactions(
    orgId: string,
    apiSvcTxnCategorizeRow: Array<Array<ApiSvcTxnCategorizeRow>>,
    options?: AxiosRequestConfig
  ) {
    return TransactionsApiFp(this.configuration)
      .categorizeTransactions(orgId, apiSvcTxnCategorizeRow, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create Transaction batch
   * @param {string} orgId
   * @param {Array<ApiSvcCreateTransactionDTO>} apiSvcCreateTransactionDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public createTransactionBatch(
    orgId: string,
    apiSvcCreateTransactionDTO: Array<ApiSvcCreateTransactionDTO>,
    options?: AxiosRequestConfig
  ) {
    return TransactionsApiFp(this.configuration)
      .createTransactionBatch(orgId, apiSvcCreateTransactionDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Provides you with additional transaction details, such as time, address, and transaction amount.
   * @param {string} orgId
   * @param {string} transactionId
   * @param {boolean} [includeExchangeRates]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public getTransaction(
    orgId: string,
    transactionId: string,
    includeExchangeRates?: boolean,
    options?: AxiosRequestConfig
  ) {
    return TransactionsApiFp(this.configuration)
      .getTransaction(orgId, transactionId, includeExchangeRates, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all the transactions for your Bitwave organization.
   * @param {string} orgId
   * @param {number} [pageLimit]
   * @param {string} [paginationToken]
   * @param {string} [categorizationStatus]
   * @param {boolean} [includeExchangeRates]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public getTransactionsPaginated(
    orgId: string,
    pageLimit?: number,
    paginationToken?: string,
    categorizationStatus?: string,
    includeExchangeRates?: boolean,
    options?: AxiosRequestConfig
  ) {
    return TransactionsApiFp(this.configuration)
      .getTransactionsPaginated(orgId, pageLimit, paginationToken, categorizationStatus, includeExchangeRates, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enables you to create a transaction manually into Bitwave.
   * @param {string} orgId
   * @param {string} transactionId
   * @param {string} sourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public head(orgId: string, transactionId: string, sourceId: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .head(orgId, transactionId, sourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} transactionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public priceTransactionSec(orgId: string, transactionId: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .priceTransactionSec(orgId, transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enables you to create a transaction manually into Bitwave.
   * @param {string} orgId
   * @param {string} transactionId
   * @param {string} sourceId
   * @param {ApiSvcUpsertTransactionRequest} apiSvcUpsertTransactionRequest
   * @param {boolean} [uncategorize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public upsertTransaction(
    orgId: string,
    transactionId: string,
    sourceId: string,
    apiSvcUpsertTransactionRequest: ApiSvcUpsertTransactionRequest,
    uncategorize?: boolean,
    options?: AxiosRequestConfig
  ) {
    return TransactionsApiFp(this.configuration)
      .upsertTransaction(orgId, transactionId, sourceId, apiSvcUpsertTransactionRequest, uncategorize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} sourceId
   * @param {Array<ApiSvcIndexedTransactionsUnionType>} apiSvcIndexedTransactionsUnionType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public upsertTransactions(
    orgId: string,
    sourceId: string,
    apiSvcIndexedTransactionsUnionType: Array<ApiSvcIndexedTransactionsUnionType>,
    options?: AxiosRequestConfig
  ) {
    return TransactionsApiFp(this.configuration)
      .upsertTransactions(orgId, sourceId, apiSvcIndexedTransactionsUnionType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TransactionsV2Api - axios parameter creator
 * @export
 */
export const TransactionsV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcBulkCategorizeTransactionsDTO} apiSvcBulkCategorizeTransactionsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCategorizeTransactions: async (
      orgId: string,
      apiSvcBulkCategorizeTransactionsDTO: ApiSvcBulkCategorizeTransactionsDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('bulkCategorizeTransactions', 'orgId', orgId);
      // verify required parameter 'apiSvcBulkCategorizeTransactionsDTO' is not null or undefined
      assertParamExists(
        'bulkCategorizeTransactions',
        'apiSvcBulkCategorizeTransactionsDTO',
        apiSvcBulkCategorizeTransactionsDTO
      );
      const localVarPath = `/orgs/{orgId}/transactions`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcBulkCategorizeTransactionsDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the categorization for a transaction
     * @param {string} orgId Identifier for the organization
     * @param {string} transactionId Identifier for the transaction
     * @param {ApiSvcCategorizeTransactionDTO} apiSvcCategorizeTransactionDTO Categorize transaction DTO
     * @param {boolean} [autoReconcile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categorizeTransactionPatch: async (
      orgId: string,
      transactionId: string,
      apiSvcCategorizeTransactionDTO: ApiSvcCategorizeTransactionDTO,
      autoReconcile?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('categorizeTransactionPatch', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('categorizeTransactionPatch', 'transactionId', transactionId);
      // verify required parameter 'apiSvcCategorizeTransactionDTO' is not null or undefined
      assertParamExists('categorizeTransactionPatch', 'apiSvcCategorizeTransactionDTO', apiSvcCategorizeTransactionDTO);
      const localVarPath = `/orgs/{orgId}/transactions/{transactionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (autoReconcile !== undefined) {
        localVarQueryParameter['autoReconcile'] = autoReconcile;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCategorizeTransactionDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Checks price IDs for dirty prices
     * @param {string} orgId Identifier for the organization
     * @param {Array<string>} priceIds Set of price IDs to check for dirty status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPriceDirty: async (
      orgId: string,
      priceIds: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('checkPriceDirty', 'orgId', orgId);
      // verify required parameter 'priceIds' is not null or undefined
      assertParamExists('checkPriceDirty', 'priceIds', priceIds);
      const localVarPath = `/orgs/{orgId}/transactions/dirty`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (priceIds) {
        localVarQueryParameter['priceIds'] = priceIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Applies a rule to to a transaction
     * @param {string} orgId Identifier for the organization
     * @param {string} transactionId Identifier for the transaction
     * @param {string} ruleId Identifier for the rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkRuleApplication: async (
      orgId: string,
      transactionId: string,
      ruleId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('checkRuleApplication', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('checkRuleApplication', 'transactionId', transactionId);
      // verify required parameter 'ruleId' is not null or undefined
      assertParamExists('checkRuleApplication', 'ruleId', ruleId);
      const localVarPath = `/orgs/{orgId}/transactions/{transactionId}/rules/{ruleId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)))
        .replace(`{${'ruleId'}}`, encodeURIComponent(String(ruleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNetworks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/networks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionAuditLog: async (
      orgId: string,
      transactionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getTransactionAuditLog', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('getTransactionAuditLog', 'transactionId', transactionId);
      const localVarPath = `/orgs/{orgId}/transactions/{transactionId}/history`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionState: async (
      orgId: string,
      transactionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getTransactionState', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('getTransactionState', 'transactionId', transactionId);
      const localVarPath = `/orgs/{orgId}/transactions/{transactionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a paginated list of all transactions for a Bitwave organization
     * @param {string} orgId Identifier for the organization
     * @param {number} [pageLimit] Maximum number of transactions to return
     * @param {string} [paginationToken] Pagination token from a previous request
     * @param {ApiSvcCategorizationStatusDTO} [categorizationFilter] Filter transactitons by a categorization status
     * @param {ApiSvcReconciliationStatusDTO} [reconciliationFilter] Flag to include exchange rates in transactions
     * @param {ApiSvcReconciliationStatusDTO} [taxReconciliationFilter] Depreciated - use \&#39;taxReconciliationFilter\&#39; parameter instead
     * @param {ApiSvcAccountDetailsTypeDTO} [accountingTransactionType] Filter transactions by a specific type
     * @param {boolean} [accountingTransactionTaxable] Filter transactions by taxable or non-taxable status
     * @param {boolean} [accountingTaxDetailsMissing] Filter transactions by missing or present tax details
     * @param {boolean} [accountingIsTrade] Filter transactions by trade or non-trade
     * @param {Array<string>} [walletFilter] Filter transactions associated with one or more wallet IDs
     * @param {ApiSvcIgnoreTypeDTO} [ignoreFilter] Filter transactions by ignored status
     * @param {number} [beforeDateTime] Filter transactions from before a datetime
     * @param {number} [afterDateTime] Filter transactions from after a datetime
     * @param {string} [pivotDate] Filter transactions from before (desc dateOrder) or after (asc dateOrder) a pivot date
     * @param {Array<string>} [addresses] Filter transactions associated with one or more wallet addresses
     * @param {Array<string>} [searchTokens] Filter Transactions by one or more crypto tokens
     * @param {boolean} [errored] Filter transactions by errored or non-errored status
     * @param {ApiSvcDateOrder} [dateOrder] Set the order of transactions returned by asc or desc date
     * @param {string} [timezone] Set the timezone displayed in transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionsLite: async (
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      categorizationFilter?: ApiSvcCategorizationStatusDTO,
      reconciliationFilter?: ApiSvcReconciliationStatusDTO,
      taxReconciliationFilter?: ApiSvcReconciliationStatusDTO,
      accountingTransactionType?: ApiSvcAccountDetailsTypeDTO,
      accountingTransactionTaxable?: boolean,
      accountingTaxDetailsMissing?: boolean,
      accountingIsTrade?: boolean,
      walletFilter?: Array<string>,
      ignoreFilter?: ApiSvcIgnoreTypeDTO,
      beforeDateTime?: number,
      afterDateTime?: number,
      pivotDate?: string,
      addresses?: Array<string>,
      searchTokens?: Array<string>,
      errored?: boolean,
      dateOrder?: ApiSvcDateOrder,
      timezone?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getTransactionsLite', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/transactions`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (categorizationFilter !== undefined) {
        localVarQueryParameter['categorizationFilter'] = categorizationFilter;
      }

      if (reconciliationFilter !== undefined) {
        localVarQueryParameter['reconciliationFilter'] = reconciliationFilter;
      }

      if (taxReconciliationFilter !== undefined) {
        localVarQueryParameter['taxReconciliationFilter'] = taxReconciliationFilter;
      }

      if (accountingTransactionType !== undefined) {
        localVarQueryParameter['accountingTransactionType'] = accountingTransactionType;
      }

      if (accountingTransactionTaxable !== undefined) {
        localVarQueryParameter['accountingTransactionTaxable'] = accountingTransactionTaxable;
      }

      if (accountingTaxDetailsMissing !== undefined) {
        localVarQueryParameter['accountingTaxDetailsMissing'] = accountingTaxDetailsMissing;
      }

      if (accountingIsTrade !== undefined) {
        localVarQueryParameter['accountingIsTrade'] = accountingIsTrade;
      }

      if (walletFilter) {
        localVarQueryParameter['walletFilter'] = walletFilter;
      }

      if (ignoreFilter !== undefined) {
        localVarQueryParameter['ignoreFilter'] = ignoreFilter;
      }

      if (beforeDateTime !== undefined) {
        localVarQueryParameter['beforeDateTime'] = beforeDateTime;
      }

      if (afterDateTime !== undefined) {
        localVarQueryParameter['afterDateTime'] = afterDateTime;
      }

      if (pivotDate !== undefined) {
        localVarQueryParameter['pivotDate'] = pivotDate;
      }

      if (addresses) {
        localVarQueryParameter['addresses'] = addresses;
      }

      if (searchTokens) {
        localVarQueryParameter['searchTokens'] = searchTokens;
      }

      if (errored !== undefined) {
        localVarQueryParameter['errored'] = errored;
      }

      if (dateOrder !== undefined) {
        localVarQueryParameter['dateOrder'] = dateOrder;
      }

      if (timezone !== undefined) {
        localVarQueryParameter['timezone'] = timezone;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healTransaction: async (
      orgId: string,
      transactionId: string,
      type: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('healTransaction', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('healTransaction', 'transactionId', transactionId);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('healTransaction', 'type', type);
      const localVarPath = `/orgs/{orgId}/transactions/{transactionId}/!heal`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcUndeleteTransactionsDTO} apiSvcUndeleteTransactionsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    undeleteTransactions: async (
      orgId: string,
      apiSvcUndeleteTransactionsDTO: ApiSvcUndeleteTransactionsDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('undeleteTransactions', 'orgId', orgId);
      // verify required parameter 'apiSvcUndeleteTransactionsDTO' is not null or undefined
      assertParamExists('undeleteTransactions', 'apiSvcUndeleteTransactionsDTO', apiSvcUndeleteTransactionsDTO);
      const localVarPath = `/orgs/{orgId}/transactions!undelete`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcUndeleteTransactionsDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {ApiSvcTransactionStateUpdateDTO} apiSvcTransactionStateUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTransactionStatus: async (
      orgId: string,
      transactionId: string,
      apiSvcTransactionStateUpdateDTO: ApiSvcTransactionStateUpdateDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateTransactionStatus', 'orgId', orgId);
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('updateTransactionStatus', 'transactionId', transactionId);
      // verify required parameter 'apiSvcTransactionStateUpdateDTO' is not null or undefined
      assertParamExists('updateTransactionStatus', 'apiSvcTransactionStateUpdateDTO', apiSvcTransactionStateUpdateDTO);
      const localVarPath = `/orgs/{orgId}/transactions/{transactionId}/state`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'transactionId'}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcTransactionStateUpdateDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransactionsV2Api - functional programming interface
 * @export
 */
export const TransactionsV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TransactionsV2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcBulkCategorizeTransactionsDTO} apiSvcBulkCategorizeTransactionsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCategorizeTransactions(
      orgId: string,
      apiSvcBulkCategorizeTransactionsDTO: ApiSvcBulkCategorizeTransactionsDTO,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcBulkCategorizeTransactions200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCategorizeTransactions(
        orgId,
        apiSvcBulkCategorizeTransactionsDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates the categorization for a transaction
     * @param {string} orgId Identifier for the organization
     * @param {string} transactionId Identifier for the transaction
     * @param {ApiSvcCategorizeTransactionDTO} apiSvcCategorizeTransactionDTO Categorize transaction DTO
     * @param {boolean} [autoReconcile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categorizeTransactionPatch(
      orgId: string,
      transactionId: string,
      apiSvcCategorizeTransactionDTO: ApiSvcCategorizeTransactionDTO,
      autoReconcile?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcTxnErrorResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categorizeTransactionPatch(
        orgId,
        transactionId,
        apiSvcCategorizeTransactionDTO,
        autoReconcile,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Checks price IDs for dirty prices
     * @param {string} orgId Identifier for the organization
     * @param {Array<string>} priceIds Set of price IDs to check for dirty status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkPriceDirty(
      orgId: string,
      priceIds: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCheckPriceDirty200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkPriceDirty(orgId, priceIds, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Applies a rule to to a transaction
     * @param {string} orgId Identifier for the organization
     * @param {string} transactionId Identifier for the transaction
     * @param {string} ruleId Identifier for the rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkRuleApplication(
      orgId: string,
      transactionId: string,
      ruleId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCheckRuleApplication200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkRuleApplication(
        orgId,
        transactionId,
        ruleId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNetworks(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNetworks(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionAuditLog(
      orgId: string,
      transactionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetTransactionAuditLog200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionAuditLog(orgId, transactionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionState(
      orgId: string,
      transactionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetTransactionState200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionState(orgId, transactionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns a paginated list of all transactions for a Bitwave organization
     * @param {string} orgId Identifier for the organization
     * @param {number} [pageLimit] Maximum number of transactions to return
     * @param {string} [paginationToken] Pagination token from a previous request
     * @param {ApiSvcCategorizationStatusDTO} [categorizationFilter] Filter transactitons by a categorization status
     * @param {ApiSvcReconciliationStatusDTO} [reconciliationFilter] Flag to include exchange rates in transactions
     * @param {ApiSvcReconciliationStatusDTO} [taxReconciliationFilter] Depreciated - use \&#39;taxReconciliationFilter\&#39; parameter instead
     * @param {ApiSvcAccountDetailsTypeDTO} [accountingTransactionType] Filter transactions by a specific type
     * @param {boolean} [accountingTransactionTaxable] Filter transactions by taxable or non-taxable status
     * @param {boolean} [accountingTaxDetailsMissing] Filter transactions by missing or present tax details
     * @param {boolean} [accountingIsTrade] Filter transactions by trade or non-trade
     * @param {Array<string>} [walletFilter] Filter transactions associated with one or more wallet IDs
     * @param {ApiSvcIgnoreTypeDTO} [ignoreFilter] Filter transactions by ignored status
     * @param {number} [beforeDateTime] Filter transactions from before a datetime
     * @param {number} [afterDateTime] Filter transactions from after a datetime
     * @param {string} [pivotDate] Filter transactions from before (desc dateOrder) or after (asc dateOrder) a pivot date
     * @param {Array<string>} [addresses] Filter transactions associated with one or more wallet addresses
     * @param {Array<string>} [searchTokens] Filter Transactions by one or more crypto tokens
     * @param {boolean} [errored] Filter transactions by errored or non-errored status
     * @param {ApiSvcDateOrder} [dateOrder] Set the order of transactions returned by asc or desc date
     * @param {string} [timezone] Set the timezone displayed in transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionsLite(
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      categorizationFilter?: ApiSvcCategorizationStatusDTO,
      reconciliationFilter?: ApiSvcReconciliationStatusDTO,
      taxReconciliationFilter?: ApiSvcReconciliationStatusDTO,
      accountingTransactionType?: ApiSvcAccountDetailsTypeDTO,
      accountingTransactionTaxable?: boolean,
      accountingTaxDetailsMissing?: boolean,
      accountingIsTrade?: boolean,
      walletFilter?: Array<string>,
      ignoreFilter?: ApiSvcIgnoreTypeDTO,
      beforeDateTime?: number,
      afterDateTime?: number,
      pivotDate?: string,
      addresses?: Array<string>,
      searchTokens?: Array<string>,
      errored?: boolean,
      dateOrder?: ApiSvcDateOrder,
      timezone?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetTransactionsLite200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionsLite(
        orgId,
        pageLimit,
        paginationToken,
        categorizationFilter,
        reconciliationFilter,
        taxReconciliationFilter,
        accountingTransactionType,
        accountingTransactionTaxable,
        accountingTaxDetailsMissing,
        accountingIsTrade,
        walletFilter,
        ignoreFilter,
        beforeDateTime,
        afterDateTime,
        pivotDate,
        addresses,
        searchTokens,
        errored,
        dateOrder,
        timezone,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healTransaction(
      orgId: string,
      transactionId: string,
      type: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcUndeleteTransactions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healTransaction(orgId, transactionId, type, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcUndeleteTransactionsDTO} apiSvcUndeleteTransactionsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async undeleteTransactions(
      orgId: string,
      apiSvcUndeleteTransactionsDTO: ApiSvcUndeleteTransactionsDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcUndeleteTransactions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.undeleteTransactions(
        orgId,
        apiSvcUndeleteTransactionsDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {ApiSvcTransactionStateUpdateDTO} apiSvcTransactionStateUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTransactionStatus(
      orgId: string,
      transactionId: string,
      apiSvcTransactionStateUpdateDTO: ApiSvcTransactionStateUpdateDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcUpdateTransactionStatus200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionStatus(
        orgId,
        transactionId,
        apiSvcTransactionStateUpdateDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TransactionsV2Api - factory interface
 * @export
 */
export const TransactionsV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TransactionsV2ApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcBulkCategorizeTransactionsDTO} apiSvcBulkCategorizeTransactionsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCategorizeTransactions(
      orgId: string,
      apiSvcBulkCategorizeTransactionsDTO: ApiSvcBulkCategorizeTransactionsDTO,
      options?: any
    ): AxiosPromise<ApiSvcBulkCategorizeTransactions200Response> {
      return localVarFp
        .bulkCategorizeTransactions(orgId, apiSvcBulkCategorizeTransactionsDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the categorization for a transaction
     * @param {string} orgId Identifier for the organization
     * @param {string} transactionId Identifier for the transaction
     * @param {ApiSvcCategorizeTransactionDTO} apiSvcCategorizeTransactionDTO Categorize transaction DTO
     * @param {boolean} [autoReconcile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categorizeTransactionPatch(
      orgId: string,
      transactionId: string,
      apiSvcCategorizeTransactionDTO: ApiSvcCategorizeTransactionDTO,
      autoReconcile?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcTxnErrorResponse> {
      return localVarFp
        .categorizeTransactionPatch(orgId, transactionId, apiSvcCategorizeTransactionDTO, autoReconcile, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Checks price IDs for dirty prices
     * @param {string} orgId Identifier for the organization
     * @param {Array<string>} priceIds Set of price IDs to check for dirty status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPriceDirty(
      orgId: string,
      priceIds: Array<string>,
      options?: any
    ): AxiosPromise<ApiSvcCheckPriceDirty200Response> {
      return localVarFp.checkPriceDirty(orgId, priceIds, options).then((request) => request(axios, basePath));
    },
    /**
     * Applies a rule to to a transaction
     * @param {string} orgId Identifier for the organization
     * @param {string} transactionId Identifier for the transaction
     * @param {string} ruleId Identifier for the rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkRuleApplication(
      orgId: string,
      transactionId: string,
      ruleId: string,
      options?: any
    ): AxiosPromise<ApiSvcCheckRuleApplication200Response> {
      return localVarFp
        .checkRuleApplication(orgId, transactionId, ruleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNetworks(options?: any): AxiosPromise<Array<any>> {
      return localVarFp.getNetworks(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionAuditLog(
      orgId: string,
      transactionId: string,
      options?: any
    ): AxiosPromise<ApiSvcGetTransactionAuditLog200Response> {
      return localVarFp
        .getTransactionAuditLog(orgId, transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionState(
      orgId: string,
      transactionId: string,
      options?: any
    ): AxiosPromise<ApiSvcGetTransactionState200Response> {
      return localVarFp.getTransactionState(orgId, transactionId, options).then((request) => request(axios, basePath));
    },
    /**
     * Returns a paginated list of all transactions for a Bitwave organization
     * @param {string} orgId Identifier for the organization
     * @param {number} [pageLimit] Maximum number of transactions to return
     * @param {string} [paginationToken] Pagination token from a previous request
     * @param {ApiSvcCategorizationStatusDTO} [categorizationFilter] Filter transactitons by a categorization status
     * @param {ApiSvcReconciliationStatusDTO} [reconciliationFilter] Flag to include exchange rates in transactions
     * @param {ApiSvcReconciliationStatusDTO} [taxReconciliationFilter] Depreciated - use \&#39;taxReconciliationFilter\&#39; parameter instead
     * @param {ApiSvcAccountDetailsTypeDTO} [accountingTransactionType] Filter transactions by a specific type
     * @param {boolean} [accountingTransactionTaxable] Filter transactions by taxable or non-taxable status
     * @param {boolean} [accountingTaxDetailsMissing] Filter transactions by missing or present tax details
     * @param {boolean} [accountingIsTrade] Filter transactions by trade or non-trade
     * @param {Array<string>} [walletFilter] Filter transactions associated with one or more wallet IDs
     * @param {ApiSvcIgnoreTypeDTO} [ignoreFilter] Filter transactions by ignored status
     * @param {number} [beforeDateTime] Filter transactions from before a datetime
     * @param {number} [afterDateTime] Filter transactions from after a datetime
     * @param {string} [pivotDate] Filter transactions from before (desc dateOrder) or after (asc dateOrder) a pivot date
     * @param {Array<string>} [addresses] Filter transactions associated with one or more wallet addresses
     * @param {Array<string>} [searchTokens] Filter Transactions by one or more crypto tokens
     * @param {boolean} [errored] Filter transactions by errored or non-errored status
     * @param {ApiSvcDateOrder} [dateOrder] Set the order of transactions returned by asc or desc date
     * @param {string} [timezone] Set the timezone displayed in transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionsLite(
      orgId: string,
      pageLimit?: number,
      paginationToken?: string,
      categorizationFilter?: ApiSvcCategorizationStatusDTO,
      reconciliationFilter?: ApiSvcReconciliationStatusDTO,
      taxReconciliationFilter?: ApiSvcReconciliationStatusDTO,
      accountingTransactionType?: ApiSvcAccountDetailsTypeDTO,
      accountingTransactionTaxable?: boolean,
      accountingTaxDetailsMissing?: boolean,
      accountingIsTrade?: boolean,
      walletFilter?: Array<string>,
      ignoreFilter?: ApiSvcIgnoreTypeDTO,
      beforeDateTime?: number,
      afterDateTime?: number,
      pivotDate?: string,
      addresses?: Array<string>,
      searchTokens?: Array<string>,
      errored?: boolean,
      dateOrder?: ApiSvcDateOrder,
      timezone?: string,
      options?: any
    ): AxiosPromise<ApiSvcGetTransactionsLite200Response> {
      return localVarFp
        .getTransactionsLite(
          orgId,
          pageLimit,
          paginationToken,
          categorizationFilter,
          reconciliationFilter,
          taxReconciliationFilter,
          accountingTransactionType,
          accountingTransactionTaxable,
          accountingTaxDetailsMissing,
          accountingIsTrade,
          walletFilter,
          ignoreFilter,
          beforeDateTime,
          afterDateTime,
          pivotDate,
          addresses,
          searchTokens,
          errored,
          dateOrder,
          timezone,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healTransaction(
      orgId: string,
      transactionId: string,
      type: string,
      options?: any
    ): AxiosPromise<ApiSvcUndeleteTransactions200Response> {
      return localVarFp
        .healTransaction(orgId, transactionId, type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcUndeleteTransactionsDTO} apiSvcUndeleteTransactionsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    undeleteTransactions(
      orgId: string,
      apiSvcUndeleteTransactionsDTO: ApiSvcUndeleteTransactionsDTO,
      options?: any
    ): AxiosPromise<ApiSvcUndeleteTransactions200Response> {
      return localVarFp
        .undeleteTransactions(orgId, apiSvcUndeleteTransactionsDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} transactionId
     * @param {ApiSvcTransactionStateUpdateDTO} apiSvcTransactionStateUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTransactionStatus(
      orgId: string,
      transactionId: string,
      apiSvcTransactionStateUpdateDTO: ApiSvcTransactionStateUpdateDTO,
      options?: any
    ): AxiosPromise<ApiSvcUpdateTransactionStatus200Response> {
      return localVarFp
        .updateTransactionStatus(orgId, transactionId, apiSvcTransactionStateUpdateDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TransactionsV2Api - object-oriented interface
 * @export
 * @class TransactionsV2Api
 * @extends {BaseAPI}
 */
export class TransactionsV2Api extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {ApiSvcBulkCategorizeTransactionsDTO} apiSvcBulkCategorizeTransactionsDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public bulkCategorizeTransactions(
    orgId: string,
    apiSvcBulkCategorizeTransactionsDTO: ApiSvcBulkCategorizeTransactionsDTO,
    options?: AxiosRequestConfig
  ) {
    return TransactionsV2ApiFp(this.configuration)
      .bulkCategorizeTransactions(orgId, apiSvcBulkCategorizeTransactionsDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the categorization for a transaction
   * @param {string} orgId Identifier for the organization
   * @param {string} transactionId Identifier for the transaction
   * @param {ApiSvcCategorizeTransactionDTO} apiSvcCategorizeTransactionDTO Categorize transaction DTO
   * @param {boolean} [autoReconcile]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public categorizeTransactionPatch(
    orgId: string,
    transactionId: string,
    apiSvcCategorizeTransactionDTO: ApiSvcCategorizeTransactionDTO,
    autoReconcile?: boolean,
    options?: AxiosRequestConfig
  ) {
    return TransactionsV2ApiFp(this.configuration)
      .categorizeTransactionPatch(orgId, transactionId, apiSvcCategorizeTransactionDTO, autoReconcile, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Checks price IDs for dirty prices
   * @param {string} orgId Identifier for the organization
   * @param {Array<string>} priceIds Set of price IDs to check for dirty status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public checkPriceDirty(orgId: string, priceIds: Array<string>, options?: AxiosRequestConfig) {
    return TransactionsV2ApiFp(this.configuration)
      .checkPriceDirty(orgId, priceIds, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Applies a rule to to a transaction
   * @param {string} orgId Identifier for the organization
   * @param {string} transactionId Identifier for the transaction
   * @param {string} ruleId Identifier for the rule
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public checkRuleApplication(orgId: string, transactionId: string, ruleId: string, options?: AxiosRequestConfig) {
    return TransactionsV2ApiFp(this.configuration)
      .checkRuleApplication(orgId, transactionId, ruleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public getNetworks(options?: AxiosRequestConfig) {
    return TransactionsV2ApiFp(this.configuration)
      .getNetworks(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} transactionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public getTransactionAuditLog(orgId: string, transactionId: string, options?: AxiosRequestConfig) {
    return TransactionsV2ApiFp(this.configuration)
      .getTransactionAuditLog(orgId, transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} transactionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public getTransactionState(orgId: string, transactionId: string, options?: AxiosRequestConfig) {
    return TransactionsV2ApiFp(this.configuration)
      .getTransactionState(orgId, transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a paginated list of all transactions for a Bitwave organization
   * @param {string} orgId Identifier for the organization
   * @param {number} [pageLimit] Maximum number of transactions to return
   * @param {string} [paginationToken] Pagination token from a previous request
   * @param {ApiSvcCategorizationStatusDTO} [categorizationFilter] Filter transactitons by a categorization status
   * @param {ApiSvcReconciliationStatusDTO} [reconciliationFilter] Flag to include exchange rates in transactions
   * @param {ApiSvcReconciliationStatusDTO} [taxReconciliationFilter] Depreciated - use \&#39;taxReconciliationFilter\&#39; parameter instead
   * @param {ApiSvcAccountDetailsTypeDTO} [accountingTransactionType] Filter transactions by a specific type
   * @param {boolean} [accountingTransactionTaxable] Filter transactions by taxable or non-taxable status
   * @param {boolean} [accountingTaxDetailsMissing] Filter transactions by missing or present tax details
   * @param {boolean} [accountingIsTrade] Filter transactions by trade or non-trade
   * @param {Array<string>} [walletFilter] Filter transactions associated with one or more wallet IDs
   * @param {ApiSvcIgnoreTypeDTO} [ignoreFilter] Filter transactions by ignored status
   * @param {number} [beforeDateTime] Filter transactions from before a datetime
   * @param {number} [afterDateTime] Filter transactions from after a datetime
   * @param {string} [pivotDate] Filter transactions from before (desc dateOrder) or after (asc dateOrder) a pivot date
   * @param {Array<string>} [addresses] Filter transactions associated with one or more wallet addresses
   * @param {Array<string>} [searchTokens] Filter Transactions by one or more crypto tokens
   * @param {boolean} [errored] Filter transactions by errored or non-errored status
   * @param {ApiSvcDateOrder} [dateOrder] Set the order of transactions returned by asc or desc date
   * @param {string} [timezone] Set the timezone displayed in transactions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public getTransactionsLite(
    orgId: string,
    pageLimit?: number,
    paginationToken?: string,
    categorizationFilter?: ApiSvcCategorizationStatusDTO,
    reconciliationFilter?: ApiSvcReconciliationStatusDTO,
    taxReconciliationFilter?: ApiSvcReconciliationStatusDTO,
    accountingTransactionType?: ApiSvcAccountDetailsTypeDTO,
    accountingTransactionTaxable?: boolean,
    accountingTaxDetailsMissing?: boolean,
    accountingIsTrade?: boolean,
    walletFilter?: Array<string>,
    ignoreFilter?: ApiSvcIgnoreTypeDTO,
    beforeDateTime?: number,
    afterDateTime?: number,
    pivotDate?: string,
    addresses?: Array<string>,
    searchTokens?: Array<string>,
    errored?: boolean,
    dateOrder?: ApiSvcDateOrder,
    timezone?: string,
    options?: AxiosRequestConfig
  ) {
    return TransactionsV2ApiFp(this.configuration)
      .getTransactionsLite(
        orgId,
        pageLimit,
        paginationToken,
        categorizationFilter,
        reconciliationFilter,
        taxReconciliationFilter,
        accountingTransactionType,
        accountingTransactionTaxable,
        accountingTaxDetailsMissing,
        accountingIsTrade,
        walletFilter,
        ignoreFilter,
        beforeDateTime,
        afterDateTime,
        pivotDate,
        addresses,
        searchTokens,
        errored,
        dateOrder,
        timezone,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} transactionId
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public healTransaction(orgId: string, transactionId: string, type: string, options?: AxiosRequestConfig) {
    return TransactionsV2ApiFp(this.configuration)
      .healTransaction(orgId, transactionId, type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcUndeleteTransactionsDTO} apiSvcUndeleteTransactionsDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public undeleteTransactions(
    orgId: string,
    apiSvcUndeleteTransactionsDTO: ApiSvcUndeleteTransactionsDTO,
    options?: AxiosRequestConfig
  ) {
    return TransactionsV2ApiFp(this.configuration)
      .undeleteTransactions(orgId, apiSvcUndeleteTransactionsDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} transactionId
   * @param {ApiSvcTransactionStateUpdateDTO} apiSvcTransactionStateUpdateDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsV2Api
   */
  public updateTransactionStatus(
    orgId: string,
    transactionId: string,
    apiSvcTransactionStateUpdateDTO: ApiSvcTransactionStateUpdateDTO,
    options?: AxiosRequestConfig
  ) {
    return TransactionsV2ApiFp(this.configuration)
      .updateTransactionStatus(orgId, transactionId, apiSvcTransactionStateUpdateDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns current user\'s account information, such as their name, email, and role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    me: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sso: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/sso`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns current user\'s account information, such as their name, email, and role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async me(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcMe200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sso(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcSso200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sso(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * Returns current user\'s account information, such as their name, email, and role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    me(options?: any): AxiosPromise<ApiSvcMe200Response> {
      return localVarFp.me(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sso(options?: any): AxiosPromise<ApiSvcSso200Response> {
      return localVarFp.sso(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * Returns current user\'s account information, such as their name, email, and role.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public me(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .me(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public sso(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .sso(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WalletsApi - axios parameter creator
 * @export
 */
export const WalletsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateWalletRequest} apiSvcCreateWalletRequest
     * @param {boolean} [makeUnique]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWallet: async (
      orgId: string,
      apiSvcCreateWalletRequest: ApiSvcCreateWalletRequest,
      makeUnique?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createWallet', 'orgId', orgId);
      // verify required parameter 'apiSvcCreateWalletRequest' is not null or undefined
      assertParamExists('createWallet', 'apiSvcCreateWalletRequest', apiSvcCreateWalletRequest);
      const localVarPath = `/orgs/{orgId}/wallets`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (makeUnique !== undefined) {
        localVarQueryParameter['makeUnique'] = makeUnique;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCreateWalletRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateWalletRoleRequestDTO} apiSvcCreateWalletRoleRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWalletRole: async (
      orgId: string,
      apiSvcCreateWalletRoleRequestDTO: ApiSvcCreateWalletRoleRequestDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createWalletRole', 'orgId', orgId);
      // verify required parameter 'apiSvcCreateWalletRoleRequestDTO' is not null or undefined
      assertParamExists('createWalletRole', 'apiSvcCreateWalletRoleRequestDTO', apiSvcCreateWalletRoleRequestDTO);
      const localVarPath = `/orgs/{orgId}/walletRoles`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcCreateWalletRoleRequestDTO,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletRoleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWalletRole: async (
      orgId: string,
      walletRoleId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('deleteWalletRole', 'orgId', orgId);
      // verify required parameter 'walletRoleId' is not null or undefined
      assertParamExists('deleteWalletRole', 'walletRoleId', walletRoleId);
      const localVarPath = `/orgs/{orgId}/walletRoles/{walletRoleId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'walletRoleId'}}`, encodeURIComponent(String(walletRoleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet: async (orgId: string, walletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getWallet', 'orgId', orgId);
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists('getWallet', 'walletId', walletId);
      const localVarPath = `/orgs/{orgId}/wallets/{walletId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'walletId'}}`, encodeURIComponent(String(walletId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletRoles: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getWalletRoles', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/walletRoles`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all the wallets associated with your organization.
     * @param {string} orgId
     * @param {ApiSvcWalletIdTypes} [walletIdType]
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {any} [networkId]
     * @param {string} [connectionId]
     * @param {boolean} [callWalletSvc]
     * @param {ApiSvcCoin} [enabledCoin]
     * @param {boolean} [loadBalances]
     * @param {boolean} [loadBalancesFairValue]
     * @param {string} [namePrefix]
     * @param {ApiSvcWalletSortOrder} [nameSortOrder]
     * @param {boolean} [excludeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallets: async (
      orgId: string,
      walletIdType?: ApiSvcWalletIdTypes,
      pageLimit?: number,
      paginationToken?: string,
      networkId?: any,
      connectionId?: string,
      callWalletSvc?: boolean,
      enabledCoin?: ApiSvcCoin,
      loadBalances?: boolean,
      loadBalancesFairValue?: boolean,
      namePrefix?: string,
      nameSortOrder?: ApiSvcWalletSortOrder,
      excludeDisabled?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getWallets', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/wallets`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (walletIdType !== undefined) {
        localVarQueryParameter['walletIdType'] = walletIdType;
      }

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      if (networkId !== undefined) {
        localVarQueryParameter['networkId'] = networkId;
      }

      if (connectionId !== undefined) {
        localVarQueryParameter['connectionId'] = connectionId;
      }

      if (callWalletSvc !== undefined) {
        localVarQueryParameter['callWalletSvc'] = callWalletSvc;
      }

      if (enabledCoin !== undefined) {
        localVarQueryParameter['enabledCoin'] = enabledCoin;
      }

      if (loadBalances !== undefined) {
        localVarQueryParameter['loadBalances'] = loadBalances;
      }

      if (loadBalancesFairValue !== undefined) {
        localVarQueryParameter['loadBalancesFairValue'] = loadBalancesFairValue;
      }

      if (namePrefix !== undefined) {
        localVarQueryParameter['namePrefix'] = namePrefix;
      }

      if (nameSortOrder !== undefined) {
        localVarQueryParameter['nameSortOrder'] = nameSortOrder;
      }

      if (excludeDisabled !== undefined) {
        localVarQueryParameter['excludeDisabled'] = excludeDisabled;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {Array<ApiSvcUpdateWalletBalanceRequest>} apiSvcUpdateWalletBalanceRequest
     * @param {number} [timestampSEC]
     * @param {boolean} [updateLiveBalance]
     * @param {boolean} [runBalanceCheck]
     * @param {boolean} [fullBalanceCheck]
     * @param {string} [walletDescription]
     * @param {string} [blockId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBalance: async (
      orgId: string,
      walletId: string,
      apiSvcUpdateWalletBalanceRequest: Array<ApiSvcUpdateWalletBalanceRequest>,
      timestampSEC?: number,
      updateLiveBalance?: boolean,
      runBalanceCheck?: boolean,
      fullBalanceCheck?: boolean,
      walletDescription?: string,
      blockId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateBalance', 'orgId', orgId);
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists('updateBalance', 'walletId', walletId);
      // verify required parameter 'apiSvcUpdateWalletBalanceRequest' is not null or undefined
      assertParamExists('updateBalance', 'apiSvcUpdateWalletBalanceRequest', apiSvcUpdateWalletBalanceRequest);
      const localVarPath = `/orgs/{orgId}/wallets/{walletId}/balance`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'walletId'}}`, encodeURIComponent(String(walletId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (timestampSEC !== undefined) {
        localVarQueryParameter['timestampSEC'] = timestampSEC;
      }

      if (updateLiveBalance !== undefined) {
        localVarQueryParameter['updateLiveBalance'] = updateLiveBalance;
      }

      if (runBalanceCheck !== undefined) {
        localVarQueryParameter['runBalanceCheck'] = runBalanceCheck;
      }

      if (fullBalanceCheck !== undefined) {
        localVarQueryParameter['fullBalanceCheck'] = fullBalanceCheck;
      }

      if (walletDescription !== undefined) {
        localVarQueryParameter['walletDescription'] = walletDescription;
      }

      if (blockId !== undefined) {
        localVarQueryParameter['blockId'] = blockId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcUpdateWalletBalanceRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {Array<ApiSvcUpdateWalletBalancesRequest>} apiSvcUpdateWalletBalancesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBalances: async (
      orgId: string,
      walletId: string,
      apiSvcUpdateWalletBalancesRequest: Array<ApiSvcUpdateWalletBalancesRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateBalances', 'orgId', orgId);
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists('updateBalances', 'walletId', walletId);
      // verify required parameter 'apiSvcUpdateWalletBalancesRequest' is not null or undefined
      assertParamExists('updateBalances', 'apiSvcUpdateWalletBalancesRequest', apiSvcUpdateWalletBalancesRequest);
      const localVarPath = `/orgs/{orgId}/wallets/{walletId}/balances`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'walletId'}}`, encodeURIComponent(String(walletId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcUpdateWalletBalancesRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {ApiSvcPatchWalletRequest} apiSvcPatchWalletRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWallet: async (
      orgId: string,
      walletId: string,
      apiSvcPatchWalletRequest: ApiSvcPatchWalletRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateWallet', 'orgId', orgId);
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists('updateWallet', 'walletId', walletId);
      // verify required parameter 'apiSvcPatchWalletRequest' is not null or undefined
      assertParamExists('updateWallet', 'apiSvcPatchWalletRequest', apiSvcPatchWalletRequest);
      const localVarPath = `/orgs/{orgId}/wallets/{walletId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'walletId'}}`, encodeURIComponent(String(walletId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiSvcPatchWalletRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateWalletDTO} apiSvcCreateWalletDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateWallet: async (
      orgId: string,
      apiSvcCreateWalletDTO: ApiSvcCreateWalletDTO,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('validateWallet', 'orgId', orgId);
      // verify required parameter 'apiSvcCreateWalletDTO' is not null or undefined
      assertParamExists('validateWallet', 'apiSvcCreateWalletDTO', apiSvcCreateWalletDTO);
      const localVarPath = `/orgs/{orgId}/wallets/validate`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(apiSvcCreateWalletDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WalletsApi - functional programming interface
 * @export
 */
export const WalletsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WalletsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateWalletRequest} apiSvcCreateWalletRequest
     * @param {boolean} [makeUnique]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWallet(
      orgId: string,
      apiSvcCreateWalletRequest: ApiSvcCreateWalletRequest,
      makeUnique?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcCreateWallet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWallet(
        orgId,
        apiSvcCreateWalletRequest,
        makeUnique,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateWalletRoleRequestDTO} apiSvcCreateWalletRoleRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWalletRole(
      orgId: string,
      apiSvcCreateWalletRoleRequestDTO: ApiSvcCreateWalletRoleRequestDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWalletRole(
        orgId,
        apiSvcCreateWalletRoleRequestDTO,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletRoleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteWalletRole(
      orgId: string,
      walletRoleId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWalletRole(orgId, walletRoleId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWallet(
      orgId: string,
      walletId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetWallet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWallet(orgId, walletId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWalletRoles(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetWalletRoles200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletRoles(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all the wallets associated with your organization.
     * @param {string} orgId
     * @param {ApiSvcWalletIdTypes} [walletIdType]
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {any} [networkId]
     * @param {string} [connectionId]
     * @param {boolean} [callWalletSvc]
     * @param {ApiSvcCoin} [enabledCoin]
     * @param {boolean} [loadBalances]
     * @param {boolean} [loadBalancesFairValue]
     * @param {string} [namePrefix]
     * @param {ApiSvcWalletSortOrder} [nameSortOrder]
     * @param {boolean} [excludeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWallets(
      orgId: string,
      walletIdType?: ApiSvcWalletIdTypes,
      pageLimit?: number,
      paginationToken?: string,
      networkId?: any,
      connectionId?: string,
      callWalletSvc?: boolean,
      enabledCoin?: ApiSvcCoin,
      loadBalances?: boolean,
      loadBalancesFairValue?: boolean,
      namePrefix?: string,
      nameSortOrder?: ApiSvcWalletSortOrder,
      excludeDisabled?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcGetWallets200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWallets(
        orgId,
        walletIdType,
        pageLimit,
        paginationToken,
        networkId,
        connectionId,
        callWalletSvc,
        enabledCoin,
        loadBalances,
        loadBalancesFairValue,
        namePrefix,
        nameSortOrder,
        excludeDisabled,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {Array<ApiSvcUpdateWalletBalanceRequest>} apiSvcUpdateWalletBalanceRequest
     * @param {number} [timestampSEC]
     * @param {boolean} [updateLiveBalance]
     * @param {boolean} [runBalanceCheck]
     * @param {boolean} [fullBalanceCheck]
     * @param {string} [walletDescription]
     * @param {string} [blockId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBalance(
      orgId: string,
      walletId: string,
      apiSvcUpdateWalletBalanceRequest: Array<ApiSvcUpdateWalletBalanceRequest>,
      timestampSEC?: number,
      updateLiveBalance?: boolean,
      runBalanceCheck?: boolean,
      fullBalanceCheck?: boolean,
      walletDescription?: string,
      blockId?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcWalletsErrorResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBalance(
        orgId,
        walletId,
        apiSvcUpdateWalletBalanceRequest,
        timestampSEC,
        updateLiveBalance,
        runBalanceCheck,
        fullBalanceCheck,
        walletDescription,
        blockId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {Array<ApiSvcUpdateWalletBalancesRequest>} apiSvcUpdateWalletBalancesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBalances(
      orgId: string,
      walletId: string,
      apiSvcUpdateWalletBalancesRequest: Array<ApiSvcUpdateWalletBalancesRequest>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSvcWalletsErrorResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBalances(
        orgId,
        walletId,
        apiSvcUpdateWalletBalancesRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {ApiSvcPatchWalletRequest} apiSvcPatchWalletRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateWallet(
      orgId: string,
      walletId: string,
      apiSvcPatchWalletRequest: ApiSvcPatchWalletRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateWallet(
        orgId,
        walletId,
        apiSvcPatchWalletRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateWalletDTO} apiSvcCreateWalletDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateWallet(
      orgId: string,
      apiSvcCreateWalletDTO: ApiSvcCreateWalletDTO,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateWallet(orgId, apiSvcCreateWalletDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WalletsApi - factory interface
 * @export
 */
export const WalletsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WalletsApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateWalletRequest} apiSvcCreateWalletRequest
     * @param {boolean} [makeUnique]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWallet(
      orgId: string,
      apiSvcCreateWalletRequest: ApiSvcCreateWalletRequest,
      makeUnique?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcCreateWallet200Response> {
      return localVarFp
        .createWallet(orgId, apiSvcCreateWalletRequest, makeUnique, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateWalletRoleRequestDTO} apiSvcCreateWalletRoleRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWalletRole(
      orgId: string,
      apiSvcCreateWalletRoleRequestDTO: ApiSvcCreateWalletRoleRequestDTO,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .createWalletRole(orgId, apiSvcCreateWalletRoleRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletRoleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWalletRole(orgId: string, walletRoleId: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteWalletRole(orgId, walletRoleId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet(orgId: string, walletId: string, options?: any): AxiosPromise<ApiSvcGetWallet200Response> {
      return localVarFp.getWallet(orgId, walletId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletRoles(orgId: string, options?: any): AxiosPromise<ApiSvcGetWalletRoles200Response> {
      return localVarFp.getWalletRoles(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     * Returns all the wallets associated with your organization.
     * @param {string} orgId
     * @param {ApiSvcWalletIdTypes} [walletIdType]
     * @param {number} [pageLimit]
     * @param {string} [paginationToken]
     * @param {any} [networkId]
     * @param {string} [connectionId]
     * @param {boolean} [callWalletSvc]
     * @param {ApiSvcCoin} [enabledCoin]
     * @param {boolean} [loadBalances]
     * @param {boolean} [loadBalancesFairValue]
     * @param {string} [namePrefix]
     * @param {ApiSvcWalletSortOrder} [nameSortOrder]
     * @param {boolean} [excludeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallets(
      orgId: string,
      walletIdType?: ApiSvcWalletIdTypes,
      pageLimit?: number,
      paginationToken?: string,
      networkId?: any,
      connectionId?: string,
      callWalletSvc?: boolean,
      enabledCoin?: ApiSvcCoin,
      loadBalances?: boolean,
      loadBalancesFairValue?: boolean,
      namePrefix?: string,
      nameSortOrder?: ApiSvcWalletSortOrder,
      excludeDisabled?: boolean,
      options?: any
    ): AxiosPromise<ApiSvcGetWallets200Response> {
      return localVarFp
        .getWallets(
          orgId,
          walletIdType,
          pageLimit,
          paginationToken,
          networkId,
          connectionId,
          callWalletSvc,
          enabledCoin,
          loadBalances,
          loadBalancesFairValue,
          namePrefix,
          nameSortOrder,
          excludeDisabled,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {Array<ApiSvcUpdateWalletBalanceRequest>} apiSvcUpdateWalletBalanceRequest
     * @param {number} [timestampSEC]
     * @param {boolean} [updateLiveBalance]
     * @param {boolean} [runBalanceCheck]
     * @param {boolean} [fullBalanceCheck]
     * @param {string} [walletDescription]
     * @param {string} [blockId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBalance(
      orgId: string,
      walletId: string,
      apiSvcUpdateWalletBalanceRequest: Array<ApiSvcUpdateWalletBalanceRequest>,
      timestampSEC?: number,
      updateLiveBalance?: boolean,
      runBalanceCheck?: boolean,
      fullBalanceCheck?: boolean,
      walletDescription?: string,
      blockId?: string,
      options?: any
    ): AxiosPromise<ApiSvcWalletsErrorResponse> {
      return localVarFp
        .updateBalance(
          orgId,
          walletId,
          apiSvcUpdateWalletBalanceRequest,
          timestampSEC,
          updateLiveBalance,
          runBalanceCheck,
          fullBalanceCheck,
          walletDescription,
          blockId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {Array<ApiSvcUpdateWalletBalancesRequest>} apiSvcUpdateWalletBalancesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBalances(
      orgId: string,
      walletId: string,
      apiSvcUpdateWalletBalancesRequest: Array<ApiSvcUpdateWalletBalancesRequest>,
      options?: any
    ): AxiosPromise<ApiSvcWalletsErrorResponse> {
      return localVarFp
        .updateBalances(orgId, walletId, apiSvcUpdateWalletBalancesRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} walletId
     * @param {ApiSvcPatchWalletRequest} apiSvcPatchWalletRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWallet(
      orgId: string,
      walletId: string,
      apiSvcPatchWalletRequest: ApiSvcPatchWalletRequest,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .updateWallet(orgId, walletId, apiSvcPatchWalletRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {ApiSvcCreateWalletDTO} apiSvcCreateWalletDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateWallet(orgId: string, apiSvcCreateWalletDTO: ApiSvcCreateWalletDTO, options?: any): AxiosPromise<any> {
      return localVarFp
        .validateWallet(orgId, apiSvcCreateWalletDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WalletsApi - object-oriented interface
 * @export
 * @class WalletsApi
 * @extends {BaseAPI}
 */
export class WalletsApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {ApiSvcCreateWalletRequest} apiSvcCreateWalletRequest
   * @param {boolean} [makeUnique]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public createWallet(
    orgId: string,
    apiSvcCreateWalletRequest: ApiSvcCreateWalletRequest,
    makeUnique?: boolean,
    options?: AxiosRequestConfig
  ) {
    return WalletsApiFp(this.configuration)
      .createWallet(orgId, apiSvcCreateWalletRequest, makeUnique, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcCreateWalletRoleRequestDTO} apiSvcCreateWalletRoleRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public createWalletRole(
    orgId: string,
    apiSvcCreateWalletRoleRequestDTO: ApiSvcCreateWalletRoleRequestDTO,
    options?: AxiosRequestConfig
  ) {
    return WalletsApiFp(this.configuration)
      .createWalletRole(orgId, apiSvcCreateWalletRoleRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} walletRoleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public deleteWalletRole(orgId: string, walletRoleId: string, options?: AxiosRequestConfig) {
    return WalletsApiFp(this.configuration)
      .deleteWalletRole(orgId, walletRoleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} walletId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public getWallet(orgId: string, walletId: string, options?: AxiosRequestConfig) {
    return WalletsApiFp(this.configuration)
      .getWallet(orgId, walletId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public getWalletRoles(orgId: string, options?: AxiosRequestConfig) {
    return WalletsApiFp(this.configuration)
      .getWalletRoles(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all the wallets associated with your organization.
   * @param {string} orgId
   * @param {ApiSvcWalletIdTypes} [walletIdType]
   * @param {number} [pageLimit]
   * @param {string} [paginationToken]
   * @param {any} [networkId]
   * @param {string} [connectionId]
   * @param {boolean} [callWalletSvc]
   * @param {ApiSvcCoin} [enabledCoin]
   * @param {boolean} [loadBalances]
   * @param {boolean} [loadBalancesFairValue]
   * @param {string} [namePrefix]
   * @param {ApiSvcWalletSortOrder} [nameSortOrder]
   * @param {boolean} [excludeDisabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public getWallets(
    orgId: string,
    walletIdType?: ApiSvcWalletIdTypes,
    pageLimit?: number,
    paginationToken?: string,
    networkId?: any,
    connectionId?: string,
    callWalletSvc?: boolean,
    enabledCoin?: ApiSvcCoin,
    loadBalances?: boolean,
    loadBalancesFairValue?: boolean,
    namePrefix?: string,
    nameSortOrder?: ApiSvcWalletSortOrder,
    excludeDisabled?: boolean,
    options?: AxiosRequestConfig
  ) {
    return WalletsApiFp(this.configuration)
      .getWallets(
        orgId,
        walletIdType,
        pageLimit,
        paginationToken,
        networkId,
        connectionId,
        callWalletSvc,
        enabledCoin,
        loadBalances,
        loadBalancesFairValue,
        namePrefix,
        nameSortOrder,
        excludeDisabled,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} walletId
   * @param {Array<ApiSvcUpdateWalletBalanceRequest>} apiSvcUpdateWalletBalanceRequest
   * @param {number} [timestampSEC]
   * @param {boolean} [updateLiveBalance]
   * @param {boolean} [runBalanceCheck]
   * @param {boolean} [fullBalanceCheck]
   * @param {string} [walletDescription]
   * @param {string} [blockId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public updateBalance(
    orgId: string,
    walletId: string,
    apiSvcUpdateWalletBalanceRequest: Array<ApiSvcUpdateWalletBalanceRequest>,
    timestampSEC?: number,
    updateLiveBalance?: boolean,
    runBalanceCheck?: boolean,
    fullBalanceCheck?: boolean,
    walletDescription?: string,
    blockId?: string,
    options?: AxiosRequestConfig
  ) {
    return WalletsApiFp(this.configuration)
      .updateBalance(
        orgId,
        walletId,
        apiSvcUpdateWalletBalanceRequest,
        timestampSEC,
        updateLiveBalance,
        runBalanceCheck,
        fullBalanceCheck,
        walletDescription,
        blockId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} walletId
   * @param {Array<ApiSvcUpdateWalletBalancesRequest>} apiSvcUpdateWalletBalancesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public updateBalances(
    orgId: string,
    walletId: string,
    apiSvcUpdateWalletBalancesRequest: Array<ApiSvcUpdateWalletBalancesRequest>,
    options?: AxiosRequestConfig
  ) {
    return WalletsApiFp(this.configuration)
      .updateBalances(orgId, walletId, apiSvcUpdateWalletBalancesRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} walletId
   * @param {ApiSvcPatchWalletRequest} apiSvcPatchWalletRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public updateWallet(
    orgId: string,
    walletId: string,
    apiSvcPatchWalletRequest: ApiSvcPatchWalletRequest,
    options?: AxiosRequestConfig
  ) {
    return WalletsApiFp(this.configuration)
      .updateWallet(orgId, walletId, apiSvcPatchWalletRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {ApiSvcCreateWalletDTO} apiSvcCreateWalletDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public validateWallet(orgId: string, apiSvcCreateWalletDTO: ApiSvcCreateWalletDTO, options?: AxiosRequestConfig) {
    return WalletsApiFp(this.configuration)
      .validateWallet(orgId, apiSvcCreateWalletDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
