<template>
  <div>
    <ba-dialog
      :btnName="!asIcon ? $t('_createOrg') : null"
      :btnIcon="asIcon ? 'add_circle_outline' : null"
      btn-color="success"
      small
      :save="save"
      :canSave="!canSave"
      :title="$t('_createOrg')"
      ref="baDialog"
    >
      <v-container fluid grid-list-xl>
        <v-layout wrap align-center>
          <v-flex xs4 sm12 md4>
            <v-text-field :label="$t('_name')" v-model="name"></v-text-field>
          </v-flex>
          <v-flex xs4 sm12 md4>
            <v-autocomplete :items="timezones" label="Timezone" v-model="timezone"> </v-autocomplete>
          </v-flex>
          <v-flex xs4 sm12 md4>
            <v-autocomplete
              :items="fiats"
              :label="$tc('_baseCurrency')"
              v-model="baseCurrency"
              item-text="name"
              item-value="name"
            >
            </v-autocomplete>
          </v-flex>
        </v-layout>
      </v-container>
    </ba-dialog>
  </div>
</template>

<script>
import 'firebase/firestore';

import moment from 'moment-timezone';

import { doCookieAuth } from '@/BaseVue';

import { setOrganizations } from '../../firebase';
import { createOrgMutation } from '../../mutations/orgMutations';
import { MUT_FLAG_NEW_ORG, MUT_SET_USER, store } from '../../store';

export default {
  props: {
    asIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      dialog: false,
      name: '',
      timezone: '',
      baseCurrency: '',
    };
  },
  computed: {
    canSave() {
      return this.name.trim() !== '' && this.timezone !== '' && this.baseCurrency !== ''; // TODO fix logic
    },
    timezones() {
      const restrictedTz = ['CST6CDT'];
      return moment.tz.names().filter((tz) => restrictedTz.indexOf(tz) === -1);
    },
    fiats() {
      return this.$store.state.fiats.fiats;
    },
  },
  watch: {},
  methods: {
    async save() {
      const requestCreateOrganizations = {
        name: this.name,
        timezone: this.timezone,
        currency: this.baseCurrency,
        test: false,
        usePriceService: true,
      };
      try {
        const variables = { requestCreateOrganizations };
        const mutation = await createOrgMutation;
        const res = await this.$apollo.mutate({
          mutation,
          variables,
        });

        // refresh our token so we have scope of the newly created org
        const user = await doCookieAuth(true);
        store.commit(MUT_SET_USER, user);
        await this.$store.dispatch('updateOrgs');

        this.dialog = false;
        console.log(res);
        if (res.data?.createOrganization?.orgId) {
          await this.$store.dispatch('setCurrentOrg', res.data.createOrganization.orgId);
          this.$store.commit(MUT_FLAG_NEW_ORG, {
            orgId: res.data.createOrganization.orgId,
            createdAt: new Date(),
          });
        }
        await this.$router.push({ name: 'Settings' });
      } catch (error) {
        console.error('Error adding document: ', error);
        return false;
      } finally {
        this.name = '';
        this.timezone = '';
      }

      return true;
    },
    open() {
      this.$refs.baDialog.dialog = true;
    },
  },
};
</script>
