import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
  theme: {
    primary: '#1d2831', // "#37474F",
    secondary: '#12566e', // "#546E7A",
    accent: '#26df93',
    error: '#E53935',
    warning: '#FBC02D',
    info: '#4FC3F7',
    success: '#26A69A',
  },
  options: {
    customProperties: true,
  },
});
