import 'firebase/firestore';

import firebase from 'firebase/app';
import Cookies from 'js-cookie';
import _ from 'lodash';

import { store } from './store';

export async function setOrganizations(user: any): Promise<any> {
  if (!user) {
    alert('user must be defined');
    return;
  }
  // Get User Organizations
  // const orgsDocs = await firebase
  //   .firestore()
  //   .collection("organizations")
  //   .where("roles." + user.uid, ">=", 1)
  //   .get();
  //
  // const orgs = [];
  // orgsDocs.docs.forEach(function(orgDoc) {
  //   const pushOrg = orgDoc.data();
  //   pushOrg.id = orgDoc.id;
  //   orgs.push(pushOrg);
  // });
  //
  // store.commit(MUT_SET_ORGS, orgs);
  await store.dispatch('updateOrgs');

  const orgIds = _.map(store.state.orgs, (org: any) => org.id);

  // if whitelisted cookie org is present in org list, change to it
  let cookieOrgId = Cookies.get('orgId');
  if (store.state.authTokens?.idToken.payload.escalationOrgs.includes(cookieOrgId ?? '')) {
    cookieOrgId = undefined;
  }
  let currOrgId: string | undefined;
  if (cookieOrgId && _.includes(orgIds, cookieOrgId)) {
    currOrgId = cookieOrgId;
  } else if (orgIds.length > 0) {
    // default to the first org available
    currOrgId = orgIds[0];
  }
  const currentOrg = store.state.orgs.find((m: any) => m.id === currOrgId);
  await store.dispatch('setCurrentOrg', currOrgId);
  return currentOrg;
  // await store.commit(MUT_SET_CURRENT_ORG, currOrgId);
}
export async function getUserLocale(user: any) {
  const defaultLocale = 'en';
  if (!user) {
    alert('user must be defined');
    return defaultLocale;
  }
  const db = firebase.firestore();
  // Get User document
  const userRef = db.collection('users').doc(user.uid);
  const doc = await userRef.get();
  if (!doc.exists) {
    alert('user not in database');
    return defaultLocale;
  }
  const docData = doc.data();
  if (!docData) throw new Error('Doc data not found');
  return docData.locale ? docData.locale : defaultLocale;
}

export async function setUserLocale(user: any, locale: any) {
  if (!user) {
    throw new Error('user must be defined');
  }

  const db = firebase.firestore();
  // Get User document
  const userRef = db.collection('users').doc(user.id);
  const doc = await userRef.get();
  if (!doc.exists) {
    throw new Error('user is not in database');
  }
  await userRef.update({ locale });
}
// export async function updateUsersTable(fbUser: any) {
//   if (!fbUser) {
//     alert('user must be defined');
//     return;
//   }
//   const db = firebase.firestore();
//   const userId = fbUser.uid;
//
//   // Get User document
//   const userRef = db.collection('users').doc(userId);
//   const doc = await userRef.get();
//
//   if (doc.exists && userMatch(doc.data(), fbUser)) {
//     // console.log("doc exists: ", doc.data());
//   } else {
//     console.log('doc does not exist, creating doc from user: ', fbUser);
//     await userRef.set({
//       displayName: fbUser.displayName,
//       photoUrl: fbUser.photoUrl ? fbUser.photoUrl : '',
//       email: fbUser.email,
//     });
//   }
// }

// export function userMatch(user1: any, user2: any) {
//   return (
//     user1.displayName === user2.displayName &&
//     user1.email === user2.email &&
//     user1.photoUrl === (user2.photoUrl ? user2.photoUrl : '')
//   );
// }
