
































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { RawLocation, Route } from 'vue-router';

import StatusIndicator from '@/components/status/StatusIndicator.vue';
import Beta from '@/components/util/Beta.vue';
import NewBadge from '@/components/util/NewBadge.vue';

import UiTooltip from './UiTooltip.vue';

export interface NavItem<TMeta extends Record<string, unknown> = Record<string, unknown>> {
  to?: RawLocation;
  icon?: string;
  title: string;
  children?: Omit<NavItem<TMeta>, 'children'>[];
  meta?: TMeta;
  newTab?: boolean;
}

@Component({ components: { StatusIndicator, UiTooltip, Beta, NewBadge } })
export default class UiSidebar extends Vue {
  /** The values to show on this sidebar */
  @Prop({ default: () => [] })
  public readonly values!: NavItem[];

  /** A path to a logo image to show while the navbar is expanded */
  @Prop()
  public readonly logoSrc?: string;

  /** A path to a small, square image to show while the navbar is collapsed */
  @Prop()
  public readonly smallLogoSrc?: string;

  /** Whether to show the sidebar or not, defaults to `true`. Use this when you need to show the slot without showing the sidebar */
  @Prop({ default: true })
  public readonly show!: boolean;

  @Prop({ default: true })
  public readonly showSidebar!: boolean;

  public collapsed = false;
  public tempExpand = false;

  public get itemParentsMap() {
    return this.values.reduce((a, x) => {
      (x.children ?? []).forEach((y) => a.set(y, x));
      return a;
    }, new Map<NavItem, NavItem>());
  }

  public handleMouseOut() {
    if (this.tempExpand) {
      this.collapsed = true;
      this.tempExpand = false;
    }
  }

  public getLink(item: NavItem): string {
    const link = item.to || item.children?.[0]?.to || '';
    if (typeof link === 'string' && link.match(/^https?:\/\//)) {
      return link;
    } else if (link) {
      return '/' + this.$router.resolve(link).href;
    }
    return '';
  }

  public isExternal(item: NavItem): boolean {
    return !!(typeof item.to === 'string' && item.to.match(/^https?:\/\//));
  }

  public isActive(item: NavItem, topLevel = false) {
    return item === this.activeItem || (item.children?.length && item === this.expandedItem);
  }

  public onTopLevelClick(item: NavItem): void {
    if (item.to) {
      return;
    }

    if (item.children?.length) {
      this.expandedItem = item;
      if (this.collapsed) {
        this.collapsed = false;
        this.tempExpand = true;
      }
    }
  }

  public expandedItem: NavItem | null = null;
  public get activeItem(): NavItem | null {
    const route = this.$route;
    for (const navItem of this.values) {
      if (navItem.to && this.compareLocation(route, navItem.to)) {
        this.expandedItem = navItem.children?.length ? this.expandedItem : null;
        return navItem;
      }
      for (const navChild of navItem.children ?? []) {
        if (navChild.to && this.compareLocation(route, navChild.to)) {
          this.expandedItem = navItem;
          return navChild;
        }
      }
    }

    return null;
  }

  private compareLocation(route: Route, location: RawLocation): boolean {
    const { route: resolvedRoute } = this.$router.resolve(location);
    return (
      resolvedRoute === route ||
      (resolvedRoute.name && resolvedRoute.name === route.name) ||
      resolvedRoute.path === route.path
    );
  }
}
