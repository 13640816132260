
















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import UiCheckbox from './UiCheckbox.vue';

export interface DropdownItemOptions {
  value: unknown;
  label: string;
  icon?: string;
  disabled?: boolean;
}

@Component({ components: { UiCheckbox } })
export default class UiDropdown extends Vue {
  @Prop()
  public readonly label?: string;

  @Prop()
  public readonly value?: unknown[] | unknown;

  @Prop({ required: true })
  public readonly values!: (string | DropdownItemOptions)[] | Record<string, unknown>;

  @Prop({ default: 'left' })
  public readonly align!: 'left' | 'right';

  @Prop({ default: false })
  public readonly hasCheckBox!: boolean;

  @Prop({ default: false })
  public readonly up!: boolean;

  public showMenu = false;
  private blurTimerId?: ReturnType<typeof setTimeout>;

  $refs!: {
    menuList: any;
  };

  public get normalValues(): (DropdownItemOptions & { selected: boolean })[] {
    return (
      Array.isArray(this.values)
        ? this.values.map((x) => (typeof x === 'string' ? { value: x, label: x } : x))
        : Object.entries(this.values).map(([label, value]) => ({ label, value }))
    ).map((x) => ({ ...x, selected: (Array.isArray(this.value) && this.value?.includes(x.value)) ?? false }));
  }

  public onSelect(value: DropdownItemOptions) {
    if (value.disabled) {
      return;
    }
    this.$emit('select', value.value);
  }

  public onFocus() {
    if (this.blurTimerId) {
      clearTimeout(this.blurTimerId);
      this.blurTimerId = undefined;
    }
    this.showMenu = true;
  }

  public onBlur() {
    if (this.blurTimerId) {
      return;
    }
    this.blurTimerId = setTimeout(() => {
      if (this.blurTimerId) {
        this.showMenu = false;
        clearTimeout(this.blurTimerId);
        this.blurTimerId = undefined;
      }
    }, 50);
  }

  public onClickRow(item: DropdownItemOptions & { selected: boolean }) {
    let selectedItems = this.normalValues
      .filter((x: DropdownItemOptions & { selected: boolean }) => x.selected)
      .map((x: DropdownItemOptions & { selected: boolean }) => x.value);
    if (item.selected) {
      selectedItems = selectedItems.filter((el: unknown) => el !== item.value);
    } else {
      selectedItems.push(item.value);
    }
    this.$emit('input', selectedItems);
  }

  @Watch('showMenu')
  onMenuOpenChanged(newVal?: boolean) {
    this.$emit('open', newVal);
    if (newVal === true && !Array.isArray(this.value)) {
      const currentIndex = this.normalValues.findIndex((el) => el.value === this.value);
      if (currentIndex >= 0) {
        this.$nextTick(() => {
          const el = this.$refs.menuList.children[currentIndex];
          if (el) {
            el.scrollIntoView({ block: 'center' });
          }
        });
      }
    }
  }
}
