export const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  timestampsInSnapshots: true,
};

const isDev = process.env.VUE_APP_NODE_ENV === 'development';

export const baConfig = {
  redirect_url: process.env.VUE_APP_BASE_URL,
  apiUrl: process.env.VUE_APP_API_URL,
  ethRpcUrl: process.env.VUE_APP_ETH_RPC_URL,
  ethChainId: process.env.VUE_APP_ETH_CHAIN_ID,
  ethChain: process.env.VUE_APP_ETH_CHAIN,
  etherenetExplorerUrl: process.env.VUE_APP_ETH_EXPLORER,
  addressSvcUrl: process.env.VUE_APP_ADDRESS_SVC_URL,
  querySvcUrl: process.env.VUE_APP_QUERY_SVC_URL,
  opsUrl: process.env.VUE_APP_OPS_URL,
  ops2Url: process.env.VUE_APP_OPS2_URL,
  enterprisePaymentsUrl: process.env.VUE_APP_ENTERPRISE_PAYMENTS_URL,
  disableInactivityWatcher: process.env.VUE_APP_DISABLE_INACTIVITY_WATCHER === 'true' && isDev,
  defiSvcUrl: process.env.VUE_APP_DEFI_SVC_URL,
  intercomAppId: process.env.VUE_APP_INTERCOM_ID,
  txnsSvcURL: process.env.VUE_APP_TXN_SVC_URL,
  api2Url: process.env.VUE_APP_API_2_SVC_URL,
  api3Url: process.env.VUE_APP_API3_URL,
  rptApiUrl: process.env.VUE_APP_RPT_API_URL,
  getFriendlyApiUrl: () => {
    const url = process.env.VUE_APP_API_URL!;
    return url.substring(0, url.length - 1);
  },
};
