







import Vue from 'vue';
export default class Beta extends Vue {}
