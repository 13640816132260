export type TooltipPoint = { point: string; subPoints?: TooltipPoint[] };
export type Tooltip = { title?: string; video?: string; points?: TooltipPoint[]; html?: string };

const Tooltips: { [key: string]: Tooltip } = {
  _dashboard: {
    title: 'Dashboard Overview',
    html: `
    <div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Dashboard Overview</h1>
    <br>
    <p><iframe width="352" height="315" src="https://www.loom.com/embed/19c7c29f0f654118af30fa1ebc879994" frameborder="0" allowfullscreen></iframe>
      <br>
    </p>`,
  },
  _walletAdds: {
    title: 'How do I create or add a wallet to Bitwave?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How do I create or add a wallet to Bitwave?</h1>
    <br>
    <p><iframe width="352" height="315" src="https://www.loom.com/embed/ab2b821efd4e4ec1821037efa2fd9b72" frameborder="0" allowfullscreen></iframe>
      <br>
      <ol type="1">
  <li><b><i>Follow these steps to create or add a wallet in Bitwave:</i></b></li>
  <ul style="list-style-type: number; margin-left: 20px;">
    <li>Click on the “Create Wallet” button.</li>
    <li>Enter the wallet name, typically one that accurately represents the type of activity expected to occur within the wallet.</li>
    <li>Enter the appropriate "network" for the blockchain that the wallet is on. For example, for a Bitcoin wallet this would be BTC, for an Ethereum wallet this would be ETH. If you do not see your network, then you may choose the “Manual” wallet option.</li>
    <li>Enter address type via two options for this field: Simple or Hierarchical Deterministic. Simple is the appropriate option for all Ethereum wallets and most BTC wallets with the exception of more complex BTC wallets like ypubs, zpubs and so on.</li>
    <li>Enter the public key in the wallet address field.</li>
  </ol>
</ol>
    </p>`,
  },
  _wallets: {
    title: 'Wallet Overview',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Wallet Overview</h1>
    <br>
    <p><iframe width="352" height="315" src="https://www.loom.com/embed/e7acb9b24d334747a38f0f4380b70043" frameborder="0" allowfullscreen></iframe>
      <br>
    </p>`,
  },
  _connections: {
    title: 'Connection Overview',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Connection Overview</h1>
    <br>
    <p><iframe width="352" height="315" src="https://www.loom.com/embed/47cd7e2358b3440eabf190208a9c9767" frameborder="0" allowfullscreen></iframe>
      <br>
    </p>`,
  },
  _walletRoles: {
    title: 'Wallet Roles Overview',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Wallet Roles Overview</h1>
    <br>
    <p><iframe width="352" height="315" src="https://www.loom.com/embed/e7acb9b24d334747a38f0f4380b70043" frameborder="0" allowfullscreen></iframe>
      <br>
    </p>`,
  },
  _exchangeConnections: {
    title: 'How do I connect an exchange or custodian to Bitwave?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How do I connect an exchange or custodian to Bitwave?</h1>
    <br>
    <p><iframe width="352" height="315" src="https://www.loom.com/embed/c874c0efc72944efb1e80adcb9e14d88" frameborder="0" allowfullscreen></iframe>
      <br>
      <ol type="1">
      <li><b><i>What information do I need to connect an exchange?</i></b></li>
      <ul style="list-style-type: disc; margin-left: 20px;">
          <li>[OPTIONAL] Connection Name: An optional name for you to identify this connection within Bitwave.</li>
          <li>[REQUIRED] API Key: This is a READ ONLY API key created on your exchange platform for use by the Bitwave application.</li>
          <li>[REQUIRED] API Secret (or Private Key): This is an API Secret created on your exchange platform for use by the Bitwave application.</li>
          <li>[REQUIRED] Exchange Contact: This is a vendor/contact created in your GL (ERP) to be associated with this exchange.</li>
      </ul>
  </ol>
</p>`,
  },
  _transactions: {
    title: 'Complete Transaction UI Explained',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Complete Transaction UI Explained</h1>
    <br>
    <p><iframe width="352" height="315" src="https://drive.google.com/file/d/12_sp5fzyjq8dlWVVLw_vKFdcg7tv4anz/preview" frameborder="0" allowfullscreen></iframe>
      <br>
      <ol type="1">
      <li><b><i>How do I access the transactions screen?</i></b></li>
      On the left hand panel, navigate to All Transactions. To view transaction filters, user the drop down menu for Wallet Name, Reconciliation Status, Categorization Status, and Ignore Status.
      <br>
      <br>
      <li><b><i>How do I perform basic transaction activities?</i></b></li>
      There are a number of activities you can perform on a transaction including:
      <ul style="list-style-type: disc; margin-left: 20px;">
      <li>Combining a transaction: This allows you to combine multiple transactions.</li>
      <li>Ignoring a transaction: This allows you to ignore a transaction. This will ignore it from consideration for tax and accounting purposes.</li>
      <li>Create a transaction: This allows the user to manually create a transaction in the system.</li>
      <li>Delete a transaction: This allows the user to delete a transaction.</li>
      </ul>
  </ol>
</p>`,
  },
  _categorizeAndPrice: {
    title: 'How do I categorize transactions and modify pricing?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How do I categorize transactions and modify pricing?</h1>
    <br>
    <p><iframe width="352" height="315" src="https://www.loom.com/embed/68e6021270cb4de3a78e47dac2925954" frameborder="0" allowfullscreen></iframe>
      <br>
      <ol type="1">
      To perform individual categorizations, from the left menu, select Transactions then All Transactions. Click anywhere on the transaction window that you want to categorize OR select the Categorize button. A categorization screen will open up.
      <br>
      <br>
      <li><b><i>What are some of the basic activities you can perform with individual categorizations?</i></b></li>
      <ul style="list-style-type: disc; margin-left: 20px;">
      <li>Standard Categorization: With this categorization type, you can assign a single category and contact to a transaction. Standard categorization also allows you to assign multiple categories and multiple contacts to a transaction using "Add Line" and "Add Split" options.</li>
      <li>Trade Categorization: With the trade categorization type, you can assign a fee contact to a trade categorization.</li>
      </ul>
      <br>
      <li><b><i>How do I edit the pricing within categorization?</i></b></li>
      <ul style="list-style-type: disc; margin-left: 20px;">
      <li>In the categorization screen, you can see the pricing that our pricing engine defines for the digital assets involved in the transaction. You can also use the pencil to override that price where appropriate.</li>
      </ul>
      <br>
      <li><b><i>What other basic activities can I perform in the categorization?</i></b></li>
      <ul style="list-style-type: disc; margin-left: 20px;">
      <li>View and edit automatically categorized contract executions: These transactions are identified by a blue symbol <> in the transaction type column. If transaction inference is setup, Bitwave is able to automatically detect these as contract execution fees and categorize these based on the defaults. To setup transaction inference, Go to Left Menu, select Administration, then select Accounting Setup. Please refer to instructions to setting up your organization.</li>
      <li>View and categorize internal transfers: When two or more wallets belonging to the same entity/organization interact with each other, Bitwave is able to automatically detect movements between them and setup the necessary categorization (primarily of fees) for review and save by the user.</li>
      <li>Collapse values: This functionality in the Standard categorization screen allows you to collapse all transaction lines for the same currency into one line and perform coding of that.</li>
      <li>Link to blockchain explorer by clicking on id hyperlink: Clicking on the hyperlink associated with the transaction id takes you to an appropriate explorer for the blockchain associated with the transaction.</li>
      <li>Search for a specific transaction id: You can enter a specific transaction id in the top right search box to locate that specific transaction.</li>
      </ul>
  </ol>
</p>`,
  },
  _register: {
    title: 'What is the Register report?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What is the Register report?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/af24546d1a8442a09810ee8e7c92001d" frameborder="0" allowfullscreen></iframe>
    <br>
    <ol type="1">
    The register report is like a bank register, which lists each transaction and its impact on the balance for that wallet.
    <br>
    <br>
    <li><b><i>How do I use the register report?</i></b></li>
    <ul style="list-style-type: disc; margin-left: 20px;">
    <li>Navigate to the Register page.</li>
    <li>Select a wallet on the dropdown and then select a through date.</li>
    <li>Specify a ticker for the token you are searching for.</li>
    <li>This will provide the line by line details, display timestamps, deposits, withdrawals, and the balance of that token in the selected wallet.</li>
    </ul>
</ol>
</p>`,
  },
  _trading: {
    title: 'What is the “Trading” page?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What is the “Trading” page?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/c3cb9d456974465bab3d8e953c94dab8" frameborder="0" allowfullscreen></iframe>
    <br>
    This is where you will be able to view all transactions categorized as a trade.
    <br>
    By 'accepting' the trade, the trade will be 'marked as reconciled' in the Bitwave transactions UI.
  </p>`,
  },
  _import: {
    title: 'How do I import transactions into Bitwave?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How do I import transactions into Bitwave?</h1>
  <br>
    <p><iframe width="352" height="315" src="https://www.youtube.com/embed/civlHNiMXQw" frameborder="0" allowfullscreen></iframe>
  <br>
    <ol type="1">
      <li><b><i>Follow these steps to import transactions:</i></b></li>
        <ul style="list-style-type: number; margin-left: 20px;">
        <li>Go to the Transactions > Import page.</li>
        <li>Download a copy of the “Import Template”.</li>
        <li>Fill out the Import Template.</li>
        <li>Click on “New Import”.</li>
        <li>Follow the prompts to validate and review your import.</li>
        </ul>
      <br>
      <li><b><i>How do I create a manual wallet?</i></b></li>
        <ul style="list-style-type: number; margin-left: 20px;">
        <li>Go to the Wallets & Connections page.</li>
        <li>Click on create wallet.</li>
        <li>Search for 'manual wallet' in wallet type.</li>
        <li>Fill in the name and description field (optional).</li>
        </ul>
      <br>
      <li><b><i>When would I need a manual wallet?</i></b></li>
      If you are uploading transactions, you should create a manual wallet and upload the transactions there.
      </ol>
</p>`,
  },
  _importErrors: {
    title: 'What are some common errors when attempting to import a transactions file?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What are some common errors when attempting to import a transactions file?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/a74b51e1fbdf46639d26e880f7139edf" frameborder="0" allowfullscreen></iframe>
    <br>
    <b><i>These are some common errors when attempting to import transactions into Bitwave:</i></b>
    <br>Column A: No duplicates allowed in the id column.
    <br>Column C and G: Amount (Column C) and Fee (Column G) must be absolute values (i.e. no negative numbers).
    <br>Column I: The time must be in UTC time zone. Example of time: 2021-03-09 01:39:42 (YYYY-DD-MM HH:MM:SS).
    <br>Column L: The transaction type must be only one of these - deposit/withdrawal/tradeAcquire/tradeDispose/tradeFee (input case exactly as written here).
    <br>Column J: No duplicates allowed in the blockchainId. It can however be empty
    <br>Column Q: tradeID can be same for three rows belonging to 1 trade, this helps Bitwave to group 1 trade and auto-categorise it. However, the values in this column shall not be repeated on new CSV uploads.
    Download your file as a .csv file
  </p>`,
  },
  _rules: {
    title: 'What are Rules?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What are Rules?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/670ea1182a8449feac1f597a14083bea" frameborder="0" allowfullscreen></iframe>
    <br>If you need to categorise a large group of similar transactions, you can set up rules to automate this process.
    <br>There are rules for inbound, outbound, trades and internal transfers.
    <br>For more complex rules setup, contact your dedicated solution's team member.

  </p>`,
  },
  _exportTxns: {
    title: 'What is the Export transactions report?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What is the Export transactions report?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/734ab0052a704956bceb5d4667d7a598" frameborder="0" allowfullscreen></iframe>
    <br>
    This report provides a list of every transaction that occurred within the period entered in the start and end date complete with date/time, amount, asset, transaction hash and more.
  </p>`,
  },
  _reconcile: {
    title: 'Reconcile and Sync transactions to the ERP',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Reconcile and Sync transactions to the ERP</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.youtube.com/embed/jGJUydCq820" frameborder="0" allowfullscreen></iframe>
    <br>
    <b><i>What does 'reconcile' do?</i></b>
    <br>Reconciling transactions means to push them to your ERP. Once you have categorized all transactions and performed all necessary balance validations and reconciliations, you can push or 'reconcile' these transactions as journal entries to your ERP. You can select single or multiple transactions to reconcile.
    <br>
    <br><b><i>Can reconciliation be reversed?</i></b>
    <br>The only way to reverse the reconciliation process is to manually delete the journal entries from your ERP, unreconcile the transaction in Bitwave, make any categorization changes, and then push again. We always recommend double/triple checking transactions before pushing anything to your ERP.
  </p>`,
  },
  _categories: {
    title: 'What are categories?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What are categories?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/7abeba29affa4d81a9d11bdf44714889" frameborder="0" allowfullscreen></iframe>
    <br>
    A category refers to your GL accounts (expense account, revenue account, asset account etc). These can either be created manually or synced through from your ERP.
  </p>`,
  },
  _contacts: {
    title: 'What are contacts?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What are contacts?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/c8635e4615724bccb3ce355b745a9261" frameborder="0" allowfullscreen></iframe>
    <br>
    Contacts refer to your vendors and customers, which you can either create manually in Bitwave or sync through from your ERP. You will need to assign a contact to every transactions when categorizing.
  </p>`,
  },
  _externalCostBasis: {
    title: 'Why use the Account Transfer treatment?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Why use the Account Transfer treatment?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/2a1c1e5f366844debac73225a2ab1304" frameborder="0" allowfullscreen></iframe>
    <br>
    There are two reasons a user may want to use “Account Transfer” treatment:
    <br>1. The user wants to push a “transfer” type transaction to the ERP
    <br>Bitwave's “Standard” treatment pushes a “deposit” (for inflows) or “check/withdrawal” (for outflows) to the ERP
    <br>This is a function of the fact that “Bitwave- Digital Assets” is set up in the ERP as a bank account. Note that “Bitwave- Digital Assets” is set up as a bank account because that is the account type in the ERP that allows us to send multi-line, multidirectional transactions.
    <br>Account Transfer sends a “transfer” transaction type to the ERP instead of “deposit” or “check” withdrawal
    <br>Note that only asset and liability categories (not expense or revenue categories) will be available for selection when using the Account Transfer treatment in Bitwave.
    <br>The resulting journal entry impact of an Account Transfer is the SAME as Standard
    <br>JE impact of an inflow: Debit to Bitwave Digital Assets, Credit to user selected category
    <br>JE impact of an outflow: Debit to user selected category, Credit to Bitwave Digital Assets
    <br>2. The user wants to edit the acquisition or disposal date for short term vs. long term gain/loss calculation.

  </p>`,
  },
  _gainLoss: {
    title: 'What is the gain/loss report?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What is the gain/loss report?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/30612a4eb19d4a6883a9ff867f0dbb3a" frameborder="0" allowfullscreen></iframe>
    <br>
    This report will provide you with several pieces of data, including your realized and unrealized gains and losses, a summary of your cost basis and a categorized a lot-by-lot breakdown of of each transaction and it's corresponding cost basis, proceeds and gain/loss (actions report).
  </p>`,
  },
  _inventoryViews: {
    title: 'Inventory Views',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Inventory Views</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.youtube.com/embed/vsKOewd4_Fw" frameborder="0" allowfullscreen></iframe>
    <br>
  </p>`,
  },
  _pricing: {
    title: 'How does pricing work?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How does pricing work?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/45cd569ef1ee483eb8e964918e565eaf" frameborder="0" allowfullscreen></iframe>
    <br>
  </p>`,
  },
  _reports: {
    title: 'What do I need to grab from each of the reports?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What do I need to grab from each of the reports?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.youtube.com/embed/3lsEYCSbU9k" frameborder="0" allowfullscreen></iframe>
    <br>
    Balance report: the total FMV of your digital assets as on the end of the last day of the period you are closing.
    <br>Rolled up journal entry report: the total debits and the total credits to Bitwave digital assets, which you will find at the bottom of the report. You need to run this report for the entire period you are closing.
    <br>Gain/loss report: the total unrealized and realized gain/loss for the period. Like the rolled up journal entry report, you need to run this for the entire period you are closing.
  </p>`,
  },
  _completeBalanceReports: {
    title: 'What is the balance report?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What is the balance report?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/29c1e08da5654255987beac648263c86" frameborder="0" allowfullscreen></iframe>
    <br>This report allows you to query the balance and FMV of each digital asset at the end of any given date. For example, you wish to know what your EOD balance of BTC was on 9/22/2021, you would run the balance report for this date.
    <br>
    <br>Some of my assets don't have pricing, what should I do?
    <br>For many digital assets there is no tradable market, so don't be alarmed if you see that not all your tokens have pricing. If you believe that an asset should have a price but it isn't showing up in the balance report, inform your dedicated solutions team member who can review and add this for you.
  </p>`,
  },
  _balanceCheck: {
    title: 'How do balance checks work?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How do balance checks work?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/b1220b801dce41d389e64caf21f3df2b" frameborder="0" allowfullscreen></iframe>
    <br>
  </p>`,
  },
  _journalEntryReport: {
    title: 'What is the journal entry report?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What is the journal entry report?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/79f38192ce8b47d18be0258d55459797" frameborder="0" allowfullscreen></iframe>
    <br>
    This report provides the detailed transaction by transaction view of the rolled up journal entry report. It can be exported to a CSV file.
  </p>`,
  },
  _rolledUpJEReport: {
    title: 'What is the rolled up journal entry report?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What is the rolled up journal entry report?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/79f38192ce8b47d18be0258d55459797" frameborder="0" allowfullscreen></iframe>
    <br>
    This report provides an aggregated view of all the journal entries for the period. It can be aggregated by category and contact.
  </p>`,
  },
  _periodEndRecon: {
    title: 'What is period end recon?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What is period end recon?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/9f871709cc5a4142bf9c11e54f9bb1bf" frameborder="0" allowfullscreen></iframe>
    <br>
  </p>`,
  },
  _organization: {
    title: 'How does the Organization page work?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How does the Organization page work?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/9760d8ab15ed4d0888b9a7770111561e" frameborder="0" allowfullscreen></iframe>
    <br>What should I select as the org name?
    <br>This should ideally be the legal name of your company. This name will appear in the various reports you can generate in Bitwave.
    <br>
    <br>What is my org ID?
    <br>This is Bitwave's internal identification number of your account and cannot be changed.
    <br>
    <br>Which timezone should I select?
    <br>You should select the timezone where the legal entity resides for tax reporting purposes. You can choose to use this time for the transactions display.
    <br>What if I need to change the base currency of my org?
    <br>Reach out to your dedicated solutions team member who will be able to assist in this process.
  </p>`,
  },
  _accountingSetup: {
    title: 'How does the Accounting Setup work?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How does the Accounting Setup work?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.youtube.com/embed/qLfnte5DofU" frameborder="0" allowfullscreen></iframe>
    <br>What is accounting setup used for?
    <br>This is where you can set the default blockchain transaction fee account and contact, and the default account receivable and payable.
    <br>
    <br>How do I set it up?
    <br>First, check the 'transaction inference' box. This is you instructing Bitwave to automatically identify the gas fee part of a transaction. After that, select the relevant categories and contacts and click save.
    <br>
    <br>What are default account receivables and account payables?
    <br>If you are creating and sending out crypto invoices/bills from Bitwave then you will first need to fill these two fields out. Reach out to your dedicated solutions team member to guide you through the crypto invoicing/bill process.
  </p>`,
  },
  _accountingConnection: {
    title: 'How does the Accounting Connection work?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How does the Accounting Connection work?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.youtube.com/embed/5KZyPoSKPbk" frameborder="0" allowfullscreen></iframe>
    <br>Where do I go to connect my ERP to Bitwave?
    <br>In the left menu, go to Administration - - > Accounting Connections - - > Click Connect New Account
    <br>
    <br>How do I connect my ERP to Bitwave?
    <br>Choose your ERP in the dropdown list. If you are using QuickBooks or XERO, you will be redirected to your account to connect. For Netsuite and Sage Intacct, you will need to fill out the fields with various keys and codes. Please refer to https://docs.bitwave.io/ for more information or contact your dedicated solutions team member for assistance. Once connected, you will need to finish setup by clicking on 'finish setup' and selecting the default accounts you will use as your primary digital assets account and crypto fees expense account. If you do not have any specific accounts created already in your ERP, Bitwave will create them for you.
    <br>
    <br>How long does it take?
    <br>It usually takes about 5 minutes for all your general ledger accounts and contacts to sync through to Bitwave
    <br>
    <br>How do I know if the connection was successful?
    <br>You will be able to see in the UI that the connection was successful. You can also see your GL accounts and contacts by going to the left menu —> Accounting —> categories & contacts.
  </p>`,
  },
  _taxStrategy: {
    title: 'What does tax strategy do?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What does tax strategy do?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/fadb188624244b56bd5e50e59b5da7d5" frameborder="0" allowfullscreen></iframe>
    <br>This is where you set a strategy for the specific identification (Spec ID) tax strategy. You can choose a spending strategy to maximize or minimize short-term or long-term gain and loss. Whatever you select here will be reflected in the outcome of the gain / loss report under the specific identification tax strategy. Reach out to your dedicated support person for more information.
  </p>`,
  },
  _systemJobs: {
    title: 'What are System Jobs?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">What are System Jobs?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/7b826b78eeba4747ba13b24b0b95bdd6" frameborder="0" allowfullscreen></iframe>
    <br>
    <b><i>Follow these steps to use system jobs:</i></b>
    <ul style="list-style-type: number; margin-left: 20px;">
      <li>Go to the Administration > System Jobs page.</li>
      <li>Create a new job by clicking “Run Job”</li>
    </ul>
    <br>
    <b><i>What are system jobs?</i></b>
    <br>System jobs is a function in Bitwave that allows you to perform 'mass actions' across an entire wallet.
    <br>You can ignore, delete, reconcile, uncategorize, mark transactions as reconciled, unignore, unreconcile.
    <br>You can select a wallet, a start and/or end date.
    <br>
    <b><i>What should I be aware of before running a system job?</i></b>
    <br>This is a powerful function and is irreversible.
    <br>Ensure you are certain you wish to perform this function before doing so.
  </p>`,
  },
  _users: {
    title: 'How do I add, remove, or change user permissions?',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">How do I add, remove, or change user permissions?</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.youtube.com/embed/HnvLzVpRDqI" frameborder="0" allowfullscreen></iframe>
    <br>Add a new user:
    <br>Add a new user by clicking 'add user', and fill out the name and e-mail address. Click save.
    <br>
    <br>Revoke access for a user:
    <br>Revoking a user involves clicking on the circular icon to the left of the pencil. First, find the user you wish to revoke access for, click on the circular icon and then click 'revoke'.
    <br>
    <br>Edit a user's role/permissions:
    <br>In order to edit a user's Bitwave access, you need to click on the pencil icon, which will open up the user roles configuration for that user. Select the role that applies to the user and click save.
  </p>`,
  },
  _jeExpandedReport: {
    title: 'Journal Entry Report (Expanded) Overview',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Journal Entry Report (Expanded) Overview</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/44349961942c4090abbb4f3881e07325" frameborder="0" allowfullscreen></iframe>
    <br>
    <b><i>To Access and Run the Journal Entry Report Expanded</i></b>
    <ul style="list-style-type: number; margin-left: 20px;">
    <li>Go to Left Menu Reports</li>
    <li>Select Journal Entry (Expanded) Report</li>
    <li>Enter a Start Date, End Date and Connection Name</li>
    <li>Select the Run Report Button</li>
    <li>Click Refresh to see that you New Run has been created and that the value in Status proceeds to “Succeeded”</li>
    <li>Once Succeeded Click Download to Download the Report</li>
    </ul>
  </p>`,
  },
  _importsV2: {
    title: 'Imports Advanced',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Imports Advanced</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/a3868494b4e84f9db5b5d660bd68896d" frameborder="0" allowfullscreen></iframe>
    <br>
    <b><i>To Import Data Using Advanced Imports Functionaility</i></b>
    <ul style="list-style-type: number; margin-left: 20px;">
    <li>Go to Left Menu -> Transactions -> Imports V2 or Imports (Advanced)</li>
    <li>Enter a Name for you Import</li>
    <li>Select a File Type for your Import. In majority of cases this should be Bitwave Standard Transaction as this is our standard import format</li>
    <li>Click Next in the bottom Right Corner.</li>
    <li>Select your file and click Upload. Note this does not create transactions in bitwave at this stage</li>
    <li>Click Generate Preview to review the file prior to validation and load</li>
    <li>Once you are concluded previewing the file click Validate. Note this does not create transactions in bitwave at this stage. It does allow you to download transactions in case of validation errors.</li>
    <li>Finally once all transactions are successfully validated click Run Import. This will create transactions in Bitwave.</li>
    </ul>
  </p>`,
  },
  _bulkPaymentsOpsV2: {
    title: 'Bitwave Ops V2',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Bitwave Ops V2</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/4d550ccf755643b99bb8fab317711383" frameborder="0" allowfullscreen></iframe>
    <br>
    <b><i>How to use Bulk Payments in Bitwave</i></b>
    <ul style="list-style-type: number; margin-left: 20px;">
    <li>Click on Bitwave Ops V2, which redirects you to a new tab.</li>
    <li>Select the org in which the sending wallet address has been added.</li>
    <li>Connect your wallet.</li>
    <li>Click 'Create new payment' and select the appropriate blockchain and token for your transaction.</li>
    <li>Click 'add line' and select the contact and category for your payment, this will be what Bitwave uses to categorise the transaction.</li>
    <li>Enter the amount and destination address, and click 'add payment line'.</li>
    <li>Add any other lines required, and click 'save payment'.</li>
    <li>To confirm the transaction, click on the icon for that transaction and click 'validate' and then confirm the transaction in your wallet.</li>
    </ul>
  </p>`,
  },
  _inLineCategorization: {
    title: 'In-Line Categorization',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">In-Line Categorization</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.youtube.com/embed/hLHg1k8_2f8" frameborder="0" allowfullscreen></iframe>
    <br>
    <b><i>To perform In-line categorization</i></b>
    <ul style="list-style-type: number; margin-left: 20px;">
    <li>Go to the All Transactions Page</li>
    <li>Within a transaction, click on the address and assign it to a Contact.</li>
    <li>This will map all transactions with that addresses to that Contact.</li>
    <li>Next, click on the 'amount' and assign a Category.</li>
    <li>This automatically categorize all similar transactions to that Category.</li>
    <li>The Confirm button will transition from GREY to BLUE.</li>
    <li>Click Confirm and the transaction will be categorized.</li>
    </ul>
  </p>`,
  },
  _tokenVarianceReconciliationReport: {
    title: 'Token Variance Reconciliation Report',
    html: `<div style="max-height: 400px; overflow-y: auto;">
    <h1 class="tw-text-lg tw-font-bold">Token Variance Reconciliation Report</h1>
  <br>
  <p><iframe width="352" height="315" src="https://www.loom.com/embed/b7ddd72652e74a5f935dc00f088ccadd" frameborder="0" allowfullscreen></iframe>
    <br>
    <b><i>Running the Token Variance Reconciliation Report</i></b>
    <ul style="list-style-type: number; margin-left: 20px;">
    <li>Go to the Transactions Tab.</li>
    <li>Select the Register.</li>
    <li>At the top of the Register Screen Select the Variance Tab.</li>
    <li>Enter a Date and a Wallet OR Enter a Date without a Wallet.</li>
    <li>Click the Run Button.</li>
    <li>Click the Download Button.</li>
    </ul>
  </p>`,
  },
  _walletTrackingMode: {
    title: '',
    html: `<div class="tw-flex tw-flex-col tw-gap-2">
     <span>Bitwave can track wallets through different modes:</span>
     <!--
      <span>
      <b>Transaction + Balance:</b> In this mode, Bitwave will track and record the wallet's transactions. In addition, Bitwave will periodically take a snapshot of the wallet's balance from the blockchain or exchange. This is the default mode for most wallet types.
      </span>
      <span>
      <b>Balance:</b> In this mode Bitwave will only take periodically snapshots of the wallet's balance, but Bitwave will not track the wallet's transactions.
      </span>
      <span>
      -->
      <b>Balance Delta:</b> In this mode Bitwave will take periodic snapshots of the wallet's balance, and subsequently create transactions that represent changes in balance in between balance snapshots.
      </span>
      </div>
    </div>`,
  },
};

export default Tooltips;
