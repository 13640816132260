<template>
  <v-dialog
    @keydown.esc="closeDialog"
    v-model="dialog"
    max-width="1250px"
    persistent
    transition="dialog-bottom-transition"
  >
    <div slot="activator">
      <template v-if="showButton">
        <div v-if="btnTooltip">
          <v-tooltip bottom open-delay="1000">
            <v-btn
              :disabled="disableButton"
              v-if="btnName || btnIcon"
              :outline="btnOutline"
              :small="true"
              slot="activator"
              :icon="!btnName"
              :color="btnColor"
              :class="btnClass"
            >
              {{ btnName }}
              <v-icon v-if="btnIcon">{{ btnIcon }}</v-icon>
            </v-btn>
            <span>{{ btnTooltip }}</span>
          </v-tooltip>
        </div>
        <div v-else>
          <v-btn
            :disabled="disableButton"
            v-if="btnName || btnIcon"
            :small="false"
            slot="activator"
            :icon="!btnName"
            :class="btnClass"
            :color="btnColor"
            :outline="btnOutline"
          >
            <v-icon small v-if="btnIcon" class="tw-pr-1">{{ btnIcon }}</v-icon>
            {{ btnName }}
          </v-btn>
          <div v-if="clickedText">
            <span style="cursor: pointer">{{ clickedText }}</span>
          </div>
        </div>
      </template>
    </div>
    <v-card class="pa-0">
      <v-toolbar dark color="secondary">
        <v-btn icon dark @click.native="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-capitalize">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="save">
          <v-btn dark flat :disabled="canSave || saving" @click.native="saveHandler">{{ $t('_save') }}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style>
.v-btn--disabled {
  color: gray !important;
}
</style>
<script>
export default {
  props: {
    btnName: {
      default: null,
      type: String,
    },
    btnOutline: {
      default: false,
      type: Boolean,
    },
    btnIcon: {
      default: null,
      type: String,
    },
    btnTooltip: {
      default: null,
      type: String,
    },
    small: {
      default: false,
      type: Boolean,
    },
    title: {
      default: null,
      type: String,
    },
    canSave: {
      default: false,
      type: Boolean,
    },
    save: {
      default: null,
      type: Function,
    },
    show: {
      default: null,
      type: Boolean,
    },
    onHide: {
      default: null,
      type: Function,
    },
    btnColor: {
      default: 'secondary',
      type: String,
    },
    btnClass: {
      default: 'secondary',
      type: String,
    },
    showButton: {
      default: true,
      type: Boolean,
    },
    disableButton: {
      default: false,
      type: Boolean,
    },
    clickedText: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      saving: false,
    };
  },
  methods: {
    closeDialog() {
      console.log('closing dialog');
      this.dialog = false;
      if (this.onHide) {
        this.onHide();
      }
    },
    async saveHandler() {
      console.log('inside save handler');
      this.saving = true;
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const AsyncFunction = (async () => {}).constructor;
      if (this.save instanceof AsyncFunction) {
        const res = await this.save();

        if (res && res.error) {
          this.saving = false;
          return;
        }
      } else {
        if (!this.save()) return;
      }

      this.dialog = false;
      this.saving = false;
      if (this.onHide) {
        this.onHide();
      }
    },
  },
  mounted() {
    if (this.show) {
      this.dialog = true;
    }
  },
  watch: {
    show(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      if (newVal) {
        this.$emit('show-dialog');
      }
    },
  },
};
</script>
