var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative tw-inline-block",on:{"click":function (e) { return e.stopPropagation(); }}},[_c('div',{staticClass:"tw-inline-block focus:tw-outline-none tw-rounded-sm tw-w-full",attrs:{"tabindex":"1"},on:{"focus":function($event){return _vm.onFocus()},"blur":function($event){return _vm.onBlur()}}},[_vm._t("default")],2),(_vm.showMenu)?_c('div',{staticClass:"tw-absolute tw-text-left tw-font-normal tw-z-10 tw-w-full",class:[_vm.align === 'left' ? 'tw-left-0' : 'tw-right-0', _vm.up ? 'tw-bottom-full' : 'tw-top-full']},[_c('div',{staticClass:"tw-origin-top-right tw-absolute tw-w-full tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none",class:[_vm.align === 'left' ? 'tw-left-0' : 'tw-right-0', _vm.up ? 'tw-bottom-full tw-mb-1' : 'tw-top-full tw-mt-1'],staticStyle:{"min-width":"224px"},attrs:{"role":"menu","aria-orientation":"vertical","aria-labelledby":"menu-button","tabindex":"1"},on:{"focus":function($event){return _vm.onFocus()},"blur":function($event){return _vm.onBlur()}}},[(_vm.label)?_c('div',{staticClass:"tw-px-4 tw-pt-4 tw-pt-2 tw-uppercase tw-neutral-300 tw-text-xs tw-font-bold tw-leading-3",staticStyle:{"letter-spacing":"0.03em"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{ref:"menuList",staticClass:"tw-py-1 tw-max-h-80 tw-overflow-y-auto tw-overflow-x-hidden",attrs:{"role":"none"}},_vm._l((_vm.normalValues),function(item){return _c('div',{key:item.label},[(!_vm.hasCheckBox)?_c('a',{staticClass:"tw-block tw-px-4 tw-py-2 tw-text-sm tw-flex tw-justify-between tw-items-center",class:{
              'tw-text-primary-300': item.value === _vm.value,
              'tw-text-gray-400 tw-cursor-default': item.value !== _vm.value && !!item.disabled,
              'tw-text-gray-700': item.value !== _vm.value && !item.disabled,
              'hover:tw-bg-primary-100 hover:tw-text-neutral-300': !item.disabled,
            },attrs:{"role":"menuitem","tabindex":"-1"},on:{"mousedown":function($event){return _vm.onSelect(item)}}},[_c('span',{staticClass:"tw-truncate"},[_vm._v(_vm._s(item.label))]),(item.icon)?_c('fa',{attrs:{"icon":item.icon}}):_vm._e()],1):_c('div',{staticClass:"tw-block tw-px-4 tw-py-2 tw-text-sm tw-cursor-pointer",class:{
              'tw-text-gray-400 tw-cursor-default': !!item.disabled,
              'hover:tw-bg-gray-100 tw-text-gray-700': !item.disabled,
            },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onClickRow(item)}}},[_c('ui-checkbox',{attrs:{"label":item.label},on:{"input":function($event){_vm.$emit(
                  'input',
                  _vm.normalValues.filter(function (x) { return x.selected; }).map(function (x) { return x.value; })
                )},"focus":function($event){return _vm.onFocus()},"blur":function($event){return _vm.onBlur()}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)])}),0)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }