












































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class UiModal extends Vue {
  @Prop({ default: '' })
  public readonly modalTitle!: string;

  @Prop({ default: true })
  public readonly useCloseButton!: boolean;

  @Prop({ default: '' })
  public readonly customClass!: string;
}
