var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"tw-w-full tw-border-b tw-border-b-gray-200 tw-relative tw-flex tw-items-center tw-py-2 tw-px-3 hover:tw-bg-gray-100 active:tw-bg-gray-200",class:{
    'tw-justify-between': !_vm.centered && !_vm.checkboxes,
    'tw-justify-center': _vm.centered,
    'tw-justify-start': _vm.checkboxes,
  },attrs:{"disabled":_vm.static},on:{"click":_vm.click}},[(_vm.checkboxes)?_c('ui-checkbox',{attrs:{"disabled":_vm.static,"value":_vm.active}}):_vm._e(),_c('a',{staticClass:"tw-truncate",class:{
      'tw-text-primary-300': _vm.active && !_vm.checkboxes,
      'tw-flex tw-items-center': _vm.icon !== undefined,
    },attrs:{"href":"#","tabindex":"0"},on:{"click":function($event){$event.preventDefault();}}},[_c('span',{staticClass:"tw-absolute tw-inset-0"}),(_vm.icon)?_c('span',{class:{ 'mr-2': _vm.icon }},[_c('fa',{attrs:{"icon":_vm.icon}})],1):_vm._e(),(_vm.image)?_c('img',{staticClass:"tw-mr-2",staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.image,"alt":"blockchain","width":"20px","height":"20px"}}):_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._t("title",function(){return [_c('span',[_vm._v(_vm._s(_vm.title))])]}),(_vm.caption)?_c('span',{staticClass:"tw-text-xs tw-text-gray-400"},[_vm._v(_vm._s(_vm.caption))]):_vm._e()],2)]),(_vm.showIcon)?_c('fa',{staticClass:"tw-text-gray-300",attrs:{"icon":"fa-regular fa-chevron-right"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }