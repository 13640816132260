import { BigNumber } from 'mathjs';

import { store } from '../store';

export function getSymbolForCoin(coin: string): string {
  if (coin === 'BTC') {
    return '฿';
  } else if (coin === 'ETH') {
    return 'Ξ';
  } else if (coin === 'EOS') {
    return 'EOS';
  } else {
    return coin;
  }
}

export function getSymbolForCurrency(currency: string) {
  const fiats: { name: string; symbol: string }[] = store.getters['fiats/FIATS'];

  const unknownSymbol = '??';

  const fiat = fiats.find((f) => f.name === currency);

  if (fiat) {
    return fiat.symbol;
  }

  return unknownSymbol;
}

export function getCoinFromEnum(coin: number): string | undefined {
  if (coin === 1) {
    return 'BTC';
  } else if (coin === 10) {
    return 'ETH';
  } else if (coin === 20) {
    return 'EOS';
  }
}

export function getCurrencyFromEnum(coin: number): string {
  switch (coin) {
    case 1:
      return 'USD';
    case 2:
      return 'GBP';
    case 3:
      return 'JPY';
    case 4:
      return 'EUR';
    case 5:
      return 'SGD';
    case 6:
      return 'CHF';
    case 100:
      return 'ZWD';
    default:
      throw new Error('Unable to convert fiat');
  }
}

export function getCoinFromEnumString(coin: string): string | undefined {
  return getCoinFromEnum(Number(coin));
}

export function numberWithCommas(x: { toString(): string }): string {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function getCoinUnitFromEnumString(unit: string): string | undefined {
  switch (unit) {
    case '1':
      return 'Satoshi';
    case '2':
      return 'Bitcoin';
    case '10':
      return 'Wei';
    case '11':
      return 'Ether';
    case '20':
      return 'EOS';
  }
}

export function convertUnits(
  coin: string,
  currentUnit: string,
  targetUnit: string,
  value: BigNumber
): BigNumber | undefined {
  if (currentUnit === targetUnit) {
    return value;
  }

  if (coin === 'BTC') {
    if (currentUnit === 'Satoshi' && targetUnit === 'Bitcoin') {
      return value.div(100000000);
    } else if (currentUnit === 'Bitcoin' && targetUnit === 'Satoshi') {
      return value.mul(100000000);
    }
  } else if (coin === 'ETH') {
    if (currentUnit === 'Wei' && targetUnit === 'Ether') {
      return value.div(1000000000000000000);
    } else if (currentUnit === 'Ether' && targetUnit === 'Wei') {
      return value.mul(1000000000000000000);
    }
  } else if (coin === 'EOS') {
    return value;
  }
}

export function getMainUnitForCoin(coin: string): string {
  if (coin === 'BTC') {
    return 'Bitcoin';
  } else if (coin === 'ETH') {
    return 'Ether';
  } else if (coin === 'EOS') {
    return 'EOS';
  } else {
    return coin;
  }
}
