































































import gql from 'graphql-tag';
import Component from 'vue-class-component';

import { BaseVue, doCookieAuth } from '../../BaseVue';
import { setOrganizations } from '../../firebase';
import UiButton from '../ui/UiButton.vue';
import UiDataTable from '../ui/UiDataTable.vue';
import UiModal from '../ui/UiModal.vue';

@Component({
  components: { UiModal, UiButton, UiDataTable },
  apollo: {
    invitedOrgs: {
      query: gql`
        query GetOrgInvited {
          invites {
            id
            orgName
          }
        }
      `,
      update(data) {
        return data.invites ? data.invites : [];
      },
      loadingKey: 'isLoading',
      fetchPolicy: 'no-cache',
    },
  },
})
export default class Join extends BaseVue {
  public invitedOrgs: any[] = [];
  public pagination: any = {
    sortBy: 'title',
  };

  public closedByUser = false;
  public isSaving = false;
  public headers = [
    {
      label: 'Organization',
      id: 'organization',
    },
  ];

  async declineInvitation(inviteId: string) {
    try {
      this.isSaving = true;
      const vars = {
        inviteId: inviteId,
      };
      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($inviteId: ID!) {
            declineInvitation(inviteId: $inviteId)
          }
        `,
        // Parameters
        variables: vars,
      });
      if (!resp.errors) {
        this.showSuccessSnackbar('Invitation declined');
        console.log(resp.data);
      } else {
        this.showErrorSnackbar('Error declining invitation');
      }

      await Promise.all([setOrganizations(this.$store.state.user), this.$apollo.queries.invitedOrgs.refetch()]);
    } catch (error) {
      console.error(error);
    } finally {
      this.isSaving = false;
    }
  }

  async acceptInvitation(inviteId: string) {
    try {
      this.isSaving = true;
      const vars = {
        inviteId: inviteId,
      };
      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($inviteId: ID!) {
            acceptInvitation(inviteId: $inviteId)
          }
        `,
        // Parameters
        variables: vars,
      });
      if (!resp.errors) {
        this.showSuccessSnackbar('Invitation accepted');
      } else {
        this.showErrorSnackbar('Error accepting invitation');
      }

      await Promise.all([setOrganizations(this.$store.state.user), this.$apollo.queries.invitedOrgs.refetch()]);
    } catch (error) {
      console.error(error);
    } finally {
      this.isSaving = false;
    }
  }
}
