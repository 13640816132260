

























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import UiLoading from './UiLoading.vue';

export enum UiAlertType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

@Component({ components: { UiLoading } })
export default class UiAlert extends Vue {
  @Prop({ default: UiAlertType.Info })
  public readonly type!: UiAlertType;

  @Prop()
  public readonly heading?: string;

  @Prop({ default: () => [] })
  public readonly actions!: string[] | Record<string, unknown>;

  @Prop({ default: false })
  public readonly dismissible!: boolean;

  @Prop({ default: false })
  public readonly global!: boolean;

  @Prop({ default: undefined }) // v-model prop to show or hide alert
  public readonly value?: boolean;

  @Prop()
  public readonly timeout?: number;

  @Prop()
  public readonly icon?: string;

  @Prop({ default: false })
  public readonly actionsLoading!: boolean;

  @Watch('value', { immediate: true })
  private onValueChange(val: boolean | undefined) {
    if (typeof val === 'boolean') {
      this.show = val;
    }
  }

  @Watch('timeout')
  @Watch('show', { immediate: true })
  private onShowChange() {
    if (this.showTimeout !== null) {
      clearTimeout(this.showTimeout);
      this.showTimeout = null;
    }
    if (this.timeout !== undefined) {
      if (this.show) {
        this.showTimeout = setTimeout(() => {
          this.show = false;
          this.showTimeout = null;
        }, this.timeout);
      }
    }
  }

  public get normalActions(): { text: string; value: unknown }[] {
    return Array.isArray(this.actions)
      ? this.actions.map((x) => ({ text: x, value: x }))
      : Object.entries(this.actions).map(([text, value]) => ({ text, value }));
  }

  private internalShow = true;
  public get show() {
    return this.internalShow;
  }

  public set show(val: boolean) {
    this.internalShow = val;
    this.$emit('input', val);
  }

  public showTimeout: ReturnType<typeof setTimeout> | null = null;

  public icons: Record<UiAlertType, string> = {
    [UiAlertType.Success]: 'fa-check-circle',
    [UiAlertType.Error]: 'fa-times-circle ',
    [UiAlertType.Info]: 'fa-info-circle',
    [UiAlertType.Warning]: 'fa-exclamation-triangle',
  };

  public colors: Record<UiAlertType, { icon: string; head: string; text: string; bg: string }> = {
    [UiAlertType.Success]: {
      bg: 'tw-bg-green-50',
      icon: 'tw-text-green-400',
      text: 'tw-text-green-700',
      head: 'tw-text-green-800',
    },
    [UiAlertType.Error]: {
      bg: 'tw-bg-red-50',
      icon: 'tw-text-red-400',
      text: 'tw-text-red-700',
      head: 'tw-text-red-800',
    },
    [UiAlertType.Info]: {
      bg: 'tw-bg-blue-50',
      icon: 'tw-text-blue-400',
      text: 'tw-text-blue-700',
      head: 'tw-text-blue-800',
    },
    [UiAlertType.Warning]: {
      bg: 'tw-bg-yellow-50',
      icon: 'tw-text-yellow-400',
      text: 'tw-text-yellow-700',
      head: 'tw-text-yellow-800',
    },
  };

  public dismissBtnStyles: Record<UiAlertType, string> = {
    [UiAlertType.Success]:
      'tw-bg-green-100 tw-text-green-800 hover:tw-bg-green-200 focus:tw-ring-offset-green-50 focus:tw-ring-green-600 disabled:tw-bg-green-100 disabled:tw-text-green-300',
    [UiAlertType.Error]:
      'tw-bg-red-100 tw-text-red-800 hover:tw-bg-red-200 focus:tw-ring-offset-red-50 focus:tw-ring-red-600 disabled:tw-bg-red-100 disabled:tw-text-red-300',
    [UiAlertType.Info]:
      'tw-bg-blue-100 tw-text-blue-800 hover:tw-bg-blue-200 focus:tw-ring-offset-blue-50 focus:tw-ring-blue-600 disabled:tw-bg-blue-100 disabled:tw-text-blue-300',
    [UiAlertType.Warning]:
      'tw-bg-yellow-100 tw-text-yellow-800 hover:tw-bg-yellow-200 focus:tw-ring-offset-yellow-50 focus:tw-ring-yellow-600 disabled:tw-bg-yellow-100 disabled:tw-text-yellow-300',
  };
}
