import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import { ActionTree } from 'vuex';

import type { Org } from '@/api-svc-types';
import { AuthService } from '@/services/authService';
import {
  MUT_ADD_NOTIFICATION,
  MUT_FEATURES,
  MUT_NETWORKS,
  MUT_PARENT_AUTH_TOKEN,
  MUT_SCOPES,
  MUT_SET_CURRENT_ORG,
  MUT_SET_ORGS,
  MUT_TOKEN_FILTERING_RULES,
  StoreStateType,
} from '@/store/index';

import { baConfig } from '../../config';
import { TransactionsV2Api } from '../../generated/api-svc';
import { ApiSvcTokenFilteringRulesConfig } from '../../generated/api-svc/api';
import { apolloProvider } from '../vueApollo';
import router from './../router';

const authService = new AuthService();
const actions: ActionTree<StoreStateType, StoreStateType> = {
  async setCurrentOrg(context, orgId: string) {
    // if (context.state.currentOrg?.id === orgId) {
    //   return;
    // }
    if (orgId && context.state.authTokens && context.state.authTokens.accessToken.orgId !== orgId) {
      // Auth tokens need to be updated
      return await authService.switchAccessOrg(orgId);
    }

    if (context.state.orgs === undefined) {
      // App is not ready yet, just set the cookie
      if (orgId) Cookies.set('orgId', orgId);
      else Cookies.remove('orgId');
      return;
    }
    context.commit(MUT_SET_CURRENT_ORG, orgId);
    // We need to wait a tick for apollo to pick up the new auth tokens (it takes a tick to update the auth header)
    await new Promise((resolve) => setTimeout(resolve, 0));
    await context.dispatch('getFeatureFlags');
    const hash = window.location.hash;
    const hashSearchParams = hash.includes('?') ? new URLSearchParams(hash.split('?')[1]) : new URLSearchParams();
    const isAuthEmbedded = hashSearchParams.get('embedded') === 'true';
    if (
      !isAuthEmbedded &&
      !process.env.VUE_APP_DISABLE_VUE_REDIRECT &&
      window.self === window.top &&
      context.state.features?.['event-sourced-txns'] === 'true'
    ) {
      const currentPath = window.location.pathname; // Get the current path
      const newBaseURL = process.env.VUE_APP_PARENT_WEB_APP; // vue 3 app base URL
      const loadOrgURL = `load_org/${orgId}`;

      // update Cookie value for the app3 domain cross site domain
      Cookies.set('orgId', orgId);

      window.location.href = `${newBaseURL}${currentPath}${loadOrgURL}`; // Perform the redirection
    }
    await context.dispatch('getScopes');
    context.dispatch('fiats/getFiats');
    await context.dispatch('getNetworks');
    if (orgId) {
      context.dispatch('categories/getCategories', orgId);
      context.dispatch('contacts/getContacts', orgId);
      context.dispatch('wallets/getWallets', orgId);
      await context.dispatch('getTokenFilteringRules');
    }
  },
  async updateOrgs(context) {
    const client = apolloProvider.clients.defaultClient;
    const resp = await client.query({
      query: gql`
        query {
          orgs {
            id
            name
            timezone
            accountingInventoryGroupId
            accountingInventoryGroupIds
            accountingSetup {
              initialSyncMethod
              isFinished
              isReconciled
              balanceExportStartDate
              balanceExportReportId
            }
            subscriptionDetails {
              accountingSeats
              walletSeats
            }
            provisionedAccountingUsers
            provisionedWalletUsers
            userRoles {
              userId
              role
            }
            taxConfig {
              capitalizeTradingFees
              wrappingTreatments
            }
            baseCurrency
            invoicingSettings {
              fromEmail
              fromName
              bitcoinAddress
              bitcoinXPub
              bitcoinXPubPath
              ethAddress
              zecAddress
              usdcAddress
              usdtAddress
            }
            accountingConfig {
              networkContactIds {
                contactId
                blockchain
              }
              defaultFeeCategoryId
              allowTxnInference
              collapseAcrossWallets
              defaultAccountsReceivableCategoryId
              defaultAccountsPayableCategoryId
              defaultFeeCategoryIds {
                categoryId
                blockchain
              }
            }
            trialExpiry
            flags {
              key
              value
            }
            displayConfig {
              useOrgTimezone
            }
            hardCloseDate
            scimEnabled
            isDisabled
          }
        }
      `,
      fetchPolicy: 'network-only',
    });

    let updatedCurrentOrg;
    if (context.state && context.state.currentOrg) {
      const currentOrgId = context.state.currentOrg.id;
      updatedCurrentOrg = resp.data.orgs.find((m: Org) => m.id === currentOrgId);
    } else {
      updatedCurrentOrg = resp.data.orgs[0];
    }
    await context.commit(MUT_SET_ORGS, resp.data.orgs);
    const orgId = Cookies.get('orgId') || (updatedCurrentOrg ? updatedCurrentOrg.id : null);
    await context.dispatch('setCurrentOrg', orgId);
  },
  async getFeatureFlags(context) {
    const client = apolloProvider.clients.defaultClient;
    if (context.state.currentOrg && context.state.currentOrg.id) {
      const currentOrgId = context.state.currentOrg.id;
      const vars = {
        orgId: currentOrgId,
      };

      try {
        const resp = await client.query({
          query: gql`
            query getFeatures($orgId: ID!) {
              features(orgId: $orgId) {
                key
                value
              }
            }
          `,
          variables: vars,
        });

        const features = {} as Record<string, string>;
        if (resp.data && resp.data.features) {
          for (const f of resp.data.features) {
            features[f.key] = f.value;
            // featureMap.set(f.key, f.value);
          }
        }

        if (context.state.currentOrg.flags) {
          for (const f of context.state.currentOrg.flags) {
            if (f) {
              features[f.key] = f.value;
            }
          }
        }

        context.commit(MUT_FEATURES, features);
      } catch (e) {
        console.log('Problem getting features', e);
      }
    } else {
      context.commit(MUT_FEATURES, {});
    }
  },
  async getScopes(context) {
    const client = apolloProvider.clients.defaultClient;
    if (context.state.currentOrg && context.state.currentOrg.id) {
      const currentOrgId = context.state.currentOrg.id;
      const vars = {
        orgId: currentOrgId,
      };

      try {
        const resp = await client.query({
          query: gql`
            query getScopes($orgId: ID!) {
              userScopes(orgId: $orgId)
            }
          `,
          variables: vars,
        });

        const scopes = {} as Record<string, string>;
        if (resp.data && resp.data.userScopes) {
          context.commit(MUT_SCOPES, resp.data.userScopes);
        }
      } catch (e) {
        console.log('Problem getting scopes', e);
      }
    } else {
      context.commit(MUT_SCOPES, []);
    }
  },
  async getNetworks(context) {
    const tApi = new TransactionsV2Api(undefined, baConfig.getFriendlyApiUrl());
    const resp = await tApi.getNetworks({ withCredentials: true });
    context.commit(MUT_NETWORKS, resp.data);
  },
  async getTokenFilteringRules(context) {
    const client = apolloProvider.clients.defaultClient;
    const implementedNetworkSet = ['eth', 'polygon'];
    if (context.state.currentOrg && context.state.currentOrg.id) {
      const currentOrgId = context.state.currentOrg.id;
      const tokenFilter = new Map<string, ApiSvcTokenFilteringRulesConfig>();
      for (const networkId of implementedNetworkSet) {
        const vars = {
          orgId: currentOrgId,
          networkId: networkId,
        };

        try {
          const resp = await client.query({
            query: gql`
              query tokenFilteringRulesConfig($orgId: ID!, $networkId: ID!) {
                tokenFilteringRulesConfig(orgId: $orgId, networkId: $networkId) {
                  allowedContractAddresses
                  allowedDeployedByAddresses
                  disabledDeployedByAddresses
                  disabledContractAddresses
                  erc20FilterThreshold
                  filteredAddresses
                  walletSyncPreferences {
                    walletId
                    syncAllLogEvents
                  }
                }
              }
            `,
            variables: vars,
          });

          if (resp.data && resp.data.tokenFilteringRulesConfig) {
            tokenFilter.set(networkId, resp.data.tokenFilteringRulesConfig);
          } else {
            const emptyRules = {
              allowedContractAddresses: [],
              allowedDeployedByAddresses: [],
              disabledDeployedByAddresses: [],
              disabledContractAddresses: [],
              filteredAddresses: [],
              erc20FilterThreshold: '1',
              walletSyncPreferences: [],
            };
            tokenFilter.set(networkId, emptyRules);
          }
        } catch (e) {
          console.log('Problem getting token filtering rules', e);
        }
      }
      // const combinedTokenFilterRules = () => {

      //   for (const networkId of implementedNetworkSet) {
      //     const rules = tokenFilter.get(networkId);
      //     if (rules) {

      //     }
      //   }
      // }
      context.commit(MUT_TOKEN_FILTERING_RULES, tokenFilter);
    } else {
      context.commit(MUT_TOKEN_FILTERING_RULES, {});
    }
  },
  receiveEvent(context, event) {
    context.commit(MUT_ADD_NOTIFICATION, event);
  },
};

export default actions;
