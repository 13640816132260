









































import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Org } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

@Component({
  components: {
    // Billing: Billing,
  },
})
export default class TrialExpired extends BaseVue {
  @Prop({ default: false })
  public readonly isEmbedded!: boolean;

  public isTrialExpired = false;
  public show = false;
  public newOrg: Org | null = null;
  private trialExpiredTimeout?: number;

  public get orgsNotCurrent(): Org[] {
    const orgs: Org[] = this.$store.state.orgs ?? [];
    return orgs.filter((org) => org && org.id !== this.$store.state.currentOrg?.id);
  }

  public get currentOrg(): Org | undefined {
    return this.$store.state.currentOrg;
  }

  public get currentOrgName(): string | undefined {
    return this.currentOrg?.name ?? undefined;
  }

  get trialExpiry(): Date | undefined {
    const raw = this.$store.state.currentOrg?.trialExpiry;
    return raw ? new Date(raw) : undefined;
  }

  public get isSubscribed(): boolean {
    const accountingSeats = this.currentOrg?.subscriptionDetails?.accountingSeats ?? 0;
    const walletSeats = this.currentOrg?.subscriptionDetails?.walletSeats ?? 0;
    return accountingSeats > 0 || walletSeats > 0;
  }

  @Watch('trialExpiry', { immediate: true })
  onTrialExpiryChange(trialExpiry?: Date) {
    if (this.trialExpiredTimeout !== undefined) {
      clearTimeout(this.trialExpiredTimeout);
      this.trialExpiredTimeout = undefined;
    }

    if (trialExpiry) {
      if (trialExpiry.getTime() > Date.now()) {
        this.isTrialExpired = false;
        const timeTillExpiry = trialExpiry.getTime() - Date.now();
        if (timeTillExpiry <= 2147483647) {
          // Prevent 32-bit integer overflow
          this.trialExpiredTimeout = window.setTimeout(() => {
            this.isTrialExpired = true;
            this.trialExpiredTimeout = undefined;
          }, timeTillExpiry);
        }
      } else {
        this.isTrialExpired = true;
      }
    } else {
      this.isTrialExpired = false;
    }
  }

  @Watch('isTrialExpired', { immediate: true })
  async onIsTrialExpiredChange(isTrialExpired: boolean) {
    if (!isTrialExpired && this.show && this.currentOrg?.subscriptionDetails?.accountingSeats) {
      // Freshly subscribed, so wait 3 seconds before closing dialog to give user time to read the message
      await new Promise((resolve) => setTimeout(resolve, 3000));
      this.show = this.isTrialExpired && !this.isEmbedded;
    } else {
      this.show = isTrialExpired && !this.isEmbedded;
    }
    this.newOrg = null;
    this.$emit('expired', isTrialExpired);
  }

  @Watch('newOrg')
  onNewOrg(newOrg: Org | null) {
    if (!newOrg || !newOrg.id || newOrg?.id === this.currentOrg?.id) {
      // Do nothing if the new org is the same as the current org
      return;
    }

    this.$store.dispatch('setCurrentOrg', newOrg.id);
  }

  async onSubscribe() {
    await this.$store.dispatch('updateOrgs');
  }
}
