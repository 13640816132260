import 'reflect-metadata';
import './plugins/vuetify';
import 'firebase/auth';
import 'firebase/firestore';
import './assets/tailwind.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import firebase from 'firebase/app';
import Cookies from 'js-cookie';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VueCookies from 'vue-cookies';
import VueI18n from 'vue-i18n';
import JsonCSV from 'vue-json-csv';
import Vuetify from 'vuetify';
import DatetimePicker from 'vuetify-datetime-picker';

import TooltipSelect from '@/components/tooltip/TooltipSelect.vue';
import { AuthService, AuthTokens } from '@/services/authService';

import { firebaseConfig } from '../config';
import App from './App.vue';
import { BaseVue, checkSiblingReferral, doCookieAuth } from './BaseVue';
import BaDialog from './components/BA/dialog.vue';
import { setOrganizations } from './firebase';
import * as icons from './icons';
import MultiFiltersPlugin from './plugins/MultiFilter';
import router from './router';
import { MUT_IS_LOADING, MUT_LOGOUT, MUT_PARENT_AUTH_TOKEN, MUT_SET_USER, MUT_SNACKBAR, store } from './store';
import { isErrorLike } from './utils/guards';
import { requestTokenFromParent } from './utils/iframeMessageRequester';
import { apolloProvider, createClients } from './vueApollo';

// initial app loading
store.commit(MUT_IS_LOADING, true);
if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production') {
  // Sentry.init({
  //   dsn: process.env.VUE_APP_SENTRY_DSN,
  //   environment: process.env.NODE_ENV,
  //   integrations: [new Sentry.Integrations.Vue()],
  // });
}

// Init FA icons
library.add(...Object.values(icons));

Vue.component('fa', FontAwesomeIcon);
Vue.component('TooltipSelect', TooltipSelect);
Vue.use(DatetimePicker);
Vue.use(VueClipboard);
Vue.use(VueCookies);
Vue.use(MultiFiltersPlugin);
Vue.use(PortalVue);
Vue.use(Vuetify, {
  theme: {
    accent: '#26df93',
  },
});

Vue.config.productionTip = false;
Vue.component('ba-dialog', BaDialog);
Vue.component('downloadCsv', JsonCSV);

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
const hash = window.location.hash;
const hashSearchParams = hash.includes('?') ? new URLSearchParams(hash.split('?')[1]) : new URLSearchParams();
const isAuthEmbedded = hashSearchParams.get('embedded') === 'true';

if (window.self !== window.top && !isAuthEmbedded) {
  axios.interceptors.request.use(async (config) => {
    const parentToken = await requestTokenFromParent();
    store.commit(MUT_PARENT_AUTH_TOKEN, parentToken);
    const accessToken = parentToken || store.state.authTokens?.accessToken.token;
    if (config.headers && accessToken && config.url) {
      const url = new URL(config.url);
      const origin = url.origin;
      const injectTokensInto = [
        process.env.VUE_APP_API_URL,
        process.env.VUE_APP_RPT_API_URL,
        process.env.VUE_APP_API_2_SVC_URL,
        process.env.VUE_APP_API3_URL,
        process.env.VUE_APP_TXN_SVC_URL,
      ];
      const origins = new Set(injectTokensInto.filter((x): x is string => !!x).map((x) => new URL(x).origin));
      if (origins.has(origin)) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  });
} else {
  axios.interceptors.request.use((config) => {
    const accessToken = store.state.authTokens?.accessToken.token ?? Cookies.get('__session');

    if (config.headers && accessToken && config.url) {
      const url = new URL(config.url);
      const origin = url.origin;
      const injectTokensInto = [
        process.env.VUE_APP_API_URL,
        process.env.VUE_APP_RPT_API_URL,
        process.env.VUE_APP_API_2_SVC_URL,
        process.env.VUE_APP_API3_URL,
        process.env.VUE_APP_TXN_SVC_URL,
      ];
      const origins = new Set(injectTokensInto.filter((x): x is string => !!x).map((x) => new URL(x).origin));
      if (origins.has(origin)) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  });
}
axios.interceptors.response.use(
  (res) => res,
  (err) => {
    const isAuthPage = window.location.hash.includes('auth');
    const statusCode = err.response.status;
    // if (statusCode === 401 && !isAuthPage) {
    //   window.location.href = `${process.env.VUE_APP_BASE_URL}/#/auth`;
    // }
    if (
      (statusCode === 403 && isAuthPage) ||
      (statusCode === 500 && err.response.data.message === 'Unexpected error Missing bearer token')
    ) {
      console.log('Detected 401/403/(500-missing-token) from axios and automatically logging out');
      const authService = new AuthService();
      authService.logout().catch((e) => console.log('problem logging out', e));
      store.commit(MUT_LOGOUT);
      store.commit(MUT_IS_LOADING, false);
      if (err.response.data && err.response.data.msg) {
        store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: err.response.data.msg,
        });
      }
      window.location.href = `${process.env.VUE_APP_BASE_URL}/#/auth`;
    } else {
      return err.response;
    }
  }
);

Vue.mixin(BaseVue);
// import moment from "moment";

Vue.use(VueI18n);

const locale = localStorage.getItem('locale');

const i18n = new VueI18n({
  locale: locale ?? 'en',
  fallbackLocale: 'en',
  messages: {
    en: require('./locales/en'),
    es: require('./locales/es'),
    fr: require('./locales/fr'),
    zh: require('./locales/zh'),
  },
  silentTranslationWarn: false,
});

firebase.initializeApp(firebaseConfig);
// store.commit(MUT_IS_LOADING, false);
const app = new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
});

app.$mount('#app');

store.watch(
  (state, getters) => getters.apolloClients,
  (newClients: ReturnType<typeof createClients> | undefined) => {
    if (newClients) {
      for (const name of Object.keys(newClients.clients)) {
        apolloProvider.clients[name] = newClients.clients[name];
      }
      apolloProvider.clients.defaultClient = newClients.default;
      apolloProvider.defaultClient = newClients.default;
    }
  }
);
if (window.self === window.top || isAuthEmbedded) {
  let checkLoginInProgress = false;
  const authService = new AuthService();
  const checkLogin = async (fbUser?: firebase.User) => {
    if (checkLoginInProgress) {
      return;
    }

    if (window.location.href.endsWith('/signout?embedded=true')) {
      // No need to check auth for the signout page (when embedded)
      store.commit(MUT_IS_LOADING, false);
      return;
    }

    checkLoginInProgress = true;
    try {
      const urlParams = new URLSearchParams(window.location.href.match(/(?<=\?)([^?]+)$/)?.[0]);
      const siblingId = urlParams.get('siblingLogin') ?? localStorage.getItem('siblingApplicationId');
      if (siblingId) {
        localStorage.setItem('siblingApplicationId', siblingId);
      }

      store.commit(MUT_IS_LOADING, true);
      if (!store.state.authTokens || store.state.authTokens.accessToken.expiry < new Date()) {
        // Check if this is a BW auth redirect
        if (
          new RegExp(`^.{${process.env.VUE_APP_BASE_URL?.length ?? 0}}/#/auth-redirect(?:$|\\?|#)`).test(
            window.location.href
          )
        ) {
          await authService.handleAuthRedirect();
        } else {
          // Try to restore tokens from local storage
          await authService.restoreTokens();
        }
      }

      // These calls will not actually do cookie auth if we are signed in with the auth-svc
      // In that case they will recognise we already have a session and just return the user from /me
      const user = await doCookieAuth();

      if (user) {
        store.commit(MUT_SET_USER, user);
        const org = await setOrganizations(user);

        // This function creates visitors and accounts in Pendo
        // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
        // Please use Strings, Numbers, or Bools for value types.
        window.pendo?.initialize({
          visitor: {
            id: user.id, // Required if user is logged in
            email: user.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: user.displayName, // Recommended if using Pendo Feedback
            // role:         // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },

          account: {
            id: org.id, // Highly recommended, required if using Pendo Feedback
            name: org.name, // Optional
            is_paying: org.is_paying, // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });

        // get the user locale from firebase and commit it to the vuex store

        if (router.currentRoute.name === 'auth') {
          try {
            await router.replace(
              typeof router.currentRoute.query.redirect === 'string' ? router.currentRoute.query.redirect : 'home'
            );
          } catch {}
        }
      } else if (router.currentRoute.name !== 'auth') {
        try {
          await router.replace('auth');
        } catch {}
      }

      const currentOrg = store.state.currentOrg;
      if (currentOrg && (!currentOrg.timezone || !currentOrg.baseCurrency)) {
        try {
          await router.replace({ name: 'Settings' });
        } catch {}
      }
    } catch (e) {
      console.log('Error logging in', e);
      if (router.currentRoute.name !== 'auth') {
        try {
          // This WILL produce a navigation cancelled error, but this is expected and not an issue
          await router.replace('auth');
        } catch {}
      }
    } finally {
      checkLoginInProgress = false;
      store.commit(MUT_IS_LOADING, false);
    }
  };

  const p = checkLogin();
  p.catch((e) => {
    if (isErrorLike(e) && e.message.startsWith('Navigation cancelled')) {
      return; // Ignore navigation cancelled errors
    }
    console.log('Failed login', e);
  });

  firebase.auth().onAuthStateChanged(async (user) => {
    try {
      await checkLogin(user ?? undefined);
    } catch (e) {
      if (isErrorLike(e) && e.message.startsWith('Navigation cancelled')) {
        return; // Ignore navigation cancelled errors
      }
      throw e;
    }
  });
  authService.initCrossWindowComms();
  const ct = undefined;
  if (ct !== undefined) {
    firebase.auth().signInWithCustomToken(ct);
  }
} else {
  const getUser = async () => {
    const parentToken = await requestTokenFromParent();
    await store.commit(MUT_PARENT_AUTH_TOKEN, parentToken);
    const user = await doCookieAuth();

    if (user) {
      store.commit(MUT_SET_USER, user);
      const org = await setOrganizations(user);
    }
    store.commit(MUT_IS_LOADING, false);
  };
  getUser();
}

window.addEventListener('error', (e) => {
  console.log(e.error);
});
