type TipState = {
  currentTip: string;
  open: boolean;
};

const MUT_SET_TIP = 'MUT_SET_TIP';
const MUT_SET_OPEN = 'MUT_SET_OPEN';

const state = () => ({
  currentTip: '_dashboard',
  open: false,
});

const getters = {};

const mutations = {
  [MUT_SET_TIP](state: TipState, tip: string) {
    state.currentTip = tip;
  },
  [MUT_SET_OPEN](state: TipState, open: boolean) {
    state.open = open;
  },
};
const actions = {
  setTip(context: any, tip: string) {
    context.commit(MUT_SET_TIP, tip);
  },
  setOpen(context: any, open: boolean) {
    context.commit(MUT_SET_OPEN, open);
  },
};

export default { state, mutations, getters, actions };
