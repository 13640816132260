import gql from 'graphql-tag';

import { apolloProvider } from '../../../vueApollo';
import { MUT_ERR_WALLETS, MUT_GET_WALLETS, MUT_SET_WALLETS } from './types';

const state = () => ({
  /** @type {import('../../../api-svc-types').Wallet[] | undefined} */
  wallets: undefined,
  /** @type {boolean | null | undefined} */
  isLoading: null,
});

const getters = {
  WALLETS: (state) => {
    return state.wallets;
  },
  WALLETS_ISLOADING: (state) => {
    return state.isLoading;
  },
};

const actions = {
  async getWallets({ commit }, orgId) {
    const client = apolloProvider.clients.defaultClient;
    commit(MUT_GET_WALLETS);
    try {
      const res = await client.query({
        query: gql`
          query GetWallets($orgId: ID!) {
            wallets(orgId: $orgId) {
              id
              name
              description
              type
              deviceType
              networkId
              address
              addresses
              path
              enabledCoins
            }
          }
        `,
        variables: {
          orgId,
        },
        fetchPolicy: 'network-only',
      });
      commit(MUT_SET_WALLETS, res.data.wallets);
    } catch (e) {
      commit(MUT_ERR_WALLETS);
    }
  },
};

const mutations = {
  [MUT_GET_WALLETS](state) {
    state.isLoading = true;
  },
  [MUT_SET_WALLETS](state, wallets) {
    state.wallets = wallets;
    state.isLoading = false;
  },
  [MUT_ERR_WALLETS](state) {
    state.wallets = undefined;
    state.isLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
