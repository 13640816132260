








































import Vue from 'vue';
import Component from 'vue-class-component';

import UiModal from '@/components/ui/UiModal.vue';

@Component({
  components: { UiModal },
})
export default class TermsOfService extends Vue {
  private acceptedTOS = false;
  private acceptedPP = false;

  get continueEnabled() {
    return this.acceptedPP && this.acceptedTOS;
  }

  public accept() {
    this.$emit('accept');
  }
}
