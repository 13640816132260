import { baConfig } from '../../config';

export interface IntercomSettings {
  user_id: string;
  name?: string;
  email?: string;
  company?: {
    id: string;
    name?: string;
  };
}

export class IntercomService {
  public isBooted = false;
  public isVisible = false;

  private _appId = baConfig.intercomAppId;
  private _defaultOptions = { app_id: this._appId, vertical_padding: 90, horizontal_padding: 32 };

  constructor() {
    this._loadScript();
  }

  /**
   * Start a new intercom session
   */
  public boot(options: IntercomSettings) {
    this.isBooted = true;
    this._callIntercom('boot', { ...this._defaultOptions, ...options });
  }

  /**
   * End session
   */
  public shutdown() {
    this.isBooted = false;
    this._callIntercom('shutdown');
  }

  /**
   * If booted, close existing session and create a new one otherwise just boot
   */
  public reboot(options: IntercomSettings) {
    if (this.isBooted) this.shutdown();
    this.boot(options);
  }

  public update(options?: Partial<IntercomSettings>) {
    this._callIntercom('update', options);
  }

  public show() {
    this._callIntercom('show');
  }

  public hide() {
    this._callIntercom('hide');
  }

  private _loadScript() {
    if (!this._appId) {
      console.warn('Intercom was not loaded. Incomplete config.');
    }

    // check if already loaded
    if (typeof (window as any).Intercom === 'function') return;

    const intercomScript = document.createElement('script');
    intercomScript.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${this._appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
    document.body.appendChild(intercomScript);

    this._callIntercom('onHide', () => (this.isVisible = false));
    this._callIntercom('onShow', () => (this.isVisible = true));
  }

  private _callIntercom(...args: any[]) {
    if (!(window as any).Intercom) return;
    return (window as any).Intercom(...args);
  }
}
