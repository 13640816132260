





























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import UiDropdown, { DropdownItemOptions } from './UiDropdown.vue';
import UiFormLabel from './UiFormLabel.vue';

@Component({ components: { UiFormLabel, UiDropdown } })
export default class UiSelect extends Vue {
  @Prop()
  public readonly value?: unknown;

  @Prop()
  public readonly label?: string;

  @Prop()
  public readonly subLabel?: string;

  @Prop()
  public readonly description?: string;

  @Prop()
  public readonly suffix?: string;

  @Prop({ required: true })
  public readonly values!: string[] | Record<string, unknown> | DropdownItemOptions[];

  @Prop()
  public readonly disabled?: boolean;

  @Prop({ default: 'left' })
  public readonly align!: 'left' | 'right';

  @Prop({ default: false })
  public readonly invertKeyValue!: boolean;

  @Prop({ default: 'Select an option' })
  public readonly placeholder!: string;

  @Prop({ default: false })
  public readonly showPlaceholder!: boolean;

  @Prop({ default: false })
  public readonly up!: boolean;

  public dropdownOpen = false;

  public onInput(value: unknown) {
    if (value !== undefined) {
      this.$emit('input', value);
    }
  }

  public get normalValues(): DropdownItemOptions[] {
    return Array.isArray(this.values)
      ? this.values.map((x) => (typeof x === 'string' ? { value: x, label: x } : x))
      : this.invertKeyValue
      ? Object.entries(this.values).map(([label, value]) => ({ label: value as string, value: label })) // "PATCH" if invertKeyValue is true, we need to swap the key and value (new select needs to be made to prevent duplicate key issue)
      : Object.entries(this.values).map(([label, value]) => ({ label, value }));
  }

  public get currentLabel() {
    let result = '';
    for (const item of this.normalValues) {
      if (item.value === this.value) {
        result = item.label;
        break;
      }
    }

    return result;
  }

  public toggleDropdownOpen(v: boolean) {
    this.dropdownOpen = v;
  }
}
