import axios from 'axios';
import firebase from 'firebase';
import Cookies from 'js-cookie';
import { v4 } from 'uuid';

import router from '../router';
import { MUT_SET_AUTH_TOKENS, store } from '../store';

interface JwtPayload {
  iat: number;
  exp: number;
  aud: string[];
  iss: string;
  sub: string;
  jti: string;
}

interface AuthorizationRequest {
  response_type: string;
  client_id: string;
  state: string;
  redirect_uri: string;
  scope: string;
  code_challenge: string;
  code_challenge_method: string;
}

interface RequestRecord {
  request: AuthorizationRequest;
  requestedAt: string;
  codeVerifier: string;
}

interface TokenResponse {
  access_token?: string;
  expires_in: number;
  scope: string;
  refresh_token?: string;
  id_token?: string;
  token_type: string;
}

export type IdTokenPayload = JwtPayload & { name: string; email: string; escalationOrgs: string[] };
export type AccessTokenPayload = JwtPayload & {
  userId: string;
  orgId: string | undefined;
  loadDynamically: boolean;
  loginMethod: 'saml' | 'firebase' | 'xero';
  scopes: string[];
  version: number;
};

export interface AuthTokens {
  accessToken: {
    token: string;
    orgId: string | undefined;
    expiry: Date;
  };
  idToken: {
    payload: IdTokenPayload;
    token: string;
    expiry: Date;
  };
}

export class AuthService {
  /** @deprecated This is part of the old auth service and is not compatible with BW auth */
  async logout() {
    try {
      const p1 = axios.get(`${process.env.VUE_APP_API_URL}logout`, {
        withCredentials: true,
      });
      const p2 = axios.get(`${process.env.VUE_APP_RPT_API_URL}logout`, {
        withCredentials: true,
      });
      const p3 = firebase.auth().signOut();
      await Promise.all([p1, p2, p3]);
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  private b64UrlDecode(data: string): string {
    const b64 = data.replaceAll('-', '+').replaceAll('_', '/');
    const dataString = atob(b64);

    return dataString;
  }

  private b64UrlEncode(data: Uint8Array | ArrayBuffer | string): string {
    let stringData: string;
    if (typeof data === 'string') {
      stringData = data;
    } else {
      const uint8Data = data instanceof Uint8Array ? data : new Uint8Array(data);
      stringData = String.fromCharCode(...uint8Data);
    }
    const b64 = btoa(stringData);

    // Confirm to base64url standards, see RFC-4648
    return b64.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
  }

  public parseJwt(token: string): JwtPayload {
    const data = this.b64UrlDecode(token.split('.')[1]);
    const jsonPayload = decodeURIComponent(
      data
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  async sendToAuth(escalation?: { userId: string; orgId: string }) {
    const url = new URL(process.env.VUE_APP_AUTH_SVC_URL ?? '');
    url.pathname = '/api/oauth/authorize';

    if (escalation) {
      url.searchParams.set('escalation', JSON.stringify(escalation));
    }

    // See RFC-7636: 7.1. 32 bit sequence used as code verifier
    const codeVerifier = new Uint8Array(32);
    crypto.getRandomValues(codeVerifier);
    const hashed = await crypto.subtle.digest('SHA-256', codeVerifier);
    const codeChallenge = this.b64UrlEncode(hashed);
    const currentOrgId: string | undefined = store.state.currentOrg?.id ?? Cookies.get('orgId');
    const authRequest = {
      response_type: 'code',
      client_id: 'web-app',
      // @ts-ignore - crypto.randomUUID() is not in this version of TS but it is available in the browser, we cannot update TS until we update Vue
      state: crypto.randomUUID(),
      redirect_uri: process.env.VUE_APP_BASE_URL + '/#/auth-redirect',
      scope: 'openid' + (currentOrgId ? ` orgId:${currentOrgId}` : ''),
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
    };

    // Save request and verifier in local storage for when we get redirected back
    localStorage.setItem(
      'authorizationRequest',
      JSON.stringify({
        request: authRequest,
        codeVerifier: this.b64UrlEncode(codeVerifier),
        requestedAt: new Date().toISOString(),
      })
    );

    for (const [key, val] of Object.entries(authRequest)) {
      url.searchParams.set(key, val);
    }

    window.location.href = url.toString();
  }

  async handleAuthRedirect(): Promise<AuthTokens | undefined> {
    // Check for oauth redirect
    const requestRecordStr = localStorage.getItem('authorizationRequest');
    if (!requestRecordStr) {
      await this.sendToAuth();
      return;
    }
    const requestRecord: RequestRecord = JSON.parse(requestRecordStr);

    // Ensure request was issued not more than 30 minutes ago
    if (new Date(requestRecord.requestedAt).valueOf() < Date.now() - 30 * 60 * 1000) {
      await this.sendToAuth();
      return;
    }

    let url = new URL(window.location.href.replace('/#/', '/'));
    const code = url.searchParams.get('code');
    const state = url.searchParams.get('state');

    if (!code || !state) {
      await this.sendToAuth();
      return;
    }

    if (requestRecord.request.state !== state) {
      throw new Error('Invalid State');
    }

    url = new URL(process.env.VUE_APP_AUTH_SVC_URL ?? '');
    url.pathname = '/api/oauth/token';

    const resp = await axios.post<TokenResponse>(
      url.toString(),
      {
        grant_type: 'authorization_code',
        code,
        redirect_uri: requestRecord.request.redirect_uri,
        client_id: requestRecord.request.client_id,
        code_verifier: requestRecord.codeVerifier,
      },
      {
        withCredentials: true,
      }
    );

    const idToken = resp.data.id_token;
    const accessToken = resp.data.access_token;
    const refreshToken = resp.data.refresh_token;

    if (!idToken || !accessToken || !refreshToken) {
      throw new Error('Did not receive the expected tokens');
    }

    // Remove request from local storage
    localStorage.removeItem('authorizationRequest');

    const tokens = this.createTokensObj(accessToken, idToken);

    // Store refresh token in local storage
    this.storeRefreshToken(refreshToken);
    // Store tokens in store
    store.commit(MUT_SET_AUTH_TOKENS, tokens);
    // Initiate refresh timer
    this.initiateSessionKeepAlive(tokens);

    // Set current org if necessary
    await store.dispatch('setCurrentOrg', tokens.accessToken.orgId);

    return tokens;
  }

  public getJwtExpiry(token: string): Date {
    const payload = this.parseJwt(token);
    return new Date(payload.exp * 1e3);
  }

  async restoreTokens(): Promise<AuthTokens | undefined> {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
      return Promise.resolve(undefined);
    }

    const refreshTokenExpiry = this.getJwtExpiry(refreshToken);

    if (refreshTokenExpiry < new Date()) {
      localStorage.removeItem('refreshToken');
      return Promise.resolve(undefined);
    }

    return this.refreshSession();
  }

  private createTokensObj(accessToken: string, idToken: string): AuthTokens {
    const idTokenPayload = this.parseJwt(idToken) as IdTokenPayload;
    const accessTokenPayload = this.parseJwt(accessToken) as AccessTokenPayload;

    return {
      accessToken: {
        token: accessToken,
        orgId: accessTokenPayload.orgId,
        expiry: new Date(accessTokenPayload.exp * 1000),
      },
      idToken: {
        payload: idTokenPayload,
        token: idToken,
        expiry: new Date(idTokenPayload.exp * 1000),
      },
    };
  }

  private static sessionKeepAliveTimeout: number | undefined;
  private static crossWindowCommsEstablished = false;
  public initiateSessionKeepAlive(currentAuthTokens: AuthTokens) {
    if (AuthService.sessionKeepAliveTimeout !== undefined) {
      window.clearTimeout(AuthService.sessionKeepAliveTimeout);
      AuthService.sessionKeepAliveTimeout = undefined;
    }
    let refreshTokenInMS = currentAuthTokens.accessToken.expiry.valueOf() - Date.now() - 30000;
    if (refreshTokenInMS < 0) {
      refreshTokenInMS = 0;
    }
    AuthService.sessionKeepAliveTimeout = window.setTimeout(async () => {
      AuthService.sessionKeepAliveTimeout = undefined;

      // Check session is still active
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        return;
      }
      // 30 seconds before expiry, refresh the access token
      await this.refreshSession();
    }, refreshTokenInMS);
  }

  private async refreshSession(orgId?: string): Promise<AuthTokens | undefined> {
    const currentRefreshToken = localStorage.getItem('refreshToken');
    if (!currentRefreshToken || this.getJwtExpiry(currentRefreshToken) < new Date()) {
      console.log('Not refreshing session. ' + currentRefreshToken ? 'Refresh token expired' : 'No refresh token');
      localStorage.removeItem('refreshToken');
      return;
    }
    if (!orgId) {
      orgId = store.state.currentOrg?.id ?? Cookies.get('orgId');
    }

    try {
      const url = new URL(process.env.VUE_APP_AUTH_SVC_URL ?? '');
      url.pathname = '/api/oauth/token';

      const resp = await axios.post<TokenResponse>(
        url.toString(),
        {
          grant_type: 'refresh_token',
          refresh_token: currentRefreshToken,
          scope: 'openid' + (orgId ? ` orgId:${orgId}` : ''),
        },
        {
          withCredentials: true,
        }
      );

      const idToken = resp.data.id_token;
      const accessToken = resp.data.access_token;
      const refreshToken = resp.data.refresh_token;

      if (!idToken || !accessToken || !refreshToken) {
        throw new Error('Did not receive the expected tokens');
      }

      const tokens = this.createTokensObj(accessToken, idToken);

      // Store refresh token in local storage
      this.storeRefreshToken(refreshToken);
      // Store tokens in store
      store.commit(MUT_SET_AUTH_TOKENS, tokens);
      this.initiateSessionKeepAlive(tokens);

      // Set current org if necessary
      if (tokens.accessToken.orgId && store.state.currentOrg?.id !== tokens.accessToken.orgId) {
        await store.dispatch('setCurrentOrg', tokens.accessToken.orgId);
      }

      return tokens;
    } catch (e) {
      console.error('Failed to refresh session. Singing out.');
      console.error(e);
      router.push('/signout');
      throw e;
    }
  }

  /** Stores the refresh token in local storage if it is newer than the existing one */
  private storeRefreshToken(refreshToken: string) {
    const existingRefreshToken = localStorage.getItem('refreshToken');
    const existingRefreshTokenExpiry = existingRefreshToken ? this.getJwtExpiry(existingRefreshToken) : undefined;
    if (!existingRefreshTokenExpiry || this.getJwtExpiry(refreshToken) > existingRefreshTokenExpiry) {
      localStorage.setItem('refreshToken', refreshToken);
    }
  }

  public initCrossWindowComms() {
    if (AuthService.crossWindowCommsEstablished) {
      return;
    }
    AuthService.crossWindowCommsEstablished = true;
    window.addEventListener('storage', (e) => {
      if (e.key === 'refreshToken') {
        if (!e.newValue) {
          // Session was cleared in another window, so we need to sign out
          router.push('/signout');
        }
      } else if (e.key === null && e.newValue === null) {
        // The entire localStorage was cleared, so we need to sign out
        router.push('/signout');
      }
    });
  }

  public async switchAccessOrg(newOrgId: string) {
    await this.refreshSession(newOrgId);
  }
}
