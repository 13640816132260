











import axios from 'axios';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '../../BaseVue';
import UiTooltip from '../ui/UiTooltip.vue';
import { Summary } from './status';

@Component({ components: { UiTooltip } })
export default class StatusIndicator extends BaseVue {
  @Prop({ default: false }) collapsed!: boolean;

  public isLoading = true;

  public summary: Summary | null = null;

  async mounted() {
    const resp = await axios.get<Summary>('https://status.bitwave.io/api/v2/status.json');
    if (resp.status === 200) {
      this.summary = resp.data;
    } else {
      // default to status unavail
    }
  }
}
