





























































































































































































































































































































































































































































































import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import UiButton from '@/components/ui/UiButton.vue';
// model
import { RowAction, RowActionType } from '@/models/uiDataTable';

import UiCheckbox from './UiCheckbox.vue';
import UiDatePicker2 from './UiDatePicker2.vue';
import UiDropdown from './UiDropdown.vue';
import UiListItem from './UiListItem.vue';
import UiLoading from './UiLoading.vue';
import UiSelect2 from './UiSelect2.vue';
import UiTextEdit from './UiTextEdit.vue';
import UiTooltip from './UiTooltip.vue';

interface HeaderItem<T> {
  label: string;
  id?: string;
  defaultVisibility?: boolean;
  selector?: (item: T, index?: number) => unknown;
  defaultWidth?: string;
  groupLabel?: string;
  sortable?: boolean;
  filterable?: boolean;
  rangeFilter?: boolean;
  singleSelectFilter?: boolean;
  textAlignment?: 'left' | 'right';
  type: 'text' | 'number' | 'date';
}

interface GroupHeaderItem {
  label: string;
  colspan: number;
}

@Component({
  components: {
    UiButton,
    UiLoading,
    UiDropdown,
    UiTextEdit,
    UiCheckbox,
    UiListItem,
    UiDatePicker2,
    UiTooltip,
    UiSelect2,
  },
})
export default class UiDataTable extends Vue {
  @Prop()
  public readonly isLoading?: boolean;

  @Prop({ default: true })
  public readonly showLoadingIcon?: boolean;

  @Prop()
  public readonly isMinorLoading?: boolean;

  @Prop()
  public readonly headers!: HeaderItem<unknown>[];

  @Prop({ default: [] })
  public readonly items!: unknown[];

  @Prop()
  public readonly noDataMessage?: string;

  @Prop({ default: false })
  public readonly hideableColumns!: boolean;

  @Prop({ default: false })
  public readonly resizeableColumns!: boolean;

  @Prop({ default: false })
  public readonly checkableRows!: boolean;

  @Prop()
  public readonly rowClassConditionMap?: any;

  @Prop({ default: false })
  public readonly showActionColumn!: boolean;

  @Prop()
  public actions?: (string | { label: string; value: unknown })[];

  @Prop()
  public rowActions?: RowAction[];

  @Prop()
  public readonly expandableRows?: boolean;

  @Prop()
  public readonly expandedRowId?: string;

  @Prop()
  public readonly showRefresh?: boolean;

  @Prop()
  public readonly alignTop?: boolean;

  @Prop()
  public readonly fixedTable?: boolean;

  @Prop({ default: false })
  public readonly showGroupHeaders?: boolean;

  @Prop()
  public readonly uniqueColumnValues?: { [id: string]: Array<string | number> };

  @Prop({ default: '0' })
  public readonly stickyMargin!: string;

  @Prop()
  public readonly checkColumnWidth?: string;

  @Prop()
  public readonly actionColumnWidth?: string;

  @Prop({ default: false })
  public readonly scrollable?: boolean;

  @Prop({ default: '' })
  public readonly className?: string;

  @Prop({ default: false })
  public readonly isError?: boolean;

  @Prop({ default: 'There was an error loading the data.' })
  public readonly errorMessage?: string;

  @Prop({ default: '20rem' })
  public readonly minHeight!: string;

  @Prop()
  public readonly maxHeight?: string;

  @Prop({ required: false })
  public readonly selectedHeaders?: string[];

  @Prop({ default: false })
  public readonly shadedHeader?: boolean;

  @Prop({ default: 'tw-divide-y tw-divide-gray-300' })
  public readonly tbodyClassName?: string;

  @Prop()
  public readonly striped?: boolean;

  @Prop()
  public readonly headerVerticalAlignment?: 'top' | 'middle' | 'bottom';

  @Prop({ default: (item: unknown) => true })
  public readonly checkableRowCallback!: (item: unknown) => boolean;

  @Prop({ default: () => ({}) })
  readonly filters!: { [id: string]: string[] };

  public selectedItems: unknown[] = [];
  public selectedIdxs: number[] = [];
  public resizingTh: HTMLTableColElement | null = null;
  public startOffset = 0;
  public visibleHeaders: HeaderItem<unknown>[] = [];
  public instanceId = uuidv4().substring(0, 8);
  public selectedSort: { id: string; asc: boolean } = { id: '', asc: false };
  public selectedFilter = '';
  public selectedFilters: { [id: string]: string[] } = {};
  public filterSearch = '';
  public filterChanged = false;
  public tempFilters: string[] = [];
  public onDebouncedSearchChange: any;

  public get selectedItemsSet(): Set<unknown> {
    return new Set(this.selectedItems);
  }

  public clearSort() {
    this.selectedSort.id = '';
    this.selectedSort.asc = false;
    this.$emit('sort', this.selectedSort);
  }

  public clearFilter(headerId: string): void {
    this.selectedFilters[headerId]?.splice(0, this.selectedFilters[headerId].length);
    this.filterChanged = true;
    this.filterSearch = '';
    this.onSearchChange(headerId);
  }

  public clearRangeFilter(headerId: string, index: number): void {
    this.selectedFilters[headerId][index] = '';
    if (this.selectedFilters[headerId].every((v) => !v.length)) this.selectedFilters[headerId] = [];
    this.filterChanged = true;
  }

  public rangeFilterChange() {
    this.filterChanged = true;
  }

  public onSearchChange(column: string) {
    this.$emit('search', { search: this.filterSearch, column });
  }

  public get uniqueFilterValues(): string[] {
    if (!this.uniqueColumnValues) {
      return (
        this.items?.reduce((acc: string[], item: any) => {
          const value = item[this.selectedFilter]?.toString() as string;
          if (value && !acc.includes(value) && value.toLowerCase().includes(this.filterSearch.toLowerCase())) {
            acc.push(value);
          }
          return acc;
        }, [] as string[]) || []
      );
    } else {
      return (
        (this.uniqueColumnValues[this.selectedFilter] || []).reduce((acc: string[], item: any) => {
          const value = item?.toString() as string;
          if (value && !acc.includes(value) && value.toLowerCase().includes(this.filterSearch.toLowerCase())) {
            acc.push(value);
          }
          return acc;
        }, [] as string[]) || []
      );
    }
  }

  public get visibleGroupHeaders(): GroupHeaderItem[] {
    if (this.showGroupHeaders === false) return [];
    return this.headers.reduce((acc, header) => {
      if (acc.length && acc[acc.length - 1].label === (header.groupLabel || '')) {
        acc[acc.length - 1].colspan++;
      } else {
        acc.push({
          label: header.groupLabel || '',
          colspan: 1,
        });
      }
      return acc;
    }, [] as GroupHeaderItem[]);
  }

  public onSort(header: HeaderItem<unknown>) {
    if (!header.sortable) return;
    if (this.selectedSort.id === header.id && this.selectedSort.asc) {
      this.selectedSort.id = '';
      this.selectedSort.asc = false;
    } else {
      this.selectedSort.asc = this.selectedSort.id === header.id ? !this.selectedSort.asc : false;
      this.selectedSort.id = header.id || header.label;
    }
    this.$emit('sort', this.selectedSort);
  }

  public changeFilter(value: string, header: HeaderItem<unknown>) {
    this.selectedFilters[header.id || header.label] = [value];
    this.filterChanged = true;
  }

  public addFilter(value: string, header: HeaderItem<unknown>) {
    if (!this.filterChanged) {
      this.tempFilters = [...this.selectedFilters[header.id || header.label]];
    }
    this.filterChanged = true;
    if (this.selectedFilters[header.id || header.label].includes(value)) {
      this.selectedFilters[header.id || header.label].splice(
        this.selectedFilters[header.id || header.label].indexOf(value),
        1
      );
    } else {
      this.selectedFilters[header.id || header.label].push(value);
    }
  }

  public openFilter(header: HeaderItem<unknown>) {
    if (!header.filterable) return;
    this.selectedFilter = header.id || header.label;
    this.$nextTick(() => {
      document.getElementById(this.selectedFilter + '-filter')?.focus();
    });
  }

  public applyFilter() {
    this.selectedFilter = '';
    this.filterSearch = '';
    this.tempFilters = [];
    if (!this.filterChanged) return;
    this.$emit('filter', this.selectedFilters);
    this.filterChanged = false;
  }

  public closeFilter(headerId: string) {
    if (this.filterChanged) Vue.set(this.selectedFilters, this.selectedFilter, this.tempFilters);
    this.filterChanged = false;
    this.selectedFilter = '';
    this.filterSearch = '';
    this.tempFilters = [];
    this.onSearchChange(headerId);
  }

  @Watch('filters')
  onFilterPropChange(filters: { [id: string]: string[] }) {
    for (const column of Object.keys(filters)) {
      this.selectedFilters[column] = filters[column];
      this.filterChanged = true;
    }
  }

  public onGripMouseDown(e: MouseEvent) {
    const th = document.getElementById('col-' + (e as any).target.parentNode.id) as HTMLTableColElement;
    this.resizingTh = th;
    this.startOffset = th.offsetWidth - e.pageX;

    document.addEventListener('mousemove', this.onGripMouseMove);
    document.addEventListener('mouseup', this.onGripMouseUp);
  }

  public onGripMouseMove(e: MouseEvent) {
    if (this.resizingTh) {
      this.resizingTh.style.width = this.startOffset + e.pageX + 'px';
    }
  }

  public onGripMouseUp(e: MouseEvent) {
    this.resizingTh = null;
    document.removeEventListener('mousemove', this.onGripMouseMove);
    document.removeEventListener('mouseup', this.onGripMouseUp);
  }

  public rowClicked(item: unknown, index: number) {
    this.$emit('row-clicked', { item, row: (this.$refs.rows as HTMLElement[])[index] });
  }

  public rowHovered(item: unknown, index: number, isHovered: boolean) {
    this.$emit('row-hovered', { item, row: (this.$refs.rows as HTMLElement[])[index], value: isHovered });
  }

  public get isAllSelected() {
    return (this.items ?? []).length === this.selectedItems.length;
  }

  public set isAllSelected(newValue: boolean) {
    if (newValue) {
      this.selectedItems = this.items ?? [];
    } else {
      this.selectedItems = [];
    }
    this.$emit('selection-changed', this.selectedItems);
  }

  public get actualVisibleHeaders() {
    return this.selectedHeaders
      ? this.headers.filter((header) => this.selectedHeaders?.includes(header?.id ?? ''))
      : this.visibleHeaders;
  }

  @Watch('headers', { immediate: true })
  private onHeadersChanged(newHeaders: HeaderItem<unknown>[]) {
    this.selectedIdxs = [];
    newHeaders.forEach((header, index) => {
      if (header.defaultVisibility ?? true) {
        this.selectedIdxs.push(index);
      }
    });
    this.visibleHeaders = newHeaders.filter((header) => header.defaultVisibility ?? true);
    this.$nextTick(() => {
      this.headers.forEach((header) => {
        if (header.defaultWidth) {
          const elem = document.getElementById(`col-${this.instanceId}-${header.id}`);
          if (elem) {
            elem.style.width = header.defaultWidth;
          }
        }
      });
    });
  }

  public onItemSelectChange(checked: boolean, item: unknown) {
    if (checked) {
      this.selectedItems.push(item);
    } else {
      const itemsSet = this.selectedItemsSet;
      itemsSet.delete(item);
      this.selectedItems = Array.from(itemsSet);
    }
    this.$emit('selection-changed', this.selectedItems);
  }

  public clearSelection() {
    this.selectedItems = [];
    this.$emit('selection-changed', this.selectedItems);
  }

  @Watch('items')
  private onItemsChanged(newItems: unknown[] | undefined) {
    this.selectedItems = [];
  }

  async created() {
    this.headers
      .filter((x) => x.filterable)
      .forEach((header) => {
        Vue.set(this.selectedFilters, header.id || header.label, []);
      });

    this.onDebouncedSearchChange = debounce(this.onSearchChange, 100);
  }

  private get columnCount() {
    let count = this.actualVisibleHeaders.length;
    if (this.checkableRows) count++;
    if (this.actions || this.hideableColumns || this.showActionColumn || this.showRefresh) count++;
    return count;
  }

  public get calcClass() {
    return {
      'tw-overflow-x-scroll': this.scrollable,
      'tw-overflow-y-auto': this.scrollable,
      ...this.className?.split(' ').reduce((a, x) => {
        a[x] = true;
        return a;
      }, {} as { [key: string]: boolean }),
    };
  }

  public get calcStyle() {
    let style = `min-height: ${this.minHeight}`;
    if (this.maxHeight) style += `; max-height: ${this.maxHeight}`;
    if (this.selectedItems.length > 0) style += `; padding-bottom: 48px`;
    return style;
  }

  public get rowActionsButtons(): RowAction[] | undefined {
    return this.rowActions
      ?.filter((btn) => btn.type === RowActionType.Button)
      .sort((a, b) => a.sortIndex - b.sortIndex);
  }

  public get rowActionsDropdown(): RowAction[] | undefined {
    return this.rowActions
      ?.filter((btn) => btn.type === RowActionType.Dropdown)
      .sort((a, b) => a.sortIndex - b.sortIndex);
  }

  public async highlightRow(id: string) {
    const row = ((this.$refs.rows as []) || []).find((x: any) => x.getAttribute('data-id') === id) as any;
    if (row) {
      row.classList.add('row-transition');
      await this.$nextTick();
      row.classList.add('tw-bg-success-100');
      setTimeout(() => {
        row.classList.remove('tw-bg-success-100');
      }, 1000);
    }
  }
}
