import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { InventoryGroup, inventoryIdToGlAccountId, WalletIdToInventoryId } from '@/api-svc-types';
import { StoreStateType } from '@/store';
import { apolloProvider } from '@/vueApollo';

import {
  InventoryActions,
  InventoryActionTypes,
  InventoryGetters,
  InventoryMutations,
  InventoryMutationTypes,
  InventoryState,
} from './types';

const state: InventoryState = {
  inventoryGroups: [],
  inventories: [],
  isLoading: false,
};
const getters: GetterTree<InventoryState, StoreStateType> & InventoryGetters = {
  INVENTORY_GROUPS: (state) => state.inventoryGroups,

  INVENTORIES: (state) => state.inventories,
};

const mutations: MutationTree<InventoryState> & InventoryMutations = {
  [InventoryMutationTypes.MUT_UPDATE_INVENTORY_GROUP](state, payload) {
    const existingIndex = state.inventoryGroups.findIndex(
      (inventoryGroup) => inventoryGroup.id === payload.inventoryGroup.updateInventoryGroup.id
    );
    if (existingIndex !== -1) {
      state.inventoryGroups[existingIndex] = payload.inventoryGroup.updateInventoryGroup;
    }
    state.isLoading = false;
  },

  [InventoryMutationTypes.MUT_CREATE_INVENTORY_GROUP](state, payload) {
    state.inventoryGroups.push(payload.inventoryGroup.createInventoryGroup);
    state.isLoading = false;
  },
  [InventoryMutationTypes.MUT_CREATE_INVENTORY](state, payload) {
    state.inventories.push(payload.inventory.createInventory);
    state.isLoading = false;
  },
  [InventoryMutationTypes.MUT_GET_INVENTORIES](state) {
    state.isLoading = true;
  },
  [InventoryMutationTypes.MUT_SET_INVENTORIES](state, payload) {
    const { inventories, inventoryGroups } = payload;
    state.inventories = inventories;
    state.inventoryGroups = inventoryGroups;
    state.isLoading = false;
  },
  [InventoryMutationTypes.MUT_ERR_INVENTORIES](state) {
    state.inventories = [];
    state.inventoryGroups = [];
    state.isLoading = false;
  },
};
const actions: ActionTree<InventoryState, StoreStateType> & InventoryActions = {
  async [InventoryActionTypes.UPDATE_INVENTORY_GROUP]({ commit }, payload) {
    try {
      commit(InventoryMutationTypes.MUT_GET_INVENTORIES, undefined);

      const { walletIdsToInventoryIds, inventoryGroupId, orgId, accountingConnectionIds, inventoryIdsToGlAccountIds } =
        payload;
      const walletIdToInventoryIdArray: WalletIdToInventoryId[] = [];
      const inventoryIdToGlAccountIdArray: inventoryIdToGlAccountId[] = [];

      const update: any = {};

      if (payload.accountingConnectionIds) {
        update.accountingConnectionIds = Array.from(accountingConnectionIds);
      }

      if (payload.walletIdsToInventoryIds) {
        Object.entries(walletIdsToInventoryIds).map((entry) =>
          walletIdToInventoryIdArray.push({ walletId: entry[0], inventoryId: entry[1] })
        );
        update.walletIdsToInventoryIds = walletIdToInventoryIdArray;
      }

      if (payload.inventoryIdsToGlAccountIds) {
        Object.entries(inventoryIdsToGlAccountIds).map((entry) =>
          inventoryIdToGlAccountIdArray.push({
            inventoryId: entry[0],
            glAccountId: entry[1],
          })
        );
        update.inventoryIdsToGlAccountIds = inventoryIdToGlAccountIdArray;
      }

      const res = await updateInventoryGroup(orgId, inventoryGroupId, update);

      commit(InventoryMutationTypes.MUT_UPDATE_INVENTORY_GROUP, { inventoryGroup: res.data });
    } catch (error) {
      console.error(error);
      commit(InventoryMutationTypes.MUT_ERR_INVENTORIES, undefined);
    }
  },
  /**
   *
   * @param param0
   * @param payload
   */
  async [InventoryActionTypes.CREATE_INVENTORY_GROUP]({ commit }, payload) {
    try {
      commit(InventoryMutationTypes.MUT_GET_INVENTORIES, undefined);
      const { name, description, orgId } = payload;
      const res = await createInventoryGroup(orgId, name, description);
      commit(InventoryMutationTypes.MUT_CREATE_INVENTORY_GROUP, { inventoryGroup: res.data });
    } catch (error) {
      console.error(error);
      commit(InventoryMutationTypes.MUT_ERR_INVENTORIES, undefined);
    }
  },
  /**
   *
   * @param param0
   * @param orgId
   */
  async [InventoryActionTypes.CREATE_INVENTORY]({ commit }, payload) {
    try {
      commit(InventoryMutationTypes.MUT_GET_INVENTORIES, undefined);
      const { orgId, inventoryGroupId, name, description, wallets, walletIdsToInventoryIds } = payload;
      const res = await createInventory(orgId, inventoryGroupId, name, description, wallets);

      const createdMapping =
        wallets?.map((wallet) => ({ walletId: wallet, inventoryId: res.data.createInventory.id })) ?? [];
      const exisitingMapping = Object.entries(walletIdsToInventoryIds).map(([walletId, inventoryId]) => ({
        walletId,
        inventoryId,
      }));

      const groupRes = await updateInventoryGroup(orgId, inventoryGroupId, {
        walletIdsToInventoryIds: [...exisitingMapping, ...createdMapping],
      });

      commit(InventoryMutationTypes.MUT_CREATE_INVENTORY, { inventory: res.data });
      commit(InventoryMutationTypes.MUT_UPDATE_INVENTORY_GROUP, { inventoryGroup: groupRes.data });
    } catch (error) {
      commit(InventoryMutationTypes.MUT_ERR_INVENTORIES, undefined);
    }
  },
  /**
   *
   * @param param0
   * @param orgId
   */
  async [InventoryActionTypes.GET_INVENTORIES]({ commit }, { orgId }) {
    commit(InventoryMutationTypes.MUT_GET_INVENTORIES, undefined);

    try {
      let inventoryRes: ApolloQueryResult<any>;
      let inventoryGroupRes: ApolloQueryResult<any>;
      await Promise.all([
        (async () => {
          inventoryRes = await queryInventories(orgId);
        })(),
        (async () => {
          inventoryGroupRes = await queryInventoryGroups(orgId);
        })(),
      ]).then(() => {
        if (inventoryRes && inventoryGroupRes) {
          commit(InventoryMutationTypes.MUT_SET_INVENTORIES, {
            inventoryGroups: inventoryGroupRes.data.inventoryGroups,
            inventories: inventoryRes.data.inventories,
          });
        } else throw new Error('unable to fetch inventories');
      });
    } catch (error) {
      commit(InventoryMutationTypes.MUT_ERR_INVENTORIES, undefined);
    }
  },
};
// mutations
const updateInventoryGroup = async (
  orgId: string,
  inventoryGroupId: string,
  inventoryUpdate: {
    walletIdsToInventoryIds?: WalletIdToInventoryId[];
    accountingConnectionIds?: string[];
    inventoryIdsToGlAccountIds?: inventoryIdToGlAccountId[];
  }
) => {
  const client = apolloProvider.defaultClient;
  const res = await client.mutate({
    mutation: gql`
      mutation updateInventoryGroup($orgId: ID!, $inventoryGroupId: ID!, $update: UpdateInventoryGroupInput) {
        updateInventoryGroup(orgId: $orgId, inventoryGroupId: $inventoryGroupId, update: $update) {
          id
          orgId
          name
          description
          accountingConnectionIds
          inventoryIdsToGlAccountIds {
            inventoryId
            glAccountId
          }
          walletIdsToInventoryIds {
            walletId
            inventoryId
          }
        }
      }
    `,
    variables: { orgId, inventoryGroupId, update: inventoryUpdate },
  });
  return res;
};
const createInventory = async (
  orgId: string,
  inventoryGroupId: string,
  name: string,
  description?: string,
  wallets?: string[]
) => {
  const client = apolloProvider.defaultClient;
  return await client.mutate({
    mutation: gql`
      mutation createInventory($orgId: ID!, $inventory: CreateInventoryInput, $wallets: [String]) {
        createInventory(orgId: $orgId, inventory: $inventory, wallets: $wallets) {
          id
          orgId
          inventoryGroupId
          name
          description
        }
      }
    `,
    variables: { orgId, inventory: { inventoryGroupId, name, description }, wallets },
  });
};
const createInventoryGroup = async (orgId: string, name: string, description: string) => {
  const client = apolloProvider.defaultClient;
  return await client.mutate({
    mutation: gql`
      mutation createInventoryGroup($orgId: ID!, $inventoryGroup: CreateInventoryGroupInput) {
        createInventoryGroup(orgId: $orgId, inventoryGroup: $inventoryGroup) {
          id
          orgId
          name
        }
      }
    `,
    variables: { orgId, inventoryGroup: { name, description } },
  });
};
// queries
const queryInventories = async (orgId: string) => {
  const client = apolloProvider.defaultClient;
  return await client.query({
    query: gql`
      query GetInventories($orgId: ID!) {
        inventories(orgId: $orgId) {
          id
          orgId
          inventoryGroupId
          name
          description
        }
      }
    `,
    variables: {
      orgId,
    },
  });
};
const queryInventoryGroups = async (orgId: string) => {
  const client = apolloProvider.defaultClient;

  return await client.query({
    query: gql`
      query GetInventoryGroups($orgId: ID!) {
        inventoryGroups(orgId: $orgId) {
          id
          orgId
          name
          description
          id
          accountingConnectionIds
          inventoryIdsToGlAccountIds {
            inventoryId
            glAccountId
          }
          walletIdsToInventoryIds {
            walletId
            inventoryId
          }
        }
      }
    `,
    variables: { orgId },
    fetchPolicy: 'no-cache',
  });
};
export default { namespaced: true, state, getters, actions, mutations };
