












































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import { store } from '@/store';

// components
import TooltipPopup from './TooltipPopup.vue';

@Component({ components: { TooltipPopup } })
export default class Tooltip extends BaseVue {
  public hidden = false;

  setOpen() {
    const open = !this.$store.state.tooltips.open;
    store.dispatch('setOpen', open);
  }

  closeTooltip() {
    store.dispatch('setOpen', false);
  }

  onHide(hidden: boolean) {
    this.hidden = hidden;
    this.$emit('setHidden', this.hidden);
  }

  @Watch('$store.state.tooltips.open')
  onOpenChanged() {
    if (this.$store.state.tooltips.open) {
      this.onHide(false);
    }
  }
}
