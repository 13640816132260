




































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { Org } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

@Component({
  components: {},
})
export default class OrgDisabled extends BaseVue {
  public isOrgDisabled = false;
  public show = false;
  public newOrg: Org | null = null;

  public get orgsNotCurrent(): Org[] {
    const orgs: Org[] = this.$store.state.orgs ?? [];
    return orgs.filter((org) => org && org.id !== this.$store.state.currentOrg?.id);
  }

  public get currentOrg(): Org | undefined {
    return this.$store.state.currentOrg;
  }

  public get currentOrgName(): string | undefined {
    return this.currentOrg?.name ?? undefined;
  }

  get isDisabled(): boolean | undefined {
    return this.$store.state.currentOrg?.isDisabled;
  }

  @Watch('isDisabled', { immediate: true })
  onOrgDisabled(isDisabled?: boolean) {
    this.isOrgDisabled = isDisabled ?? false;
  }

  @Watch('isOrgDisabled', { immediate: true })
  async onIsOrgDisabledChange(isOrgDisabled: boolean) {
    if (isOrgDisabled) {
      this.show = true;
      this.newOrg = null;
    } else this.show = false;

    this.$emit('disabled', isOrgDisabled);
  }

  @Watch('newOrg')
  onNewOrg(newOrg: Org | null) {
    if (!newOrg || !newOrg.id || newOrg?.id === this.currentOrg?.id) {
      // Do nothing if the new org is the same as the current org
      return;
    }

    this.$store.dispatch('setCurrentOrg', newOrg.id);
  }
}
