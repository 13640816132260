export enum InactivityWatcherEvent {
  Active = 'active',
  Warning = 'warning',
  Inactive = 'inactive',
}

class InactivityWatcherKeepActive {
  private disposed = false;
  private keepActive: () => void;

  public dispose(): void {
    if (this.disposed) {
      return;
    }
    this.inactivityWatcher.removeEventListener(InactivityWatcherEvent.Warning, this.keepActive);
    this.inactivityWatcher.setActive();
    this.disposed = true;
  }

  constructor(public condition: () => boolean, private inactivityWatcher: InactivityWatcher) {
    this.keepActive = () => {
      if (this.condition()) {
        this.inactivityWatcher.setActive();
      } else {
        this.dispose();
      }
    };

    this.inactivityWatcher.addEventListener(InactivityWatcherEvent.Warning, this.keepActive);
  }
}

export class InactivityWatcher {
  public static readonly focusEvents: (keyof DocumentEventMap)[] = ['keydown', 'mousedown', 'focus'];
  private timer?: number;
  private warningInProgress = false;

  private channel = new BroadcastChannel('activity-watcher');

  private _enabled = false;
  public get enabled() {
    return this._enabled;
  }

  public set enabled(value: boolean) {
    if (value === this._enabled) {
      return;
    }
    this._enabled = value;

    if (!value) {
      if (this.timer !== undefined) {
        clearTimeout(this.timer);
      }

      this.warningInProgress = false;
    } else {
      this.setActive();
    }
  }

  private constructor(
    public maxInactiveTime = 6 * 60 * 60 * 1000, // 30 minutes
    // public maxInactiveTime = 30 * 60 * 1000, // 30 minutes
    public giveWarningAt = 2 * 60 * 1000 // 2 minutes
  ) {
    for (const event of InactivityWatcher.focusEvents) {
      document.addEventListener(event, () => {
        if (!this.warningInProgress) {
          this.setActive();
        }
      });
    }

    this.channel.onmessage = () => {
      this.localSetActive();
    };
  }

  // eslint-disable-next-line no-use-before-define
  public static instance?: InactivityWatcher;

  public static initialize(options: { maxInactiveTime?: number; giveWarningAt?: number }): InactivityWatcher {
    if (!this.instance) {
      this.instance = new InactivityWatcher(options.maxInactiveTime, options.giveWarningAt);
    } else {
      this.instance.maxInactiveTime = options.maxInactiveTime ?? this.instance.maxInactiveTime;
      this.instance.giveWarningAt = options.giveWarningAt ?? this.instance.giveWarningAt;
      this.instance.clearEventListeners();
    }

    this.instance.setActive();
    return this.instance;
  }

  private fireEvent(event: InactivityWatcherEvent) {
    for (const listener of this.eventListeners[event]) {
      listener();
    }
  }

  /**
   * Sets the user as active and **does not** notify other windows.
   */
  private localSetActive(): void {
    if (!this.enabled) {
      return;
    }

    this.fireEvent(InactivityWatcherEvent.Active);
    this.warningInProgress = false;

    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }

    this.timer = window.setTimeout(() => {
      if (this.enabled) {
        this.fireEvent(InactivityWatcherEvent.Warning);
        this.warningInProgress = true;
        this.timer = window.setTimeout(() => {
          if (this.enabled) {
            this.warningInProgress = false;
            this.fireEvent(InactivityWatcherEvent.Inactive);
          }
        }, this.giveWarningAt);
      }
    }, this.maxInactiveTime - this.giveWarningAt);
  }

  /**
   * Sets the user as active and notifies other windows.
   */
  public setActive(): void {
    this.channel.postMessage('active'); // notifies other windows about user activity
    this.localSetActive();
  }

  private eventListeners = Object.values(InactivityWatcherEvent).reduce(
    (acc, event) => ({ ...acc, [event]: [] }),
    {} as Record<InactivityWatcherEvent, (() => void)[]>
  );

  public addEventListener(event: InactivityWatcherEvent, listener: () => void): void {
    this.eventListeners[event].push(listener);
  }

  public removeEventListener(event: InactivityWatcherEvent, listener: () => void): void {
    const idx = this.eventListeners[event].indexOf(listener);
    if (idx !== -1) {
      this.eventListeners[event].splice(idx, 1);
    }
  }

  private clearEventListeners() {
    for (const event of Object.values(InactivityWatcherEvent)) {
      this.eventListeners[event] = [];
    }
  }

  /**
   * Keeps the session active while a given condition is true.
   * The keep-active is automatically disposed when the condition evaluates to false.
   * Or it can be manually disposed using the returned object.
   * The condition is checked when the inactivity warning event is fired.
   * @returns a disposable object that can be used to remove the keep-active listener
   */
  public keepActive(condition: () => boolean): InactivityWatcherKeepActive {
    return new InactivityWatcherKeepActive(condition, this);
  }
}
