import { MUT_SET_ADDRESSES, MUT_SET_CONNECTED, MUT_SET_NETWORK_ID, MUT_SET_WEB3 } from './types';

const state = () => ({
  isConnected: false,
  connectedNetworkId: null,
  addresses: [],
  web3: null,
});

const getters = {
  IS_CONNECTED: (state) => {
    return state.isConnected;
  },
  ADDRESSES: (state) => {
    return state.addresses;
  },
  CONNECTED_NETWORK_ID: (state) => {
    return state.connectedNetworkId;
  },
};

const actions = {
  async init({ commit }, payload) {
    if (payload) {
      commit(MUT_SET_WEB3, payload.web3);
      commit(MUT_SET_NETWORK_ID, payload.networkId);
      commit(MUT_SET_CONNECTED, true);
      const addresses = await payload.web3.eth.getAccounts();
      commit(MUT_SET_ADDRESSES, addresses);
    } else {
      commit(MUT_SET_CONNECTED, false);
      commit(MUT_SET_NETWORK_ID, undefined);
      commit(MUT_SET_ADDRESSES, []);
      commit(MUT_SET_WEB3, null);
    }

    // const client = apolloProvider.clients.defaultClient;
    // commit(MUT_GET_WALLETS);
    // try {
    //   const res = await client.query({
    //     query: gql`
    //       query GetWallets($orgId: ID!) {
    //         wallets(orgId: $orgId) {
    //           id
    //           name
    //           description
    //           type
    //           deviceType
    //           address
    //           addresses
    //           path
    //           enabledCoins
    //         }
    //       }
    //     `,
    //     variables: {
    //       orgId
    //     },
    //     fetchPolicy: "no-cache"
    //   });
    //   commit(MUT_SET_WALLETS, res.data.wallets);
    // } catch (e) {
    //   commit(MUT_ERR_WALLETS);
    // }
  },
};

const mutations = {
  [MUT_SET_ADDRESSES](state, addresses) {
    state.addresses = addresses;
  },
  [MUT_SET_CONNECTED](state, connected) {
    state.isConnected = connected;
  },
  [MUT_SET_WEB3](state, web3) {
    state.web3 = web3;
  },
  [MUT_SET_NETWORK_ID](state, networkId) {
    state.connectedNetworkId = networkId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
