





















































import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';

import Tooltips, { Tooltip, TooltipPoint } from '../../contracts/tooltips';

@Component({ components: {} })
export default class TooltipPopup extends BaseVue {
  public alphabet = 'abcdefghijklmnopqrstuvwxyz';
  public tipOpen = false;
  public tips = Tooltips;
  public tipArray = Object.values(Tooltips);
  public searchValue = '';

  get searchResults() {
    return this.tipArray.filter(
      (tip) =>
        tip.title?.toLocaleLowerCase().includes(this.searchValue.toLocaleLowerCase()) ||
        this.getCombinedTips(tip.points).toLocaleLowerCase().includes(this.searchValue.toLocaleLowerCase())
    );
  }

  get tip() {
    return this.tips[this.$store.state.tooltips.currentTip];
  }

  mounted() {
    this.dragElement(document.getElementById('myDiv'));
  }

  public getCombinedTips(innerTipArray: TooltipPoint[] | undefined) {
    let combined = '';
    innerTipArray?.map((tip) => {
      combined += tip.point;
      tip.subPoints?.map((subPoint) => (combined += subPoint.point));
    });
    return combined;
  }

  public getTipIdFromTitle(title: string) {
    const found = Object.entries(Tooltips).find((tip) => tip[1].title?.localeCompare(title) === 0);
    if (found) return found[0];
  }

  public setTip(tip: Tooltip) {
    const tipId = tip.title?.length ? this.getTipIdFromTitle(tip.title) : null;
    if (tipId) {
      this.$store.dispatch('setTip', tipId);
    }
    this.searchValue = '';
  }

  public dragElement(elmnt: any) {
    var pos1 = 0;
    var pos2 = 0;
    var pos3 = 0;
    var pos4 = 0;
    const headElem = document.getElementById(elmnt.id + 'Header');
    if (headElem !== null) {
      // if present, the header is where you move the DIV from:
      headElem.onmousedown = dragMouseDown;
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e: any) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e: any) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
      elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
}
