import gql from 'graphql-tag';

import { apolloProvider } from '../../../vueApollo';
import { MUT_ERR_FIATS, MUT_GET_FIATS, MUT_SET_FIATS } from './types';

const state = () => ({
  fiats: [],
  isLoading: null,
});

const getters = {
  FIATS: (state) => {
    return state.fiats;
  },
  FIATS_ISLOADING: (state) => {
    return state.isLoading;
  },
};

const actions = {
  async getFiats({ commit }) {
    const client = apolloProvider.clients.defaultClient;
    commit(MUT_GET_FIATS);
    try {
      const res = await client.query({
        query: gql`
          query GetFiats {
            fiats {
              id
              name
              symbol
            }
          }
        `,
        fetchPolicy: 'no-cache',
      });
      commit(MUT_SET_FIATS, res.data.fiats);
    } catch (e) {
      console.error('Failed to load fiats');
      console.error(e);
      commit(MUT_ERR_FIATS);
    }
  },
};

const mutations = {
  [MUT_GET_FIATS](state) {
    state.isLoading = true;
  },
  [MUT_SET_FIATS](state, fiats) {
    state.fiats = fiats;
    state.isLoading = false;
  },
  [MUT_ERR_FIATS](state) {
    state.fiats = undefined;
    state.isLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
