import gql from 'graphql-tag';

import { apolloProvider } from '../../../vueApollo';
import { MUT_ERR_CONTACTS, MUT_GET_CONTACTS, MUT_SET_CONTACTS } from './types';

const state = () => ({
  contacts: undefined,
  isLoading: null,
});

const getters = {
  CONTACTS: (state) => {
    return state.contacts;
  },
  ENABLED_CONTACTS: (state) => {
    if (state.contacts) {
      return state.contacts.filter((c) => c.enabled === true);
    } else {
      return [];
    }
  },
  CONTACTS_ISLOADING: (state) => {
    return state.isLoading;
  },
};

const actions = {
  async getContacts({ commit }, orgId) {
    const client = apolloProvider.clients.defaultClient;
    commit(MUT_GET_CONTACTS);
    try {
      const res = await client.query({
        query: gql`
          query GetContacts($orgId: ID!) {
            contacts(orgId: $orgId, includeDisabled: true) {
              id
              source
              name
              enabled
              type
              metadata
              addresses {
                coin
                address
                memo
                defaultExpenseCategoryId
                defaultRevenueCategoryId
                networkId
              }
              accountingConnectionId
              defaultExpenseCategoryId
              defaultRevenueCategoryId
            }
          }
        `,
        variables: {
          orgId,
        },
        fetchPolicy: 'network-only',
      });
      commit(MUT_SET_CONTACTS, res.data.contacts);
    } catch (e) {
      commit(MUT_ERR_CONTACTS);
    }
  },
};

const mutations = {
  [MUT_GET_CONTACTS](state) {
    state.isLoading = true;
  },
  [MUT_SET_CONTACTS](state, contacts) {
    state.contacts = contacts;
    state.isLoading = false;
  },
  [MUT_ERR_CONTACTS](state) {
    state.contacts = undefined;
    state.isLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
