interface Constructible<T> {
  new (...args: unknown[]): T;
}

type BaseTypes = {
  string: string;
  bigint: BigInt;
  // eslint-disable-next-line @typescript-eslint/ban-types
  function: Function;
  object: Record<string, unknown>;
  boolean: boolean;
  number: number;
  undefined: undefined;
  symbol: symbol;
};

// #region Is x
export function isDefined<T>(val: T): val is NonNullable<T> {
  return val != null;
}

export function isInstanceOf<T>(constructor: Constructible<T>, val: unknown): val is T {
  return val instanceof constructor;
}

export function isObject(val: unknown): val is Record<string, unknown> {
  return val != null && typeof val === 'object';
}

export function isErrorLike(val: unknown): val is Error {
  return (
    isObject(val) &&
    typeof val.name === 'string' &&
    typeof val.message === 'string' &&
    (val.stack === undefined || typeof val.stack === 'string')
  );
}

export function hasProperty<T extends string, U extends keyof BaseTypes = never>(
  property: T,
  val: unknown,
  type?: U
): val is { [key in T]: [U] extends [never] ? unknown : BaseTypes[U] } {
  return isObject(val) && (type ? typeof val[property] === type : val[property] != null);
}
// #endregion

// #region Assert x
export function assertDefined<T>(val: T): asserts val is NonNullable<T> {
  if (!isDefined(val)) {
    throw new TypeError('Value was required but not defined');
  }
}

export function assertInstanceOf<T>(constructor: Constructible<T>, val: unknown): asserts val is T {
  if (!isInstanceOf(constructor, val)) {
    throw new TypeError(`Expected an instance of ${constructor.name}`);
  }
}

export function assertObject(val: unknown): asserts val is Record<string, unknown> {
  if (!isObject(val)) {
    throw new TypeError('Expected an object');
  }
}

export function assertErrorLike(val: unknown): asserts val is Error {
  if (!isErrorLike(val)) {
    throw new TypeError('Expected an error object');
  }
}
// #endregion

// #region As x
export function asDefined<T>(val: T): NonNullable<T> {
  assertDefined(val);
  return val;
}

export function asInstanceOf<T>(constructor: Constructible<T>, val: unknown): T {
  assertInstanceOf(constructor, val);
  return val;
}

export function asObject(val: unknown): Record<string, unknown> {
  assertObject(val);
  return val;
}

export function asErrorLike(val: unknown): Error {
  assertErrorLike(val);
  return val;
}
// #endregion
