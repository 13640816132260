


















import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import UiFormLabel from './UiFormLabel.vue';

@Component({ components: { UiFormLabel } })
export default class UiCheckbox extends Vue {
  @Prop()
  public readonly value?: boolean;

  @Prop()
  public readonly label?: string;

  @Prop()
  public readonly disabled?: boolean;

  public instanceId = uuidv4();
  public checkStatus = false;

  @Watch('value', { immediate: true })
  private onValueChange(newValue: boolean) {
    this.checkStatus = newValue;
  }

  @Watch('checkStatus')
  private onCheckStatusChanged(newValue: boolean) {
    this.$emit('input', newValue);
  }
}
