




















import Component from 'vue-class-component';
import { ModelSync, Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

@Component({})
export default class Timer extends BaseVue {
  @Prop({ required: true })
  public readonly duration!: number;

  @Prop({ required: true })
  public readonly enabled!: boolean;

  @ModelSync('elapsedProp', 'tick', { required: true })
  public elapsed!: number;

  private interval?: number;

  public get remainingTimeString(): string {
    const remainingTime = this.duration - this.elapsed;
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime - minutes * 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  @Watch('elapsedProp')
  onElapsedPropChanged() {
    if (this.elapsed < this.duration && this.enabled) {
      this.start();
    }
  }

  @Watch('enabled', { immediate: true })
  onEnabledChanged(val: boolean) {
    if (val) {
      this.start();
    } else {
      this.stop();
    }
  }

  public start() {
    if (this.interval !== undefined) {
      return;
    }

    this.interval = window.setInterval(() => {
      if (this.elapsed < this.duration) {
        this.elapsed++;
      }
      if (this.elapsed >= this.duration) {
        this.$emit('done');
        this.stop();
      }
    }, 1000);
  }

  public stop() {
    if (this.interval === undefined) {
      return;
    }

    clearInterval(this.interval);
    this.interval = undefined;
  }
}
