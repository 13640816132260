// used in the UIDatatableComponent | TransactionTable | TransactionsTable 2
export enum RowActionType {
  Button = 'button',
  Dropdown = 'dropdown',
}

export enum RowActionPlace {
  Main = 'main',
  RowActions = 'row-actions', // only this is used in the UIDatatableComponent
}

export interface RowAction {
  label: string;
  value: () => void;
  disabled?: boolean;
  type: RowActionType;
  place: RowActionPlace[];
  sortIndex: number;
  separator?: boolean;
  tooltip?: string;
}
