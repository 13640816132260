











































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiCheckbox from '@/components/ui/UiCheckbox.vue';

@Component({ components: { UiCheckbox } })
export default class UiListItem extends Vue {
  @Prop({ default: '' })
  public readonly title!: string;

  @Prop({ default: false })
  public readonly showIcon?: boolean;

  @Prop({ default: false })
  public readonly active?: boolean;

  @Prop({ default: '' })
  public readonly icon?: string;

  @Prop({ default: '' })
  public readonly image?: string;

  @Prop({ default: false })
  public readonly centered?: boolean;

  @Prop({ default: false })
  public readonly checkboxes?: boolean;

  @Prop({ default: false })
  public readonly static?: boolean;

  @Prop()
  public readonly caption?: string;

  public click() {
    if (!this.static) this.$emit('click');
  }
}
