import qs from 'qs';

type FilterOperator = '==' | '!=' | '<' | '<=' | '>' | '>=' | 'in';
type FilterValue = string | number | (string | number)[] | null;
type FilterOptions = {
  skipNullValue?: boolean;
  skipEmptyValue?: boolean;
  skipAllKeyword?: boolean;
};
type QueryParamFilter = {
  filterName: string;
  operator: FilterOperator;
  value: FilterValue;
};

export function constructFilter(key: string, value: FilterValue, operator?: FilterOperator): QueryParamFilter {
  let op = operator;

  if (!op && value instanceof Array) op = 'in';
  else if (!op) op = '==';

  return {
    filterName: key,
    value,
    operator: op,
  };
}

export function construcRangeFilter(key: string, [from, to]: (string | number | undefined)[]) {
  const filters: QueryParamFilter[] = [];
  if (from) filters.push(constructFilter(key, from, '>='));
  if (to) filters.push(constructFilter(key, to, '<='));
  return filters;
}

export function filtersFromObject(object: Record<string, FilterValue | undefined>, options?: FilterOptions) {
  const filters: QueryParamFilter[] = [];
  for (const key of Object.keys(object)) {
    const value = object[key];
    const skipNull = options?.skipNullValue && value === null;
    const skipEmpty = options?.skipEmptyValue && (typeof value === 'string' || value instanceof Array) && !value.length;
    const skipAllKeyword =
      options?.skipAllKeyword && (typeof value === 'string' || value instanceof Array) && value.includes('All');
    if (value === undefined || skipNull || skipEmpty || skipAllKeyword) continue;
    filters.push(constructFilter(key, value));
  }
  return filters;
}

export function stringifyParams(object: Record<string, unknown>, options?: any) {
  return qs.stringify(object, options);
}

export function parseParams(queryParams: string, options?: any) {
  return qs.parse(queryParams, options);
}

export function getEndpointUrl(baseUrl: string, pathSegments?: string | string[], params?: Record<string, unknown>) {
  let endpoint = baseUrl;
  if (endpoint.slice(-1) !== '/') endpoint += '/';
  if (pathSegments) {
    if (pathSegments instanceof Array) {
      endpoint += `${pathSegments.join('/')}`;
    } else {
      baseUrl += pathSegments;
    }
  }
  if (params) {
    const queryParams = stringifyParams(params);
    endpoint += `?${queryParams}`;
  }
  return endpoint;
}
