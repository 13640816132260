<template>
  <div>
    <v-alert :value="true" color="success" v-if="checkFeatureFlag('create-org')">
      <h2>{{ $t('_onboardingWelcome') }}!</h2>
      <p>{{ $t('_onboardingMustCreateOrg') }}</p>
      <p>{{ $t('_onboardingHowToCreateOrg') }}</p>
    </v-alert>
    <v-alert :value="true" color="warning" v-else>
      <p>{{ $t('_onboardingNoOrg') }}</p>
    </v-alert>
  </div>
</template>

<script>
export default {};
</script>
