




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class UiTooltip extends Vue {
  @Prop()
  public readonly text?: string;

  @Prop({ default: 'top' })
  public readonly position!: 'top' | 'right' | 'bottom' | 'left';

  @Prop({ default: false })
  public readonly fixed!: boolean;

  @Prop({ default: '' })
  public readonly tooltipClass!: string;

  @Prop({ default: true })
  public readonly show!: boolean;

  public get styles() {
    if (!this.fixed) {
      switch (this.position) {
        case 'top':
          return { bottom: 'calc(100% + 5px)', left: '50%', transform: 'translateX(-50%)' };
        case 'right':
          return { left: 'calc(100% + 5px)', top: '50%', transform: 'translateY(-50%)' };
        case 'bottom':
          return { top: 'calc(100% + 5px)', left: '50%', transform: 'translateX(-50%)' };
        case 'left':
          return { right: 'calc(100% + 5px)', top: '50%', transform: 'translateY(-50%)' };
      }
    } else {
      // Fixed mode
      if (!this.fixedPos) {
        return { top: '0', left: '0' };
      }
      switch (this.position) {
        case 'top':
          return {
            bottom: this.fixedPos.top + 5 + 'px',
            left: this.fixedPos.left + this.fixedPos.width / 2 + 'px',
            transform: 'translateX(-50%)',
          };
        case 'right':
          return {
            left: this.fixedPos.right + 5 + 'px',
            top: this.fixedPos.top + this.fixedPos.height / 2 + 'px',
            transform: 'translateY(-50%)',
          };
        case 'bottom':
          return {
            top: this.fixedPos.bottom + 5 + 'px',
            left: this.fixedPos.left + this.fixedPos.width / 2 + 'px',
            transform: 'translateX(-50%)',
          };
        case 'left':
          return {
            right: this.fixedPos.left + 5 + 'px',
            top: this.fixedPos.top + this.fixedPos.height / 2 + 'px',
            transform: 'translateY(-50%)',
          };
      }
    }
  }

  private fixedPos: DOMRect | null = null;

  public onMouseIn(): void {
    if (this.fixed) {
      this.fixedPos = this.$el.getBoundingClientRect();
    }
  }
}
