import { ApiSvcTokenFilteringRulesConfig } from 'generated/api-svc/api';
import Cookies from 'js-cookie';
import _ from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';

import { MeResponse } from '@/services/userService';
import { createClients } from '@/vueApollo';

import { AuthTokens } from '../services/authService';
import Actions from './actions';
import categories from './modules/categories';
import connect from './modules/connect';
import contacts from './modules/contacts';
import fiats from './modules/fiats';
import inventories from './modules/inventories';
import { InventoryState } from './modules/inventories/types';
import tooltips from './modules/tooltips';
import wallets from './modules/wallets';

Vue.use(Vuex);

// https://vuex.vuejs.org/guide/mutations.html#using-constants-for-mutation-types
export const MUT_SET_USER = 'MUT_SET_USER';
export const MUT_IS_LOADING = 'MUT_IS_LOADING';
export const MUT_SET_FIREBASE_TOKEN = 'MUT_SET_FIREBASE_TOKEN';
export const MUT_SET_ORGS = 'MUT_SET_ORGS';
export const MUT_SET_CURRENT_ORG = 'MUT_SET_CURRENT_ORG';
export const MUT_LOGOUT = 'MUT_LOGOUT';
export const MUT_SNACKBAR = 'MUT_SNACKBAR';
export const MUT_FEATURES = 'MUT_FEATURES';
export const MUT_SET_INVOICING_SETTINGS = 'MUT_SET_INVOICING_SETTINGS';
export const MUT_IS_UPDATE_WALLET = 'MUT_IS_UPDATE_WALLET';
export const MUT_FLAG_NEW_ORG = 'MUT_FLAG_NEW_ORG';
export const MUT_SET_AUTH_TOKENS = 'MUT_SET_AUTH_TOKENS';
export const MUT_TOKEN_FILTERING_RULES = 'MUT_TOKEN_FILTERING_RULES';
export const MUT_SET_LOCALE = 'MUT_SET_LOCALE';
export const MUT_SCOPES = 'MUT_SCOPES';
export const MUT_NETWORKS = 'MUT_NETWORKS';
export const MUT_PARENT_AUTH_TOKEN = 'MUT_PARENT_AUTH_TOKEN';
export const MUT_ADD_NOTIFICATION = 'MUT_ADD_NOTIFICATION';
export const MUT_ADD_WALLET_ROLE = 'MUT_ADD_WALLET_ROLE';

const initialState = {
  user: undefined as MeResponse | undefined,
  isLoading: true,
  firebaseIdToken: undefined as string | undefined,
  orgs: undefined as any,
  locale: undefined,
  currentOrg: undefined as any,
  snackbar: undefined as any,
  features: undefined as Record<string, string> | undefined,
  scopes: undefined as string[] | undefined,
  networks: undefined as any,
  isUpdateWallet: false,
  newOrgDetails: undefined as { orgId: string; createdAt: Date } | undefined,
  /** Auth tokens created by BW auth */
  authTokens: undefined as AuthTokens | undefined,
  tokenFilteringRules: undefined as ApiSvcTokenFilteringRulesConfig | undefined,
  parentAuthToken: undefined as string | undefined,
  notifications: [] as any[],
  addedWalletRole: undefined as string | undefined,
};

export type StoreStateType = typeof initialState & { categories: ReturnType<typeof categories.state> } & {
  contacts: ReturnType<typeof contacts.state>;
} & { wallets: ReturnType<typeof wallets.state> } & { connect: ReturnType<typeof connect.state> } & {
  fiats: ReturnType<typeof fiats.state>;
} & { tooltips: ReturnType<typeof tooltips.state> } & { inventories: InventoryState };

export const store = new Vuex.Store<StoreStateType>({
  modules: {
    inventories,
    categories,
    contacts,
    wallets,
    connect,
    fiats,
    tooltips,
  },
  state: initialState as StoreStateType,
  getters: {
    CURRENT_ORG: (state) => {
      return state.currentOrg;
    },
    ORGS: (state) => {
      return state.orgs;
    },
    features: (state) => {
      return {
        ...state.features,
        ...state.user?.featureFlags,
      };
      // return state.features;
    },
    scopes: (state) => {
      return state.scopes;
    },
    networks: (state) => {
      return state.networks;
    },
    apolloClients: (state) => {
      // Get query parameters before the hash
      const urlSearchParams = new URLSearchParams(window.location.search);

      // Get query parameters within the hash
      const hash = window.location.hash;
      const hashSearchParams = hash.includes('?') ? new URLSearchParams(hash.split('?')[1]) : new URLSearchParams();

      // Combine both URLSearchParams objects into one, prioritizing parameters from the hash
      const embeddedParam = hashSearchParams.get('embedded') || urlSearchParams.get('embedded');
      const isAuthEmbedded = embeddedParam === 'true';
      const useParentAuthToken = window.self !== window.top && !isAuthEmbedded;
      return createClients(
        state.features?.['dev-console'] === 'true' ? { debug: 'true' } : undefined,
        useParentAuthToken ? state.parentAuthToken : state.authTokens?.accessToken.token
      );
    },
  },
  mutations: {
    [MUT_SET_LOCALE](state, locale) {
      state.locale = locale;
    },
    [MUT_SET_USER](state, user) {
      state.user = user;
    },
    [MUT_IS_LOADING](state, bool) {
      state.isLoading = bool;
    },
    [MUT_SET_FIREBASE_TOKEN](state, token) {
      state.firebaseIdToken = token;
    },
    [MUT_SET_ORGS](state, orgs) {
      state.orgs = _.sortBy(orgs, ['name']);
    },
    [MUT_SET_CURRENT_ORG](state, orgId) {
      if (state.orgs) {
        state.orgs.forEach((org: any) => {
          if (org.name === 'SPANNER Test') {
            org.name = '[Sandbox] Bitwave';
          }
          if (org.id === orgId) {
            state.currentOrg = org;
            Cookies.set('orgId', orgId);
          }
        });
      }
    },
    [MUT_LOGOUT](state) {
      state.user = undefined;
      state.firebaseIdToken = undefined;
      state.orgs = undefined;
      state.currentOrg = undefined;
      state.authTokens = undefined;
    },
    [MUT_SNACKBAR](state, snackbar) {
      state.snackbar = snackbar;
    },
    [MUT_IS_UPDATE_WALLET](state) {
      state.isUpdateWallet = !state.isUpdateWallet;
    },
    [MUT_FEATURES](state, features) {
      state.features = features;
    },
    [MUT_SCOPES](state, scopes) {
      state.scopes = scopes;
    },
    [MUT_PARENT_AUTH_TOKEN](state, token) {
      state.parentAuthToken = token;
    },
    [MUT_NETWORKS](state, networks) {
      state.networks = networks;
    },
    [MUT_SET_INVOICING_SETTINGS](state, invoicingSettings) {
      state.currentOrg.invoicingSettings = invoicingSettings;
    },
    [MUT_FLAG_NEW_ORG](state, newOrgDetails: { orgId: string; createdAt: Date }) {
      state.newOrgDetails = newOrgDetails;
    },
    [MUT_SET_AUTH_TOKENS](state, authTokens: AuthTokens) {
      state.authTokens = authTokens;
    },
    [MUT_TOKEN_FILTERING_RULES](state, tokenFilteringRules) {
      state.tokenFilteringRules = tokenFilteringRules;
    },
    [MUT_ADD_NOTIFICATION](state, notification) {
      state.notifications.push(notification);
    },
    [MUT_ADD_WALLET_ROLE](state, role) {
      state.addedWalletRole = role;
    },
  },
  actions: Actions,
});
