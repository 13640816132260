
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiLoading from './UiLoading.vue';

@Component({ components: { UiLoading } })
export default class UiButton extends Vue {
  @Prop()
  public readonly icon?: string;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: false })
  public loading!: boolean;

  @Prop({ default: 'primary' })
  public readonly color!: 'primary' | 'secondary' | 'white' | 'error' | 'success';

  public get classes(): string[] {
    switch (this.color) {
      case 'primary':
        return [
          'tw-border',
          'tw-text-white',
          'tw-bg-primary-300',
          'hover:tw-bg-primary-400',
          'focus:tw-outline-none',
          'focus:tw-ring-2',
          'focus:tw-ring-offset-2',
          'focus:tw-ring-primary-200',
        ];
      case 'secondary':
        return [
          'tw-p-2',
          'tw-bg-white',
          'tw-rounded-md',
          'tw-shadow-sm',
          'tw-border',
          'tw-border-solid',
          'tw-border-neutral-200',
          'tw-flex',
          'tw-items-center',
          'tw-justify-between',
          'tw-whitespace-nowrap',
          'tw-h-9',
          'tw-overflow-hidden',
          'hover:tw-bg-blue-100',
          'focus:tw-outline-none',
          'focus:tw-ring-2',
          'focus:tw-ring-offset-2',
          'focus:tw-ring-neutral-300',
        ];
      case 'white':
        return [
          'tw-text-gray-900',
          'tw-bg-white',
          'tw-border-2',
          'tw-border-solid',
          'tw-border-neutral-300',
          'hover:tw-bg-blue-100',
          'focus:tw-outline-none',
          'focus:tw-ring-2',
          'focus:tw-ring-offset-2',
          'focus:tw-ring-neutral-300',
        ];
      case 'error':
        return [
          'tw-border-red-500',
          'tw-border-solid',
          'tw-border-2',
          'tw-rounded-sm',
          'tw-text-red-500',
          'tw-bg-white',
        ];
      case 'success':
        return [
          'tw-border',
          'tw-text-white',
          'tw-bg-success-300',
          'hover:tw-bg-success-200',
          'focus:tw-outline-none',
          'focus:tw-ring-2',
          'focus:tw-ring-offset-2',
          'focus:tw-ring-primary-200',
        ];
    }
  }
}
