import gql from 'graphql-tag';

import { apolloProvider } from '../../../vueApollo';
import { MUT_ERR_CATEGORIES, MUT_GET_CATEGORIES, MUT_SET_CATEGORIES } from './types';

const state = () => ({
  categories: undefined,
  isLoading: null,
});

const getters = {
  CATEGORIES: (state) => {
    return state.categories;
  },
  ENABLE_CATEGORIES: (state) => {
    if (state.categories) {
      return state.categories.filter((c) => c.enabled === true);
    } else {
      return [];
    }
  },
  CATEGORIES_ISLOADING: (state) => {
    return state.isLoading;
  },
};

const actions = {
  async getCategories({ commit }, orgId) {
    const client = apolloProvider.clients.defaultClient;
    commit(MUT_GET_CATEGORIES);
    try {
      const res = await client.query({
        query: gql`
          query GetCategories($orgId: ID!) {
            categories(orgId: $orgId, includeDisabled: true) {
              id
              source
              name
              code
              enabled
              type
              accountingConnectionId
              lastUpdatedSEC
            }
          }
        `,
        variables: {
          orgId,
        },
        fetchPolicy: 'no-cache',
      });
      commit(MUT_SET_CATEGORIES, res.data.categories);
    } catch (e) {
      commit(MUT_ERR_CATEGORIES);
    }
  },
};

const mutations = {
  [MUT_GET_CATEGORIES](state) {
    state.isLoading = true;
  },
  [MUT_SET_CATEGORIES](state, categories) {
    state.categories = categories;
    state.isLoading = false;
  },
  [MUT_ERR_CATEGORIES](state) {
    state.categories = undefined;
    state.isLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
