import { ActionContext } from 'vuex';

import { StoreStateType } from '@/store';

export interface InventoryState {
  inventoryGroups: any[];
  inventories: any[];
  isLoading: boolean;
}
export interface InventoryGetters {
  INVENTORY_GROUPS(state: InventoryState): any[];
  INVENTORIES(state: InventoryState): any[];
}

export enum InventoryMutationTypes {
  MUT_SET_INVENTORIES = 'MUT_SET_INVENTORIES',
  MUT_GET_INVENTORIES = 'MUT_GET_INVENTORIES',
  MUT_ERR_INVENTORIES = 'MUT_ERR_INVENTORIES',
  MUT_CREATE_INVENTORY = 'MUT_CREATE_INVENTORY',
  MUT_CREATE_INVENTORY_GROUP = 'MUT_CREATE_INVENTORY_GROUP',
  MUT_UPDATE_INVENTORY_GROUP = 'MUT_UPDATE_INVENTORY_GROUP',
}
export type SetInventoriesPayload = {
  inventories: any[];
  inventoryGroups: any[];
};
export type CreateInventoryPayload = {
  inventory: any;
};
export type CreateInventoryGroupPayload = {
  inventoryGroup: any;
};
export type UpdateInventoryGroupPayload = {
  inventoryGroup: any;
};
export interface InventoryMutations {
  [InventoryMutationTypes.MUT_GET_INVENTORIES](state: InventoryState): void;
  [InventoryMutationTypes.MUT_SET_INVENTORIES](state: InventoryState, payload: SetInventoriesPayload): void;
  [InventoryMutationTypes.MUT_ERR_INVENTORIES](state: InventoryState): void;
  [InventoryMutationTypes.MUT_CREATE_INVENTORY](state: InventoryState, payload: CreateInventoryPayload): void;
  [InventoryMutationTypes.MUT_CREATE_INVENTORY_GROUP](
    state: InventoryState,
    payload: CreateInventoryGroupPayload
  ): void;
  [InventoryMutationTypes.MUT_UPDATE_INVENTORY_GROUP](
    state: InventoryState,
    payload: CreateInventoryGroupPayload
  ): void;
}
export enum InventoryActionTypes {
  GET_INVENTORIES = 'GET_INVENTORIES',
  CREATE_INVENTORY = 'CREATE_INVENTORY',
  CREATE_INVENTORY_GROUP = 'CREATE_INVENTORY_GROUP',
  UPDATE_INVENTORY_GROUP = 'UPDATE_INVENTORY_GROUP',
}
export type AugmentedActionContext = {
  commit<K extends keyof InventoryMutations>(
    key: K,
    payload: Parameters<InventoryMutations[K]>[1]
  ): ReturnType<InventoryMutations[K]>;
} & Omit<ActionContext<InventoryState, StoreStateType>, 'commit'>;

type CreateInventoryGroupParams = {
  orgId: string;
  name: string;
  description: string;
};
type CreateInventoryParams = {
  orgId: string;
  inventoryGroupId: string;
  name: string;
  description?: string;
  wallets?: string[];
  walletIdsToInventoryIds: { [key: string]: string };
};

type UpdateInventoryParams = {
  orgId: string;
  inventoryGroupId: string;
  walletIdsToInventoryIds: { [key: string]: string };
  inventoryIdsToGlAccountIds: { [key: string]: string };
  accountingConnectionIds: Set<string>;
};
type GetInventoriesParams = {
  orgId: string;
};
export interface InventoryActions {
  [InventoryActionTypes.GET_INVENTORIES](
    { commit }: AugmentedActionContext,
    getInventoriesParams: GetInventoriesParams
  ): Promise<void>;
  [InventoryActionTypes.CREATE_INVENTORY](
    { commit }: AugmentedActionContext,

    createInventoryParams: CreateInventoryParams
  ): Promise<void>;
  [InventoryActionTypes.CREATE_INVENTORY_GROUP](
    { commit }: AugmentedActionContext,
    createInventoryGroupParams: CreateInventoryGroupParams
  ): Promise<void>;
  [InventoryActionTypes.UPDATE_INVENTORY_GROUP](
    { commit }: AugmentedActionContext,
    updateInventoryGroupParams: UpdateInventoryParams
  ): Promise<void>;
}
