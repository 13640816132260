







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class UiFormLabel extends Vue {
  @Prop({})
  public readonly description?: string;

  @Prop({})
  public readonly for!: string;

  @Prop({ default: true })
  public readonly spacing?: boolean;

  public get forProxy() {
    return this.for;
  }
}
