import axios from 'axios';
import firebase from 'firebase/app';
import * as math from 'mathjs';
import moment from 'moment-timezone';
import Vue from 'vue';
import Component from 'vue-class-component';
import Web3 from 'web3';

import { ScopeLiterals } from './api-svc-types';
import { MeResponse, UserService } from './services/userService';
import { MUT_SNACKBAR, store } from './store';
import { getSymbolForCurrency, numberWithCommas } from './utils/coinUtils';
import { checkScope } from './utils/security';

export async function doCookieAuth(forceRefresh?: boolean): Promise<MeResponse> {
  const url = process.env.VUE_APP_API_URL + 'sessionLogin';
  const us = new UserService();
  const user = await us.getMe();
  if (user && !forceRefresh) {
    return user;
  } else if (firebase.auth().currentUser) {
    console.log('Logging in with firebase user');
    const token = await firebase.auth().currentUser?.getIdToken(true);
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    try {
      const resp = await axios({
        method: 'POST',
        url,
        headers,
        withCredentials: true,
      });
      if (resp.status === 200) {
        const user = await us.getMe();
        if (user === undefined) {
          throw new Error('Unexpectedly unable to get me on api');
        }
        await checkSiblingReferral();
        return user;
      } else {
        const error = `Failure Logging in: ${resp.status} - ${
          typeof resp.data === 'object' ? JSON.stringify(resp.data) : resp.data
        }`;
        throw new Error(error);
      }
    } catch (e) {
      console.log('Problem logging in', e);
      throw e;
    }
  } else {
    console.log('Unable to log in with firebase auth, failing do cookie auth');
    throw new Error('Unable to login with firebase or cookie');
  }
}

/**
 * helper function to check if we have a sibling referral before we do anything else
 */
export async function checkSiblingReferral() {
  const urlParams = new URLSearchParams(window.location.href.match(/(?<=\?)([^?]+)$/)?.[0]);
  const siblingId = urlParams.get('siblingLogin') ?? localStorage.getItem('siblingApplicationId');
  if (siblingId) {
    const siblingUrl = getSiblingApplicationUrl(siblingId);
    if (siblingUrl) {
      // we want to remove this any time we leave the page for good
      localStorage.removeItem('siblingApplicationId');
      window.location.href = siblingUrl;
    }
  }
}

export function getSiblingApplicationUrl(identifier: string) {
  switch (identifier) {
    case 'wallet-dashboard-web-app':
      return process.env.VUE_APP_WALLET_MANAGER_WEB_APP_URL;
    default:
      throw new Error(`No sibling application url found for ${identifier}`);
  }
}
@Component
export class BaseVue extends Vue {
  get $store(): typeof store {
    return super.$store;
  }

  set $store(val: typeof store) {
    super.$store = val;
  }

  toPreferredDateTime(timestamp: number, short?: boolean): string | undefined {
    if (timestamp === null || timestamp === undefined) {
      return undefined;
    }
    const useOrgTimezone = this.$store.state.currentOrg.displayConfig?.useOrgTimezone ?? false;
    return useOrgTimezone ? this.toOrgLocalTime(timestamp, short) : this.toLocalDateTime(timestamp);
  }

  toLocalDateTime(timestamp: number): string {
    return moment.unix(timestamp).tz(moment.tz.guess()).format('lll z');
  }

  getOrgMoment() {
    const m = moment().tz(this.$store.state.currentOrg.timezone);
    return m;
  }

  public get features() {
    return this.$store.getters.features;
  }

  public get scopes() {
    return this.$store.getters.scopes;
  }

  public get orgId() {
    return this.$store.state.currentOrg.id;
  }

  public get scopeLiterals() {
    return ScopeLiterals;
  }

  nc<T>(item: T | undefined | null, other: T) {
    if (item) {
      return item;
    } else {
      return other;
    }
  }

  checkFeatureFlag(flag: string, featureFlags?: Record<string, string>): boolean {
    if (featureFlags) {
      const u = featureFlags[flag];
      return u === 'true';
    } else {
      if (this.features) {
        return this.features[flag] === 'true';
      }

      return false;
    }
  }

  convertBigNumberScalar(bigNumber: { mathjs: string; value: string } | string) {
    const num = typeof bigNumber === 'string' ? bigNumber : bigNumber.value;
    return math.bignumber(num);
  }

  checkScope(scope: string): boolean {
    return checkScope(scope);

    // if (this.$store.state.currentOrg === undefined) {
    //   return false;
    // }

    // const orgId = this.$store.state.currentOrg.id;
    // const resolvedScope = scope.replace('$orgId', orgId).replace('$walletId', '*');
    // if (this.scopes) {
    //   return this.scopes.some((m: string) => m === resolvedScope);
    // }
    //
    // return false;
    // return true;
  }

  bn(value: Parameters<typeof math.bignumber>[0]): math.BigNumber {
    return math.bignumber(value);
  }

  web3(): Web3 {
    if (this.$store.state.connect.web3) {
      return this.$store.state.connect.web3;
    } else {
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'No wallet connected',
      });
      throw new Error('Not connected to wallet');
    }
  }

  public toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }

  parseDate(dateString: string): boolean {
    try {
      const regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateString.match(regEx)) return false; // Invalid format
      const d = new Date(dateString);
      const dNum = d.getTime();
      if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
      return d.toISOString().slice(0, 10) === dateString;
    } catch (e) {
      return false;
    }
  }

  getFunctionsBaseUrl(): string | undefined {
    return process.env.VUE_APP_API_URL;
  }

  getReportApiUrl(): string | undefined {
    return process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;
  }

  formatCurrency(value: NonNullable<Parameters<typeof math.bignumber>[0]>): string {
    if (value) {
      const bn = math.bignumber(value).toDecimalPlaces(4);
      const v = bn.toNumber();
      return numberWithCommas(v);
    } else {
      return '0';
    }
  }

  formatAccounting(value: unknown, forceNegative?: boolean, useCommas = false): unknown {
    let tempValue = value;
    if (typeof value === 'string') {
      tempValue = value.replace(/,/g, '');
    }
    const v = Number(tempValue);
    if (v < 0 || forceNegative) {
      const _value = useCommas ? numberWithCommas(math.abs(v)) : math.abs(v);
      return '(' + _value + ')';
    } else {
      return useCommas ? numberWithCommas(v) : value;
    }
  }

  showErrorSnackbar(message: string): void {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'error',
      message: message,
    });
  }

  showSuccessSnackbar(message: string): void {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'success',
      message: message,
    });
  }

  toOrgLocalTime(timeSEC: number, short?: boolean): string {
    const tz = this.$store.state.currentOrg.timezone;
    const m = moment.unix(timeSEC).tz(tz);
    if (short) {
      return m.format('l hh:mm a');
    } else {
      return m.format('lll z');
    }
  }

  sym(): string {
    const bc = this.$store.state.currentOrg.baseCurrency;
    return getSymbolForCurrency(bc);
  }

  async cookieAuth(): Promise<MeResponse | undefined> {
    return doCookieAuth();
  }

  cloneDeep(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  formatNumber(value: string | number, decimalPlaces?: number) {
    const d = decimalPlaces || 2;
    if (value !== undefined && value !== '') {
      const vn = math.bignumber(value);
      const n = vn.toDecimalPlaces(d);
      const ret = n.toNumber().toLocaleString(undefined, {
        minimumFractionDigits: d,
        maximumFractionDigits: d,
      });
      return ret;
    } else {
      return '';
    }
  }

  getElapsedTime(startTime: number) {
    // if we want to register an event for report run time we can do it here
    return ((Date.now() - startTime) / 1000).toFixed(2);
  }
}
